import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {message, Modal, Button, Popconfirm, Table} from "antd";
import { SyncOutlined, ExclamationCircleOutlined } from "@ant-design/icons";


import Iconfont from "../../../../components/Iconfont/Iconfont";
import NoneData from "../../../../components/NoneData";
import {zuStandStore} from "../../../../zustand";
import {getSizeText} from "../../../../utils/tool";
import {fileLimitType} from "../../../../utils/defaultValue"
import {setKnowledgeState} from "../../../../features/editKnowledge/editKnowledgeReducer";
import "./index.scss";

const { confirm } = Modal;
const UploadFile = (props) => {
    const dispatch = useDispatch();

    const {
        fileDataList,
        okText,
        cancelText,
        onOk,
        onCancel,
        deleteFileFunc,
        isChoiceFile = true,
        choiceFileChange,
        isSelected = false,
        uploadType,
        cancelBtnDisabled = false,
        okBtnDisabled = false,
        name,
        defaultFileLists
    } = props;
    const history = useHistory();
    const uploadFileState = zuStandStore(state => state);
    const changeUploadStateFunc = uploadFileState.changeUploadStateFunc;
    const isCompleteUpload = uploadFileState.linkStatus === "closed";
    const [tableId, setTableId] = useState(`tableContentBox-${Math.random()}`);

    const columns = [
        // {
        //     dataIndex: "uploadType",
        //     key: "uploadType",
        //     title: "文件类型",
        //     align: "left",
        //     width: "80px",
        //     render: val => val === "knowledge" ? "知识文件" : "政策文件"
        // },
        {
            dataIndex: "name",
            key: "name",
            title: "文件名",
            align: "left",
        },
        {
            dataIndex: "size",
            key: "size",
            title: "大小",
            width: "100px",
            render: (val, record, idx) => {
                return getSizeText(val || 0, "b")
            },
        },
        {
            dataIndex: "status",
            key: "status",
            title: "状态",
            width: "120px",
            align: "left",
            render: (val, record, idx) => {
                let icon = "icon-time_fill";
                let text = "待上传";
                if (!isChoiceFile) {
                    // 上传状态
                    if (val === "success") {
                        icon = "icon-dui";
                        text = "上传成功";
                    } else if (val === "processing") {
                        let process = `${Number(Math.round(record.process * 10000) / 100)}%`;
                        icon = (<SyncOutlined spin />);
                        text = `上传${process}`;
                    } else if (val === "fail") {
                        icon = "icon-locationiconfail";
                        text = record.msg || "上传失败";
                    }

                    // 解析状态
                    else if(val === "parsingProcessing"){
                        icon = (<SyncOutlined spin />);
                        text = `解析中`;
                    }
                    else if(val === "parsingFail"){
                        icon = "icon-locationiconfail";
                        text = record.msg || "解析失败";
                    }

                    else if(val === "parsingSuccess"){
                        icon = "icon-dui";
                        text = record.msg || "解析成功";
                    }
                }

                return (
                    <span className="statusBox">{
                        (!isChoiceFile && (val === "processing" || val === "parsingProcessing")) ? icon : 
                        (<Iconfont type={icon} />)}{text}</span>
                )
            },
        },
        {
            dataIndex: "operate",
            key: "operate",
            title: "操作",
            width: "100px",
            render: (val, record, idx) => {
                const {status, fileId, uploadType} = record;
                return (
                    <div className="uploadOperateBox">
                        {
                            (isChoiceFile || status === "fail" || status === "parsingFail"  || status === "parsingSuccess") ? (
                                <Popconfirm
                                    placement={"left"}
                                    title={"确定删除吗？"}
                                    okText={"确定"}
                                    cancelText={"取消"}
                                    onConfirm={() => deleteSelectFileFunc(record)}
                                    overlayClassName={"deletePupConfirmBox"}
                                >
                                    <span className="operateBtn deleteBtn">删除</span>
                                </Popconfirm>
                            ) : (
                                (status === "success" && uploadType != "parsingFiles") ? 
                                (<span className="operateBtn shareBtn" onClick={() => shareFunc(record)}>分享</span>) : 
                                "-"
                            )
                        }
                    </div>
                )
            },
        },
    ];
    const [fileList, setFileList] = useState([
        // {fileId: 0, name: "adfad", status: "success",},
        // {fileId: 1, status: "fail",},
        // {fileId: 2, status: "processing", process: "40%"},
        // {fileId: 3, status: "", process: "40%"},
    ]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [tableHeight, setTableHeight] = useState(0);

    useEffect(() => {
        let dom = document.getElementById(tableId);
        if (dom) {
            setTableHeight(dom.clientHeight);
        }
    })

    useEffect(() => {
        console.log(fileList,"fileListfileList")
    }, [fileList])

    useEffect(() => {
        if(defaultFileLists){
            selectFilesChangeFunc(defaultFileLists, state)
        }
    }, [defaultFileLists])
    
    useEffect(() => {
        setFileList([...(fileDataList || [])]);
    }, [fileDataList])

    useEffect(() => {
        console.log(fileList,"fileListfileList")
        // 默认全选
        // if((okText === "批量分享" || okText === "分享"  || okText === "开始对话") && !isChoiceFile && !okBtnDisabled && isCompleteUpload){
            let fileIds = [];
            fileList.map(item=>{
                if(item.status === "parsingSuccess" || item.status === "success"){
                    fileIds.push(item.fileId)
                }
            })
            setSelectedKeys(fileIds)
        // }
    }, [okText, isChoiceFile, okBtnDisabled, isCompleteUpload])
    
    // 已选择文件
    async function selectFilesChangeFunc(fileList, state, e) {

        let list = [...state.fileList];

        for (const item of fileList) {
            // accept 配置的文件类型，无法拦截拖动上传的文件
            if (fileLimitType.includes(item.type)) {
                let encodeName = window.encodeURI(item.name);
                let idStr = `${item.lastModified}-${encodeName}-${item.size}-${item.type}-${Date.now()}`;
                item.fileId = window.btoa(idStr);
                list.push(item);
            }
        }

        setFileList(list);
        if(e){
            e.target.value = "";
        }
    }

    // 分享文件
    function shareFunc (file) {
        const fileOtherData = uploadFileState.fileOtherData;
        let {fileId, name: fileName} = file;
        const lastDotIndex = fileName.lastIndexOf(".");
        let fileType = fileName.slice(lastDotIndex + 1).toLowerCase();
        fileName = fileName.slice(0, lastDotIndex);
        const value = [{
            fileName,
            fileId: fileOtherData[fileId].fId || fileOtherData[fileId].fileId,
            fileType,
            id: fileId,
            knowledgeId:fileOtherData[fileId].knowledgeId || fileOtherData[fileId].knowledgeManagementId,
        }];
        // dispatch(setKnowledgeState({
        //     knowledgeList:value,
        //     editType: "share", //share 分享 补充文章信息。updateFile 更新
        // }))
        sessionStorage.setItem("editFileVlue", JSON.stringify({
            knowledgeList:value,
            editType: "share", //share 分享 补充文章信息。updateFile 更新
        }))
        // localStorage.setItem("editFileVlue",JSON.stringify(value))
        window.open(`/page/editKnowledge`);
    }

    // 删除文件
    function deleteSelectFileFunc (file) {
        // 删除文件

        if (isChoiceFile) {
            let idx = fileList.findIndex(item => item.fileId === file.fileId);
            if (idx > -1) {
                let newFileList = [...fileList];
                newFileList.splice(idx, 1);
                setFileList(newFileList);
            }
        } else {
            if (deleteFileFunc) {
                deleteFileFunc(file);
            }
        }

        if(selectedKeys.indexOf(file.fileId) != -1){
            selectedKeys.splice(selectedKeys.indexOf(file.fileId), 1);
            setSelectedKeys(selectedKeys)
        }
        
    }

    // 确定上传文件
    function uploadFile (state) {
        const {fileList,} = state;
        if (fileList.length < 1) {
            message.warning("请选择需要上传的文件！");
            return;
        }
        const {
            fileProcessObject, fileUploadStatus, uploadFileList, fileBelongSpaceObject,
            choiceFileModalIsShow, selectedSpaceTypeId
        } = uploadFileState;
        let processObject = {};
        let fileStatus = {};
        let fileBelongSpace = {};
        const enableRecord = choiceFileModalIsShow && selectedSpaceTypeId;
        let list = [...uploadFileList];
        let newFileList = [];
        for (const item of fileList) {
            const {fileId} = item;
            processObject[fileId] = 0;
            fileStatus[fileId] = "processing";
            if (enableRecord) {
                fileBelongSpace[fileId] = selectedSpaceTypeId;
            }
            item.uploadType = uploadType || "common";
            newFileList.push(item);
        }
        list.push({
            uploadType,
            fileList: newFileList,
        })

        const newState = {
            uploadFileList: list,
            fileProcessObject: {
                ...fileProcessObject,
                ...processObject,
            },
            fileUploadStatus: {
                ...fileUploadStatus,
                ...fileStatus,
            },
            fileBelongSpaceObject: {
                ...fileBelongSpaceObject,
                ...fileBelongSpace,
            },
            uploadOpen: true,
            modalStatus: "big",
        };
        changeUploadStateFunc(newState);
        cancelFunc();
    }

    const cancelFunc = (state) => {
        if (onCancel) {
            onCancel(state);
        }
    }

    const confirmFunc = (state) => {
        if (onOk) {
            onOk(state);
        } else if(isChoiceFile) {
            uploadFile(state);
        }
    }

    const synchronizeModel = async(state) => {
        console.log(state,uploadFileState.fileOtherData,"statestatestate")
        let ids = [];
        state.selectedKeys.map(item=>{
            ids.push(uploadFileState.fileOtherData[item].fId || uploadFileState.fileOtherData[item].fileId)
        })

        confirm({
            title: `确定同步选中的${ids.length}条数据至大模型吗？`,
            icon: <ExclamationCircleOutlined />,
            content: '',
            onOk: ()=>{
                return new Promise(async (resolve, reject) => {
                    try{
                        const res = await Api.AIModel.reSyncFile({data:ids});
                        resolve();
                        if(res&&res.code === 0){
                            message.success("同步成功")
                        }
                    }catch(erro){
                        reject();
                    }
                }).catch(() => {console.log('Oops errors!');});
            },
            onCancel() {},
        });
    }

    const state = {
        fileList,
        selectedKeys,
    };

    return (
        <div className="uploadFileComponentBox">
            {/* head */}
            <div className="uploadBtn">
                <div className={`choiceFileBtn ${isChoiceFile ? "" : "disabledChoiceFile"}`}>
                    <span className="btnText">选择文件</span>
                    {/* 文件选择器 */}
                    {
                        isChoiceFile ? (
                            <input
                                id="fileInput"
                                type="file"
                                multiple={true}
                                accept={fileLimitType.join(",")}
                                onChange={(e) => selectFilesChangeFunc(e.target.files, state, e)}
                            />
                        ) : undefined
                    }

                </div>
                <span className="tipText">支持批量上传文件，压缩包仅支持ZIP格式</span>
            </div>
            <div className="tableContentBox" id={tableId}>
                <Table
                    rowKey="fileId"
                    columns={columns}
                    bordered={false}
                    pagination={false}
                    dataSource={fileList}
                    rowSelection={isSelected ? {
                        type: "checkBox",
                        selectedRowKeys: selectedKeys,
                        onChange: setSelectedKeys,
                        getCheckboxProps: (record) => ({
                            disabled: (record.status === "fail" || record.status === "parsingFail") || (
                                record.uploadType != 'knowledge' && 
                                record.uploadType != 'parsingFiles'
                            ), 
                            // Column configuration not to be checked
                        }),
                    }: null}
                    scroll={{y: tableHeight}}
                    locale={"点击上方”选择文件”或将文件拖拽到此区域"}
                />
                {
                    (fileList?.length < 1 && isChoiceFile) ? (
                        <div className="dragFileBox">
                            <span className="tipText">点击上方”选择文件"或将文件拖拽到此区域</span>
                            <input
                                onDrop={(e, a) => {
                                    e?.preventDefault();
                                    const files = e.dataTransfer.files;
                                    selectFilesChangeFunc(files, state, e)
                                }}
                                id="fileInput2"
                                type="file"
                                multiple={true}
                                accept={fileLimitType.join(",")}
                                onChange={(e) => selectFilesChangeFunc(e.target.files, state, e)}
                            />
                        </div>
                    ) : undefined
                }

            </div>
            {/* footBox */}
            <div className="footBox">
                {/* {uploadType != "parsingFiles" && */}
                <span
                    className={`operateBtn cancelBtn ${cancelBtnDisabled ? "disabled" : ""}`}
                    onClick={() => {
                        if (cancelBtnDisabled) {
                            return
                        }
                        cancelFunc(state);
                    }}
                >{cancelText || (isChoiceFile ? "取消上传" : "查看文件")}</span>
                {/* // } */}
                
                <span
                    className={`operateBtn confirmBtn ${(okBtnDisabled || fileList.length < 1 || (!isChoiceFile && (!isCompleteUpload || selectedKeys.length < 1))) ? "disabled" : ""}`}
                    onClick={() => {
                        if (okBtnDisabled) {
                            return
                        }
                        if (isChoiceFile) {
                            if (fileList.length < 1) {
                                return message.warning("请选择文件上传");
                            }
                        } else if (!isCompleteUpload || selectedKeys.length < 1) {
                            return;
                        }
                        console.log(state,"statestatestate")
                        confirmFunc(state);
                    }}
                >{okText || (isChoiceFile ? "点击上传" : "批量分享")}</span>
                {/* {!isChoiceFile&&<span
                    className={`operateBtn confirmBtn ${(okBtnDisabled || fileList.length < 1 || (!isChoiceFile && (!isCompleteUpload || selectedKeys.length < 1))) ? "disabled" : ""}`}
                    onClick={() => {
                        synchronizeModel(state)
                    }}
                >同步至大模型</span>} */}
            </div>
        </div>
    );
};

UploadFile.propTypes = {
    fileDataList: PropTypes.array,
    deleteFileFunc: PropTypes.func,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    uploadType: PropTypes.string,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    choiceFileChange: PropTypes.func,
    isChoiceFile: PropTypes.bool,
    isSelected: PropTypes.bool,
    cancelBtnDisabled: PropTypes.bool,
    okBtnDisabled: PropTypes.bool,
}

export default UploadFile;
