import React, { useState, useEffect} from 'react';
import Search from "./components/search";
import HotSpot from "./components/hotSpot";
import KnowledgeExploration from "./components/knowledgeExploration";
import AiBase from "./components/AiBased";
import RecommendKnowledge from "./components/recommendKnowledge";
import QuickEntrance from "./components/quickEntrance";
import OtherProjectCarousel from "./components/otherProjectCarousel";
import LatestKnowledge from "./components/latestKnowledge";

import "./index.scss";

const DefaultScheme = () => {

    return (
        <div className="defaultSchemePageFrame">
            <div className="leftBox">
                <div className="leftTopBox">
                    <div className="searchBox"><Search /></div>
                    <div className="hotSpotBox"><HotSpot /></div>
                </div>
                <div className="leftMiddleBox">
                    <div className="knowledgeExplorationBox"><KnowledgeExploration /></div>
                    <div className="aiBaseBox"><AiBase /></div>
                </div>
                <div className="leftBottomBox"><RecommendKnowledge /></div>
            </div>

            <div className="rightBox">
                <div className="rightTopBox"><QuickEntrance /></div>
                <div className="rightMiddleBox"><OtherProjectCarousel /></div>
                <div className="rightBottomBox"><LatestKnowledge /></div>
            </div>
        </div>
    );
}
export default DefaultScheme;
