import React,{useState} from 'react';
import PropTypes from 'prop-types';

const Iconfont = ({onClick,type,iStyle={}, className}) => {

    const [style, setStyle] = useState({
        verticalAlign: 'middle',
        position: 'relative',
        lineHeight: 1,
    })

    const clickFunc = (e) => {
        if (Object.prototype.toString.call(onClick) === "[object Function]") {
            onClick(e)
        }
    }

    return (<i style={{...style, ...iStyle}} className={`iconfont ${type} ${className}`} onClick={(e) => clickFunc(e)}></i>);
}

Iconfont.propTypes = {
    type: PropTypes.string.isRequired,
    iStyle: PropTypes.object,
};

export default Iconfont;
