import myAxios from '../utils/request.js';

export default {

    // 获取推荐知识或者最新知识
    async getRecommendOrLatestKnowledge (params={}, cb) {
        let res = await myAxios.getJson('/knowledgeManagement/getRecommendOrLatestKnowledge', params);
        return res.data;
    },

    // 获取热点知识
    async getHotKnowledge (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeManagement/getHotKnowledge', params);
        return res.data;
    },

    // 专家榜
    async getProfessorRanking (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeAnswerManagement/getProfessorRanking', params);
        return res.data;
    },

    // 精选问答
    async getHigherQuestionAnswer (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeQuestionManagement/higherQuestionAnswer', params);
        return res.data;
    },

    // 搜索热词
    async getHotWords (params={}, cb) {
        let res = await myAxios.postJson('/hotWord/pageList', params);
        return res.data;
    },

    // 删除热词
    async deleteHotWords (params={}, cb) {
        let res = await myAxios.postJson('/hotWord/delete', params);
        return res.data;
    },

    // 新增热词
    async addHotWords (params={}, cb) {
        let res = await myAxios.postJson('/hotWord/insert', params);
        return res.data;
    },

    // 修改热词
    async updateHotWords (params={}, cb) {
        let res = await myAxios.postJson('/hotWord/update', params);
        return res.data;
    },


    // 新增热点知识
    async addHotKnowledge (params={}, cb) {
        let res = await myAxios.postJson('/hotKnowledge/addByIds', params);
        return res.data;
    },
    // 查讯热点知识
    async getHotKnowledgeList (params={}, cb) {
        let res = await myAxios.postJson('/hotKnowledge/pageList', params);
        return res.data;
    },
    // 删除热点知识
    async deleteHotKnowledge (params={}, cb) {
        let res = await myAxios.postJson('/hotKnowledge/deleteByIds', params);
        return res.data;
    },
    // 修改热点知识
    async updateHotKnowledge (params={}, cb) {
        let res = await myAxios.postJson('/hotKnowledge/update', params);
        return res.data;
    },
    // 根据条件查询未添加至热点知识的数据
    async getNotHotKnowledge (params={}, cb) {
        let res = await myAxios.postJson('/hotKnowledge/pageNotHotKnowledge', params);
        return res.data;
    },
}
