import React, { useState, useEffect} from 'react';
import {Spin, Tooltip, Row, Col, Empty} from 'antd';
import { MinusSquareOutlined, PlusSquareOutlined, SyncOutlined } from '@ant-design/icons';

import Api from '../../../../../api'
import ViewImg from "../../../../../components/ViewImg";
import {goKnowledgeDetails} from '../../../../../utils/tool'
import {pagingParam} from "../../../../../utils/defaultValue";

import './index.scss';

const RecommendKnowledge = () => {
    const [title] = useState();

    const [colSpan, setColSpan] = useState(12);

    const [recommendedKnowledge, setRecommendedKnowledge] = useState([]);
    const [loadingState, setLoadingState] = useState(false);

    const [openZipKey, setOpenZipKey] = useState([]);
    
     // 图片预览列表
    const [viewImgList, setViewImgList] = useState([]);
    const [isShowViewImg, setIsShowViewImg] = useState(false);
    const [viewImgIdx, setViewImgIdx] = useState(0);

    // 查询数据
    const [searchData, setSearchData] = useState({
        "pageIndex": 1,
        "pageSize": 6,
    });
    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "pages":0
    });

    useEffect(()=>{
        getKnowledgeList();
    },[searchData])

     // 知识列表
     const getKnowledgeList = async() => {
        setLoadingState(true);
        try{
            let res = await Api.Home.getHotKnowledgeList({data:{
                ...searchData,
                hotFlag:1
            }});
            setLoadingState(false);
            if(res && res.code === 0 && res.data && res.data.records){
                let _records = res.data.records;
                setRecommendedKnowledge(_records);
                setPagination({
                    pageIndex: res.data.current,
                    pageSize: res.data.size,
                    pages:res.data.pages,
                })
            }else{
                setRecommendedKnowledge([]);
            }
        }catch{setLoadingState(false);}
    }

    const openZipList = (item, knowledgeId) => {
        let _openZipKey = [...openZipKey];

        if(_openZipKey.includes(knowledgeId)){
            let newOpenZipKey = _openZipKey.filter((id)=>id != knowledgeId);

            setViewImgList([]);
            setOpenZipKey(newOpenZipKey)
        }else{
            let urlList = [];

            item.childList.map((childItems,index)=>{
                if(childItems.fileUrl){
                    urlList.push({
                        url:childItems.fileUrl,
                        name:childItems.knowledgeName,
                        id:index
                    })
                }
            })

            setViewImgList([...urlList]);

            _openZipKey.push(knowledgeId)
            setOpenZipKey(_openZipKey)
        }
    }

    const getChildrenList = (item) => {
        
        return(
            <div className={`childrenlistBox`}>
                {item.childList.map((childItem,index)=>{

                    if(childItem.fileUrl){
                        childItem.id = index
                    }

                    return(
                        <div className="childrenlistItemHeader"
                            key={index}
                            onClick={()=>{
                                
                                if(childItem.fileUrl){
                                    setViewImgIdx(childItem.id);
                                    setIsShowViewImg(true);
                                }else if(childItem.id){
                                    goKnowledgeDetails({
                                        ...item,
                                        type,
                                        selecthValue
                                    },childItem)
                                }
                            }}
                        >
                            {/* 文件图标 */}
                            <KnowledgeFileIcon
                                fileType={childItem.fileType}
                                isPic={childItem.isPic}
                            />

                            {/* 标题 */}
                            <div className="listTitle" title={childItem.knowledgeName}>{childItem.knowledgeName}</div>
                        </div>
                    )
                })}
            </div>
        )
    }

    const exchangeList = () => {
        // 热词
        let _searchData = {...searchData}
        if(_searchData.pageIndex >= pagination.pages){
            _searchData.pageIndex = 1
        }else{
            _searchData.pageIndex = _searchData.pageIndex+1
        }
        setSearchData(_searchData)
    }

    return (
        <div className="recommendKnowledgeFrame">
            <div className="title">推荐知识
                <div className="exchange" onClick={exchangeList}>
                    <SyncOutlined />换一换
                </div>
            </div>
            <Spin tip="数据加载中" spinning={loadingState}>
                <div className="recommendKnowledge">
                {recommendedKnowledge && recommendedKnowledge.length > 0 ?<Row gutter={[8, 8]}>
                    {recommendedKnowledge.map(item=>{
                        let knowledgeId = item.knowledgeId;
                        
                        return <Col span={colSpan} key={knowledgeId}><div className="recommendKnowledgeItem"  onClick={()=>{
                            goKnowledgeDetails({
                                ...item,
                                type:'home'
                            })
                        }}>
                            <div className="knowledgeImg">
                                <img src={item.fileUrl}/>
                            </div>
                            <div className="knowledgeDetails">
                                <div className="title" title={item.knowledgeName}>
                                    {item.knowledgeName}
                                </div>

                                <div className="detailsItem">
                                    <span className="detaileItemTitle">知识类型</span>
                                    <span className="detaileItemText">{item.knowledgeTypeName}</span>
                                </div>
                                <div className="detailsItem">
                                    <span className="detaileItemTitle">作者</span>
                                    <span className="detaileItemText" title={item.authors}>{item.authors}</span>
                                </div>
                                <div className="detailsItem">
                                    <span className="detaileItemTitle">创建时间</span>
                                    <span>{item.knowledgePublishTime}</span>
                                </div>

                                {/* 展开按钮 */}
                                {item.fileType === 'zip' && item.childList &&
                                    <>{openZipKey.includes(knowledgeId)?
                                        <Tooltip  title="收起">
                                            <MinusSquareOutlined
                                                className="openZipIcon"
                                                onClick={(e)=>{
                                                    openZipList(item,knowledgeId);
                                                    e.stopPropagation();
                                                }}
                                                style={{marginTop: 4}}
                                            />
                                        </Tooltip>:
                                        <Tooltip  title="查看更多文件">
                                            <PlusSquareOutlined 
                                                className="openZipIcon"
                                                onClick={(e)=>{
                                                    openZipList(item,knowledgeId);
                                                    e.stopPropagation();
                                                }}
                                                style={{marginTop: 4}}
                                            />
                                        </Tooltip>
                                    }</>
                                }
                            </div>

                            {/* ZIP下的文件列表 */}
                            {openZipKey.includes(knowledgeId)&&item.childList&&item.childList.length>0&&<>
                                {getChildrenList(item)}
                            </>}
                        </div></Col>
                    })}
                    </Row> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </div>
            </Spin>
            <ViewImg
                viewImgList={viewImgList}
                isShowViewImg={isShowViewImg}
                viewImgIdx={viewImgIdx}
                closeViewImg={() => setIsShowViewImg(false)}
            />
        </div>
    )
}

export default RecommendKnowledge;
