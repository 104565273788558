import React , {useEffect, useState} from 'react';
import { Modal, Button, Form, Input, message, Cascader} from 'antd'
import { PlusSquareOutlined, FileSearchOutlined, SearchOutlined, DownOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from "react-redux";
import { fetchEventSource } from '@microsoft/fetch-event-source';


import AIModal from "../../../components/AIModal";
import { setAIModalState } from "../../../features/AIIntelligent/AIReducer";
import HistoryTopic from '../../../components/AIModal/components/HistoryTopic/index'
import BackIcon from '../../../components/BackIcon';
import storage from "../../../utils/storage";
import AddFiles from '../components/AddFiles';
import FileList from '../components/FileList';
import Api from '../../../api';
import './index.scss';



const ReadAssistant = () => {
    let recQuestionsSource = null
    const dispatch = useDispatch();
    
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const historyTopicId = useSelector(state => state.AIModal.historyTopicId);
    
    const [historyId, setHistoryId] = useState('');
    const [relQuestionsList, setRelQuestionsList] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const [showAiModal, setShowAiModal] = useState(false);

    const [knowledgeData, setKnowledge] = useState([]);
    const [rangeName , setRangeName] = useState('整个知识库');
    const [rangeIds , setRangeIds] = useState([]);

    useEffect(()=>{
        if(historyTopicId){
            setHistoryId(historyTopicId);
            setShowAiModal(true);
        }else{
            getRecQuestions();
            setShowAiModal(false);
        }
    },[historyTopicId])

    useEffect(()=>{
        getInitDate();
    },[])

    const getInitDate = async() => {
        const res = await Api.Knowledge.getAllKnowledger({});
        if(res && res.code === 0){
            let _data = []
            res.data.map(item=>{
                item.isLeaf = false;
                item.name = item.knowledgeTypeName
                _data.push(item)
            })
            
            setKnowledge(_data); 
        }
    }
    


    // 获取推荐问题
    const getRecQuestions = (param = {}) => {
        recQuestionsSource = new EventSource('/api/largeModelQa/qa');
        let data = {
            query: "推荐问题",
            module: 'summary',
            questionLocation: 2,
            relQuestions:2,
            query:"推荐问题",
        };
        
        fetchEventSource("/api/largeModelQa/qa", {
            openWhenHidden:true,
            method: 'POST',
            headers: {
                "Content-Type": 'application/json',
                "Accept": 'text/event-stream',
                "satoken": storage.getItem("token")
            },
            body: JSON.stringify(data),
            onmessage(event) {
                // 在这里操作流式数据
                console.info("接受到回复：",event.data);
                try {
                    const data = event.data;
                    let decodeData, messageInfo;
        
                    if(data){
                        decodeData = JSON.parse(data);
                    }else{
                        console.error("数据格式错误");
                        return false;
                    }
                    
                    if(decodeData){
                        messageInfo = JSON.parse(decodeData.data);
                    }else{
                        console.error("数据格式错误");
                        return false;
                    }

                    if(messageInfo.code != 0){
                        console.error("数据格式错误");
                        return false;
                    }
                    
                    
                    if (messageInfo.data) {
                        let _data = messageInfo.data;
                        
                        if(_data.isEnd){
                            // 关闭连接
                            closeConnection();
        
                        }
                        const {relQuestions } = _data;

                        if(relQuestions){
                            setRelQuestionsList(relQuestions || [])
                        }else{
                            console.log("没有推荐问题")
                        }
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            onclose(ee) {
                // 关闭流
                console.log("关闭")
            },
            onerror(error) {
                console.info(error);
                throw error;
                // return 5000;
                // 如果抛出时间，则间隔多少时间重连
                //返回流报错
            }
        })
    }

    // 关闭会话
    const closeConnection = () => {
        console.log(recQuestionsSource)
        if(recQuestionsSource){
            recQuestionsSource.close();
        }
    }

    const addTopicBtn = () => {
        setIsModalOpen(true);
    }

    const handleOk = async() => {
        try {
            const row = await form.validateFields();
            sendApi(row,(id)=>{
                setIsModalOpen(false);
                setHistoryId(id)
            })
        }catch(e){
            console.log(e)
        }
    };

    const sendApi = async(param,calback) => {
        try {
            const res = await Api.Chat.createQa({
                data:{
                    ...param,
                    questionLocation:0 // 0文档中的问答， 1知识中心 ， 2ES搜索界面，3查讯全部
                }
            })
            if(res.code === 0){
                message.success("新建成功");
                if(calback){
                    calback(res.data)
                }
            }
        }catch(e){
            console.log(e)
        }
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const calbackIds = (ids)=>{
        console.log(ids,"idsidsids")
        sendApi({
            knowledgeIds: ids
        },(id)=>{
            setHistoryId(id);
            dispatch(setAIModalState({
                historyTopicId:id,
                historyTitle:''
            }))
        })
    }

    const onSearch = () => {
        if(searchValue){
            dispatch(setAIModalState({
                questionLocation:2,
                questionModule:"ragChat",
                QAtype:"sum",
                sendQuestionText:searchValue,
                knowledgeEsParam:{
                    // "text":searchValue,
                    "departmentIds":rangeIds[1]?[rangeIds[1]]:[],
                    "knowledgeTypeIds":rangeIds[0]?[rangeIds[0]]:[],
                    "searchResearchReport":false
                }
            }))
            setShowAiModal(true);
        }else{
            message.info('请输入需要搜索的内容');
        }
    }

    const onChange = (value, selectedOptions) => {
        console.log(value, selectedOptions);
        let text = ""
        selectedOptions.map(item=>{
            text = text === "" ? text +''+ item.name : text +' - '+ item.name
        })
        setRangeName(text);
        setRangeIds(value)
    };

    const loadData = async(selectedOptions) => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;

        const res = await Api.Department.getDepartmentListAll({data:{
            knowledgeRel: 0,
            knowledgeTypeIds: [targetOption.id]
        }});
        if(res&&res.code === 0){
            targetOption.loading = false;
            targetOption.children = res.data;
            setKnowledge([...knowledgeData]);
        }
    };

    return (<div className='searchAssistantFrame' id='searchAssistantFrame'>
        <div className='header'>
            <div className='topTitle'>
                <span>知识检索助手</span>
                <BackIcon/>
            </div>
        </div>
        <div className='readContent'>
            <div className='left'>
                <HistoryTopic 
                    type={"clickEffect"} 
                    questionLocations={[1,2]} 
                    onClickHistoryDetails={(id, title)=>{
                        setHistoryId(id);
                        dispatch(setAIModalState({
                            historyTopicId:id,
                            historyTitle:title,
                            knowledgeEsParam:{},
                            selectKnowledgeParam:{},
                            knowledgePublishedParam:{},
                        }))
                        setShowAiModal(true);
                    }}
                    onDeleteTopic={(type)=>{
                        if(type){
                            // 选中的对话记录被删除
                            setHistoryId('');
                            dispatch(setAIModalState({
                                historyTopicId:null,
                                historyTitle:''
                            }))
                            setShowAiModal(false);
                        }
                    }}
                    historyId={historyId}
                />
                
                <div className='addTopicBtnBox'>
                    <Button onClick={addTopicBtn} className='addTopicBtn' type="primary" icon={<PlusSquareOutlined />}> 新建对话</Button>
                </div>
            </div>
            <div className='right'>
                {showAiModal?<AIModal searchValue={searchValue} historyTopicId={historyId}/>:
                <div className='aiSearchFrame'>
                    <div className='centerText'>
                        <div className='fileIcon'>
                            <FileSearchOutlined />
                        </div>
                        <div>
                            <p>知识检索助手</p>
                            <p className='describe'>输入你需要搜索的问题，为你找到相应的文件</p>
                        </div>
                    </div>
                    <div className='aiSearchBox'>
                        <Input placeholder='输入你想搜索的问题' value={searchValue} onChange={(e)=>{
                            setSearchValue(e.target.value)}}/>
                        <div className='footerBtn'>
                            
                            <Cascader 
                                options={knowledgeData} 
                                loadData={loadData} 
                                onChange={onChange} 
                                changeOnSelect 
                                fieldNames={{
                                    label: 'name',
                                    value: 'id'
                                }}
                            >
                                <div className='searchType'><span className='sign'></span>{rangeName}<DownOutlined /></div>

                            </Cascader>
                            
                            <Button type="primary" icon={<SearchOutlined />} onClick={onSearch}>搜索</Button>
                        </div>
                    </div>
                    <div className='recQuestions'>
                        {relQuestionsList?.length > 0 ? relQuestionsList.map(item=>{
                            return <div className='recQuestionsItem' onClick={()=>{
                                setSearchValue(item)
                            }}>{item}</div>
                        }):null}
                    </div>
                </div>}
            </div>
        </div>

        <Modal title="新建对话" centered={true} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Form
                name="basic"
                form={form}
                labelCol={{span: 6}}
                wrapperCol={{span: 16}}
                initialValues={{remember: true}}
                autoComplete="off"
            >
                <Form.Item
                    label="对话标题"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: '请输入对话标题!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    </div>)
}

export default ReadAssistant