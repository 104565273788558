import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
const NoteEllipsis = ({ text, noteBelongMessage }) => {
  const deta = useRef();
  const theOriginalRef = useRef();
  
  const [showTotal, setShowTotal] = useState(true);
  const [changeBtn, setChangeBtn] = useState(true);
  const [isShowBtn, setIsShowBtn] = useState(true);

  const [moreText, setMoreText] = useState(true);
  const [moreButton, setMoreButton] = useState(true);
  const [showMore, setShowMore] = useState(true);

  useEffect(() => {
    if(noteBelongMessage){
      calculateOriginal();
    }
    calculateText();
  }, []);

  const isShowTotal = (e) => {
    e.stopPropagation();
    setShowTotal(!showTotal);
    setChangeBtn(!changeBtn);
  };

  const calculateText = () => {
    let abstractHeight = window
      .getComputedStyle(deta.current)
      .height.replace("px", "");
    if (parseInt(abstractHeight) > 63) {
      setChangeBtn(true);
      setShowTotal(false);
      setIsShowBtn(true);
    } else {
      setChangeBtn(false);
      setShowTotal(true);
      setIsShowBtn(false);
    }
  };

  const calculateOriginal = () => {
    let theOriginalHeight = window.getComputedStyle(theOriginalRef.current).height.replace("px", "");
    if (parseInt(theOriginalHeight) > 60) {
      setMoreButton(true);
      setMoreText(true);
    } else {
      setMoreButton(false)
      setMoreText(false);
    }
  };

  const changeBtnStatus = (e) => {
    e.stopPropagation();
    setMoreText(!moreText);
    setShowMore(!showMore)
  }

  return (
    <div className="noteManagePageFrame">
      <div className={showTotal ? "upper-part" : "pack-up"}>
        <div className="abstract">
          <span className="content-deta" ref={deta}>{text}</span>
          {isShowBtn?<div className="unfold" onClick={isShowTotal}>
            <p>{changeBtn ? "...展开" : "收起"}</p>
          </div>: null}
        </div>
      </div>
      {noteBelongMessage?(<div className={moreText ? "theOriginalMore theOriginal" : "theOriginal"}>

      <div ref={theOriginalRef}>{noteBelongMessage}</div>

      {moreButton ? <span className="moreBtn" onClick={changeBtnStatus}>{showMore? "... 展开" : "收起"}</span> : null}

      </div>):null}
      
    </div>
  );
};

export default NoteEllipsis;