import React, { useEffect, useState, useRef } from "react";
import { Typography, message, Space, Button, Input, Form } from "antd";
import moment from "moment";

import Api from "../../../../api";
import SelfTable from "../../../../components/SelfTable/index"
import KnowledgeFileIcon from "../../../../components/KnowledgeFileIcon";
import NoneData from "../../../../components/NoneData";
import { ModalConfirm, getSearchParams, jumpToPage } from "../../../../utils/tool";
import ViewImg from "../../../../components/ViewImg";

const { Title } = Typography;
const { Search } = Input;

const PreviewFile = ({detailsData, updateFileId}) => {

    const viewImgType = ["png", "jpg", "jpeg", "gif", "bmp", "titf", "psd", "raw", "eps", "svg"];
    const containerRef = useRef(null);
    const [jsSDK, setJsSDK] = useState(null);

    // 图片预览列表
  const [viewImgList, setViewImgList] = useState([]);
  const [isShowViewImg, setIsShowViewImg] = useState(false);
  const [viewImgIdx, setViewImgIdx] = useState(0);

    useEffect(() => {
        if(jsSDK && document.querySelector(".custom-mount")){
            document.querySelector(".custom-mount").innerHTML = "";
        }
        
        if(detailsData.relationFileId && detailsData.fileType != "zip"){
            getWPSFileUrl();
        }else if (detailsData.fileType === "zip") {
            const imgList = (detailsData.unzipFile || []).filter(item => viewImgType.includes(String(item.type).toLowerCase()));
            setViewImgList(imgList);
        } 
    }, [detailsData.relationFileId])

    useEffect(() => {
        if(detailsData.fileType === "zip"){
            if(jsSDK && document.querySelector(".custom-mount")){
                document.querySelector(".custom-mount").display = "none";
            }
        }
    }, [detailsData.fileType])

    useEffect(()=>{
        if(updateFileId){
            if(jsSDK){
                document.querySelector(".custom-mount").innerHTML = "";
            }
            getWPSFileUrl(updateFileId);
        }
    },[updateFileId])

    const isMobile = () => {
        let userAgentInfo = navigator.userAgent;
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(userAgentInfo);
    }

    // 获取WPS内容
    const getWPSFileUrl = async (updateFileId) => {
        try {
            let res = null;
            if(updateFileId){
                res = await Api.Knowledge.getPreviewUrlByFileId({ data:{fileId: updateFileId}})
            }else{
                res = await Api.Knowledge.getWPSFileEdit({
                    fileId: detailsData.relationFileId,
                });
            }
           
            if (res && res.code === 200 && res.data) {
                const container = containerRef.current;
                const data = JSON.stringify({
                    tagName: container.tagName,
                    className: container.className,
                    // 在这里可以提取其他需要的HTML属性
                });
                const jsSdk = OpenSDK.config({
                    url: res.data.link,
                    mount: document.querySelector(".custom-mount"),
                });
                if (jsSdk.iframe) {
                    jsSdk.iframe.width = "100%";
                    jsSdk.iframe.height = "100%";
                }
                setJsSDK(jsSdk);
            }
        } catch (error) {}
    };

    // 获取wps地址
    async function getFileViewUrl (fileId, type, viewImgList, fileItem) {
        if (viewImgType.includes(String(type).toLowerCase())) {
            if (!isMobile()) {
                const idx = viewImgList.findIndex(item => item.id === fileId);
                setViewImgIdx(idx);
                setIsShowViewImg(true);
            }
            return
        }

        if (fileItem.knowledgeId) {
            jumpToPage({
                ...fileItem,
                "selecthValue":null,
                "id":"",
                "fileType":type
            });
        }else{
            message.info("当前文件类型不支持")
        }
    }

    const unzipFileList = detailsData?.unzipFile || [];

    return <div className="previewFileFrame">
        {
          detailsData.fileType === "zip" ? (
              <div className="zipChildFileList">
                <div className="scrollBox">
                  {
                    unzipFileList.length > 0 ? unzipFileList.map(item => {
                      const {id, name, createTime, type} = item;
                      return (
                          <div
                              className="fileItem"
                              key={id}
                              onClick={() => getFileViewUrl(id, type, viewImgList,item)}
                          >
                            <div className="infoBox">
                              <KnowledgeFileIcon fileType={type} />
                              <span className="fileName overflowEllipsis">{name}</span>
                            </div>
                            <span className="timeText">{moment(createTime).format("YYYY-MM-DD HH:mm:ss")}</span>
                          </div>
                      )
                    }) : (
                        NoneData({text: "无文件"})
                    )
                  }
                </div>
              </div>
          ) : null
        }
        <div style={{display: detailsData.fileType === "zip" ? "none" : "block"}} className="custom-mount" ref={containerRef}></div>
        
        <ViewImg
          viewImgList={viewImgList}
          isShowViewImg={isShowViewImg}
          viewImgIdx={viewImgIdx}
          closeViewImg={() => setIsShowViewImg(false)}
        />
    </div>;
}
export default PreviewFile;