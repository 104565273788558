import React, { useState, useEffect, useRef } from 'react';
import { Spin, Typography, Modal, message, Tag, Space } from 'antd';
import * as marked from "marked";

import Api from "../../../../api";
import SelfTable from "../../../../components/SelfTable"
import './index.scss'

const { Link } = Typography;
const DocumentMode = () => {

    const [listData, setListData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [detailsLoading, setDetailsLoading] = useState(false);
    const columns = [
        {
            title: '文件类型',
            dataIndex: 'type',
            width: '',
        },
        {
            title: '分片模式',
            dataIndex: 'typeName',
            width: '',
            // render: (_, record) => {
            //     return record.processTypes.map(item=>item.typeName).join(",")
            // }
        },
        // {
        //     title: '当前分片模式',
        //     dataIndex: 'description',
        //     width: '150px',
        //     render: (_, record) => {
        //         return <Switch 
        //         checkedChildren="开启" 
        //         unCheckedChildren="关闭" 
        //         // 0是 1 不是
        //         checked={record.isDefault === 0}
        //         onChange={(checked)=>{
        //             if(checked){
        //                 onChangeType(record)
        //             }else{
        //                 message.info("必须设置一个默认分片模式")
        //             }
        //         }}/>
        //     }
        // },
        {
            title: '操作',
            width: '150px',
            dataIndex: 'operation',
            render: (_, record) => {
                return <Space size={"large"}>
                <Link onClick={() => edit(record)}>
                    调整
                </Link>
            </Space>
            }
        },
    ];

    // 当前正在操作的数据KEY
    const [fragmentationtData, setFragmentationtData] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedRowKey, setSelectedRowKey] = useState('');
    const fragmentationColumns = [
        {
            title: '名称',
            dataIndex: 'typeName',
            width: '200px',
        },
        {
            title: '描述',
            dataIndex: 'sketch',
            width: '',
        },
        {
            title: '操作',
            width: '150px',
            dataIndex: 'operation',
            render: (_, record) => {
                return <Space size={"large"}>
                <Link onClick={()=>getDetails(record.typeId)}>
                    详情
                </Link>
            </Space>
            }
        },
    ];


    const [modeListStatus, setModeListStatus] = useState(false);
    

    useEffect(() => {
        getList();
    }, []);

    useEffect(() => {
        getList();
    }, [modeListStatus]);

    // 修改
    const edit = (record) => {
        setFragmentationtData(record.processTypes);
        setEditingKey(record.type);
        setEditModalOpen(true);
    };
    const getList = async() => {
        setTableLoading(true);
        try{
            const res = await Api.AIModel.getProcessType({data:{isAll:modeListStatus}});
            setTableLoading(false);
            if(res&&res.code === 0){
                let _data = res.data;
                setListData(_data.fileTypeProcessTypes);
            }else{
                setListData([]);
            }
        }catch(e){setTableLoading(false);}
    }

    const getDetails = async(typeId) => {
        setIsModalOpen(true);
        setDetailsLoading(true);
        try{
            const res = await Api.AIModel.getProcessTypeDetail({data:{typeId}});
            setDetailsLoading(false);
            if(res&&res.code === 0){
                const htmlString = marked.parse(res.data.description || "");
                // 将解析后的HTML字符串设置到页面的某个元素中
                document.getElementById('markedContent').innerHTML = htmlString;
            }else{
            }
        }catch(e){setDetailsLoading(false);}
    }

    const onChangeType = async(record) => {
        try{
            const res = await Api.AIModel.setProcessType({data: {
                "description": record.description,
                "typeId": record.typeId,
                "typeName": record.typeName,
            }});
            if(res&&res.code === 0){
                message.success("操作成功");
                getList();
            }else{
            }
        }catch(e){}
    }

    const handleOk = async() => {
        if(!selectedRowKey){
            message.info("必须设置一个默认分片模式");
        }
        try{
            const res = await Api.AIModel.setProcessType({data: {
                "fileType": editingKey,
                "typeName": selectedRowKey,
            }});
            if(res&&res.code === 0){
                setEditModalOpen(false);
                setSelectedRowKey('');
                getList();
                message.success("操作成功");
            }
        }catch(e){}
    }

    const switchModeList = () => {
        setModeListStatus(!modeListStatus)
    }
    
    return (
        <div className="documentModeFrame">
            <div className="title">
                文档切分模式 
                <Tag onClick={switchModeList} color={modeListStatus ? "default" : "processing"} className='switchModeList'>只显示常用模式</Tag>
            </div>
            <div className="table">
                <SelfTable
                    rowKey={"typeId"}
                    dataSource={listData}
                    columns={columns}
                    loading={tableLoading}
                    selfClassName="documentModeTable"
                />
            </div>

            <Modal 
                title="分片详情" 
                open={isModalOpen} 
                onCancel={()=>setIsModalOpen(false)}
                maskClosable={false}
                destroyOnClose={true}
                footer={null}
                width={700}
            >
                <Spin spinning={detailsLoading}>
                    <div id="markedContent" className='markedContent'></div>
                </Spin>
            </Modal>

            <Modal 
                title="选择分片模式" 
                open={editModalOpen} 
                onCancel={()=>{setEditModalOpen(false);setSelectedRowKey('');}}
                maskClosable={false}
                destroyOnClose={true}
                width={800}
                onOk={handleOk}
            >
                {editModalOpen&&<SelfTable
                    rowKey={"typeName"}
                    dataSource={fragmentationtData}
                    columns={fragmentationColumns}
                    tableBodyHight={400}
                    rowRadioSelection={{
                        type: 'radio',
                        selectedRowKey: selectedRowKey,
                        setSelectedRowKey:setSelectedRowKey,
                    }}
                />}
            </Modal>
        </div>
    )
}

export default DocumentMode;