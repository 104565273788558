import myAxios from '../utils/request.js';
import callback from "../utils/callback.js";

export default {
    
    /*
    * 部门知识贡献概览
    */ 
    async getDepartmentStatisticsList (params={}, cb) {
        let res = await myAxios.postJson('/departmentContributionManagement/pageList', params);
        return res.data;
    },

    // 导出
    async getDepartmentStatisticsExport (params={}, cb) {
        let res = await myAxios.downloadJson('/departmentContributionManagement/export', params);
        return res.data;
    },
    
    /*
    * 部门知识贡献统计
    */ 
    async getContributeStatisticsList (params={}, cb) {
        let res = await myAxios.postJson('/departmentKnowledgeStatisticsManagement/pageList', params);
        return res.data;
    },

    async getContributeStatisticsExport (params={}, cb) {
        let res = await myAxios.downloadJson('/departmentKnowledgeStatisticsManagement/export', params);
        return res.data;
    },

    /*
    * 个人知识贡献统计
    */ 
    async getPersonalStatisticsList (params={}, cb) {
        let res = await myAxios.postJson('/personalKnowledgeContributionManagement/pageList', params);
        return res.data;
    },

    async getPersonalStatisticsExport (params={}, cb) {
        let res = await myAxios.downloadJson('/personalKnowledgeContributionManagement/export', params);
        return res.data;
    },

    /*
    * 知识价值统计
    */ 
     async getKnowledgeWorthStatisticsList (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeWorthStatisticsManagement/pageList', params);
        return res.data;
    },

    async getKnowledgeWorthStatisticsExport (params={}, cb) {
        let res = await myAxios.downloadJson('/knowledgeWorthStatisticsManagement/export', params);
        return res.data;
    },

    /*
    * 个人知识学习统计
    */ 
    async getPersonalStudyStatisticsList (params={}, cb) {
        let res = await myAxios.postJson('/personalKnowledgeStudyManagement/pageList', params);
        return res.data;
    },

    async getPersonalStudyStatisticsExport (params={}, cb) {
        let res = await myAxios.downloadJson('/personalKnowledgeStudyManagement/export', params);
        return res.data;
    },
};