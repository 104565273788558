import React, {useState, useEffect, useRef} from 'react';
import { Space, Button, List, message, Input, Result, Spin } from "antd";
import { CaretDownOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";

import Api from "../../api";
import Iconfont from "../../components/Iconfont/Iconfont";
import {getSearchParams} from '../../utils/tool'
import './index.scss';

const ExamineList = () => {

    const history = useHistory();

    const [userInfo, setUserInfo] = useState("");
    
    const [spinType, setSpinType] = useState(false);

    const [checkList, setCheckList] = useState([]);

    useEffect(()=>{
        getCheckMessageList();
    },[])

    const getCheckMessageList = async () => {
        setSpinType(true);

        try {
            // userInfo是base64编码的，数据中带有=号，所以用常规的=分割数据会有问题
            // 采用截取的方式
            let param = location.search.split("&");
            let userInfo = param[0].substring(9) // userInfo= 后所有的数据
            setUserInfo(userInfo);

            let res = await Api.Common.getCheckToDoMessageForQT({
                data: { 
                    userInfo: userInfo 
                },
            });
          
            setSpinType(false);
            if (res && res.code === 0) {
                setCheckList(res.data);
            }

        } catch (error) {
            console.log("报错了")
            setSpinType(false);
        }
    };

    const goApproval = (item) => {
        history.replace(`/examine?checkId=${item.checkId}&userInfo=${userInfo}&flowType=${item.flowType}`);
    }

    return (
        <div className="examineListFrame">
            <List
                className="examineList"
                loading={spinType}
                itemLayout="horizontal"
                dataSource={checkList}
                renderItem={(item) => (
                    <List.Item
                        actions={[<a onClick={()=>{goApproval(item)}} key="list-loadmore-edit">立即审批</a>]}
                    >
                        <div className="content" key={item.checkId}>
                        {item.applyUserName}
                        申请
                        {item.applyForType === 1 ? "下载":
                        item.applyForType === 4 ? "查看":
                        item.applyForType === 3 ? "取消发布":
                        item.applyForType === 2 ? "发布":
                        item.applyForType === 5 ? "更新":""}
                        《{item.knowledgeName}》
                        </div>
                    </List.Item>
                )}
                />
        </div>
    );
}

export default ExamineList;