import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

import {message, Pagination, Spin, Empty, Modal} from "antd";

import KnowledgeItem from "../../components/KnowledgeItem";
import SelfPagination from "../../../../components/SelfPagination";
import {ModalConfirm} from "../../../../utils/tool";
import Api from "../../../../api";

import "./notPublished.scss";

const NotPublished = () => {
    const history = useHistory();
    const [isAuditSpinning, setAuditIsSpinning] = useState(true);
    const [isDraftSpinning, setDraftIsSpinning] = useState(true);

    // 俩板块的数据
    const [auditList, setAuditList] = useState([]);
    const [draftList, setDraftList] = useState([]);
    // 草稿箱请求数据的所有参数
    const [data, setData] = useState({});
    // 知识驿站条数
    const [total, setTotal] = useState(null);
    // 请求数据需要传递的参数
    const [siftData, setSiftData] = useState({
        isCreated: null,
        isReadNum: null,
        knowledgeTypeId: null,
        pageIndex: 1,
        pageSize: 10,
        type: 2,
    });
    const [auditSiftData, setAuditSiftData] = useState({
        isCreated: null,
        isReadNum: null,
        knowledgeTypeId: null,
        pageIndex: 1,
        pageSize: 10,
        type: 0,
    });
    const [documentWidth, setDocumentWidth] = useState(0);

    // 页面初始化获取知识驿站与草稿箱内容-- type：0--未发布  2--草稿箱
    // 知识的审核状态 knowledgeCheckStatus  1-已经通过  2-待审核 0-未通过
    useEffect(() => {
        getAuditList(auditSiftData);
    }, [auditSiftData]);
    useEffect(() => {
        getDraftList(siftData);
    }, [siftData]);

    useEffect(() => {
        window.addEventListener('resize', getDocumentWidth);
        getDocumentWidth();

        return () => {
            window.removeEventListener('resize', getDocumentWidth);
        }
    }, [])

    // 监听窗口大小变化回调
    function getDocumentWidth () {
        const dom = document.documentElement || document.body;
        setDocumentWidth(dom.clientWidth);
    }

    // 知识驿站
    const getAuditList = async (data) => {
        setAuditIsSpinning(true);
        let res = await Api.PersonalCenter.getKnowledgeNotPublished({data});
        setAuditList(res.data.records);
        setTotal(res.data.total);
        setAuditIsSpinning(false);
    };
    // 草稿箱
    const getDraftList = async (data) => {
        setDraftIsSpinning(true);
        let res = await Api.PersonalCenter.getKnowledgeNotPublished({data});
        setDraftList(res.data.records);
        setData(res.data);
        // console.log(res.data, "res.datares.data");
        setDraftIsSpinning(false);
    };
    const init = () => {
        getAuditList(auditSiftData);
        getDraftList(siftData);
    };
    // 点击发布、删除、编辑
    const showModal = async (data) => {
        console.log(data, "整条只是数据");
        const dataType = data.type;

        if (dataType === "publish") { // 发布
            let result = await Api.Knowledge.reviewApply({
                data: {
                    knowledgeId: data.item.knowledgeId,
                    applyForType: 2,
                },
                loading: true,
                loadingText: "发布中...",
            });
            if (result.data.bool) {
                message.success(result.data.message);
                init();
            }
        } else if (dataType === "delete") { // 删除
            let reqData = {
                knowledgeId: data.item.knowledgeId,
                removeType: 2,
            };
            let func;
            if (data.KnowledgeType === "audit") {
                func = Api.PersonalCenter.deleteKnowledgeNotPublished;
            } else {
                reqData.removeType = 1;
                func = Api.PersonalCenter.deleteKnowledgeNotPublished;
            }

            const res = await func({
                data: reqData,
                loading: true,
                loadingText: "删除中...",
            })
            if (res.data) {
                message.success("删除成功");
                if (data.KnowledgeType === "audit") {
                    getAuditList(auditSiftData);
                } else {
                    getDraftList(siftData);
                }
            } else {
                message.error("操作失败,请稍后再试");
            }
        } else if (dataType === "edit") { // 编辑
            // if (data.item.fileType === "online") {
            //   history.push("/page/onLineWrite", {
            //     knowledgeId: data.item.knowledgeId,
            //   });
            // } else {
            //   history.push("/page/newKnowledge", {
            //     fileType: data.item.fileType,
            //     fileId: data.item.fileId,
            //     knowledgeId: data.item.knowledgeId,
            //     fileName: data.item.knowledgeName,
            //   });
            // }
            let encodedObject = encodeURIComponent(data.item.knowledgeId);
            let fileIdEncode = encodeURIComponent(data.item.fileId);
            let url;
            if (data.item.fileType === "online") {
                url = `/page/onLineWrite?knowledgeId=${encodedObject}`;
            } else {
                url = `/page/newKnowledge?knowledgeId=${encodedObject}&fileId=${fileIdEncode}&fileType=${data.item.fileType}&fileName=${data.item.knowledgeName}`;
            }
            window.open(url);
        }
    };

    // 页码变化时
    const changePage = (page, pageSize, type) => {
        console.log(page, type);
        let _siftData;
        if (type == "0") {
            _siftData = {...auditSiftData};
            _siftData.pageIndex = page;
            _siftData.pageSize = pageSize;
            setAuditSiftData(_siftData);
        } else {
            _siftData = {...siftData};
            _siftData.pageIndex = page;
            _siftData.pageSize = pageSize;
            setSiftData(_siftData);
        }
    };
    // const showSizeChanger = (page,pageSize) => {
    //   console.log(page,pageSize);
    // }
    return (
        <div className="notPublished">
            <div className="audit">
                <div className="auditHeadBox">
                    <div className="headTitleText">知识驿站</div>
                </div>

                <div className="auditCon">
                    <Spin size="large" spinning={isAuditSpinning}>
                        {auditList.length > 0 ? (
                            <KnowledgeItem
                                list={auditList}
                                type={"audit"}
                                showModal={(data) => showModal(data)}
                            ></KnowledgeItem>
                        ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                        )}
                    </Spin>
                </div>
                {total > 0 ? (
                    <div className="pagination">
                        <SelfPagination
                            current={auditSiftData.pageIndex}
                            onSizeChange={(page, pageSize) => changePage(page, pageSize, 0)}
                            total={total * 1}
                            simple={documentWidth < 1100}
                        ></SelfPagination>
                    </div>
                ) : undefined}
            </div>
            {/* <div className="draft">
                <div className="auditHeadBox">
                    <div className="headTitleText">草稿箱</div>
                    {
                        data.total > 0 ? (
                            <div className="pagination">
                                <SelfPagination
                                    current={siftData.pageIndex}
                                    pageSize={siftData.pageSize}
                                    onSizeChange={(page, pageSize) => changePage(page, pageSize, 2)}
                                    total={data.total * 1}
                                    showTotal={(total) => `共${total} 条`}
                                    simple={documentWidth < 1100}
                                ></SelfPagination>
                            </div>
                        ) : undefined
                    }
                </div>

                <div className="draftCon">
                    <Spin size="large" spinning={isDraftSpinning}>
                        {draftList.length > 0 ? (
                            <KnowledgeItem
                                list={draftList}
                                type={"draft"}
                                showModal={(data) => showModal(data)}
                            ></KnowledgeItem>
                        ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                        )}
                    </Spin>
                </div>
            </div> */}
        </div>
    );
};

export default NotPublished;
