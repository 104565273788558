import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Typography, message, Space, Button, Input, Form, Spin, Result, Modal } from "antd";
import {CloudUploadOutlined, DeleteColumnOutlined} from "@ant-design/icons";
import moment from "moment";

import Api from "../../../api";
import SelfTable from "../../../components/SelfTable/index"
import { getData } from "../../../features/editKnowledge/editKnowledgeReducer";
import AddKnowledgeFormModal from "../components/addKnowledgeForm/AddKnowledgeFormModal"
import KnowledgeInfo from "../components/addKnowledgeForm/KnowledgeInfo"
import { ModalConfirm, getFileLimitType } from "../../../utils/tool";
import FileList from "./components/FileList"
import PreviewFile from "./components/PreviewFile"
import ProcessConfirmation from "./components/ProcessConfirmation"
import fileIcon from "../../../assets/img/fileIcon.png";
import Image from "../../../components/Image/Image";
import SplitUpload from "../../../components/SplitUpload/index-websocket";
import './index.scss'

const { Title, Link, Text } = Typography;
const { Search } = Input;

const EdictKnowledge = () => {
    const history = useHistory();

    
    const [actionList, setActionList] = useState([]);
    const [selectedRowKey, setSelectedRowKey] = useState([]);
    const [fileListDisabled, setFileListDisabled] = useState(false);
    const [knowledgeDetail, setKnowledgeDetail] = useState({});
    const [spinning, setSpinning] = useState(true);
    const [spinningTips, setSpinningTips] = useState("加载中...");
    const [buttonDisabled, setButtonDisabled] = useState(false);
    
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showTipsModal, setShowTipsModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    // 可选分享所有部门
    const [shareDepartmentObject, setShareDepartmentObject] = useState({});

    const [currentIndex, setCurrentIndex] = useState('');

    // 详情，   逐篇编辑，     单个编辑， 批量编辑，  提示页面
    // details editOneByOne  editSingle editBatch  tips
    const [editStatus, setStatus] = useState('');

    const [form] = Form.useForm();
    const [confirmType, setConfirmType] = useState('');

    // const knowledgeList = useSelector(state => state.editKnowledge.knowledgeList);
    // const editType = useSelector(state => state.editKnowledge.editType); // 页面类型 updateFile 更新，share 分享

    const [knowledgeList,setKnowledgeList] = useState([]);
    const [editType,setEditType] = useState("");
    const [defaultOperationType,setDefaultOperationType] = useState("");

    const [updateFileId, setUpdateFileId] = useState("");
    // useEffect(() => {

    //     // 默认选中所有数据
    //     let ids = [];
    //     knowledgeList.map(item => {
    //         ids.push(item.knowledgeId)
    //     })
    //     setSelectedRowKey(ids);
    //     setActionList(knowledgeList)

    //     getAllDepartment();

    // }, [])

    useEffect(() => {
        if(sessionStorage.getItem("editFileVlue")){
            let editFileVlue = JSON.parse(sessionStorage.getItem("editFileVlue"))
            // 我的空间点击批量修改 、逐篇确认
            if(editFileVlue.operationType){
                setDefaultOperationType(editFileVlue.operationType)
                setStatus(editFileVlue.operationType);
                
                setFileListDisabled(false);
                setSpinning(false);
            }

            setKnowledgeList(editFileVlue.knowledgeList);
            setEditType(editFileVlue.editType);

        }
    }, [])

    useEffect(() => {
        
        if(knowledgeList?.length > 0){
            // 默认选中所有数据
            let ids = [];
            knowledgeList.map(item => {
                ids.push(item.knowledgeId)
            })
            setSelectedRowKey(ids);
            console.log("4",knowledgeList)
            setActionList(knowledgeList)

            getAllDepartment();

            // defaultOperationType 有值代表在我的空间已经检查过了
            if(defaultOperationType === 'editOneByOne'){
                setCurrentIndex(0);
            }else if(knowledgeList[0] && knowledgeList.length > 1 && !defaultOperationType){
                
                checkInformation(knowledgeList);
                
            }else if(knowledgeList.length === 1){
                // 只有一个文件的时候
                setStatus('editSingle');
                setSpinning(false);
                inquiryInformation(0, knowledgeList);
                setCurrentIndex(0); 
            }
        }
    }, [knowledgeList])

    useEffect(() => {
        
        console.log(currentIndex,"------------------------")
        if(currentIndex !== ""){
            inquiryInformation(currentIndex);
        }
    }, [currentIndex])


    useEffect(() => {
        return () => {
            const url = sessionStorage.getItem("shareBeforePath");
            if (url) {
                sessionStorage.removeItem("shareBeforePath");
            }
        }
    }, [])

    const getAllDepartment = async () => {
        try {
            let res = await Api.Department.getDepartmentListAll();
            if (res && res.code === 0 && res.data) {
                let _data = res.data;
                let shareDepartment = {};
                _data.forEach((item) => {
                    shareDepartment[item.id] = item.name;
                })
                setShareDepartmentObject(shareDepartment)
            } else {
            }
        } catch {}
    }

    const checkInformation = async(list,callBack) => {
        // 检查文件信息是否想同，或者未填写信息
        let knowledgeIds = [], data = list ? [...list] : [...actionList]
        data.map(item=>{
            knowledgeIds.push(item.knowledgeId)
        })
        try{
            let res = await Api.Knowledge.checkKnowledgeInfo({data: knowledgeIds});
            if (res && res.code === 0) {
                setSpinning(false);
                if(callBack){
                    callBack(res.data);
                    return false;
                }
                // 0一致 1不一致 2信息未填写
                let status = res.data === 2 ? "editBatch": res.data === 1 ? "tips" : res.data === 0 ? "details" : "";
                if(res.data === 2){
                    setFileListDisabled(true);
                }else{
                    setFileListDisabled(false);
                }
                
                if(res.data === 0){
                    inquiryInformation(0, list);
                }
                setStatus(status);
            }else{
                setSpinning(false);
            }
        }catch(e){setSpinning(false);}
    }

    useEffect(() => {
        // 获取知识详情
        console.log(actionList,"actionListactionList")
    }, [actionList])

    const inquiryInformation = async(key, list) => {
        setSpinning(true);
        setSpinningTips("加载中...");
        // 查讯详情，共同信息相同的情况下才查讯
        let knowledgeId = list ? list[key].knowledgeId: actionList[key].knowledgeId
        try{
            let res = await Api.Knowledge.getKnowledgeDetailById({data: {knowledgeId}});
            setSpinning(false);
            if (res && res.code === 0 && res.data) {
                let _data = res.data;
                setKnowledgeDetail(_data);

                // 赋值数据
                let newActionList = [], dataList = list ? list: actionList;
                dataList.map(item => {
                    newActionList.push({
                        ..._data,
                        ...item,
                        knowledgeName: item.fileName
                    })
                })
                console.log("1",newActionList)
                setActionList(newActionList)
            }
        }catch(e){setSpinning(false);}
    }

    const saveSuccess = (type) => {
        // 保存成功
        // if(confirmType === 0){
        //     // 发布
        //     confirmRealese();
           
        // }else{
            checkInformation();
            // setStatus("details");
            // setFileListDisabled(false);
            // inquiryInformation(0);
            form.resetFields();
        // }
    }

    const useApi = async (data) => {
        return new Promise( async (resolve,reject) => {
          try{
            let res = null;
            // 更新文件
            if(editType === "updateFile"){
                if(updateFileId){
                    // 如果新上传了文件就用新的ID，不然就用以前的
                    data.relationFileId = updateFileId;
                }
                res = await Api.Knowledge.applyUpdate({ data });
            }else{
                // 正常保存
                res = await Api.PersonalCenter.addNewKnowledge({ data });
            }
            
            if (res.data) {
              resolve(res.data);
            }else{
              message.error(res.msg);
              reject(res.data)
            }
          }catch(e){
            reject("error")
          }
        })
    };


    // 发布
    const releaseApi = async (knowledgeId) => {
        return new Promise( async (resolve,reject) => {
            try{
              let res = await Api.Knowledge.reviewApply({data:{ 'knowledgeId':knowledgeId, "applyForType": 2}});
              if (res.data) {
                resolve(res.data);
              }else{
                message.error(res.msg);
                reject(res.data)
              }
            }catch(e){
              reject("error")
            }
        })
    }


    // 保存基本信息
    const saveInfo = async(callBack) => {
        const row = await form.validateFields();
        
        if(JSON.stringify(row) === "{}"){
            return false
        }
        setConfirmType(2);
        setSpinning(true);
        setSpinningTips("保存中...");
        try{
            if (row.knowledgeTypeId == "2" && row.time && row.time.length > 0) {
                row.projectStartTime = moment(row.time[0]._d).format("YYYY-MM-DD");
                row.projectEndTime = moment(row.time[1]._d).format("YYYY-MM-DD");
            }
            /* 6 参考资料；7 参考研报 时不选择作者，输入知识来源*/
            if(row.knowledgeTypeId != 6 && row.knowledgeTypeId != 7){
                row.knowledgeUserRelDTOList = row.knowledgeUserRelDTOListObj;
            }
            row.operateType = 2 //confirmType; //0 发布  2保存草稿箱
            // row.knowledgeName
            let param = {...row}, promiseList = [], fileName = "", newActionList = [];
            
            if(editStatus === 'editSingle' || editStatus === 'editOneByOne'){
                newActionList = [...actionList];
                fileName = param.knowledgeName || actionList[currentIndex].fileName;
                param.fileType = actionList[currentIndex].fileType;
                param.relationFileId = actionList[currentIndex].fileId || actionList[currentIndex].relationFileId;
                param.knowledgeManagementId = actionList[currentIndex].knowledgeId;
                param.knowledgeId = actionList[currentIndex].knowledgeId;
                promiseList.push(useApi(param))
                newActionList[currentIndex] = {
                    ...param,
                    "knowledgeName" : fileName
                }
            }else if(editStatus === 'editBatch'){
                actionList.map(item=>{
                    fileName = param.knowledgeName || item.fileName
                    param.fileType = item.fileType;
                    param.relationFileId = item.fileId || item.relationFileId;
                    param.knowledgeManagementId = item.knowledgeId;
                    param.knowledgeId = item.knowledgeId;
                    // 提交
                    promiseList.push(useApi(param))
                    newActionList.push({
                        ...param,
                        "knowledgeName" : fileName
                    }) 
                })
            }
            
            const result = Promise.all(promiseList);
            result.then(arr => {
                setSpinning(false);
                console.log("2",newActionList)
                setActionList(newActionList)
                // saveSuccess();
                // resolve();
                if(callBack){
                    callBack()
                }else{
                    checkInformation();
                    form.resetFields();
                }
            }, e => {
                // reject()
                setSpinning(false);
                setButtonDisabled(false);
            })

        }catch(e){
            setSpinning(false);
            setButtonDisabled(false);
        }
        // console.log(row,"rowrowrow")
        // form.submit();
    }

    const onRealese = () => {

        // 获取数据储存在actionList中

        // updateDataList
        saveInfo(()=>{
            setShowConfirmModal(true)
        })
        
    };

    const onCancel = () => {
        ModalConfirm({
            title: "确认取消基本信息填写吗？取消后将丢失填写的信息",
            onOk: async (resolve, reject) => {
                const url = sessionStorage.getItem("shareBeforePath");
                if (url) {
                    sessionStorage.removeItem("shareBeforePath");
                    location.replace(url);
                    return;
                }
                checkInformation();
                resolve();
            },
        });
    }

    const backList = () => {
        history.push("/personalCenter/myUpload/notPublished");
    }

    const viewProgress = () => {
        sessionStorage.activeKey = 4
        history.push("/page/notifications");
    }

    const onListOperation = (type, record, index) => {
        // 默认选中所有数据
        let list = [];
        actionList.map(item => {
            if(selectedRowKey.includes(item.knowledgeId)){
                list.push(item)
            }
        })

        console.log("3",list)
        setActionList(list)

        switch (type) {
            case "batchModify": //批量修改
                checkInformation(list,(code)=>{
                    // 0一致 1不一致 2信息未填写
                    if(code === 1){
                        setStatus("tips");
                    }else{
                        setFileListDisabled(true);
                        setStatus("editBatch");
                    }
                });
                cancelTipsModal();
                break;
            case "reviseOneByOne": //逐篇修改
                setStatus('editSingle');
                if(index){
                    setCurrentIndex(index);
                }else{
                    setCurrentIndex(0);
                }
                cancelTipsModal();
                break;
            case "batchRelease": //批量发布
                // updateDataList
                // 文档信息已保存后直接保存
                if(editStatus === 'details' || editStatus === 'tips'){
                    setShowTipsModal(true)
                }else{
                    saveInfo(()=>{
                        setShowTipsModal(true)
                    })
                }
                break;
            case "releaseOne": //单篇发布
            
                const result = Promise.all([releaseApi(record.knowledgeId)]);
                result.then(arr => {
                    setShowSuccessModal(true);
                    // 发布成功
                }, e => {
                    console.log("发布失败")
                })
            
                // setShowTipsModal(true)
                break;
        }
    }

    // 不确认信息，直接发布
    const onDirectlyPublish = () => {
        setShowConfirmModal(true);
        // saveInfo(()=>{
            // setButtonDisabled(true)
            // confirmRealese("release");
        // });
    }

    const editOneByOne = () => {
        setStatus('editOneByOne');
        setCurrentIndex(0);
    }

    const onPreviousArticle = () => {
        if(currentIndex - 1 >= 0){
            setCurrentIndex(currentIndex - 1)
        }
    }
    const onNextArticle = async() => {

        // 获取数据储存在actionList中
         // updateDataList
         saveInfo(()=>{
            if(currentIndex + 1 <= actionList.length - 1){
                setCurrentIndex(currentIndex + 1);
            }
        })
    }


    const updateDataList = async(callBack) => {
         // 获取数据储存在actionList中
         const row = await form.validateFields();
         try{
             if (row.knowledgeTypeId == "2" && row.time && row.time.length > 0) {
                 row.projectStartTime = moment(row.time[0]._d).format("YYYY-MM-DD");
                 row.projectEndTime = moment(row.time[1]._d).format("YYYY-MM-DD");
             }
             /* 6 参考资料；7 参考研报 时不选择作者，输入知识来源*/
             if(row.knowledgeTypeId != 6 && row.knowledgeTypeId != 7){
                 row.knowledgeUserRelDTOList = row.knowledgeUserRelDTOListObj;
             }
             let index = currentIndex ? currentIndex : 0
             let _actionList = [...actionList];
             
             let param = {...row};
             
             if(editStatus === 'editSingle' || editStatus === 'editOneByOne'){
                param.relationFileId = _actionList[index].fileId;
                param.knowledgeManagementId = _actionList[index].knowledgeId;
                _actionList[index] = {..._actionList[index],...param}
             
            }else if(editStatus === 'editBatch'){
                _actionList.map((item,key)=>{
                    param.relationFileId = item.fileId;
                    param.knowledgeManagementId = item.knowledgeId;
                    // 提交
                    _actionList[key] = {...item,...param}
                })
            }

             console.log(_actionList,"_actionList")
             
             setActionList(_actionList);
 
             if(callBack){callBack();}
             
         }catch(e){}
    }

    const cancelRealese = () => {
        setShowConfirmModal(false)
    }

    const confirmRealese = (selectedRowKey) => {
        setConfirmType(0);
        
        // if(type === "release"){
        //     // 直接发布
            realeseKnoeledge(selectedRowKey)
        // }else{
            // saveAndRealese(selectedRowKey);
        // }
        
    }

    // 调用发布接口
    const realeseKnoeledge = (selectedRowKey) => {
        
        let promiseList = [];
        actionList.map(item=>{
            if(selectedRowKey.includes(item.knowledgeId)){
                // 提交
                promiseList.push(releaseApi(item.knowledgeId))
            }
        })

        const result = Promise.all(promiseList);
        result.then(arr => {
            setShowSuccessModal(true);
            setButtonDisabled(false);
            // 发布成功
        }, e => {
            setButtonDisabled(false);
            console.log("发布失败")
        })
    }

    // 保存基本信息+发布
    const saveAndRealese = async(selectedRowKey,callBack) => {
        setConfirmType(0);
        setSpinning(true);
        setSpinningTips("发布中...");
        try{
            let promiseList = [];
            actionList.map(item=>{
                if(selectedRowKey.includes(item.knowledgeId)){
                    delete item.fileName;
                    item.operateType = 0;
                    // 提交
                    promiseList.push(useApi(item))
                }
            })
            const result = Promise.all(promiseList);
            result.then(arr => {
                setSpinning(false);
                if(callBack){
                    callBack()
                }else{
                    setShowSuccessModal(true);
                }
            }, e => {
                setSpinning(false);
            })
        }catch(e){
            setSpinning(false);
        }
    }

    const cancelTipsModal  = () => {
        setShowTipsModal(false)
    }

    // 已选择文件
    const selectFilesChangeFunc = async(fileList,e) => {
        console.log(fileList,e)

        await SplitUpload(fileList,(params, socket)=>{
            console.log(params, socket)
            if(params.status === "success"){
                setUpdateFileId(params.otherData.fileId)
                console.log(params.otherData.fileId,"fileId")
                socket?.close();
            }
        });

        e.target.value = "";
    }

    const updateFile = async(fileId) => {
        try{
            let res = await Api.Knowledge.applyUpdate({ data:{
                fileId: fileId,
                id: knowledgeDetail.id
            } });
            if (res.data) {
                
                inquiryInformation(0);
            }
        }catch(e){}
    }

return <>
    <Spin tip={spinningTips} wrapperClassName="edictKnowledgeLoadingBox" spinning={spinning}>
        <div className="edictKnowledgeFrame">
            <div className="leftBox">
                {fileListDisabled&&<div className="disabledBox"></div>}
                {(editStatus === 'editBatch' || editStatus === 'details' || editStatus === 'tips') && <FileList
                    knowledgeList={knowledgeList}
                    selectedRowKey={selectedRowKey}
                    setSelectedRowKey={setSelectedRowKey}
                    onOperation={onListOperation}
                />}
                {(editStatus === 'editSingle' || editStatus === 'editOneByOne') && 
                    <PreviewFile detailsData={knowledgeDetail} updateFileId={updateFileId}/>
                }
            </div>
            <div className="rightBox">
                <div className="toptitle">
                    {(editStatus === 'details' || editStatus === 'tips') && <Title level={5}>文档共同基本信息</Title>}
                    {editStatus === 'editBatch' && <Title level={5}>批量填写基本信息</Title>}
                    {(editStatus === 'editSingle' || editStatus === 'editOneByOne') && <Title level={5}>填写基本信息</Title>}
                </div>

                {/* 更新 */}
                {editType === "updateFile" &&
                    <Button className="uploadBtn" icon={<CloudUploadOutlined />}>
                    上传文件
                    <input
                        id="addFfileInput"
                        type="file"
                        multiple={true}
                        accept={()=>{
                            let fileLimitType = getFileLimitType()
                            return fileLimitType.join(",")
                        }}
                        onChange={(e) => selectFilesChangeFunc(e.target.files,e)}
                    />
                    </Button>
                }

                <div className="fromBox">
                    {editStatus === 'details' && <KnowledgeInfo knowledgeDetail={knowledgeDetail} shareDepartmentObject={shareDepartmentObject}/>}
                    {(editStatus === 'editBatch' || editStatus === 'editSingle' || editStatus === 'editOneByOne') &&
                    <AddKnowledgeFormModal
                        form={form}
                        type={confirmType}
                        knowledgeDetail={knowledgeDetail}
                        editStatus={editStatus}
                    />}

                    {editStatus === 'tips' && <div className="tipsBox">
                        <Image
                            imgSrc={fileIcon}
                            iClassName="fileIcon"
                        />
                        列表内文档基本信息不相同请<Link onClick={editOneByOne} target="_blank">逐篇查看</Link>
                    </div>}
                </div>

                <div className="bottonBox">
                    {editStatus === 'editBatch' && <Space>
                        <Button onClick={onCancel}>取消</Button>
                        {/* 更新文件不显示保存按钮 */}
                        {editType != "updateFile" && <Button type="primary" onClick={()=>{saveInfo()}}>保存</Button>}
                    </Space>}

                    {/* 逐篇修改 、单篇修改 */}
                    {(editStatus === 'editSingle' || editStatus === 'editOneByOne') && <Space>
                        <Button onClick={onCancel}>取消</Button>
                        {currentIndex != 0 && <Button onClick={onPreviousArticle}>上一篇</Button>}
                        {(currentIndex != actionList.length - 1) && <Button onClick={onNextArticle}>下一篇</Button>}
                        {/* 更新文件不显示保存按钮 */}
                        {editType != "updateFile" && <Button onClick={()=>{saveInfo()}}>保存</Button>}
                        {currentIndex === actionList.length - 1 && <Button type="primary" onClick={onRealese}>
                        {editType === "updateFile" ? "更新" : "发布"}    
                        </Button>}
                    </Space>}
                </div>
            </div>

            <ProcessConfirmation
                knowledgeList={actionList}
                confirmRealese={confirmRealese}
                cancelRealese={cancelRealese}
                showConfirmModal={showConfirmModal}
                editType={editType}
                spinning={spinning}
            />

            <Modal title=""
                open={showTipsModal}
                destroyOnClose={true}
                maskClosable={false}
                footer={null}
                width={660}
                onCancel={cancelTipsModal}
            >
                <Result
                    title="批量发布文档"
                    subTitle="请确认每篇文档基本信息的正确性"
                    extra={[
                        <Button onClick={cancelTipsModal}>取消分享</Button>,
                        <Button onClick={()=>onListOperation('batchModify')}>批量修改</Button>,
                        <Button onClick={()=>onListOperation('reviseOneByOne')} type="primary">逐篇确认</Button>,
                        <Button onClick={()=>onDirectlyPublish()} type="primary" disabled={buttonDisabled}>直接发布</Button>
                    ]}
                />
            </Modal>

            <Modal title=""
                open={showSuccessModal}
                destroyOnClose={true}
                maskClosable={false}
                footer={null}
                width={660}
                onCancel={backList}
                style={{textAlign: 'center'}}
            >
                <Result
                    status="success"
                    title="提交成功"
                    subTitle="发布申请提交成功！"
                    extra={[
                        <Button onClick={viewProgress}>查看进度</Button>,
                        <Button onClick={backList} type="primary">返回列表</Button>
                    ]}
                />
                <Text>*如需再次查看审批进度可前往“通知中心-我的待办”</Text>
            </Modal>
        </div>
    </Spin>
</>
}
export default EdictKnowledge;
