import React, {useEffect, useState, useRef, memo} from 'react';
import { Spin, Pagination, Space, message, Empty} from 'antd';

import Api from "../../../../api/index";
import {ModalConfirm} from '../../../../utils/tool'

import Iconfont from "../../../../components/Iconfont/Iconfont";
import { getSearchParams } from '../../../../utils/tool'
import './index.scss'

const ExcerptList = ({excerptList,getExcerptList,excerptPagination, updataExcerpt, excerptLoading, setLocationNotes}) => {

    const [newExcerptList, setNewExcerptList] = useState([]);
    
    useEffect(()=>{
        setNewExcerptList(excerptList)
    },[JSON.stringify(excerptList)])

    const excerptChange = (current) => {
        getExcerptList(current);
    }

    const deleteExcerpt = async(item) => {
        ModalConfirm({
            title: '确定要删除这条摘录吗？',
            onOk:async(resolve, reject)=> {
                try{
                    let res = await Api.Knowledge.deleteExcerpt({data:{
                        id:item.id,
                        "type" : getSearchParams().type === 'policy' ? 1 : 0 //0知识 1政策）
                    }});
                    resolve();
                    if(res && res.code === 0){
                        getExcerptList();
                        message.success("删除成功")
                    }else{}
                }catch{}
            },
        })
    }
    const openText = (items) =>{
        let _excerptList = [...newExcerptList]
        _excerptList.map(item=>{
            if(item.id === items.id){
                item.isShow = !item.isShow
            }
        })
        setNewExcerptList(_excerptList)
    }

    return(
        <div className="excerptFrame">
            <div className="excerptTable">

                <Spin size="small" spinning={excerptLoading}>

                    {newExcerptList&&newExcerptList.length>0?newExcerptList.map(item=>{

                        let showIcon = false
                        document.querySelector('#visibilityBox').innerHTML = item.knowledgeExcerptMessage;
                        if(document.querySelector('#visibilityBox').scrollHeight - 1 > 100){
                            showIcon = true
                        }

                        return( 
                            <div 
                                key={item.id} 
                                className="noteContent"
                            >
                                <div className="title">
                                    <Iconfont type={'icon-wj-bjb'} iStyle={{flex:1}}/>
                                    
                                    <div className="operation">
                                        
                                        <Space>
                                            <Iconfont type={'icon-bianji2'}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    updataExcerpt(item);
                                                }}
                                            />
                                            <Iconfont type={'icon-shanchu1'}
                                                onClick={()=>{
                                                    deleteExcerpt(item)
                                                }}
                                            />
                                        </Space>
                                    </div>
                                </div>

                                <span style={{fontSize: '0.8rem'}}>{item.knowledgeExcerptType}</span>   

                                <div className="content"
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        setLocationNotes(item)
                                    }}
                                >
                                    <div className={`textContent ${item.isShow ? "":"textEllipsis"}`}>
                                        {item.knowledgeExcerptMessage}
                                        {showIcon?(
                                            <div 
                                                className={item.isShow ? "moreBtn": "moreBtnOpen moreBtn"}
                                                onClick={(e)=>{
                                                    e.stopPropagation();
                                                    openText(item)
                                                }}
                                            >
                                            {item.isShow ? "收起" : "... 展开"}
                                            </div>
                                        ):""}
                                    </div>

                                    {/* {showIcon?(<Iconfont 
                                        type={`${item.isShow ? "icon-shangjiantou4":"icon-xiala"}`}
                                        className={`defalutRotate`}
                                        onClick={()=>{openText(item)}}
                                    />):""} */}

                                    {/* <div className="originalDetails">{item.noteBelongMessage}</div> */}
                                </div>
                            </div>
                        )

                    }):null}
                    {/* <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> */}
                </Spin>
                {excerptPagination && newExcerptList && newExcerptList.length>0 ?(
                    <Pagination 
                        size="small" 
                        pageSize={excerptPagination.pageSize}
                        total={excerptPagination.total} 
                        onChange={excerptChange}
                        className="pagination"
                    />
                ):""}
            </div>
        </div>
    )
}

export default ExcerptList;
