/*
    * 职务
*/ 
import myAxios from '../utils/request.js';

export default {
    // 获取职务列表
    async getDutiesList (params={}, cb) {
        let res = await myAxios.getJson('/jobInfo/selectAll', params);
        return res.data;
    },
    // 删除职务列表
    async deleteDuties (params={}, cb) {
        let res = await myAxios.deleteJson('/jobInfo/delete', params);
        return res.data;
    },
    // 修改职务
    async updateDuties (params={}, cb) {
        let res = await myAxios.postJson('/jobInfo/update', params);
        return res.data;
    },
    // 新增职务
    async addDuties (params={}, cb) {
        let res = await myAxios.postJson('/jobInfo/insert', params);
        return res.data;
    },
    // 查询所有未绑定角色的职务
    async getBoundJob (params={}, cb) {
        let res = await myAxios.getJson('/jobInfo/notBoundJob', params);
        return res.data;
    },
}