
import myAxios from '../utils/request.js';

export default {
    // 搜索用户空间使用情况
    async getUserSpaceList (params={}, cb) {
        let res = await myAxios.postJson('/sysUserSapce/pageUserSpace', params);
        return res.data;
    },

    // 修改用户的额外容量
    async updateUserSpace (params={}, cb) {
        let res = await myAxios.postJson('/sysUserSapce/updateUserSpace', params);
        return res.data;
    },

    // 查询全局默认空间
    async getGlobalInitData (params={}, cb) {
        let res = await myAxios.getJson('/spaceManage/getSpace', params);
        return res.data;
    },

    // 设置全局默认空间
    async updateGlobalInitData (params={}, cb) {
        let res = await myAxios.postJson('/spaceManage/setSpace', params);
        return res.data;
    },


    // 获取各个角色的初始容量
    async getInitDataList (params={}, cb) {
        let res = await myAxios.postJson('/spaceManage/getSpaceRole', params);
        return res.data;
    },

    // 修改角色的初始容量
    async updateInitSpace (params={}, cb) {
        let res = await myAxios.postJson('/spaceManage/setSpaceRole', params);
        return res.data;
    },
}
