import react, {useEffect, useState} from 'react';
import {Tree, Empty, Typography, Space, Modal, Form, Input, InputNumber, message, Tooltip, Popconfirm} from 'antd';
import { CaretDownOutlined, FileTextOutlined, FolderOpenOutlined, FolderOutlined,} from '@ant-design/icons';
import {getSearchParams, ModalConfirm} from "../../../../../utils/tool";
import Iconfont from "../../../../../components/Iconfont/Iconfont";
import Api from "../../../../../api/index";

import './index.scss';

const { Text } = Typography;

const DepartmentTree = ({
        departmentTree,
        onSelect,
        departmentTreeId,
        departmentId,
        getDepartmentTree,
        knowledgeTypeId,
    }) => {
    const [addForm] = Form.useForm();
    const [draggable, setDraggable] = useState(false)
    const [gData, setGData] = useState([]);
    const [selectedNode, setSelectedNode] = useState(null);
    const [modalType, setModalType] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ConfirmLoading, setConfirmLoading] = useState(false);
    const [departmentTreeIds, setDepartmentTreeIds] = useState("");
    const [randomKey, setRandomKey] = useState(Date.now());

    // ======== 右键菜单
    const [activeNode, setActiveNode] = useState(null);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [menuStyle, setMenuStyle] = useState({});

    const [modalTitle, setModalTitle] = useState(""); // 弹窗title
    const [isEdit, setIsEdit] = useState(false); // 是否编辑

    const menuList = [
        {text: "新增子分类", icon: "icon-add", key: "addSort",},
        {text: "编辑", icon: "icon-edit1", key: "edit",},
        {text: "删除", icon: "icon-shanchu1", key: "delete",},
    ];
    useEffect(() => {
        window.addEventListener("click", cancelActiveNode);
        return () => {
            window.removeEventListener("click", cancelActiveNode)
        }
    }, [])

    useEffect(() => {
        let choiceNode = firstNoChildrenNode(departmentTree);
        setGData(departmentTree);
        setDraggable(false);
        setRandomKey(Date.now());
        if (choiceNode) {
            onDepartmentSelect(choiceNode);
        }
    }, [departmentTree])

    useEffect(() => {
        setDepartmentTreeIds(departmentTreeId)
    }, [departmentTreeId])

    // 高亮显示第一个末尾节点
    function firstNoChildrenNode (list) {
        for (const item of list) {
            if (item.childList?.length > 0) {
                return firstNoChildrenNode(item.childList);
            } else if(item.id) {  // 不提示 "for statement does not loop"
                return [item.id];
            }
        }
        return undefined;
    }

    function cancelActiveNode () {
        setActiveNode(null);
        setMenuIsOpen(false);
        const domList = document.getElementsByClassName("activeNodeStatus");
        for (let domListElement of domList) {
            domListElement.className = domListElement.className.replace(/\s+activeNodeStatus/g, "");
        }
    }

    const onRightMenuFunc = ({event, node}) => {
        const domList = document.getElementsByClassName("activeNodeStatus");
        for (let domListElement of domList) {
            domListElement.className = domListElement.className.replace(/\s+activeNodeStatus/g, "");
        }
        const domId = `nodeId-${node.id}`;
        let targetDom = document.getElementById(domId);
        if (targetDom) {
            let dom = targetDom.parentNode.parentNode;
            if (dom) {
                dom.className += " activeNodeStatus";
                setActiveNode(node);
                setMenuStyle({
                    width: dom.clientWidth,
                    height: dom.clientHeight,
                    top: dom.offsetTop,
                    left: dom.offsetLeft - 8,
                });
                setMenuIsOpen(true);
            }
        }
    }

    const onDrop = (info) => {
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
        const loop = (data, key, callback) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].id === key) {
                    return callback(data[i], i, data);
                }
                if (data[i].childList) {
                    loop(data[i].childList, key, callback);
                }
            }
        };
        const data = [...gData];

        // Find dragObject
        let dragObj, parentId, sort;
        loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;
        });

        if (!info.dropToGap) {
            // Drop on the content
            loop(data, dropKey, (item) => {
                item.childList = item.childList || [];
                // where to insert 示例添加到头部，可以是随意位置
                item.childList.unshift(dragObj);
                parentId = item.id;
            });
        } else if (
            (info.node.props.childList || []).length > 0 &&
            // Has childList
            info.node.props.expanded &&
            // Is expanded
            dropPosition === 1 // On the bottom gap
        ) {
            loop(data, dropKey, (item) => {
                item.childList = item.childList || [];
                // where to insert 示例添加到头部，可以是随意位置
                item.childList.unshift(dragObj);
                parentId = item.id;
                // in previous version, we use item.childList.push(dragObj) to insert the
                // item to the tail of the childList
            });
        } else {
            let ar = [];
            let i;
            loop(data, dropKey, (_item, index, arr) => {
                ar = arr;
                i = index;
                sort = _item.sort;
                parentId = _item.parentId == "0" ? 0 : _item.id;
            });
            if (dropPosition === -1) {
                ar.splice(i, 0, dragObj);
            } else {
                ar.splice(i + 1, 0, dragObj);
                sort = sort + 1
            }

        }
        setGData(data);
        modifySorting(sort, parentId, dragObj);
    }

    const modifySorting = async (sort, parentId, dragObj) => {
        try {
            let res = await Api.Department.updateSubclassification({
                data: {
                    "departmentId": departmentId,
                    "id": dragObj.id,
                    "parentId": parentId,
                    "sort": sort,
                    "subclassification": dragObj.subclassification,
                    "knowledgeTypeId": knowledgeTypeId
                }
            });

        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    const onDepartmentSelect = (selectedKeys, e) => {
        setDepartmentTreeIds(selectedKeys[0]);
        sessionStorage.departmentTreeId = selectedKeys[0];
        if (!draggable) {
            onSelect(selectedKeys);
        }
    }

    const handleOk = async (state) => {
        const {isEdit, selectedNode, addForm,} = state;
        setConfirmLoading(true);
        try {
            const row = await addForm.validateFields();
            let params = {
                ...row,
                "departmentId": departmentId,
                "parentId": selectedNode ? selectedNode.id : 0,
                "knowledgeTypeId": knowledgeTypeId
            };
            let func = Api.Department.addSubclassification;
            let tipText = "新增";
            if (isEdit) {
                func = Api.Department.updateSubclassification;
                params.id = selectedNode.id;
                params.parentId = selectedNode.parentId;
                tipText = "修改";
            }
            let res = await func({
                data: params,
            });
            setConfirmLoading(false);
            if (res && res.code === 0) {
                getDepartmentTree(departmentId)
                handleCancel();
                message.success(tipText + "成功");
            } else {
                message.error(tipText + "失败");
            }
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }

    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setModalType(false);
        addForm.setFieldsValue({
            "subclassification": "",
            "sort": "",
        });
    };

    const deleteModal = async (nodeData) => {
        if (!nodeData) {
            message.success("请选择需要删除的部门子分类");
            return false;
        }
        try {
            let res = await Api.Department.deleteSubclassification({
                data: {
                    "id": nodeData.id
                }
            });
            if (res && res.code === 0) {
                getDepartmentTree(departmentId)
                message.success("删除成功");
            }
        } catch (e) {
            console.log('验证失败:', e);
        }
    }

    const titleRender = (nodeData) => {
        const domId = `nodeId-${nodeData.id}`;
        return (
            <div id={domId} style={{whiteSpace: "pre-wrap", wordBreak: "break-all",}}>{nodeData.subclassification}（{nodeData.count}）</div>
        );
    }

    const menuClickFunc = (type, nodeData) => {
        setMenuIsOpen(false)
        setSelectedNode(nodeData); // 记录操作的节点

        switch (type) {
            case "addRootDepartment":
                setModalTitle("新增根部门");
                setIsEdit(false);
                setIsModalOpen(true);
                break;
            case "sort":
                setDraggable(true);
                break;
            case "cancelSort":
                setDraggable(false);
                break;
            case "addSort":
                setModalTitle("新增子分类");
                setIsEdit(false);
                setIsModalOpen(true);
                break;
            case "edit":
                setModalTitle("编辑部门");
                setIsEdit(true);

                addForm.setFieldsValue({
                    "subclassification": nodeData.subclassification,
                    "sort": nodeData.sort,
                });
                setIsModalOpen(true);
                break;
            case "delete":
                deleteModal(nodeData);
                break;
        }
    }

    const deleteClassification = (nodeData) => {
        if (!nodeData) {
            message.info("请选择需要删除的子分类数据");
            return false;
        }

        ModalConfirm({
            title: '你确定要删除该分类吗？',
            content: <Text type="danger">删除此分类后分类下所有数据都会被删除</Text>,
            onOk:async(resolve, reject)=> {
                try{
                    let res = await Api.Department.deleteSubclassification({
                        data: {
                            "id": nodeData.id
                        }
                    });
                    if (res && res.code === 0) {
                        resolve()
                        getDepartmentTree(departmentId)
                        message.success("删除成功");
                    }
                }catch(errInfo){
                    console.log('验证失败:', errInfo);
                    reject();
                }
            },
        })
    }

    const {id: activeId, childList} = activeNode || {};
    const isHaveChildren = childList?.length > 0;

    const state = {
        isEdit, selectedNode, addForm,
    };

    const isManage = getSearchParams().inlet === "manage";

    return (
        <div className="departmentTreeFrame">
            {isManage ? (
                <div className="classificationList">
                    <span className="titleText">分类列表</span>
                    <div className="operateBtnBox">
                        <Tooltip title={draggable ? "排序完成" : "排序"}>
                            <span
                                className={`operateBtn ${draggable ? "activeStatus" : ""}`}
                                onClick={() => {
                                    menuClickFunc(draggable ? "cancelSort" : "sort")
                                }}
                            ><Iconfont type={"icon-paixu"} /></span>
                        </Tooltip>
                        <Tooltip title="新增根部门">
                            <span
                                className="operateBtn"
                                onClick={() => {
                                    menuClickFunc("addRootDepartment");
                                }}
                            ><Iconfont type={'icon-roundadd'}/></span>
                        </Tooltip>
                    </div>
                </div>
            ) : ""}
            <div className="treeContentBox">
                {
                    gData?.length > 0 ?
                        (
                            <Tree
                                key={randomKey}
                                showIcon={true}
                                icon={(item) => {
                                    const isHaveChild = item.childList?.length > 0;
                                    return ((isHaveChild && item.expanded) ? <FolderOpenOutlined /> : <FolderOutlined />);
                                }}
                                switcherIcon={<CaretDownOutlined/>}
                                onSelect={(keys) => {
                                    if (isManage) {
                                        onDepartmentSelect(keys);
                                    } else if (keys.length > 0) {
                                        onDepartmentSelect(keys);
                                    }
                                }}
                                treeData={gData}
                                titleRender={titleRender}
                                selectedKeys={[departmentTreeIds]}
                                defaultExpandAll={true}
                                blockNode
                                draggable={draggable}
                                onDrop={onDrop}
                                onRightClick={(data) => {
                                    // 未分类id为-1；未分类的类型不能做操作
                                    if (isManage && (data.node.id != '-1')) {
                                        onRightMenuFunc(data)
                                    }
                                }}
                                fieldNames={{
                                    title: "subclassification",
                                    key: "id",
                                    children: "childList"
                                }}
                            />
                        ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                }
                <Tooltip
                    placement={"right"}
                    open={menuIsOpen}
                    // getPopupContainer={() => document.getElementById(domId)}
                    key={activeId}
                    overlayClassName="menuToolTipBox"
                    trigger={"contextMenu"}
                    title={(
                        <div
                            className="menuBox"
                            onClick={(e) => e?.stopPropagation()}
                        >
                            {
                                menuList.map(item => {
                                    const {text, icon, key} = item;
                                    let content = (
                                        <div
                                            className="menuItem"
                                            key={key}
                                            onClick={() => menuClickFunc(key, activeNode)}
                                        >
                                            <Iconfont type={icon}></Iconfont>
                                            <span className="menuText">{text}</span>
                                        </div>
                                    );
                                    switch (key) {
                                        case "delete":
                                            // if ((activeId === departmentTreeIds) || isHaveChildren){
                                            //     content = null;
                                            // } else {
                                                content = (
                                                    // <Popconfirm
                                                    //     placement={"right"}
                                                    //     title={`你确定要删除该分类吗？`}
                                                    //     overlayClassName={"deletePupConfirmBox"}
                                                    //     onConfirm={() => menuClickFunc(key, activeNode)}
                                                    // >
                                                        <div
                                                            className="menuItem"
                                                            key={key}
                                                            onClick={() => deleteClassification(activeNode)}
                                                        >
                                                            <Iconfont type={icon}></Iconfont>
                                                            <span className="menuText">{text}</span>
                                                        </div>
                                                    // </Popconfirm>
                                                )
                                            // }
                                            break;
                                        case "addSort":
                                            break;
                                        case "edit":
                                            break;
                                    }

                                    return content;
                                })
                            }
                        </div>
                    )}
                >
                    <div style={menuStyle} className="menuRootNode"></div>
                </Tooltip>
            </div>

            <Modal
                title={modalTitle}
                open={isModalOpen}
                onOk={() => handleOk(state)}
                onCancel={handleCancel}
                confirmLoading={ConfirmLoading}
                forceRender={true}
                centered={true}
                wrapClassName={"selfDesignModal"}
            >
                <Form
                    form={addForm}
                    component={false}
                    labelCol={{span: 6}}
                    wrapperCol={{span: 18}}
                    preserve={false}
                >
                    <Form.Item
                        key={'subclassification'}
                        name={'subclassification'}
                        label={'子分类名称'}
                        rules={[{required: true, message: `请输入子分类名称!`}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        key={'sort'}
                        name={'sort'}
                        label={'排序'}
                        rules={[{required: true, message: `请输入排序!`}]}
                    >
                        <InputNumber min={0} style={{width: "100%"}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default DepartmentTree;
