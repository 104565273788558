import React, { useState, useEffect } from "react";
import { List, message } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

import Api from "../../../../api";
import ProcessStatus from "../../../../components/ProcessStatus";


const CheckMessage = ({
  checkMessageList,
  toKnowledgeEdit,
  toKnowledgeDetail,
  downloadKnowledge,
  checkStatus,
  setMessageMadelOpen
}) => {

  const [processStatusID, setProcessStatusID] = useState("");

  const [flowType, setFlowType] = useState("");

  const checkButtonStatus = async(item,type) => {
    let res = await Api.Message.checkMessageStatus({
      data: {
        id:item.knowledgeId,
        applyId:item.applyId
      },
    });

    if(res.code === 0){
      let _code = res.data;
      // -1已被删除 0已拒绝，1已通过，2待审批，3草稿文章
      if(_code === -1){
        message.info("当前知识已被删除");
      }
      // {/* applyForType 1下载申请,2发布知识申请,3取消发布知识申请,4阅读权限申请, 5 更新知识 */}
      else if(
        item.applyForType === 2 || 
        item.applyForType === 5 || 
        (item.applyForType === 4 && item.applyStatus === 1 && _code === 1)
      ){
        // 查看
        toKnowledgeDetail({
          ...item,
          actualState:_code
        })
      }
      else if(item.applyForType === 3 && item.myselfApply && _code === 3){
        // 取消发布知识，查讯当前知识是否在草稿箱，或者取消发布操作已通过
        // 编辑
        toKnowledgeEdit(
          item.fileType,
          item.knowledgeId,
          item.noticeId
        )
      }else if(item.applyForType === 1 && _code === 1){
        // 下载，查讯当前知识是否是已发布状态
        // 下载
        downloadKnowledge(item)
      }else {
        message.info("当前知识已取消发布");
      }
    }
  }

  const viewStatus = (items) => {
    setFlowType(items.flowType);
    setProcessStatusID(items.applyId);
    setMessageMadelOpen(false);
  }

  return (
    <div className="checkMessageFrame">
      <List
        size="small"
        dataSource={checkMessageList}
        renderItem={(item) => (
          <List.Item>
            {item.applyStatus != 0 ? (
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                style={{ marginRight: "0.3rem" }}
              />
            ) : (
              <CloseCircleTwoTone
                twoToneColor="#f00"
                style={{ marginRight: "0.3rem" }}
              />
            )}
            你申请
            {item.applyForType === 1
              ? "下载"
              : item.applyForType === 2
              ? "发布"
              : item.applyForType === 3
              ? "取消发布"
              : item.applyForType === 4
              ? "阅读文件": 
              item.applyForType === 5
              ? "更新文件"
              : null}

            {/* 申请查看的时候，只有通过了，并且没有被删除 才能跳转详情 */}
            {/* applyForType 1下载申请,2发布知识申请,3取消发布知识申请,4阅读权限申请*/}
            {/* actualState -1已被删除 0已拒绝，1已通过，2待审批，3草稿文章    ------ 该知识当前实际状态 */}
            <span
              style={{
                cursor:'pointer'
              }}
              onClick={() =>{
                if((item.applyForType != 4 || (item.applyForType === 4 && item.applyStatus === 1))){
                  checkStatus(item)
                }else{
                  message.info("当前申请未通过")
                }
              }}
            >
              《{item.knowledgeName}》
            </span>

            审批
            {item.applyStatus === 0 ? (
              <>
                已被拒绝,
                {/* {item.applyForType === 2 ? ( */}
                  <>
                    点击
                    <span
                      className="cursorSpan"
                      onClick={() =>
                        viewStatus(item,"edit")
                      }
                    >
                      查看原因
                    </span>
                  </>
                {/* ) : (
                  "联系管理员获取详情"
                )} */}
              </>
            ) : (
              <>
                已通过{(item.applyForType === 3 && !item.myselfApply) ? "" : ",点击"}
                {item.applyForType === 1 ? (
                  <span
                    className="cursorSpan"
                    onClick={() =>
                      checkButtonStatus(item,"download")
                    }
                  >
                    立即下载
                  </span>
                ) : item.applyForType === 2 ? (
                  <span
                    className="cursorSpan"
                    onClick={() =>
                      checkButtonStatus(item,"view")
                    }
                  >
                    立即查看
                  </span>
                ) : item.applyForType === 3 && item.myselfApply ? (
                  <span
                    className="cursorSpan"
                    onClick={() =>
                      checkButtonStatus(item,"edit")
                    }
                  >
                    立即编辑
                  </span>
                ) : item.applyForType === 4 || item.applyForType === 5 ? (
                  <span
                    className="cursorSpan"
                    onClick={() =>
                      checkButtonStatus(item,"view")
                    }
                  >
                    立即阅读
                  </span>
                ) : null}
              </>
            )}
          </List.Item>
        )}
      ></List>

      {processStatusID?(<ProcessStatus
          id={processStatusID}
          flowType={flowType}
          onCancelBack={()=>{
              setProcessStatusID("")
          }}
      />):""}
    </div>
  );
};

export default CheckMessage;
