import React, { useState} from 'react'
import "./index.scss"
const PointsMall = () => {
  const [isSpinning, setIsSpinning] = useState(true);

  return (
    <div className='pointsMallFrame'>
      积分商城
    </div>
  )
}

export default PointsMall
