import React, {useState, useEffect, useRef} from 'react';
import {Spin, Empty, Tabs, Tooltip, Image, message} from 'antd';
import Iconfont from "../../../../../components/Iconfont/Iconfont";

import {isVideoFunc} from "../../../../../utils/tool";
import Api from "../../../../../api/index";

import './index.scss';

const OtherProjectCarousel = () => {
    const positionObject = {
        0: "leftBox",
        1: "centerBox",
        2: "rightBox",
    };

    const stateRef = useRef({});

    const [intervalInstance, setIntervalInstance] = useState(null);
    const [bannerList, setBannerList] = useState([]);
    const [currentIdx, setCurrentIdx] = useState(0);
    const [direct, setDirect] = useState("right");

    useEffect(() => {
        getBannerList();
    }, []);

    useEffect(() => {
        stateRef.current = {
            ...stateRef.current,
            bannerList,
            currentIdx,
        };
        computedDirect(stateRef.current);
    }, [currentIdx, bannerList]);

    useEffect(() => {
        stateRef.current = {
            ...stateRef.current,
            direct,
            intervalInstance,
        };
    }, [direct, intervalInstance]);

    // 获取bannerList
    async function getBannerList () {
        const res = await Api.Banner.getAllBannerListOfNormal();
        const {data} = res || {};
        if (data) {
            setBannerList(data);
            addInterval({bannerList: data, intervalInstance: null});
        }
    }

    // 前往指定系统页面
    function toPage(path) {
        window.open(path, "_blank");
    }

    // 添加计时器
    function addInterval(state) {
        const {instance, bannerList} = state;
        if (!instance && bannerList?.length > 1) {
            const intervalInstance = setInterval(() => {
                autoChangeCurrentIdx();
            }, 3000);
            setIntervalInstance(intervalInstance);
        }
    }

    // 移除计时器
    function removeInterval(state) {
        const {intervalInstance} = state;
        if (intervalInstance) {
            clearInterval(intervalInstance);
            setIntervalInstance(null);
        }
    }

    // 自动轮播
    function autoChangeCurrentIdx() {
        const {currentIdx, direct} = stateRef.current;
        setCurrentIdx(currentIdx + (direct === "right" ? 1 : -1));
    }

    // 计算轮播方向
    function computedDirect(state) {
        const {currentIdx, bannerList} = state;
        if (currentIdx >= bannerList.length - 1) {
            setDirect("left");
        } else if (currentIdx <= 0) {
            setDirect("right");
        }
    }

    // 滚动到某个轮播块
    function scrollToIdx(idx, state) {
        if (idx !== state.currentIdx) {
            removeInterval(state);
            setCurrentIdx(idx);
        }
    }

    const state = stateRef.current;

    return (
        <div className="otherProjectCarouselFrame">
            {/* banner */}
            <div
                className="bannerBox"
                id="components-anchor-banner"
                onMouseOver={() => removeInterval(state)}
                onMouseLeave={() => addInterval(state)}
            >
                <div
                    className="scrollBox"
                    style={{width: `${bannerList.length * 100}%`, left: `-${currentIdx * 100}%`}}
                >
                    {
                        bannerList.map(item => {
                            const {
                                id, name, officialDocument, fileUrl,
                                buttonName, location, address, subSystemId, titleColor
                            } = item;
                            return (
                                <div
                                    className="bannerItem"
                                    key={`banner-${id}`}
                                    // style={{background: `#c9c9c9 url(${fileUrl}) no-repeat center top`}}
                                >
                                    <div className="bgImgBox">
                                        {
                                            isVideoFunc(fileUrl) ?
                                                (<video height={"100%"} width={"auto"} autoPlay={true} muted={true}
                                                        loop={true} src={fileUrl}>视频已失效</video>)
                                                :
                                                (<img src={fileUrl} alt=""/>)
                                        }
                                    </div>
                                    <div className={`bannerInfo ${positionObject[location]}`}>
                                        <span className="bannerTitle"
                                              style={{
                                                  visibility: name ? "" : "hidden",
                                                  color: titleColor === 1 ? "#333" : "#fff"
                                              }}
                                        >{name}</span>
                                        <span className="bannerIntro" style={{visibility: officialDocument ? "" : "hidden"}}>{officialDocument}</span>
                                        {
                                            buttonName ? (
                                                <div className="btnBox" onClick={() => toPage(item.address)}>
                                                    <span className="btnText">{buttonName || "立即进入"}</span>
                                                    <i className="line"/>
                                                </div>
                                            ) : undefined
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    bannerList.length > 1 ? (
                        <React.Fragment>
                            {/* 坐标点 */}
                            <div className="dotListBox">
                                {
                                    bannerList.map((item, idx) => {
                                        const {id} = item;
                                        return (
                                            <i
                                                key={`dot-${id}`}
                                                className={`dot ${idx === currentIdx ? "activeStatus" : ""}`}
                                                onClick={() => scrollToIdx(idx, state)}
                                            />
                                        )
                                    })
                                }
                            </div>
                            {/* 左右按钮 */}
                            <div className="changeIndexBtnBox">
                                <span
                                    className={`changeIndexBtn leftBtn ${currentIdx < 1 ? "disabledStatus" : ""}`}
                                    onClick={() => {
                                        if (currentIdx > 0) {
                                            scrollToIdx(currentIdx - 1, state);
                                        }
                                    }}
                                >
                                    <Iconfont type="icon-you"/>
                                </span>
                                <span
                                    className={`changeIndexBtn rightBtn ${currentIdx >= (bannerList.length - 1) ? "disabledStatus" : ""}`}
                                    onClick={() => {
                                        if (currentIdx < (bannerList.length - 1)) {
                                            scrollToIdx(currentIdx + 1, state);
                                        }
                                    }}
                                >
                                    <Iconfont type="icon-you"/>
                                </span>
                            </div>
                        </React.Fragment>
                    ) : undefined
                }
            </div>
        </div>
    )
}

export default OtherProjectCarousel;
