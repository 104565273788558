import React, { useState, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import Iconfont from "../../../../../components/Iconfont/Iconfont";

import './index.scss';

const QuickEntrance = () => {
    const historyInstance = useHistory();

    const entranceList = [
        {icon: "icon-fenxiang2", title: "知识分享", desc: "学然后知不足，教然后知困", path: "/personalCenter/myUpload/published",},
        {icon: "icon-bijiben2", title: "我的笔记", desc: "君子生非异也，善假于物也", path: "/personalCenter/myNote/noteManage",},
        {icon: "icon-shoucang4", title: "我的收藏", desc: "学而时习之,不亦说乎", path: "/personalCenter/myStar",},
    ];

    const goPage = (path) => {
        historyInstance.push(path)
    }

    return (
        <div className="quickEntranceFrame">
            <span className="moduleName">快捷入口</span>
            <div className="entranceListBox">
                {
                    entranceList.map((item, idx) => {
                        const {icon, title, desc} = item;
                        return (
                            <div key={idx} className="entranceItemBox" onClick={() => goPage(item.path)}>
                                <Iconfont type={icon} />
                                <span className="titleText">{title}</span>
                                <span className="descText">{desc}</span>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default QuickEntrance;
