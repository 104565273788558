import React, {useEffect, useState, useRef, memo} from 'react';
import { Tag, Input, Button, Modal, Form, message, Spin, Space, Tooltip} from 'antd';
import { PlusOutlined, LoadingOutlined, SwapOutlined, SendOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import copy from "copy-to-clipboard";

import { getSearchParams } from '../../../../utils/tool'
import Api from "../../../../api/index";
import Iconfont from "../../../../components/Iconfont/Iconfont";

import './index.scss'

const { TextArea } = Input;

const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  
const DocumentOperations = ({
    showOperateBox,
    selectedText,
    textRange,
    callback,
    NodeDetails,
    textUrl,
    noteType, 
    knowledgeId,
    ExcerptDetails,
    knowledgeDetails,
    currentPage,
    pageType,
    showCloseBtn
}) => {
    const [addForm] = Form.useForm();
    const location = useLocation();
    const lableInputRef = useRef(null);

    const [showNewExtract, setShowNewExtract] = useState(false);

    const [OperateBoxType, setOperateBoxType] = useState(false);

     // 标签
     const [lableList, setLableList] = useState([]);
     const [lableInputVisible, setLableInputVisible] = useState(false);
     const [lableInputValue, setLableInputValue] = useState('');

    // Node
    const [showNewNode, setShowNewNode] = useState(false);

    const [translateType, setTranslateType] = useState(false);
    const [translateResult, setTranslateResult] = useState("");

    const [confirmLoading, setConfirmLoading] = useState(false);

    const [translateToLanguage, setTranslateToLanguage] = useState('en'); // 标识翻译成哪种语言

    const [addNoteModalStyle, setAddNoteModalStyle] = useState({});
    const isMove = useRef(false);
    const startX = useRef(0);
    const startY = useRef(0);

    useEffect(() => {
        document.addEventListener("mousemove", moveFunc);
        document.addEventListener("mouseup", moveEndFunc);
        return () => {
            document.removeEventListener("mousemove", moveFunc)
            document.removeEventListener("mouseup", moveEndFunc)
        }
    }, [])

    useEffect(()=>{
        setOperateBoxType(showOperateBox)
    },[showOperateBox]);

    useEffect(()=>{
        if(NodeDetails){
            // 笔记修改
            setShowNewNode(true);
            setLableList(NodeDetails.noteDetailLabel || []);
            addForm.setFieldsValue({
                "knowledgeNoteTitle":NodeDetails.noteDetailTitle,
                "noteDetailMessage":NodeDetails.noteDetailMessage,
                "noteDetailLabelList":NodeDetails.noteDetailLabel ? NodeDetails.noteDetailLabel.join(",") : "",
            });
        }
        if(ExcerptDetails){
            // 摘录修改
            setLableList(ExcerptDetails.excerptLabels || []);
            addForm.setFieldsValue({
                "knowledgeExcerptType":ExcerptDetails.knowledgeExcerptType,
                "noteDetailLabelList":ExcerptDetails.excerptLabels ? ExcerptDetails.excerptLabels.join(","):"",
            });
            setShowNewExtract(true);
        }
    },[
        NodeDetails,
        ExcerptDetails
    ])

    useEffect(()=>{
        if (lableInputVisible && lableInputRef.current) {
            lableInputRef.current.focus()
        }
    },[lableInputVisible]);


    useEffect(()=>{
        let text = selectedText?.replace(/[\u200B-\u200D\uFEFF]/g, ''); // 去掉某些 具有零宽度的Unicode字符
        if (text?.length > 0) {
            const isChinese = decideIsChinese(text);
            setTranslateToLanguage(isChinese ? "en" : "zh");
        }
    },[selectedText]);


    function decideIsChinese(str){
        let isChinese = false;
        let chineseArr = str.match(/[\u4e00-\u9fa5]/g);
        if (chineseArr?.length >= Math.floor(str.replace(/\s/g, "").length / 2)) {
            isChinese = true;
        }
        return isChinese;
    }


    const onNote = () => {
        addForm.setFieldsValue({
            "knowledgeNoteTitle":"",
            "noteDetailMessage":"",
        });
        setLableList([]);
        setAddNoteModalStyle({});
        setShowNewNode(true);
    }

    const onExtract = () => {
        setShowNewExtract(true);
    }

    const handleLableClose = (removedTag) => {
        const newTags = lableList.filter((tag) => tag !== removedTag);
        addForm.setFieldsValue({
            "noteDetailLabelList":newTags.join(","),
        });
        setLableList(newTags)
    };
    const showLableInput = () => {
        setLableInputVisible(true);
    };
    const handleLableInputChange = (e) => {
        setLableInputValue(e.target.value);
    };
    const handleLableInputConfirm = () => {
        if (lableInputValue && lableList.indexOf(lableInputValue) === -1) {
            setLableList([...lableList, lableInputValue]);
            addForm.setFieldsValue({
                "noteDetailLabelList":[...lableList, lableInputValue].join(","),
            });
        }else{
            addForm.setFieldsValue({
                "noteDetailLabelList":[...lableList].join(","),
            });
        }
        setLableInputVisible(false);
        setLableInputValue('');
    };

    const moveStartFunc = (e) => {
        isMove.current = true;
        startX.current = e.screenX;
        startY.current = e.screenY;
    }

    const moveFunc = (e) => {
        if (isMove?.current && e) {
            const {screenX, screenY} = e;
            let {left, top} = addNoteModalStyle;
            const dom = document.getElementById("addNoteModal");
            const rect = dom.getClientRects()[0];
            if (left === undefined) {
                left = rect.x;
                top = rect.y;
            }
            setAddNoteModalStyle({
                left: left + screenX - startX.current,
                top: top + screenY - startY.current,
            })
            startX.current = screenX;
            startY.current = screenY;
        }
    }

    const moveEndFunc = (e) => {
        isMove.current = false;
    }

    const NodeOk = async() => {
        try {
            let param = {};
            const row = await addForm.validateFields();
            setConfirmLoading(true);
            if(NodeDetails && NodeDetails.id){
                param = {
                    ...row,
                    "noteDetailLabelList":lableList,
                    "id":NodeDetails.id,
                    "knowledgeId": knowledgeId,
                }
            }else{

                let startIndex = "", endIndex = "";

                // 有textRange就是正常选择内容做笔记进入
                if(textRange.length > 0){
                    startIndex = textRange[0];
                    endIndex = textRange[1];
                }
                // ppt 没有textRange，根据当前页做笔记，startIndex默认为0，endIndex为当前做笔记的页码
                else if((knowledgeDetails.fileType === "ppt" || knowledgeDetails.fileType === "pptx") &&  !NodeDetails.endIndex){
                    startIndex = 0;
                    endIndex = currentPage;
                } else{
                    // NodeDetails 为从共享笔记借鉴一下进入
                    startIndex = NodeDetails.startIndex;
                    endIndex = NodeDetails.endIndex;
                }

                param = {
                    ...row,
                    "noteDetailLabelList":lableList,
                    "knowledgeId": knowledgeId,
                    "startIndex":startIndex, // textRange和selectedText没有的话可能是从借鉴一下过来的，用NodeDetails中的数据
                    "endIndex":endIndex,
                    "noteBelongMessage":selectedText ? selectedText : NodeDetails.noteBelongMessage, ///
                    "isOwner":0
                }
            }

            param.type = getSearchParams().type === 'policy' ? 1 : 0 //0知识 1政策）

            const res = await Api.Knowledge.saveKnowledgeNote({data:param});
            setConfirmLoading(false);
            if(res && res.code === 0){
                message.success(NodeDetails && NodeDetails.id ? "修改成功":"新增成功",2,()=>{
                    setShowNewNode(false);
                    setLableList([]);
                    callback("NodeOk");
                })
            }else{}
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    }

    const ExtractCancel = () => {
        addForm.setFieldsValue({});
        setShowNewExtract(false);
        setLableList([]);
        callback("ExtractCancel");
    }

    const NodeCancel = () => {
        addForm.setFieldsValue({});
        setLableList([]);
        callback("NodeCancel");
        setShowNewNode(false);
    }

    const ExtractOk = async() => {
        try {
            
            const row = await addForm.validateFields();

            setConfirmLoading(true);
            let res = "";
            if(ExcerptDetails&&ExcerptDetails.id){
                res = await Api.Knowledge.updateExcerpt({data:{
                    ...row,
                    "knowledgeExcerptMessage":selectedText,
                    "excerptId":ExcerptDetails.id,
                    "knowledgeId": knowledgeId,
                    "excerptLabels":lableList,
                    "type": getSearchParams().type === 'policy' ? 1 : 0 //0知识 1政策
                }});
            }else{
                
                let startIndex = "", endIndex = "";

                // 又textRange就是正常选择内容做笔记进入
                if(textRange.length > 0){
                    startIndex = textRange[0];
                    endIndex = textRange[1];
                }
                // ppt 没有textRange，根据当前页做笔记，startIndex默认为0，endIndex为当前做笔记的页码
                else if((knowledgeDetails.fileType === "ppt" || knowledgeDetails.fileType === "pptx") &&  !ExcerptDetails.endIndex){
                    startIndex = 0;
                    endIndex = currentPage;
                } else{
                    // NodeDetails 为从共享笔记借鉴一下进入
                    startIndex = ExcerptDetails.startIndex;
                    endIndex = ExcerptDetails.endIndex;
                }

                res = await Api.Knowledge.addExcerpt({data:{
                    ...row,
                    // "knowledgeExcerptMessage":selectedText,
                    "knowledgeId": knowledgeId,
                    "excerptLabels":lableList,
                    "startIndex":startIndex, // textRange和selectedText没有的话可能是从借鉴一下过来的，用NodeDetails中的数据
                    "endIndex":endIndex,
                    "knowledgeExcerptMessage":selectedText ? selectedText : ExcerptDetails.knowledgeExcerptMessage,
                    "type": getSearchParams().type === 'policy' ? 1 : 0 //0知识 1政策 
                }});
            }
            setConfirmLoading(false);
            if(res && res.code === 0){
                message.success((ExcerptDetails&&ExcerptDetails.id) ? "修改成功": "新增成功",2,()=>{
                    setShowNewExtract(false);
                    setLableList([]);
                    callback("ExtractOk");
                })
            }else{}
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    }

    const onTranslate = async() =>{
        setTranslateType(true);
        try {
            let res = await Api.Knowledge.getTransResult({data:{
                "q":selectedText,
                "appType": '1',
                "to":translateToLanguage
            }});
            if(res && res.code === 0){
                setTranslateResult(res.data.transResult);
            }else{}
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    const claerTranslate = () => {
        setTranslateType(false);
        setTranslateResult("");
        callback("translate");
    }

    const switchTanslateFrom = () => {
        setTranslateResult("")
        setTranslateToLanguage(translateToLanguage === 'en' ? 'zh' : 'en');
    }

    const copyFunc = () => {
        const dom = document.getElementById("translateContentText");
        if (dom) {
            const text = dom.innerText;
            copy(text,{
                message: "复制失败，请升级浏览器！",
                format: "text/plain",
                debug: true,
                onCopy: () => {
                    message.success("复制成功！")
                }
            });
        }
    }

    return(
        <div className="documentOperations"
            style={OperateBoxType?{}:{display:'none'}}
        >

            {knowledgeDetails.fileType === "ppt" || knowledgeDetails.fileType === "pptx"?
            <div className="operateBoxPPT" style={pageType === "multiAi" ? {top: '130px'}: {top: '65px'}}>
                <Tooltip placement="top" title={"想法"}>
                    <div className="operateButton" onClick={onNote}>
                        <Iconfont type={'icon-bijiben1'}/>
                    </div>
                </Tooltip>
                {/* PPT不做摘录 */}
                {/* <Tooltip placement="top" title={"摘录"}>
                    <div className="operateButton"onClick={onExtract}>
                        <Iconfont type={'icon-zhailu'}/>
                    </div>
                </Tooltip> */}
            </div>:

            <div 
                className="operateRightBox"
                style={{
                    visibility: (showNewExtract || showNewNode || translateType) ? "hidden" : "",
                    top: pageType === "multiAi" ? "130px" : "65px"
                }}
            >
                <Tooltip placement="left" title={"想法"}>
                    <div className="operateButton" onClick={onNote}>
                        <Iconfont type={'icon-bijiben1'}/>
                    </div>
                </Tooltip>
                <Tooltip placement="left" title={"摘录"}>
                    <div className="operateButton"onClick={onExtract}>
                        <Iconfont type={'icon-zhailu'}/>
                    </div>
                </Tooltip>
                
                {textUrl ? (
                    <Tooltip placement="left" title={"翻译"}>
                        <div className="operateButton"onClick={onTranslate}>
                            <img
                                className="operateBoxIcon"
                                style={{height: '20px', marginTop: '1px',}}
                                src={textUrl}
                            />
                        </div>
                    </Tooltip>
                ):""}
            </div>
            // <div 
            //     className="operateBox"
            //     style={{visibility: (showNewExtract || showNewNode || translateType) ? "hidden" : ""}}
            // >
            //     <Space>
            //         <Button type="primary" ghost onClick={onNote}>
            //         <Iconfont className="operateBoxIcon" type={'icon-bijiben1'}/>想法
            //         </Button>
            //             <Button type="primary" ghost onClick={onExtract}>
            //             <Iconfont className="operateBoxIcon" type={'icon-zhailu'}/>摘录</Button>
            //             {textUrl ? (
            //                 <Button type="primary" ghost onClick={onTranslate}>
            //                     <img
            //                         className="operateBoxIcon"
            //                         style={{height: '20px', marginTop: '1px',}}
            //                         src={textUrl}
            //                     />
            //                 翻译</Button>
            //             ):""}
            //     </Space>
            // </div>
            }
            


            {ExtractOk&&<Modal 
                title="新增摘录" 
                open={showNewExtract} 
                onOk={ExtractOk} 
                onCancel={ExtractCancel}
                maskClosable={false}
                confirmLoading={confirmLoading}
                destroyOnClose={true}
            >
                <Form
                    form={addForm}
                    component={false}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                    preserve={false}
                >
                    <Form.Item
                        key={'knowledgeExcerptType'}
                        label={'摘录标题'}
                        name={'knowledgeExcerptType'}
                        rules={[
                        () => ({
                            validator(_, value) {
                            if (!value || value.length <= 30) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                new Error("标题最多不超过30个字!")
                            );
                            },
                        }),
                        ]}
                    >
                        <Input placeholder="请输入标题（选填）"/>
                    </Form.Item>

                    <Form.Item
                        label={"标签"}
                        key={"noteDetailLabelList"}
                        name={'noteDetailLabelList'}
                        className="noteLabelList"
                        // required
                        rules={[{ required:  true, message: `请添加标签!` }]}
                    >
                        <div>
                            {lableList.map((item,index)=>{
                                return <Tag key={index} closable={true}
                                            onClose={(e) => {
                                                e.preventDefault();
                                                handleLableClose(item);
                                            }} key={item}>{item}</Tag>
                            })}
                            {lableInputVisible&& (
                                <Input
                                    ref={lableInputRef}
                                    type="text"
                                    size="small"
                                    style={{
                                        width: 78,
                                    }}
                                    value={lableInputValue}
                                    onChange={handleLableInputChange}
                                    onBlur={handleLableInputConfirm}
                                    onPressEnter={handleLableInputConfirm}
                                />
                            )}
                            {!lableInputVisible&& (
                                <Tag
                                    onClick={showLableInput}
                                    className="site-tag-plus"
                                    style={{cursor: "pointer"}}
                                ><PlusOutlined /></Tag>
                            )}
                        </div>
                    </Form.Item>
                    
                </Form>
            </Modal>}


            {showNewNode&&<Modal
                title="添加想法"
                open={showNewNode}
                maskClosable={false}
                maskStyle={{background: "none"}}
                confirmLoading={confirmLoading}
                forceRender={true}
                wrapClassName="addNoteModalWrapBox"
                transitionName={""}
                maskTransitionName={""}
                modalRender={() => {
                  return (
                      <div className="addNoteModal" style={addNoteModalStyle} id="addNoteModal">
                          <div className="modalHeader" onMouseDown={moveStartFunc}><span>添加想法</span></div>
                          <div className="modalBody">
                              <Form
                                  form={addForm}
                                  component={false}
                                  labelCol={{ span: 4 }}
                                  wrapperCol={{ span: 20 }}
                                  preserve={false}
                              >
                                <Form.Item
                                    key={'knowledgeNoteTitle'}
                                    label={'想法'}
                                    name={'knowledgeNoteTitle'}
                                    rules={[
                                    () => ({
                                        validator(_, value) {
                                        if (value.length <= 30) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error("标题最多不超过30个字!")
                                        );
                                        },
                                    }),
                                    ]}
                                >
                                    <Input placeholder="请输入标题（选填）"/>
                                </Form.Item>
                                <Form.Item
                                    label={'想法内容'}
                                    key={'noteDetailMessage'}
                                    name={'noteDetailMessage'}
                                    rules={[{ required:  true, message: `请输入想法内容!` }]}
                                >
                                    <TextArea rows={10}  placeholder="输入内容"/>
                                </Form.Item>
                                <Form.Item
                                    label={"标签"}
                                    key={"noteDetailLabelList"}
                                    name={'noteDetailLabelList'}
                                    className="noteLabelList"
                                    //   required
                                    rules={[{ required:  true, message: `请添加标签!` }]}
                                >
                                    <div>
                                        {lableList.map((item,index)=>{
                                            return <Tag key={index} closable={true}
                                                        onClose={(e) => {
                                                            e.preventDefault();
                                                            handleLableClose(item);
                                                        }} key={item}>{item}</Tag>
                                        })}
                                        {lableInputVisible&& (
                                            <Input
                                                ref={lableInputRef}
                                                type="text"
                                                size="small"
                                                style={{
                                                    width: 78,
                                                }}
                                                value={lableInputValue}
                                                onChange={handleLableInputChange}
                                                onBlur={handleLableInputConfirm}
                                                onPressEnter={handleLableInputConfirm}
                                            />
                                        )}
                                        {!lableInputVisible&& (
                                            <Tag
                                                onClick={showLableInput}
                                                className="site-tag-plus"
                                                style={{cursor: "pointer"}}
                                            ><PlusOutlined /></Tag>
                                        )}
                                    </div>
                                </Form.Item>
                              </Form>
                          </div>
                          <div className="modalFooter">
                            <Space>
                                <Button onClick={
                                    showNewNode?NodeCancel:ExtractCancel
                                }>取 消</Button>
                                <Button onClick={
                                    showNewNode?NodeOk:ExtractOk
                                } type="primary" loading={confirmLoading}>确 定</Button>
                            </Space>
                          </div>
                      </div>
                  )
                }}
            >
            </Modal>}

            <Modal 
                title="翻译结果" 
                open={translateType} 
                onOk={claerTranslate} 
                onCancel={claerTranslate} 
                maskClosable={false}
                wrapClassName={"translateModalClass"}
            >
                <div className="translateSwitch">
                    <span className="text">{translateToLanguage === 'en' ? '中文' : '英文'}</span>
                    <SendOutlined />
                    <span className="text">{translateToLanguage === 'en' ? '英文' : '中文'}</span>
                </div>
                <div>原文：{selectedText}</div>
                {translateResult ? (
                    <div className="translateContentBox">
                        翻译：<span id={"translateContentText"}>{translateResult}</span><span className="copyBtn" onClick={copyFunc}>复制</span>
                    </div>
                ):<div><Spin indicator={antIcon} /><span>翻译中...</span></div>}
            </Modal>
        </div>
    )
}

export default DocumentOperations;