import createStore from "zustand";

const zuStandStore = createStore((set, get) => {
    return {
        // 文件进度
        uploadFileList: [],
        fileProcessObject: {},
        fileUploadStatus: {},
        fileOtherData: {},
        fileBelongSpaceObject: {},
        uploadOpen: false,
        modalStatus: "small",
        knowledgeId: null,
        linkStatus: null, // success/closed/stop/fail/null
        isShowAiChat: false,
        choiceFileModalIsShow: false, // 选择知识文件上传的弹窗是否显示
        spaceTypeIds: [], // 空间分类ids
        selectedSpaceTypeId: null, // 选中的单个空间分类id
        isFlush: false,


        changeUploadStateFunc: (myStore) => {
            return set(myStore || {})
        },

        getUploadStateFunc: () => {
            return get()
        },

        getIsShowAiChat () {
            return get().isShowAiChat;
        },

        changeIsShowAiChat (isShow) {
            return set({isShowAiChat: isShow})
        },

        changeChoiceFileModalIsShow (flag) {
            return set({choiceFileModalIsShow: flag})
        },

        changeSpaceTypeIds (ids) {
            return set({
                spaceTypeIds: ids,
                selectedSpaceTypeId: ids?.length === 1 ? ids[0] : null,
            })
        },
    }
})

export {zuStandStore};
