import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, List, message, Input } from "antd";
import { CaretDownOutlined } from '@ant-design/icons';

import Api from "../../../../api";
import Iconfont from "../../../../components/Iconfont/Iconfont";
import './index.scss';

const { TextArea } = Input;

const ApprovalModal = ({checkId, onCancelBack, checkStatus, flowType}) => {
    const [modalOpen, setModalOpen] = useState(false);

    const [checkMessage, setCheckMessage] = useState({});
    const [okLoading, setOkLoading] = useState(false);
    const [rejectLoading, setRejectLoading] = useState(false);

    const [isEdit, setIsEdit] = useState(false);
    const [checkOption, setCheckOption] = useState(null);
    const textareaRef = useRef(null);

    useEffect(()=>{
        if(checkId){
            getCheckMessage(checkId);
        }
    },[checkId, flowType])

    useEffect(() => {
        if (isEdit) {
          textareaRef.current.focus();
        }
    }, [isEdit]);

    const getCheckMessage = async (id) => {
        try {
            let res = null;
            if(String(flowType) === '1'){
                // 流程平台
                res = await Api.Knowledge.getWorkflowDetailByCheckIdPlatform({
                    data: { checkId: id },
                  });
            }else{
                res = await Api.Knowledge.getWorkflowDetailByCheckId({
                    data: { checkId: id },
                  });
            }
        
          if (res && res.code === 0) {
            setModalOpen(true);

            let approvalProcessUsers = [];

            res.data.workflowUsers.map(item=>{
                let names = [], steps = {};

                item.map(items=>{
                    names.push(items.checkUserName)
                })

                steps = {
                  ...item[0],
                  names,
                }
                approvalProcessUsers.push(steps)
            })

            var departmentNamesArr = [...res.data.shareKnowledgeDepartmentNames];
                departmentNamesArr.sort(function (a, b) {
                    return b.length - a.length ; // 按照文本长度从大到小排序
                });

            res.data.shareKnowledgeDepartmentNames = departmentNamesArr;

            res.data.approvalProcessUsers = [...approvalProcessUsers];

            setCheckMessage(res.data);
          }
        } catch (error) {

        }
    };

    // 审批弹框标题组件
    const titleContent = (
        <div className="modalTitleContent">
            <Iconfont
                type={"icon-chuizi"}
                iStyle={{ marginRight: "0.5rem", fontSize: "1.3rem", color: "#49A9EE" }}
            />
            <span>申请审核流程</span>
        </div>
    );

    const handleOk = async () => {
        let data = {
          checkId: checkId,
          checkOption:checkOption?checkOption:"同意",
          type: 1
        };
          try {
            setOkLoading(true);

            let res = null;
            if(String(flowType) === '1'){
                data.checkType = data.type
                // 流程平台
                res = await Api.Message.updateApplyTypePlatform({ data });
            }else{
                res = await Api.Message.updateApplyType({ data });
            }
            
            if (res.data) {
                message.success("操作成功");
                setOkLoading(false);
                setModalOpen(false);
                setIsEdit(false);
                setCheckOption("");
                onCancelBack();
                // 更新消息总数以及待办消息列表
                document.dispatchEvent(new CustomEvent("notify"));
                document.dispatchEvent(new CustomEvent("checkToDoMessage"));
            }else{
              setOkLoading(false);
            }
          } catch (error) {
            setOkLoading(false);
            setModalOpen(false);
          }
      };

    const handleReject = async () => {
        let data = { checkId: checkId, checkOption, type: 0 };
        if (checkOption) {
          try {
            setRejectLoading(true);
            let res = null;
            if(String(flowType) === '1'){
                // 流程平台
                data.checkType = data.type
                res = await Api.Message.updateApplyTypePlatform({ data });
            }else{
                res = await Api.Message.updateApplyType({ data });
            }
            if (res.data) {
                message.success("操作成功");
                setRejectLoading(false);
                setModalOpen(false);
                setIsEdit(false);
                setCheckOption("");
                onCancelBack();
                // 更新消息总数以及待办消息列表
                document.dispatchEvent(new CustomEvent("notify"));
                document.dispatchEvent(new CustomEvent("checkToDoMessage"));
            }
          } catch (error) {
            setModalOpen(false);
          }
          setModalOpen(false);
        } else {
          message.info("请输入审批意见后,再进行审批哦");
        }
    };

    const handleCancel = () => {
        setModalOpen(false);
        onCancelBack();
    };

    // 上级审批人批签
    const superiorOpinionMessage = (item) => {
        return (
        <div className="opinionMessageS">
            <div className="checkOptionS">
                {item.checkOption}
            </div>
            <div className="optionInfoS">
                <span>{item.checkUserName}</span>
                <span>批准于{item.updateTime}</span>
            </div>
        </div>);
    }

    // 当前审批人批签
  const opinionMessage = (
    <div className="opinionMessage">
      {isEdit ? (
        <TextArea
          ref={textareaRef}
          placeholder="请输入审批意见"
          showCount
          maxLength={100}
          style={{ width: "100%", height: "80%" }}
          value={checkOption}
          onChange={(e) => setCheckOption(e.target.value)}
        />
      ) : (
        <div
          onClick={() => {
            setIsEdit(true);
          }}
        >
          <Iconfont
            type={"icon-bianji"}
            iStyle={{ color: "#AAAAAA", fontSize: "1rem" }}
          ></Iconfont>
          请批签
        </div>
      )}
    </div>
  );
    console.log(checkMessage);

    return (
        <Modal
                open={modalOpen}
                title={titleContent}
                onOk={handleOk}
                onCancel={handleCancel}
                maskClosable={false}
                destroyOnClose={true}
                width={520}
                wrapClassName={"approvalModalModal selfDesignModal"}
                footer={[
                    <Button
                        danger
                        key="back"
                        loading={rejectLoading}
                        onClick={handleReject}
                        disabled={okLoading}
                    >驳回</Button>,
                    <Button
                        key="submit"
                        type="primary"
                        loading={okLoading}
                        onClick={handleOk}
                        disabled={rejectLoading}
                    >通过</Button>,
                ]}
            >
                    <div className="detailsTitle">基本信息</div>

                    <div className="detailsItem">
                        <div className="title">申请时间</div>
                        <div className="content">{checkMessage.createTime}</div>
                    </div>

                    <div className="detailsItem">
                        <div className="title">申请人</div>
                        <div className="content">{checkMessage.userName}</div>
                    </div>

                    <div className="detailsItem">
                        <div className="title">申请人工号</div>
                        <div className="content">{checkMessage.jobNum}</div>
                    </div>

                    <div className="detailsItem">
                        <div className="title">申请人所在部门</div>
                        <div className="content">{checkMessage.userDepartmentName}</div>
                    </div>

                    <div className="detailsTitle">申请详情</div>

                    <div className="detailsItem">
                        <div className="title">申请类型</div>
                        <div className="content">{
                            checkMessage.applyForType === 1 ? "下载" :
                            checkMessage.applyForType === 2 ? "发布知识" :
                            checkMessage.applyForType === 3 ? "取消发布知识" :
                            checkMessage.applyForType === 4 ? "阅读权限" : 
                            checkMessage.applyForType === 5 ? "更新知识" : ""
                        }</div>
                    </div>
                    <div className="detailsItem">
                        <div className="title">知识名称</div>
                        <div className="content">
                            {checkMessage.knowledgeName}
                            <Button type="primary" size={'small'} onClick={()=>{
                                checkStatus(checkMessage)
                            }}>查看</Button>
                        </div>
                    </div>
                    <div className="detailsItem">
                        <div className="title">知识类型</div>
                        <div className="content">{checkMessage.knowledgeTypeName}</div>
                    </div>
                    <div className="detailsItem">
                        <div className="title">分享范围</div>
                        <div className="content">{checkMessage.shareKnowledgeDepartmentNames&&checkMessage.shareKnowledgeDepartmentNames.map(item=>{
                            return <span className="shareDepartmentNames">{item}</span>
                        })}</div>
                    </div>

                    <div className="detailsTitle">流转意见</div>

                    <List itemLayout="horizontal">
                        {checkMessage.approvalProcessUsers&&checkMessage.approvalProcessUsers.map(item=>{
                            return(<List.Item>
                            <List.Item.Meta
                                avatar={
                                    <div className="avatar">
                                        <div className={`currentStatus names`} title={item.names.join("/")}>
                                            {item.names.join("/")}
                                        </div>

                                        {item.checkType === 1 &&
                                            <div className="arrowIcon"
                                                style={{borderColor:"#1890ff"}}
                                            >
                                                <CaretDownOutlined
                                                    style={{color:"#1890ff"}}
                                                />
                                            </div>
                                        }
                                    </div>
                                }
                                title={
                                    <div className="userDepartmentInfo">
                                        <span>
                                        {item.departmentName}
                                        </span>
                                        <span>{item.businessRoleName}</span>
                                    </div>
                                }
                                description={item.checkType === 1?superiorOpinionMessage(item):opinionMessage}
                            />
                            </List.Item>)
                        })
                        }
                    </List>
                </Modal>
    )
}

export default ApprovalModal
