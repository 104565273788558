import { Tabs } from 'antd';
import React, { useState, useEffect } from 'react';
import InitData from "./initData";
import UserSpaceList from "./userSpaceList";
import BackIcon from "../../../components/BackIcon/index";

import './index.scss';

const CloudSpace = () => {
    const items = [
        {key: "userSpaceList", label: "用户空间管理", children: <UserSpaceList />},
        {key: "initData", label: "容量配置", children: <InitData />},
    ];

    const [activeKey, setActiveKey] = useState(null);

    const onChange = (activeKey) => {
        setActiveKey(activeKey)
        sessionStorage.setItem("activeCloudKey", activeKey);
    }

    useEffect(() => {
        let activeCloudKey = sessionStorage.getItem("activeCloudKey");
        if (activeCloudKey) {
            onChange(activeCloudKey);
        } else {
            onChange("userSpaceList");
        }
    }, [])

    return (
        <div className="cloudSpaceFrame">
            <div className="cloudSpaceTabs">
                <Tabs activeKey={activeKey} onChange={onChange} items={items} destroyInactiveTabPane></Tabs>
            </div>
            <BackIcon />
        </div>
    );
};
export default CloudSpace;
