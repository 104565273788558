import React,{useState} from 'react';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from "react-redux";
import Iconfont from "../Iconfont/Iconfont";
import { setAIModalState } from "../../features/AIIntelligent/AIReducer";

import "./index.scss"

const BackIcon = ({position = "right"}) => {
    const dispatch = useDispatch();
    const goBack = () => {
        history.go(-1);
        
        dispatch(setAIModalState({
            modalOpen:false,
            questionLocation: null,
            knowledgeIds: [],
            historyTopicId:null,
            QAtype:""
        }))
    }

    return (
        <Iconfont 
            type={"icon-fanhui"} 
            className="backButton"
            onClick={goBack}
            iStyle={position == "left" ? {left:"1rem"} : {right:"1rem"}}
        />
    );
}


export default BackIcon;
