import React, {useState} from 'react';
import {Button, message, Select, Input} from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import Image from "../../components/Image/Image";
import Iconfont from '../Iconfont/Iconfont';
import {serachType} from '../../utils/defaultValue'
import storage from "../../utils/storage";
import KnowledgeTitle from '../../assets/img/knowledgeTitle.png'
import { setAIModalState } from "../../features/AIIntelligent/AIReducer";

import './index.scss';

const TopSearchBar = ({type}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [selecthValue, setSelecthValue] = useState('1');

    const [searchValue, setSearchValue] = useState('');

    const onSearchValueChange = (e) => {
        setSearchValue(e.target.value)
    }

    const onSelect = (value) => {
        setSelecthValue(value);
    }

    const onSearch = () => {
        let searchValueTrim = searchValue.trim()
        // if(searchValueTrim){
            storage.setItem('searchData', JSON.stringify({
                text:searchValueTrim,
                searchType:selecthValue
            }));
            history.push('/page/knowledgeSearch');
        // }else{
        //     message.info('请输入需要搜索的内容')
        // }
        
    }

    const onAISearch = () => {
        let searchValueTrim = searchValue.trim();
        
        let param = {
            modalOpen:true,
            questionLocation:1, //1 知识中心
            knowledgeIds:[], 
            queryText:"推荐问题",
            questionModule:"summary",
            relQuestions:2,
            refLoc: true, //开启来源定位
            sendAPI: Date.now().toString(),
            multiChatId:null,
            historyTopicId:null,
            selectKnowledgeParam:{},
            knowledgeEsParam:{},
            knowledgePublishedParam:{},
        }
        
        if(searchValueTrim){
            param.queryText = searchValueTrim;
            param.questionModule = "ragRetrieve";
            param.relQuestions = 0;
        }
        
        dispatch(setAIModalState(param))
    }

    return (
        <div className='topSearchBar'>
            <Image imgSrc={KnowledgeTitle} iClassName={type?"KnowledgeTitleIndex":"KnowledgeTitle"} />
            <div className="searchBox">
                <div className="searchBar">
                    <Select 
                        value={selecthValue || '全文'}
                        size={"large"} 
                        options={[...serachType]}  
                        onChange={onSelect}
                        bordered={false}
                        style={{width:100}}>
                    </Select>
                    <Input
                        value={searchValue}
                        placeholder="输入关键字"
                        size={"large"}
                        bordered={false}
                        onChange={onSearchValueChange}
                        onPressEnter={onSearch}
                    />
                    <Button 
                    onClick={onSearch} 
                    type="primary" 
                    size={"large"}>搜索一下</Button>
                </div>
                <div className="AiBtnBox">
                    <Button 
                        icon={<Iconfont type="icon-waixingren" />}
                        onClick={onAISearch} 
                        type="primary" 
                        size={"large"}>
                            AI帮我找
                    </Button>
                </div>
            </div>
        </div>    
    );
}
export default TopSearchBar;
