import React from "react";
import { List } from "antd";
const SystemMessage = ({ systemList }) => {
  return (
    <div className="systemMessageFrames">
      <List
        size="small"
        dataSource={systemList}
        renderItem={(item) => <List.Item>{item.notificationMessage}</List.Item>}
      ></List>
    </div>
  );
};

export default SystemMessage;
