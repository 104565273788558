import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Form, Input, Button, Radio, Space, Checkbox, Select, Modal, Table, DatePicker, Switch } from 'antd';
import {
    FileTextOutlined, FileWordOutlined, FileZipOutlined,
    FilePptOutlined, FilePdfOutlined, FileExcelOutlined,
    FileJpgOutlined
} from '@ant-design/icons';
import moment from 'moment'


import Api from "../../../api";
import SelfTable from "../../../components/SelfTable"
import {pagingParam} from "../../../utils/defaultValue";
import BackIcon from "../../../components/BackIcon/index";
import {geThisYearDateRange, gePreviousYear, getLastYear} from '../../../utils/tool'
import ExportExcel from '../../../utils/ExportExcel'
import KnowledgeFileIcon from "../../../components/KnowledgeFileIcon/index";
import Iconfont from '../../../components/Iconfont/Iconfont'

import './index.scss'

const { RangePicker } = DatePicker;

const KnowledgeList = () => {

    const formatData = [
        {
            value: 'pdf',
            label: 'pdf',
        },
        {
            value: 'word',
            label: 'word',
        },
        {
            value: 'excel',
            label: 'excel',
        },
        {
            value: 'ppt',
            label: 'ppt',
        },
        {
            value: 'online',
            label: '在线文档',
        },
    ]

    const checkboxOptions = [
        {
            label: '知识名称',
            value: 'knowledgeName',
        },
        {
            label: '格式类型',
            value: 'fileType',
        },
        {
            label: '所属部门',
            value: 'departmentName',
        },
        {
            label: '知识类型',
            value: 'knowledgeTypeName',
        },
        {
            label: '部门分类',
            value: 'departmentSubclassificationName',
        },
        {
            label: '作者',
            value: 'authors',
        },
        {
            label: '创建人',
            value: 'creatorName',
        },
        {
            label: '访问链接',
            value: 'accessLink',
        },
    ];
    
    const [form] = Form.useForm();

    const [searchValue, setSearchValue] = useState('');

    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });

    // 列表数据
    const [listData, setListData] = useState([]);

    const [tableLoading, setTableLoading] = useState(false);


    // 查询数据
    const [searchData, setSearchData] = useState({
        ...pagingParam,
    });

    const [operationData, setOperationData] = useState({});
    // 部门
    const [department, setDepartment] = useState([]);
    // 知识类型
    const [knowledgeList, setKnowledgeList] = useState([]);

    const [userList, setUserList] = useState([]);

    // 列表选择数据
    const [selectedRowKey, setSelectedRowKey] = useState([]);

    const [exportModalOpen, setExportModalOpen] = useState(false);
    const [timeFrame, setTimeFrame] = useState('');
    const [checkedList, setCheckedList] = useState([
        "knowledgeName","fileType","departmentName","knowledgeTypeName","departmentSubclassificationName",
        "authors","creatorName","accessLink"
    ]);
    const [dataRange, setDataRange] = useState([]);
    
    const [confirmLoading, setConfirmLoading] = useState(false);

    const columns = [
        Table.EXPAND_COLUMN,
        {
            title: '知识名称',
            dataIndex: 'knowledgeName',
            width: '',
            editable: true,
        },
        {
            title: '格式类型',
            dataIndex: 'fileType',
            width: '100px',
            editable: true,
        },
        {
            title: '所属部门',
            dataIndex: 'departmentName',
            width: '170px',
            editable: true,
        },

        {
            title: '知识类型',
            dataIndex: 'knowledgeTypeName',
            width: '100px',
            editable: true,
        },
        {
            title: '部门分类',
            dataIndex: 'departmentSubclassificationName',
            width: '170px',
            editable: true,
        },
        {
            title: '作者',
            dataIndex: 'authors',
            width: '100px',
            editable: true,
        },

        {
            title: '创建人',
            dataIndex: 'creatorName',
            width: '100px',
            filters: userList,
            filterMultiple:false,
            filteredValue:searchData.creator ? [searchData.creator] : [],
            editable: true,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            width: '160px',
            sorter: true,
            sortOrder: searchData.sort,
            editable: true,
        },
        {
            title: '是否开启下载',
            width: '130px',
            dataIndex: 'downPerm',
            render: (status,records) => {
                return records.fileType != "online" ? <Switch 
                checkedChildren="开启" 
                unCheckedChildren="关闭" 
                checked={status === 1 ? 0 : 1} 
                loading={confirmLoading}
                onChange={()=>onSwitchChange(records)} 
                /> : ""
            }
        },
    ];

    useEffect(() => {
        getList();
    }, [searchData]);

    useEffect(() => {
        setInitDate();
    }, []);


    const cycleData = (data) => {
        data.map(item=>{
            item.value = item.userId;
            item.text = item.userName+'-'+item.roleName;
        })
        return data
    }

    const onSwitchChange = async (records) => {
        setConfirmLoading(true);
        try {
            let param = {
                knowledgeId:records.id,
                downPerm: records.downPerm == 1 ? 0 : 1
            }
            const res = await Api.Knowledge.setKnowledgeDownPerm({data:param});
            setConfirmLoading(false);
            if(res.code === 0){
                getList();
            }
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    }

    const setInitDate = async() => {
        // 获取所有用户
        const res = await Api.Common.getAllDepUser({data:{}});
        if(res&&res.code === 0&& res.data){
            let _data = res.data;
            _data.map(item=>{
                item.value = item.id;
                item.text = item.name;
                item.children = item.users
                if(item.users){
                    item.children = cycleData(item.users)
                }
            })
            setUserList(_data)
        }else{
            setUserList([])
        }

        
        //  flag = 1 需要查询班子成员
        const resDepartment = await Api.Department.getDepartmentListAll({data:{
            flag:1
        }});
        if(resDepartment && resDepartment.code === 0){
            let newData = resDepartment.data.map(item=>{
                return{
                    value: item.id,
                    label: item.name,
                    text: item.name,
                }
            });
            newData.push({
                value: '',
                label: '无',
            })
            setDepartment(newData);
        }
        
        // 知识类型
        const resKnowledge = await Api.Knowledge.getAllKnowledgeList({});
        if(resKnowledge && resKnowledge.code === 0){
            let newData = resKnowledge.data.map(item=>{
                return{
                    value: item.id,
                    label: item.knowledge_type_name,
                    text: item.knowledge_type_name,
                }
            });
            setKnowledgeList(newData);
        }
    }

    const getList = async() => {
        setTableLoading(true);
        let newSearchData = {
            ...searchData
        }
        if(newSearchData.sort){
            newSearchData.sort = newSearchData.sort ==='descend' ? 0 : newSearchData.sort === 'ascend' ? 1 : 0
        }
        const res = await Api.Knowledge.getInventoryPage({data:newSearchData});
        setTableLoading(false);
        if(res&&res.code === 0){
            let _data = res.data;
            setListData(_data.records);
            setPagination({
                "pageIndex": _data.current,
                "pageSize": _data.size,
                "total":_data.total
            });
        }else{
            setListData([]);
        }
    }


    // 搜索框
    const changeValue = (e) => {
        setSearchValue(e.target.value)
    }

    const onSearch = () => {
        let newSearchData = {...searchData}
        newSearchData.pageIndex = 1;
        newSearchData.knowledgeName = searchValue;
        setSearchData({
            ...newSearchData,
            ...operationData
        });
    }

    const onResetting = () => {
        setSearchValue("");
        setSearchData({
            ...pagingParam,
            "knowledgeName":"",
        })
        setOperationData({})
    }


    const disabledDate = (current) => {
        // Can not select days after today and today
        return current && current > moment().endOf('day');
    };

    const searchChange = (value, type) => {
        let newOperationData = {...operationData};
        if(type === "timeRange"){
            newOperationData.startTime = value[0];
            newOperationData.endTime = value[1];
        }else{
            newOperationData[type] = value;
        }
        newOperationData.pageIndex = 1;
        setOperationData(newOperationData)
    }
    
    const onChanges = (pagination, filters, sorter) => {
        let newSearchData = {...searchData}

        newSearchData.pageIndex = pagination.current ? pagination.current : 1;
        newSearchData.pageSize = pagination.pageSize ? pagination.pageSize : 10;

        newSearchData.sort = sorter.order;
        
        if(filters.creatorName){
            newSearchData.creator = filters.creatorName[0]
        }

        setSearchData(newSearchData);
    };

    const onCheckboxChange = (checkedValue) => {
        setCheckedList(checkedValue)
    }

    const exportExcel = () => {
        if(selectedRowKey && selectedRowKey.length > 0){
            queryExportList()
        }else{
            setExportModalOpen(true);
        }
    }

    const handleOk = () => {
        setExportModalOpen(false);

        queryExportList();
    };

    const queryExportList = async() => {

        let data = {
            ids: selectedRowKey
        };

        if(dataRange.length >0){
            data.startTime = dataRange[0];
            data. endTime = dataRange[1];
        }

        const res = await Api.Knowledge.getExportInventory({data});
        
        if(res&&res.code === 0){
            let _data = res.data;
            exportList(_data);
        }
    }

    const exportList = async(dataList) => {

        let exportColumns = [];
        columns.map(item=>{
            if(checkedList.includes(item.dataIndex)){
                exportColumns.push(item)
            }
        })

        if(checkedList.includes("accessLink")){
            exportColumns.push({
                title: '访问链接',
                dataIndex: 'accessLink',
            })
        }

        ExportExcel([
            {
                column: exportColumns,
                dataSource: dataList,
                options:{
                    colWidth: 8,
                    fontSize: 10,
                    isBold: true
                }
            }
        ],"已发布知识列表")
    }


    const handleCancel = () => {
        setExportModalOpen(false);
    };

    const onRadioChange = (e) => {
        setTimeFrame(e.target.value)
        let type = e.target.value;
        if(type == 2){
            // 今年以来
            setDataRange(geThisYearDateRange())
        }else if(type == 3){
            // 上一年度
            setDataRange(gePreviousYear())
        }else if(type == 4){
            // 最近一年
            setDataRange(getLastYear())
        }else{
            setDataRange([])
        }
    }

    const rangePickerChange = (dates, dateStrings) => {
        setDataRange(dateStrings)
    }

    return (
        <div className="publishedKnowledgeListFrame">
             <div className="title">已发布知识</div>
            <div className="operation">
                <div className="operationLeft">
                    <div className="operationItem">
                        <span>选择时段：</span>
                        <RangePicker 
                        disabledDate={disabledDate}
                        value={operationData.startTime?[moment(operationData.startTime), moment(operationData.endTime)]:[]}
                        onChange={(date, dateString)=>{
                            searchChange(dateString,"timeRange")
                        }}/>
                    </div>
                    <div className="operationItem">
                        <span>格式类型：</span>
                        <Select
                            style={{width: 120,}}
                            value={operationData.fileType}
                            onChange={(value)=>{
                                searchChange(value,"fileType")
                            }}
                            options={formatData}
                            placeholder="格式类型"
                            allowClear
                        />
                    </div>
                    <div className="operationItem">
                        <span>所属部门：</span>
                        <Select
                            style={{width: 120,}}
                            value={operationData.departmentId}
                            onChange={(value)=>{
                                searchChange(value,"departmentId")
                            }}
                            options={department}
                            placeholder="所属部门"
                            allowClear
                        />
                    </div>
                    <div className="operationItem">
                        <span>知识类型：</span>
                        <Select
                            style={{width: 120,}}
                            value={operationData.knowledgeTypeId}
                            onChange={(value)=>{
                                searchChange(value,"knowledgeTypeId")
                            }}
                            options={knowledgeList}
                            placeholder="知识类型"
                            allowClear
                        />
                    </div>
                    <div className="operationItem">
                        <Input
                            placeholder="输入关键词搜索"
                            onChange={changeValue}
                            onPressEnter={onSearch}
                            value={searchValue}
                        />
                    </div>
                    <div className="operationItem" style={{width:170}}>
                        <Space size={"large"}> 
                            <Button type="primary" onClick={onSearch}>搜索</Button>
                            <Button onClick={onResetting}>重置</Button>
                        </Space>
                    </div>
                </div>
                
                <div className="operationRight">
                    <Space size={"large"}>
                        <span>共找到 {pagination.total}篇</span>
                        <Button type="primary" onClick={exportExcel}>{selectedRowKey && selectedRowKey.length > 0?"导出选中项目":"导出知识清单"}</Button>
                    </Space>
                </div>
            </div>
            <BackIcon />
            <div className="table">
                <Form form={form} component={false}>
                    <SelfTable
                        dataSource={listData}
                        columns={columns}
                        loading={tableLoading}
                        rowRadioSelection={{
                            selectedRowKey:selectedRowKey,
                            setSelectedRowKey:setSelectedRowKey,
                            type:"checkbox",
                            preserveSelectedRowKeys:true
                        }}
                        pagination={pagination}
                        onChange={onChanges} 
                        expandable={{
                            // childrenColumnName:"childList",
                            expandedRowRender: (record) => {
                                return <div className="expandableList">
                                    {record.childList.map((childItem,index)=>{
                                        return(
                                            <div className="childrenlistItemHeader"
                                            >
                                                {/* 文件图标 */}
                                                <KnowledgeFileIcon
                                                    fileType={childItem.fileType}
                                                    isPic={childItem.isPic}
                                                />

                                                {/* 标题 */}
                                                <div className="listTitle">
                                                {childItem.knowledgeName}
                                                {/* .{childItem.fileType} */}
                                                </div>
                                            </div>
                                        )
                                        })}
                                </div>
                            },
                            rowExpandable: (record) => record.childList,
                        }}
                    />
                </Form>
            </div>
            <Modal title="导出列表" wrapClassName="exportModalWrap" open={exportModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <p className="exportTitle">选择要导出的字段</p>
                <div className="checkboxGroup">
                    <Checkbox.Group 
                        value={checkedList} 
                        options={checkboxOptions} 
                        onChange={onCheckboxChange}
                    />
                </div>
                <p className="exportTitle">选择要导出的时段</p>
                <div className="checkboxGroup">
                    <Radio.Group onChange={onRadioChange}>
                        <Space direction="vertical">
                            <Radio value={1}>全部时段</Radio>
                            <Radio value={2}>今年以来</Radio>
                            <Radio value={3}>上一年度</Radio>
                            <Radio value={4}>最近一年</Radio>
                            <Radio value={5}>自定义</Radio>
                        </Space>
                    </Radio.Group>
                    
                    {timeFrame === 5 ? (<div style={{marginTop:15}}>
                        选择时段：<RangePicker onChange={rangePickerChange}/>
                    </div>) :""}
                    
                </div>
            </Modal>
        </div>
    );
};
export default KnowledgeList;
