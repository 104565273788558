import React, { useState, useEffect } from "react";
import { Form, Input, Popconfirm, Typography, message ,InputNumber} from "antd";

import Api from "../../../../api";
import SelfTable from "../../../../components/SelfTable/index";
// import "./index.scss";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  // const inputNode = <InputNumber />;
  const inputNode = typeof(children[1]) === "number" ? <InputNumber min={1} /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `请输入${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ScoreForStudyTable = () => {
  const [form] = Form.useForm();
  const [answerScoreList, setAnswerScoreList] = useState([]);

  // 当前正在操作的数据KEY
  const [editingKey, setEditingKey] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  useEffect(() => {
    getUserScoreConvertCoefficientList();
  }, []);
  const getUserScoreConvertCoefficientList = async () => {
    try {
      setTableLoading(true);
      let res = await Api.Points.getKnowledgeStudyScore();
      console.log(res, "计分规则表");
      if (res && res.code === 0) {
        if (res.data) {
          setAnswerScoreList([res.data]);
        } else {
          setAnswerScoreList([
            {
              id: new Date().getTime(),
              readScore: null,
              readDurationScore: null,
              downloadScore: null,
              collectionScore: null,
              questionScore: null,
              noteScore: null,
            },
          ]);
        }
        setEditingKey("");
        setTableLoading(false);
      }
    } catch (error) {}
  };
  const isEditing = (record) => record.id === editingKey;
  // 修改
  const edit = (records) => {

    console.log(records, "recordsrecordsrecords回填");

    // 回填数据
    form.setFieldsValue({
      ...records,
    });
    setEditingKey(records.id);
  };
  // 取消
  const cancel = () => {
    setEditingKey("");
  };
  // 修改-保存
  const save = async (records) => {
    console.log(records);
    try {
      const row = await form.validateFields();
      setTableLoading(true);
      // row.id = records.id;
      //   row.ruleName = records.ruleName;
      const res = await Api.Points.setKnowledgeStudyScore({
        data: row,
      });
      if (res && res.code === 0) {
        message.success("修改成功", 2, () => {
          getUserScoreConvertCoefficientList();
        });
      } else {
        setTableLoading(false);
      }
    } catch (errInfo) {
      setTableLoading(false);
      console.log("验证失败:", errInfo);
    }
  };
  const columns = [
    // {
    //   title: "评价指标",
    //   dataIndex: "ruleName",
    //   // editable: true,
    // },
    {
      title: "阅读知识(篇)",
      dataIndex: "readScore",
      editable: true,
    },
    {
      title: "阅读时长(小时)",
      dataIndex: "readDurationScore",
      editable: true,
    },
    {
      title: "下载知识(次)",
      dataIndex: "downloadScore",
      editable: true,
    },
    {
      title: "收藏知识(次)",
      dataIndex: "collectionScore",
      editable: true,
    },
    {
      title: "提出问题(次)",
      dataIndex: "questionScore",
      editable: true,
    },
    {
      title: "记录笔记(条)",
      dataIndex: "noteScore",
      editable: true,
    },
    {
      title: "操作",
      render: (_, records) => {
        const editable = isEditing(records);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(records)}
              style={{
                marginRight: 8,
              }}
            >
              保存
            </Typography.Link>
            <Popconfirm title="取消后数据将不会保存" onConfirm={cancel}>
              <a>取消</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(records)}
          >
            调整
          </Typography.Link>
        );
      },
    },
  ];

  return (
    <>
      <Form form={form} component={false}>
        <SelfTable
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={columns}
          dataSource={answerScoreList}
          isEditing={isEditing}
          loading={tableLoading}
          footerHeight={55}
        ></SelfTable>
      </Form>
    </>
  );
};

export default ScoreForStudyTable;
