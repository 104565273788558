import myAxios from '../utils/request.js';

export default {
    // 待办消息
    async getCheckToDoMessage (params={}, cb) {
        let res = await myAxios.postJson('/checkManagement/getCheckToDoMessage', params);
        return res.data;
    },
    // 系统消息
    async getSystemMessage (params={}, cb) {
        let res = await myAxios.postJson('/notificationManagement/getSystemMessage', params);
        return res.data;
    },
    // 互动消息
    async getInteractionMessage (params={}, cb) {
        let res = await myAxios.postJson('/notificationManagement/getInteractionMessage', params);
        return res.data;
    },
    // 审核消息
    async getCheckMessage (params={}, cb) {
        let res = await myAxios.postJson('/notificationManagement/getCheckMessage', params);
        return res.data;
    },
    // 获取通知的未读数量
    async getStatusNum (params={}, cb) {
        let res = await myAxios.postJson('/notificationManagement/getStatusNum', params);
        return res.data;
    },
    // 根据审核ID获取申请信息
    async getCheckMessageByCheckId (params={}, cb) {
        let res = await myAxios.postJson('/checkManagement/getCheckMessageByCheck', params);
        return res.data;
    },
    // 通过/驳回
    async updateApplyType (params={}, cb) {
        let res = await myAxios.postJson('/checkManagement/updateApplyType', params);
        return res.data;
    },
    // 通过/驳回  流程平台
    async updateApplyTypePlatform (params={}, cb) {
        let res = await myAxios.postJson('/applyWf/skip', params);
        return res.data;
    },

    // 临时授权
    // 获取可进行临时授权的人选
    // /checkAuthorizationManagement/getAuthorizationUserMessage
    async getAuthorizationUserMessage (params={}, cb) {
        let res = await myAxios.postJson('/checkAuthorizationManagement/getAuthorizationUserMessage', params);
        return res.data;
    },
    // 获取当前授权信息
    async getCheckAuthorizationUser (params={}, cb) {
        let res = await myAxios.postJson('/checkAuthorizationManagement/getCheckAuthorizationUser', params);
        return res.data;
    },
    // 调整授权信息--也可新增
    async updateCheckAuthorizationUser (params={}, cb) {
        let res = await myAxios.postJson('/checkAuthorizationManagement/updateCheckAuthorizationUser', params);
        return res.data;
    },
    // 获取我的申请 applyManagement/getOwnerApply
    async getOwnerApply(params = {}, cb) {
        let res = await myAxios.postJson("/applyManagement/getOwnerApply", params)
        return res.data
    },
    // 修改消息状态为已读
    async changeNotificationIsReadById(params = {}, cb) {
        let res = await myAxios.getJson("/notificationManagement/changeNotificationIsReadById", params)
        return res.data
    },
    // 检查当前消息的知识状态
    async checkMessageStatus(params = {}, cb) {
        let res = await myAxios.getJson("/knowledgeManagement/getActualState", params)
        return res.data
    },
    // 根据条件获取 评论与回复分页数据
    async questNotificationPage(params = {}, cb) {
        let res = await myAxios.postJson("/notificationManagement/questNotificationPage", params)
        return res.data
    },
    // 获取系统消息
    async getSystemNotification(params = {}, cb) {
        let res = await myAxios.postJson("/notificationManagement/systemNotificationPage", params)
        return res.data
    }
    
}
