import React, { useEffect, useState, useRef } from "react";
import {
  Spin,
  Empty,
  Modal,
  Input,
  Tag,
  Tooltip,
  message,
  Form,
} from "antd";
import {
  PlusOutlined,
} from "@ant-design/icons";

import KnowledgeFileIcon from "../../../../../components/KnowledgeFileIcon";
import SelfPagination from "../../../../../components/SelfPagination";
import Iconfont from "../../../../../components/Iconfont/Iconfont";
import Ueditor from "../../../../../components/Ueditor/Ueditor";
import NoteEllipsis from "../../../components/NoteEllipsis";
import {jumpToPage, ModalConfirm} from "../../../../../utils/tool";
import Api from "../../../../../api";
import "./index.scss";

const ExcerptList = ({searchValue, type}) => {

  const [extractForm] = Form.useForm();
  const lableInputRef = useRef(null);

  const [list, setList] = useState([]);
  const [total, setTotal] = useState(null);

  const [siftData, setSiftData] = useState({
    pageIndex: 1,
    pageSize: 10,
  });

  // 控制笔记内容的展开
  const [isSpinning, setIsSpinning] = useState(true);
  // 获取笔记参数
  const [noteData, setNoteData] = useState({});
  // 文档标签
  const [tags, setTags] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [expandKeyObject, setExpandKeyObject] = useState({});

  // 笔记标签
  const [lableList, setLableList] = useState([]);
  const [lableInputVisible, setLableInputVisible] = useState(false);
  const [lableInputValue, setLableInputValue] = useState('');

  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    getNoteOriginal();
  }, [siftData]);

  useEffect(() => {
    let _siftData = { ...siftData };
    _siftData.searchMessage = searchValue;
    _siftData.pageIndex = 1;
    setSiftData(_siftData);
  }, [searchValue]);

  // 获取原文管理数据
  const getNoteOriginal = async () => {
    setIsSpinning(true);
    try {
      let res = await Api.PersonalCenter.getMyExcerptList({
        data: siftData,
      });
      if (res.data && res.code === 0) {
        let _data = res.data;

        // 默认全部展开
        let expandKey = {};
        for (let item of _data.records) {
            expandKey[item.knowledgeId] = 1;
        }
        setExpandKeyObject(expandKey);

        setList(_data.records);
        setTotal(_data.total);
        setIsSpinning(false);
      }
    } catch (error) {
      setIsSpinning(false);
    }
  };

  // 编辑弹框
  const showModal = ({ obj, knowledgeId }) => {

    setLableList(obj.excerptLabels || []);
    extractForm.setFieldsValue({
        "knowledgeExcerptType":obj.knowledgeExcerptType,
        "excerptLabels":obj.excerptLabels ? obj.excerptLabels.join(",") : "",
    });
    setNoteData({
      id: obj.id,
      knowledgeId: knowledgeId,
    });
    setIsModalOpen(true);
  };

  const ExtractOk = async() => {
    try {
        const row = await extractForm.validateFields();
        if(lableList&&lableList.length<=0){
          message.info("请添加标签！")
          return false;
        }
        setConfirmLoading(true);
        let res = await Api.Knowledge.updateExcerpt({data:{
          ...row,
          "excerptId":noteData.id,
          "knowledgeId": noteData.knowledgeId,
          "excerptLabels":lableList
      }});
        setConfirmLoading(false);
        if(res && res.code === 0){
            message.success("修改成功",2,()=>{
              setIsModalOpen(false);
              getNoteOriginal();
            })
        }else{}
    } catch (errInfo) {
        setConfirmLoading(false);
        console.log('验证失败:', errInfo);
    }
}

const ExtractCancel = () => {
  setIsModalOpen(false);
}

  const deleteNote = async (obj) => {
    ModalConfirm({
      title: "确认删除这条摘要吗？",
      onOk: async (resolve, reject) => {
        try {
          let res = await Api.Knowledge.deleteExcerpt({
            data: { id: obj.id },
          });
          resolve();
          if (res.data) {
            getNoteOriginal();
            message.success("删除成功");
          }
        } catch (error) {}
      },
    });
  };

  const changePage = (page, pageSize) => {
    let _siftData = { ...siftData };
    _siftData.pageIndex = page;
    _siftData.pageSize = pageSize;
    setSiftData(_siftData);
  };

  const handleLableClose = (removedTag) => {
    const newTags = lableList.filter((tag) => tag !== removedTag);
    extractForm.setFieldsValue({
      "excerptLabels":newTags.join(","),
  });
    setLableList(newTags)
  };
  const showLableInput = () => {
      setLableInputVisible(true);
  };
  const handleLableInputChange = (e) => {
      setLableInputValue(e.target.value);
  };
  const handleLableInputConfirm = () => {
      if (lableInputValue && lableList.indexOf(lableInputValue) === -1) {
          setLableList([...lableList, lableInputValue]);
          extractForm.setFieldsValue({
            "excerptLabels":[...lableList, lableInputValue].join(","),
          });
      }
      setLableInputVisible(false);
      setLableInputValue('');
  };

  const goKnowlageDetail = async(item) => {
    let res = await Api.Message.checkMessageStatus({
      data: {
          id: item.knowledgeId,
      },
    });
    if(res.code === 0){
      // // -1已被删除 0已拒绝，1已通过，2待审批，3草稿文章
      let _code = res.data;
      if(_code === -1){
          message.info("当前知识已被删除");
      }else if(_code === 1){
          jumpToPage({...item, id: undefined, type: ""});
      }else{
          message.info("当前知识已取消发布")
      }
    }
  }

  return (
    <div className="excerptListFrame">
      <div className="originalManage">
        <Spin spinning={isSpinning} size="large">

              {list && list.length > 0 ? <>
                {
                  list.map((item) => {

                    let isExpand = expandKeyObject[item.knowledgeId];
                    return (
                      <div className="knowledge" key={item.knowledgeId}>
                        <div className="title" onClick={() => {
                                let expandKey = {...expandKeyObject,[item.knowledgeId]:1};
                                if (isExpand) {
                                    delete expandKey[item.knowledgeId]
                                }
                                setExpandKeyObject(expandKey)
                            }}>
                          <div style={{flex:1}}>
                            <KnowledgeFileIcon
                              fileType={item.fileType}
                            ></KnowledgeFileIcon>
                            <span>{item.knowledgeName}</span>
                          </div>

                          <span className={`expandSortBtn ${isExpand ? 'expandStatus' : ''}`}><Iconfont type={"icon-right"} /></span>
                        </div>

                        <div className="noteView" style={isExpand?{}:{maxHeight: 0}}>

                          {item.knowledgeExcerptManagements &&
                            item.knowledgeExcerptManagements.map((obj) => {

                              return (
                                <div
                                  className="noteDetail"
                                  key={obj.id}
                                >
                                  <div className="topTitle">
                                    <div className="noteName"><Iconfont type={'icon-wj-bjb'} iStyle={{flex:1}}/>{obj.knowledgeExcerptType}</div>

                                    {!type&&(<div className="noteOpr">
                                      <Iconfont type={'icon-bianji2'}
                                        onClick={(e) => {
                                          showModal({
                                            obj,
                                            knowledgeId: item.knowledgeId,
                                            type: "edit",
                                          })
                                        }}
                                      />
                                      <Iconfont type={'icon-shanchu1'}
                                        onClick={() => deleteNote(obj)}
                                      />

                                    </div>)}
                                  </div>

                                  <div className="noteMessage"
                                    onClick={(e) =>
                                      goKnowlageDetail(item)
                                    }
                                  >
                                    <NoteEllipsis
                                      text={obj.knowledgeExcerptMessage}
                                      // noteBelongMessage={obj.noteBelongMessage}
                                    ></NoteEllipsis>
                                  </div>

                                  <div className="labelList">
                                    {obj.excerptLabels&&obj.excerptLabels.map(item=>{
                                      return <span>{item}</span>
                                    })}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })
                }
                <div className="pager">
                  {total / siftData.pageSize > 0 ? (
                    <SelfPagination
                      total={total * 1}
                      onSizeChange={changePage}
                      current={siftData.pageIndex}
                    ></SelfPagination>
                  ) : null}
                </div>
              </>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              {/* 分页器 */}
        </Spin>
      <Modal
        title="修改摘录"
        open={isModalOpen}
        onOk={ExtractOk}
        onCancel={ExtractCancel}
        maskClosable={false}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
    >
        <Form
            form={extractForm}
            component={false}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            preserve={false}
        >
            <Form.Item
                key={'knowledgeExcerptType'}
                label={'摘录标题'}
                name={'knowledgeExcerptType'}
                rules={[
                () => ({
                    validator(_, value) {
                    if (!value || value.length <= 30) {
                        return Promise.resolve();
                    }
                    return Promise.reject(
                        new Error("标题最多不超过30个字!")
                    );
                    },
                }),
                ]}
            >
                <Input placeholder="单击键入标题（选填）"/>
            </Form.Item>

            <Form.Item
                label={"标签"}
                key={"excerptLabels"}
                name={'excerptLabels'}
                className="noteLabelList"
                // required
                rules={[{ required:  true, message: `请添加标签!` }]}
            >
                <div>
                    {lableList.map((item,index)=>{
                        return <Tag key={index}  closable={true}
                                    onClose={(e) => {
                                        e.preventDefault();
                                        handleLableClose(item);
                                    }} key={item}>{item}</Tag>
                    })}
                    {lableInputVisible&& (
                        <Input
                            ref={lableInputRef}
                            type="text"
                            size="small"
                            style={{
                                width: 78,
                            }}
                            value={lableInputValue}
                            onChange={handleLableInputChange}
                            onBlur={handleLableInputConfirm}
                            onPressEnter={handleLableInputConfirm}
                        />
                    )}
                    {!lableInputVisible&& (
                        <Tag
                            onClick={showLableInput}
                            className="site-tag-plus"
                            style={{cursor: "pointer"}}
                        ><PlusOutlined /></Tag>
                    )}
                </div>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default ExcerptList;
