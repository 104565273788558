import React from "react";
import Axios from "axios";
import {Modal, Spin} from "antd";
import storage from "./storage.js";

let errorModal = null;
let modalInfo = null;

// Axios.defaults.baseURL = 'https://yt44869160.oicp.vip/api';
// Axios.defaults.baseURL = 'https://kbf.aicon.com.cn/api';
// Axios.defaults.baseURL = 'http://10.76.7.74:8080';
Axios.defaults.baseURL = "/api";
Axios.defaults.timeout = 30000;
Axios.defaults.headers.post = {
    "X-Requested-With": "XMLHttpRequest",
    mode: "json",
};
Axios.defaults.headers.get = {
    "X-Requested-With": "XMLHttpRequest",
    mode: "json",
};

// 添加请求拦截器
Axios.interceptors.request.use(
    function (config) {
        if (config.loading) {
            loading(config.loadingText);
        }

        // 在发送请求之前做些什么
        config.headers.satoken = storage.getItem("token");
        const configUrl = config.url;
        if (configUrl.indexOf("/chat/third") > -1 || configUrl.indexOf("/chat/files") > -1) {
            config.baseURL = "";
            config.headers.SysName = "CISDI_KNOWLEDGE_SYSTEM";
            config.headers.SysSecret = "0F728C5C24A991034299D70ABE7149E9";
        }
        return config;
    },
    function (error) {
        closeLoading();
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
Axios.interceptors.response.use(function (response) {
        // if(response.config.responseType != "blob"){
        // 对响应数据做点什么
        // delete response.config;
        // delete response.headers;
        // delete response.request;
        // }
        const {code, msg} = response.data || {};

        const {noVerify} = response.config || {};

        closeLoading();
        // 400 登录失效
        if ((code === 400 || code < 0) && !errorModal && !noVerify) {
            errorModal = true;
            return Modal.error({
                content: msg,
                centered: true,
                footer: null,
                keyboard: false,
                maskClosable: false,
                okText: "确定",
                onOk: function () {
                    errorModal = null;
                    if (code === 400) {
                        storage.removeItem("token");
                        storage.removeItem("WebSocketSubProtocol");
                        sessionStorage.prePathname = `${location.pathname}${location.search}`;
                        location.replace("/login?isLogin=0");
                    }
                },
            });
        }
        return response;
    },
    function (error) {
        closeLoading();
        // 对响应错误做点什么
        if (error && error.response && !errorModal) {
            errorModal = true;
            Modal.error({
                content: error.response.data.msg,
                centered: true,
                footer: null,
                keyboard: false,
                maskClosable: false,
                okText: "确认",
                onOk: function () {
                    errorModal = null;
                    // location.replace('/login');
                },
            });
        }
        return Promise.reject(error);
    }
);

function getJson(url, options = {}) {
    let opt = {...options};
    opt.params = options.data;
    delete opt.data;

    return new Promise((resolve, reject) => {
        Axios.get(url, opt)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

function postJson(url, options = {}) {
    let opt = {...options};
    const data = opt.data || opt.params || {};
    delete opt.data;
    delete opt.params;
    return new Promise((resolve, reject) => {
        Axios.post(url, data, opt)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

function deleteJson(url, options = {}) {
    let opt = {...options};
    opt.params = options.data;
    delete opt.data;
    return new Promise((resolve, reject) => {
        Axios.delete(url, opt)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

function downloadJson(url, options = {}) {
    let opt = {...options, 'responseType': 'blob'};
    const data = opt.data || opt.params || {};
    delete opt.data;
    delete opt.params;
    return new Promise((resolve, reject) => {
        Axios.post(url, data, opt).then((res) => {
            resolve(res);
        }).catch((error) => {
            reject(error);
        })
    });
}

function downloadGetJson(url, options = {}) {
    let opt = {...options, 'responseType': 'blob'};
    opt.params = options.data;
    delete opt.data;
    return new Promise((resolve, reject) => {
        Axios.get(url, opt).then((res) => {
            resolve(res);
        }).catch((error) => {
            reject(error);
        })
    });
}

function axiosFunc(options = {}) {
    let opt = {...options};
    return new Promise((resolve, reject) => {
        Axios(opt).then((res) => {
            resolve(res);
        }).catch((error) => {
            reject(error);
        })
    });
}

const axiosFailFun = (error) => {
    const {response = {}} = error || {};
    if (response.status) {
        switch (response.status) {
            case 400:
                error.message = "请求错误(400)";
                break;
            case 401:
                error.message = "未授权，请重新登录(401)";
                break;
            case 403:
                error.message = "拒绝访问(403)";
                break;
            case 404:
                error.message = "请求出错(404)";
                break;
            case 408:
                error.message = "请求超时(408)";
                break;
            case 500:
                error.message = "服务器错误(500)";
                break;
            case 501:
                error.message = "服务未实现(501)";
                break;
            case 502:
                error.message = "网络错误(502)";
                break;
            case 503:
                error.message = "服务不可用(503)";
                break;
            case 504:
                error.message = "网络超时(504)";
                break;
            case 505:
                error.message = "HTTP版本不受支持(505)";
                break;
            default:
                error.message = `连接出错(${error.response.status})!`;
        }
        return error;
    }
};

function loading(loadingText) {
    modalInfo = Modal.info({
        title: null,
        icon: null,
        content: <div className="loadingBox"><Spin size={"large"} tip={loadingText || "数据加载中"}/></div>,
        centered: true,
        className: 'loadingModal',
        maskStyle: {
            backgroundColor: 'rgba(0,0,0,0.7)'
        }
    })
}

function closeLoading() {
    if (modalInfo) {
        console.log(modalInfo);
        modalInfo.destroy();
        modalInfo = null;
    }
}

export default {
    getJson,
    postJson,
    axiosFunc,
    deleteJson,
    downloadJson,
    downloadGetJson
};
