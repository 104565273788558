import { Form, Input, Button, Popconfirm, Space, Typography, Select, Modal, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import React, { useState, useEffect, useRef } from 'react';

import './index.scss'
import Api from "../../../../api";
import {pagingParam} from "../../../../utils/defaultValue";
import SelfTable from "../../../../components/SelfTable/index"

const UserList = ({tableHight}) => {
    const [form] = Form.useForm();
    // 角色
    const [role, setRole] = useState([]);
    // 列表数据
    const [listData, setListData] = useState([]);
    const [defaultDate, setDefaultDate] = useState([]);
    // 当前正在操作的数据KEY
    const [editingKey, setEditingKey] = useState('');

    const [searchValue, setSearchValue] = useState('');
    // 查询数据
    const [searchData, setSearchData] = useState({
        ...pagingParam,
        "jobOrName":"",
    });
    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });

    const [tableLoading, setTableLoading] = useState(false);

    useEffect(() => {
        setInitDate();
    }, []);

    useEffect(() => {
        getList();
    }, [searchData]);

    const setInitDate = async() => {
        const resRole = await Api.Roles.getAllSysRolesList({});
        if(resRole && resRole.code === 0){
            let newData = resRole.data.map(item=>{
                return{
                    value: item.id,
                    label: item.name,
                }
            });
            setRole(newData);
        }
    }

    // 获取列表数据
    const getList = async() => {
        setTableLoading(true);
        const res = await Api.User.getUserList({data:searchData});
        setTableLoading(false);
        if(res&&res.code === 0){
            let _data = res.data;
            setListData(_data.records);
            setDefaultDate(_data.records);
            setPagination({
                "pageIndex": _data.current,
                "pageSize": _data.size,
                "total":_data.total
            });
            setEditingKey('');
        }else{
            setListData([]);
            setDefaultDate([]);
        }
    }

    const isEditing = (record) => record.id === editingKey;

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        changeType,
        record,
        index,
        children,
        ...restProps
    }) => {
        const inputNode =<Select
            options={role}
        />;

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        key={dataIndex}
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: changeType === `请选择${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                        children
                    )}
            </td>
        );
    };

    // 修改
    const edit = (record) => {
        form.setFieldsValue({
            ...record,
        });
        setEditingKey(record.id);
    };

    // 取消
    const cancel = () => {
        setListData([...defaultDate])
        setEditingKey('');
    };

    // 保存
    const save = async (key) => {
        try {
            const row = await form.validateFields();
            setTableLoading(true);
            row.userId = key;
            const res = await Api.Roles.addUserRole({data:row});
            if(res&&res.code === 0){
                message.success("修改用户信息成功",2,()=>{
                    getList();
                })
            }else{
                setTableLoading(false);
            }

        } catch (errInfo) {
            setTableLoading(false);
            console.log('验证失败:', errInfo);
        }
    };

    // 搜索框
    const changeValue = (e) => {
        setSearchValue(e.target.value)
    }
    const onSearch = () => {
        let newSearchData = {...searchData}
        newSearchData.pageIndex = 1;
        newSearchData.jobOrName = searchValue;
        setSearchData(newSearchData);
    }
    // 分页变化
    const paginationChange = (page, pageSize) => {
        let newSearchData = {...searchData}
        newSearchData.pageIndex = page;
        newSearchData.pageSize = pageSize;
        setSearchData(newSearchData);
    }


    const columns = [
        // {
        //     title: '序号',
        //     dataIndex: 'index',
        //     width: '70px',
        //     editable: false,
        //     render:(text, record, index)=>{
        //         return index+1
        //     }
        // },
        {
            title: '工号',
            dataIndex: 'jobNum',
            width: '100px',
            editable: false,
        },
        {
            title: '姓名',
            dataIndex: 'userName',
            width: '100px',
            editable: false,
        },
        {
            title: '所属部门',
            dataIndex: 'departmentId',
            width: '',
            editable: false,
            render:(text, record, index)=>{
                return record.departmentName
            },
            changeType:"select"
        },
        {
            title: '职务',
            dataIndex: 'jobId',
            width: '',
            editable: false,
            render:(text, record, index)=>{
                return record.jobName
            },
            changeType:"select"
        },
        {
            title: '系统角色',
            dataIndex: 'roleId',
            width: '',
            editable: true,
            render:(text, record, index)=>{
                return record.roleName
            },
            changeType:"select"
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            width: '200px',
            editable: false,
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            width: '160px',
            editable: false,
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: '100px',
            editable: false,
            render:(text, record, index)=>{
                // 状态 0正常 1暂停 2注销 3删除
                return text === 0 ? '正常' :
                text === 1 ? '暂停' :
                text === 2 ? '注销' :
                text === 3 ? '删除':"";
            },
            changeType:"select"
        },
        {
            title: '操作',
            width: '140px',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record.id)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            保存
                        </Typography.Link>
                        <Popconfirm title="取消后数据将不会保存" onConfirm={cancel}>
                            <a>取消</a>
                        </Popconfirm>
                    </span>
                ) : (
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                            调整
                        </Typography.Link>
                    );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        col.align="center";
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                changeType: col.changeType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const onResetting = () => {
        setSearchValue("");
        setSearchData({
            ...pagingParam,
            "jobOrName":"",
        })
    }

    return (
        <div className="userListFrame">
            <div className="title">用户管理</div>
            <div className="operation">
                <Space size={"large"}>
                    <div className="operationItem">
                        <Input
                            placeholder="输入用户姓名、工号进行搜索"
                            onChange={changeValue}
                            onPressEnter={onSearch}
                            style={{width:350,float:"right"}}
                            value={searchValue}
                            // allowClear
                            // suffix={
                            //     <SearchOutlined onClick={onSearch} />
                            // }
                        />
                    </div>
                    <Button type="primary" onClick={onSearch}>搜索</Button>
                    <Button onClick={onResetting}>重置</Button>
                </Space>
                {/* <div className="operationRight">
                    <Button type="primary" onClick={addUser}>新增用户</Button>
                </div> */}
            </div>
            <div className="table">
                <Form form={form} component={false}>
                    <SelfTable
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        dataSource={listData}
                        columns={columns}
                        loading={tableLoading}
                        pagination={{
                            ...pagination,
                            onChange: paginationChange,
                        }}
                        isEditing={isEditing}
                    />
                    {/* <Table
                        rowKey="id"
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        // sticky={true}
                        scroll={{
                            scrollToFirstRowOnChange:true,
                            y:tableHight
                        }}
                        bordered
                        dataSource={listData}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        pagination={{
                            total:pagination.total,
                            current:pagination.pageIndex,
                            pageSize:pagination.pageSize,
                            onChange: paginationChange,
                            showSizeChanger:true,
                            showQuickJumper:true,
                            showTotal:()=> `总计${pagination.total}条数据`
                        }}
                        loading={tableLoading}
                    /> */}
                </Form>
            </div>
        </div>
    );
};
export default UserList;
