import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { message, Empty, Spin, Modal } from "antd";

import KnowledgeItem from "../components/KnowledgeItem";
import { ModalConfirm , KnowledgeApply} from "../../../utils/tool";
import AllNumView from "../components/allNumView";
import Api from "../../../api";
import "./index.scss";

const index = () => {

  const [readList, setReadList] = useState([]);
  const [uploadList, setUploadList] = useState([]);
  const [isSpinning, setIsSpinning] = useState(true);

  useEffect(() => {
    (async () => {
      let res2 = await Api.PersonalCenter.getUserKnowledgeRead({ pageSize: 4 });
      getList();
      setReadList(res2.data.records.slice(0, 4));
    })();
  }, []);
  // 获取数据
  const getList = async () => {
    setIsSpinning(true);
    let res = await Api.PersonalCenter.getUserKnowledgeUpload({
      pageSize: 4,
    });
    setIsSpinning(false);
    if(res&&res.data&&res.data.records&&res.data.records.length>0){
      setUploadList(res.data.records.slice(0, 4));
    }
    
  };
  // 取消发布
  const showModal = (knowledgeId) => {
    ModalConfirm({
      title: "确定取消发布这条知识吗？",
      onOk: async (resolve, reject) => {
        try {
          let applyRres = await KnowledgeApply(knowledgeId, 3);
          if(applyRres.bool){
            let res = await Api.Knowledge.reviewApply({
                data: {
                  knowledgeId,
                  applyForType:3
                },
            });
            if(res && res.code === 0){
                message.success("取消发布成功");
                getList();
            }else{reject();}
          }else{
            getList();
          }
          resolve();
          // let res = await Api.PersonalCenter.cancelPublish({
          //   data: { knowledgeId },
          // });
          // if (res && res.code === 0) {
          //   resolve();
          //   getList();
          //   message.success("取消发布成功");
          // } else {
          // }
        } catch {}
      },
    });
  };
  return (
    <div className="myHomeWrap">
      <div className="dataOverview">
        <AllNumView></AllNumView>
      </div>
      <div className="readView">
        <div className="topTitle">
          <p>最近阅读</p>
          <NavLink to="/personalCenter/myRead">更多&gt;</NavLink>
        </div>
        <div className="showView">
          {isSpinning ? (
            <Spin size="large" />
          ) : (
            <>
              {readList.length > 0 ? (
                <KnowledgeItem list={readList} type={"read"}></KnowledgeItem>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </>
          )}
        </div>
      </div>
      <div className="upload">
        <div className="topTitle">
          <p>最近发布</p>
          <NavLink to="/personalCenter/myUpload/published">更多&gt;</NavLink>
        </div>
        <div className="showView">
          {isSpinning ? (
            <Spin size="large" />
          ) : uploadList.length > 0 ? (
            <KnowledgeItem
              list={uploadList}
              type={"published"}
              showModal={(knowledgeId) => showModal(knowledgeId)}
            ></KnowledgeItem>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </div>
    </div>
  );
};

export default index;
