import React, { useState, useEffect } from "react";
import { Form, Input, Popconfirm, Typography, message, Popover,InputNumber } from "antd";
import { QuestionCircleTwoTone } from "@ant-design/icons";
import Api from "../../../../api";
import SelfTable from "../../../../components/SelfTable/index";
// import "./index.scss";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  // const inputNode =  <InputNumber />;
  const inputNode = typeof(children[1]) === "number" ? <InputNumber min={1} /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `请输入${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ScoreForAnsweringTable = () => {
  const [form] = Form.useForm();
  const [answerScoreList, setAnswerScoreList] = useState([]);

  // 当前正在操作的数据KEY
  const [editingKey, setEditingKey] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  useEffect(() => {
    getUserScoreConvertCoefficientList();
  }, []);
  const getUserScoreConvertCoefficientList = async () => {
    try {
      setTableLoading(true);
      let res = await Api.Points.getAnswerQuestionScore();
      console.log(res, "计分规则表");
      if (res && res.code === 0) {
        if (res.data) {
          setAnswerScoreList([res.data]);
        } else {
          setAnswerScoreList([
            {
              id: new Date().getTime(),
              baseScore: null,
              readScore: null,
              likeScore: null,
              commentScore: null,
            },
          ]);
        }
        setEditingKey("");
        setTableLoading(false);
      }
    } catch (error) {}
  };
  const isEditing = (record) => record.id === editingKey;
  // 修改
  const edit = (records) => {
    console.log(records, "recordsrecordsrecords回填");
    // 回填数据
    form.setFieldsValue({
      ...records,
    });
    setEditingKey(records.id);
  };
  // 取消
  const cancel = () => {
    setEditingKey("");
  };
  // 修改-保存
  const save = async (records) => {
    // console.log(records);
    try {
      const row = await form.validateFields();
      setTableLoading(true);
      const res = await Api.Points.setAnswerQuestionScore({
        data: row,
      });
      if (res && res.code === 0) {
        message.success("修改成功", 2, () => {
          getUserScoreConvertCoefficientList();
        });
      } else {
        setTableLoading(false);
      }
    } catch (errInfo) {
      setTableLoading(false);
      console.log("验证失败:", errInfo);
    }
  };
  const columns = [
    {
      title: "基础得分",
      dataIndex: "baseScore",
      editable: true,
    },
    {
      title: "被阅读量(次)",
      dataIndex: "readScore",
      editable: true,
    },
    {
      title: "收获点赞量(次)",
      dataIndex: "likeScore",
      editable: true,
    },
    {
      title: "收获评论量(条)",
      dataIndex: "commentScore",
      editable: true,
    },
    {
      title: "操作",
      render: (_, records) => {
        const editable = isEditing(records);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(records)}
              style={{
                marginRight: 8,
              }}
            >
              保存
            </Typography.Link>
            <Popconfirm title="取消后数据将不会保存" onConfirm={cancel}>
              <a>取消</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(records)}
          >
            调整
          </Typography.Link>
        );
      },
    },
  ];
  const ScoreForAnsweringContent = (
    <ol style={{ padding: "1rem 0 1rem 1rem" }}>
      <li>
        当用户自行删除回答，已获取的积分中基础得分部分将被扣回，其他积分不受影响。
      </li>
      <li>当问答被取消收藏、取消点赞、删除评论，已获取的相应积分将被扣回</li>
    </ol>
  );
  return (
    <div className="scoreForAnsweringTableFrame">
      <div className="tableTitleSmall">
        <span>计分规则</span>
        <Popover
          placement="rightTop"
          title={"规则说明"}
          content={ScoreForAnsweringContent}
          trigger="click"
        >
          <QuestionCircleTwoTone twoToneColor="#FFD700" />
        </Popover>
      </div>
      <Form form={form} component={false}>
        <SelfTable
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={columns}
          dataSource={answerScoreList}
          isEditing={isEditing}
          loading={tableLoading}
          footerHeight={55}
        ></SelfTable>
      </Form>
    </div>
  );
};

export default ScoreForAnsweringTable;
