import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Tooltip, Space, Typography, Tag, Modal, message, Select } from 'antd';
import { CloseCircleOutlined, CheckCircleOutlined, ExclamationCircleOutlined, SyncOutlined, ClockCircleOutlined } from '@ant-design/icons';

import Api from "../../../../api";
import SelfTable from "../../../../components/SelfTable"
import {pagingParam} from "../../../../utils/defaultValue";

import './index.scss'

const { Text, Link } = Typography;
const { confirm } = Modal;

const AnalyzeList = () => {

    const [form] = Form.useForm();
    const syncStatusData = [
        {
            value: '[0]',
            label: '成功',
        },
        {
            value: '[1,3]',
            label: '同步中',
        },
        {
            value: '[2]',
            label: '同步失败',
        },
        {
            value: '[4]',
            label: '未同步',
        }
    ]

    const [listData, setListData] = useState([]);
    const [esStatusDate, setEsStatusDate] = useState({});
    
    const [userList, setUserList] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    
    // 查询数据
    const [searchData, setSearchData] = useState({
        ...pagingParam,
        sort: 0,
        syncStatusList:[]
    });
    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });

    const [tableLoading, setTableLoading] = useState(false);
    // 列表选择数据
    const [selectedRowKey, setSelectedRowKey] = useState([]);
    const columns = [
        {
            title: '知识名称',
            dataIndex: 'knowledgeName',
            width: '',
        },
        {
            title: '格式类型',
            dataIndex: 'fileType',
            width: '100px',
        },
        {
            title: '知识类型',
            dataIndex: 'knowledgeTypeName',
            width: '120px',
        },
        {
            title: '创建人',
            dataIndex: 'userName',
            width: '140px',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            width: '150px',
            sorter: true,
            defaultSortOrder: 'descend',
        },
        {
            title: '同步状态',
            dataIndex: 'syncStatus',
            width: '120px',
            render: (text,record) => {
                // 0:处理成功 1:正在处理;2:处理失败; 3:未处理、4未推送
                if(text == 0){
                    return <Text type="success">
                        成功
                    </Text>
                }else if(text == 1 || text == 3){
                    return <Link>
                        同步中
                    </Link>
                }else if(text == 2){
                    return <div>
                        <Text type="danger">
                            同步失败
                        </Text>
                        <Tooltip title={record.receiveMsg}>
                            <ExclamationCircleOutlined  style={{color: '#ff4d4f', marginLeft: 5}}/>
                        </Tooltip>
                    </div>
                }else if(text == 4){
                    return <Text type="success">
                        未同步
                    </Text>
                }else if(text == 5){
                    return <Text type="success">
                        部分成功
                    </Text>
                }
            }
        },
        {
            title: '操作',
            width: '130px',
            dataIndex: 'operation',
            render: (_, record) => {
                if(record.syncStatus == 2 || record.syncStatus == 4 || record.syncStatus == 5){
                    return <Link onClick={()=>syncToAI(record.id)}>
                    同步
                </Link>
                }
            }
        },
    ];

    useEffect(() => {
        setInitDate();
    }, []);

    useEffect(() => {
        if(userList && userList.length>0){
            console.log("getList")
            getList();
        }
    }, [searchData,userList]);

    const setInitDate = async() => {

        // 获取所有用户
        const res = await Api.Common.getAllDepUser({data:{}});
        if(res&&res.code === 0&& res.data){
            let _data = res.data;
            _data.map(item=>{
                item.value = item.id;
                item.text = item.name;
                item.children = item.users
                if(item.users){
                    item.children = cycleData(item.users)
                }
            })
            setUserList(_data)
        }else{
            setUserList([])
        }
        
    }

    const getList = async() => {
        setTableLoading(true);
        let newSearchData = {
            ...searchData
        }
        if(newSearchData.sort){
            newSearchData.sort = newSearchData.sort ==='descend' ? 0 : newSearchData.sort === 'ascend' ? 1 : 0
        }
        const res = await Api.AIModel.getPageLMSyncStatus({data:newSearchData});
        setTableLoading(false);
        if(res&&res.code === 0){
            let _data = res.data;
            setListData(_data.records);
            setPagination({
                "pageIndex": _data.current,
                "pageSize": _data.size,
                "total":_data.total
            });
        }else{
            setListData([]);
        }
    }

    const cycleData = (data) => {
        data.map(item=>{
            item.value = item.userId;
            item.text = item.userName+'-'+item.roleName;
        })
        return data
    }

    const onChanges = (pagination, filters, sorter) => {
        let newSearchData = {...searchData}

        newSearchData.pageIndex = pagination.current ? pagination.current : 1;
        newSearchData.pageSize = pagination.pageSize ? pagination.pageSize : 10;

        newSearchData.sort = sorter.order;
        
        if(filters.userName){
            newSearchData.userId = filters.userName[0]
        }else{
            delete newSearchData.userId
        }

        setSearchData(newSearchData);
    };

    const syncToAI = async(id) => {

        let _knowledgeIds = [], text = '确定同步所有数据至大模型吗？';
        if(id){
            _knowledgeIds = [id];
            text = '确定同步这条数据至大模型吗？'
        }else if(selectedRowKey.length>0){
            _knowledgeIds = [...selectedRowKey];
            selectedRowKey
            text = `确定同步选中的${selectedRowKey.length}条数据至大模型吗？`
        }

        confirm({
            title: text,
            icon: <ExclamationCircleOutlined />,
            content: '',
            onOk: ()=>{
                return new Promise(async (resolve, reject) => {
                    try{
                        const res = await Api.AIModel.reSyncFile({data:_knowledgeIds});
                        resolve();
                        if(res&&res.code === 0){
                            getList();
                            setSelectedRowKey([]);
                            message.success("操作成功，请耐心等待！")
                        }
                    }catch(erro){
                        reject();
                    }
                }).catch(() => {console.log('Oops errors!');});
            },
            onCancel() {},
        });
        
    }

    // 搜索框
    const changeValue = (e) => {
        setSearchValue(e.target.value)
    }
    const onSearch = () => {
        let newSearchData = {...searchData}
        newSearchData.pageIndex = 1;
        newSearchData.name = searchValue;
        setSearchData(newSearchData);
    }

    const onResetting = () => {
        setSearchValue("");
        setSearchData({
            ...pagingParam,
            "name":"",
        })
    }

    const searchChange = (value, type) => {
        let newSearchData = {...searchData}
        newSearchData[type] = value;
        newSearchData.pageIndex = 1;
        setSearchData(newSearchData);
    }

    return (
        <div className="analyzeListrame">
            <div className="title">解析状态清单</div>
            <div className="operation">
                <Space size={"large"}>
                    <div className="operationItem">
                        知识名称：
                        <Input
                            placeholder="输入知识名称进行搜索"
                            onChange={changeValue}
                            onPressEnter={onSearch}
                            style={{width:300,float:"right"}}
                            value={searchValue}
                            allowClear
                        />
                    </div>
                    <div className="operationItem">
                        同步状态：
                        <Select
                            style={{width: 160,}}
                            onChange={(value)=>{
                                let _value = value ? JSON.parse(value) : []
                                searchChange(_value,"syncStatusList")
                            }}
                            options={syncStatusData}
                            placeholder="同步状态"
                            allowClear
                        />
                    </div>
                    <Button type="primary" onClick={onSearch}>搜索</Button>
                    <Button onClick={onResetting}>重置</Button>
                </Space>

                <div className="operationRight">
                    <Link onClick={()=>syncToAI()}>{selectedRowKey && selectedRowKey.length > 0?"同步选中数据":"一键全部同步"}</Link>
                </div>
            </div>

            <div className="table">
                <Form form={form} component={false}>
                    <SelfTable
                        rowKey={"id"}
                        dataSource={listData}
                        columns={columns}
                        onChange={onChanges} 
                        pagination={pagination}
                        loading={tableLoading}
                        childrenColumnName={"childList"}
                        rowRadioSelection={{
                            selectedRowKey:selectedRowKey,
                            setSelectedRowKey:setSelectedRowKey,
                            type:"checkbox",
                            preserveSelectedRowKeys:true,
                            getCheckboxProps: (record) => ({
                                disabled: (record.syncStatus != 2 && record.syncStatus != 4 && record.syncStatus != 5),
                                name: record.knowledgeName,
                            }),
                        }}
                        scroll={
                            {
                                x:"1200px"
                            }
                        }
                    />
                </Form>
            </div>
        </div>
    );
    
};
export default AnalyzeList;
