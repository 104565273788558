import React,{ useState, useEffect, useRef } from 'react';
import * as marked from "marked";
import {Form, Input, Typography, Space, message, Spin, Tooltip, Modal, Checkbox, Button, Popover, Empty} from 'antd';
import { HistoryOutlined, CloudDownloadOutlined, CheckCircleOutlined, CloseCircleOutlined, ClearOutlined, ExclamationCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useSelector, useDispatch } from "react-redux";
import $ from 'jquery'

import HistoryTopic from './components/HistoryTopic/index'
import Iconfont from '../Iconfont/Iconfont';
import storage from "../../utils/storage";
import Api from "../../api/index";
import {responseDataFilter, downloadFileBlob, copyToClip} from '../../utils/tool'
import { setAIModalState } from "../../features/AIIntelligent/AIReducer";
import './index.scss';

const {Title, Text} = Typography;

const AIModal = ({searchValue, historyTopicId, aiType, callbackPositioning}) => {
    let eventSource = null;
    const contentRef = useRef(null);
    const userInfo = storage.getItem("userInfo") || {};
    const dispatch = useDispatch();

    const [chatIsCreating, setChatIsCreating] = useState(false) // 聊天室是否处于创建中
    const [isAnswering, setIsAnswering] = useState(false) // 是否正在回答问题
    const [inputMessageLength, setInputMessageLength] = useState(0) // 聊天框字数
    const [sendQuestionText, setSendQuestionText] = useState("");// 需要发送的问题
    const [inputText, setInputText] = useState("");// 输入框的文字
    
    const questionLocation = useSelector(state => state.AIModal.questionLocation);
    // 0 带文档的问答， 1 知识中心 ， 2 ES搜索界面， 3查讯全部

    const questionModule = useSelector(state => state.AIModal.questionModule);// 问题类型 “检索ragRetrieve，问答ragChat 文档总结summary”
    const knowledgeIds = useSelector(state => state.AIModal.knowledgeIds); //需要提问的知识id
    const knowledgeEsParam = useSelector(state => state.AIModal.knowledgeEsParam); //需要提问知识范围
    const selectKnowledgeParam = useSelector(state => state.AIModal.selectKnowledgeParam); //需要提问知识范围
    const knowledgePublishedParam = useSelector(state => state.AIModal.knowledgePublishedParam); //个人中心分享成功的参数
    // const historyTopicId = useSelector(state => state.AIModal.historyTopicId); //历史记录ID
    // const QAtype = useSelector(state => state.AIModal.QAtype); // rec推荐知识， sum文档总结
    const refLoc = useSelector(state => state.AIModal.refLoc); // 是否开启来源定位，默认为False
    const sendAPI = useSelector(state => state.AIModal.sendAPI); // 是否开启来源定位，默认为False
    const queryText = useSelector(state => state.AIModal.queryText); // 提问的内容
    const relQuestions = useSelector(state => state.AIModal.relQuestions); // 推荐问题的数量

    const multiChatId = useSelector(state => state.AIModal.multiChatId);
    
    const [dataList, setDataList] = useState([]) // 会话信息列表
    const [expandKeyObject, setExpandKeyObject] = useState([]) // 回复消息下展开来源key
    const [isFocus, setIsFocus] = useState(false)
    const [chatId, setChatId] = useState(null) //问答记录id

    // 最新一条AI回复的内容的ID
    const [largeModelQaLogDeatilId, setLargeModelQaLogDeatilId] = useState(null)
    const [isStop, setIsStop] = useState(false) // 是否中断
    const [questionId, setQuestionId] = useState(null) //当前问题ID
    
    // 历史对话
    const [historyTopicOpen, setHistoryTopicOpen] = useState(false);
    const [historyPageParam, setHistoryPageParam] = useState({
        pageIndex: 1,
        total:0
    });
    const [searchParam, setSearchParam] = useState({
        largeModelQaLogId:null,
        pageIndex: 1,
        pageSize:10
    });
    const [moreLoading, setMoreLoading] = useState(false);

    // 导出makdown
    const [exportModalOpen, setExportModalOpen] = useState(false);
    const [exporting, setExporting] = useState(0); //是否正在导出中
    const [exportIds, setExportIds] = useState([]); // 需要导出的数据ID

    // 优化提问
    const [optimizing, setOptimizing] = useState(false);

    // 提问参数
    const [sendParam, setSendParam] = useState({})

    // 提示框
    const [aIModalTips,setAIModalTips] = useState(false)

    // 记录历史记录滚动的位置
    const [historyScrollTop,setHistoryScrollTop] = useState(0)
    
    useEffect(()=>{
        
        // ES搜索 - 并且有查询条件
        if(questionLocation === 2 && (JSON.stringify(knowledgeEsParam) != '{}')){
            setDataList([
                {
                    id: Date.now().toString(),
                    userId: "robot",
                    messageContent: '我是AI助手，检测到当前已有搜索结果，希望让我在结果中进一步挖掘信息吗？',
                    messageType:"ESConfirm"
                }
            ]);
        }else if(questionLocation === 2 && (JSON.stringify(knowledgeEsParam) === '{}')){
            
        }
        
        // if(questionLocation === 1){
        //     // 知识中心
        //     // 知识中心不带文件问答，注意清除knowledgeIds
        //     dispatch(setAIModalState({
        //         knowledgeIds:[]
        //     }))
        // }
    },[questionLocation])

    useEffect(()=>{
        setAIModalTips({
            status:'loading',
            msg: '正在分析，请耐心等待...',
            position: 'top',
            timeOut: 'maintain'
        })
    },[])

    useEffect(()=>{
        if(sendAPI){
            sendConnection();
        }
    },[sendAPI])

    // useEffect(()=>{
    //     // 知识详情进入
    //     if(aiType === 'detailsSum' && questionModule === "summary" && questionLocation === 0 && knowledgeIds.length > 0){
    //         // 带文档的问答 - 需要默认调用一次，返回文档总结
    //         sendConnection({
    //             relQuestions:2,
    //         });
    //     }
    // },[aiType, questionModule])
    


    // useEffect(()=>{
    //     if(QAtype === 'rec' && !searchValue){
    //         // 知识中心的推荐问题不带knowledgeIds
    //         // 购物车过去的推荐问题要带
    //         sendConnection({
    //             relQuestions:2,
    //             query:"推荐问题",
    //             knowledgeIds:questionLocation === 1 ? []: knowledgeIds
    //         });
    //     }
    // },[QAtype])

    // 点击历史记录
    useEffect(()=>{
        if(searchParam.largeModelQaLogId){
            getHistoryTopicDetail();
        }
    },[searchParam])


    // 结束当前话题的提示框
    useEffect(()=>{
        if(aIModalTips && aIModalTips.timeOut != 'maintain'){
            setTimeout(()=>{
                setAIModalTips(false);
            },2000)
        }
    },[aIModalTips])

    // 点击发送按钮
    useEffect(() => {
        if(questionId && sendQuestionText){
            let param = {
                queryText: sendQuestionText,
                questionModule:"ragChat",
                relQuestions:0,
                refLoc: true,
                sendAPI: Date.now().toString()
            }
            
            if(questionLocation === 1 || questionLocation === 2){
                // 知识中心和ES调用AI检索
                param.questionModule = 'ragRetrieve';
            }

            dispatch(setAIModalState(param))

            setInputText("");
            setSendQuestionText("");
            setInputMessageLength(0);
            setInputTextF();
        }
    }, [JSON.stringify(questionId)])

    // 文档检索助手
    useEffect(() => {
        if(searchValue){
            setSendQuestionText(searchValue)
            sendText(searchValue)
        }
    }, [searchValue])



    // 查询历史记录
    useEffect(() => {
        if(historyTopicId){
            onClickHistoryDetails(historyTopicId);
            setChatId(historyTopicId);
        }
    }, [historyTopicId])
    
    
    //AI小赛助手-多文档查讯历史记录 
    useEffect(() => {
        if(multiChatId){
            setDataList([]);
            onClickHistoryDetails(multiChatId);
            setChatId(multiChatId);
        }
    }, [multiChatId])

    useEffect(() => {
        const handleClick = (event) => {
          // 确保点击事件来自于设置的HTML内容
          if (contentRef.current && event.target.closest('.onClickGoDetails') && questionLocation != 1 && questionLocation != 2) {
            let num = event.target.textContent;
            console.log(event.target.dataset.myData,"myData")
            let _largeModelQaLogDeatilId = event.target.dataset.myData
            num = num.replace('[', '').replace(']', '');
            if(aiType === 'detailsSum' && callbackPositioning){
                // 单文档 - 知识详情中问答 -- 点击直接当前页定位
                callbackPositioning(parseInt(num) - 1, _largeModelQaLogDeatilId)
            }else{
                console.log(parseInt(num) - 1,largeModelQaLogDeatilId,"numnumnum")
                getSource(parseInt(num) - 1,_largeModelQaLogDeatilId)
            }
          }
        };
     
        // 添加点击事件监听器到document
        document.addEventListener('click', handleClick);
     
        // 清理函数，组件卸载时移除事件监听
        return () => {
          document.removeEventListener('click', handleClick);
        };
      }, []);
    

    // 内容滚动到底部
    useEffect(()=>{
        if(historyScrollTop){
            const dom = document.getElementById("chatScrollBox");
            if (dom) {
                dom.scrollTop = dom.scrollHeight - historyScrollTop;
            }
            setHistoryScrollTop(0)
        }else{
            chatAreaScrollBottom();
        }
    },[dataList])
    
    const addEventListenerF = () => {
        document.querySelector('#chatScrollBox').addEventListener("scroll",useFn);
    }

    const removeEventListenerF = () => {
        document.querySelector('#chatScrollBox').removeEventListener("scroll", useFn);
    }

    const getSource = async(sourceNum, _largeModelQaLogDeatilId) => {
        try{
            let res = await Api.Chat.getContentByDetailIdAndSource({data:{
                'largeModelQaLogDeatilId': _largeModelQaLogDeatilId,
                'sourceNum': sourceNum
            }})

            const {code, data} = res;
            if(code === 0){
                const {knowledgeId, fileType, pages} = data;
                dispatch(setAIModalState({
                    modalOpen: false,
                    multiOpen: true,
                    multiKnowledgeIds:[knowledgeId],
                    query:"",
                    sendAPI:false,
                    multiPage:pages[0] 
                }))
            }
            
        }catch(err){}
    }

    /**
     * 判断是否触底
     * 此函数进行判断是否触底
     * @param    handler  必填  判断后执行的回调函数
     * @returns  null
     */
    const isTouchBottom = (handler) => {

        let scrollTop = Math.ceil(document.querySelector('#chatScrollBox').scrollTop);
        
        if(scrollTop === 0){
            handler();
        }
    };

    const handleLoadMore = () => {
        
        // 为测试效果临时使用 message
        removeEventListenerF();

        setMoreLoading(true);
        setSearchParam({
            "largeModelQaLogId": searchParam.largeModelQaLogId,
            "pageIndex":searchParam.pageIndex+1,
            "pageSize":10,
        });
    };

    const useFn = () => {
        // 此处调用 加载更多函数
        isTouchBottom(handleLoadMore);
    };

    // 创建会话
    const sendConnection = (param = {}, newItem) => {
        eventSource = new EventSource('/api/largeModelQa/qa');
        setIsAnswering(true);
        let data = {
            query: sendQuestionText || queryText,
            module: questionModule,
            largeModelQaLogId: chatId,
            questionLocation,
            knowledgeIds,
            refLoc,
            relQuestions,
            knowledgeEsParam,
            selectKnowledgeParam,
            knowledgePublishedParam,
            ...param,
        };

        //AI帮我找查询范围, 0 ES搜索的查询条件 1 知识中心的查询条件 2 已发布知识查询条件
        if(!knowledgeEsParam || JSON.stringify(knowledgeEsParam) != '{}'){
            data.searchScope = 0;
        }
        if(!selectKnowledgeParam || JSON.stringify(selectKnowledgeParam) != '{}'){
            data.searchScope = 1;
        }
        
        if(!knowledgePublishedParam || JSON.stringify(knowledgePublishedParam) != '{}'){
            data.searchScope = 2;
        }
        
        // ES 搜索参数
        // if(questionLocation === 2 && (JSON.stringify(knowledgeEsParam) != '{}')){
        //     data.knowledgeEsParam = knowledgeEsParam;
        // }
        // 知识中心搜索参数
        // if(questionLocation === 1 && (JSON.stringify(knowledgeCenterParam) != '{}')){
        //     data.selectKnowledgeParam = knowledgeCenterParam;
        // }
        
        setSendParam(data);

        // 第一次问答的时候
        setAIModalTips({
            status:'loading',
            msg: '正在分析，请耐心等待...',
            position: 'top',
            timeOut: 'maintain'
        })

        fetchEventSource("/api/largeModelQa/qa", {
            openWhenHidden:true,
            method: 'POST',
            headers: {
                "Content-Type": 'application/json',
                "Accept": 'text/event-stream',
                "satoken": storage.getItem("token")
            },
            body: JSON.stringify(data),
            onmessage(event) {
                // 在这里操作流式数据
                console.info("接受到回复：",event.data);
                if(event.data){
                    receiveMessage(event, newItem)
                }
            },
            onclose(ee) {
                // 关闭流
                stopInputAnswerFunc();
                console.log("关闭")
            },
            onerror(error) {
                console.info(error);
                stopInputAnswerFunc();
                throw error;
                // return 5000;
                // 如果抛出时间，则间隔多少时间重连
                //返回流报错
            }
        })
    }

    const stopInputAnswerFunc = () => {
        setIsAnswering(false);
        closeConnection();
        setAIModalTips(false)
    }

    // 关闭会话
    const closeConnection = () => {
        console.log(eventSource)
        if(eventSource){
            eventSource.close();
        }
    }

    // 接收消息
    const receiveMessage =(evt, newItem) => {
        try {
            const data = evt.data;
            let state = {}, decodeData, messageInfo;

            if(data){
                decodeData = JSON.parse(data);
            }else{
                setIsAnswering(false);
                console.error("数据格式错误");
                return false;
            }
            
            if(decodeData){
                messageInfo = JSON.parse(decodeData.data);
            }else{
                setIsAnswering(false);
                console.error("数据格式错误");
                return false;
            }

            if(messageInfo.code != 0){
                setAIModalTips({
                    status:'error',
                    msg:messageInfo.msg
                })
                setIsAnswering(false);
                console.error("数据格式错误");
                return false;
            }
            
            if (messageInfo.data) {
                
                let _data = messageInfo.data;
                console.log(_data)
                
                if(_data.isEnd){
                    setIsAnswering(false);
                    // 获取当前聊天id
                    setChatId(_data.largeModelQaLogId); 
                    setLargeModelQaLogDeatilId(_data.largeModelQaLogDeatilId); 
                    // 关闭连接
                    closeConnection();
                }
                    let newList = [...dataList, newItem];
                    const {answer="", query, end: isComplete, sources, status, largeModelQaLogDeatilId, relQuestions, largeModelQaLogId} = _data;

                    if(newList[0] && newList[0].messageType === "ESConfirm"){
                        // 删除第一条提示数据
                        newList.shift();
                    }

                    const lastChat = {
                        "id": largeModelQaLogDeatilId,
                        "userId": "robot",
                        "messageContent": answer,
                        "status":status,
                        "sources":sources,
                        'relQuestions':relQuestions,
                        'largeModelQaLogId':largeModelQaLogId,
                        "query":query
                    };

                    setDataList([...newList, lastChat])
                    state.isComplete = isComplete;
            } else {
            }

            chatAreaScrollBottom();
        } catch (e) {
            // setIsAnswering(false);
            console.log(e);
        }
    }
    const renderChatList = () => {
        const {userName} = userInfo || {};
        return (
            <div id="chatScrollBox" className={`chatScrollBox`}>
                {moreLoading&&<Spin tip={"加载中..."} size={"small"} style={{width: "100%"}}></Spin>}
                {
                    dataList?.map((item, idx) => {
                        // aiType = detailsSum 知识详情页面
                        // 知识中心 ES 等Ai帮我找的时候，只需要显示推荐问题
                        // 知识详情 - 多文档问答的时候，查讯历史记录只需要显示推荐问题
                        if(!item){return false;}
                        
                        if((aiType != 'detailsSum' || multiChatId) && item.relQuestions?.length > 0){
                            // 推荐问题
                            item.messageContent = '我是AI助手，我可以根据你的问题，快速帮你找到需要的文章和内容，你可以这么问我：'
                        }

                        // 有推荐问题返回的时候，不显示下面的操作按钮
                        if(item.relQuestions?.length > 0){
                            item.messageType = 'AItips'
                        }

                        const {id, userId, query, messageContent, parentId, relQuestions, questionType, status: supportStatus, sources, messageType, largeModelQaLogId} = item;
                        
                        // 是否只显示推荐问题
                        let showRec = ((aiType != 'detailsSum' || multiChatId) && relQuestions?.length > 0);
                        
                        // 是否显示文档总结+推荐问题
                        let showCoreAndRec = (aiType === 'detailsSum' && relQuestions?.length > 0 && !multiChatId);
                        
                        const isSelf = userId !== 'robot';
                        const messageContentId = `messageContent-${id}`;
                        const tipstId = `tips-${id}`;

                        
                        let formatContent = marked.parse(messageContent || "");
                        // 去掉最后一个换行
                        formatContent = formatContent.replace(/[\n|\r]$/, "");
                        
                        // 处理数据格式
                        let detailsID = parentId ? parentId : id
                        formatContent = processingDataFormat(formatContent, largeModelQaLogId, detailsID)
                        
                        return (
                            <div className={`${exporting != 0 ? "selectingStatus" : ""}`} key={id}>
                                <div className={`chatMessageItem ${exportIds.includes(id)?'selectedStatus':''}`}>

                                    {/* 多选框 */}
                                    {!isSelf&&<i className={`checkBox checkBoxLeft`} onClick={()=>handleSelect(id)}/>}

                                    {/* 消息行 */}
                                    <div className={`chatMessage ${isSelf ? "selfChatMessage" : ""}`}>
                                        {/* 头像 */}
                                        <div className="headIcon" style={isSelf ? {} : {background: "#1A9DF9"}}>
                                            {isSelf ? (userName?.charAt(0)) : (<Iconfont type={"icon-jiqirenpeixun"}/>)}
                                        </div>
                                        {/* 消息 */}
                                        <div
                                            className="messageContentBox"
                                            style={{maxWidth: "40rem",}}
                                        >
                                            {
                                            // {/* AI帮我读 - 知识详情页面 - 默认需要显示核心观点+推荐问题 */}
                                            // {/* Core viewpoint + recommended questions */}
                                            showCoreAndRec ? getCoreAndRec(item, formatContent) : 
                                            
                                            // 只显示推荐问题
                                            showRec ? getRecQ(item):

                                            // ES - AI帮我找范围确认框
                                            messageType === "ESConfirm" ? getESTips(item) : 

                                            // 自己的提问内容
                                            isSelf ? qQuestionContent(item, formatContent) : 

                                            // AI回答的内容
                                            <>
                                                <div
                                                    ref={contentRef}
                                                    className={`messageContent answerMessageContent`}
                                                    id={messageContentId}
                                                    dangerouslySetInnerHTML={{__html: formatContent}}
                                                ></div>

                                                {
                                                    !isSelf ? (
                                                        <React.Fragment>

                                                            {sources?.length > 0 && (sendParam.module === 'ragRetrieve' || questionType === 'ragRetrieve') && getSeachSourcesContent(item)}

                                                            {/* 底部操作 */}
                                                            <div className="bottomTipBox">
                                                                <span className="tipText">以上内容为 AI 生成，不代表开发者立场，请勿删除或修改本标记</span>
                                                                <div className="operateBox">
                                                                    <Tooltip 
                                                                        placement={"top"} 
                                                                        title={"答得不错"}
                                                                        getPopupContainer={() => document.getElementById(`${tipstId}-good`)}
                                                                    >
                                                                            <span
                                                                                id={`${tipstId}-good`}
                                                                                className={`operateBtn ${supportStatus === "0" ? "lightStatus" : ""}`}
                                                                                onClick={() => {
                                                                                    if (supportStatus === "0") {
                                                                                        return
                                                                                    }
                                                                                    changeSupportReplyMessage(id, "0", idx);
                                                                                }}
                                                                            ><Iconfont type="icon-dianzan"/></span>
                                                                    </Tooltip>
                                                                    <Tooltip placement={"top"} title={"还不够好"}
                                                                        getPopupContainer={() => document.getElementById(`${tipstId}-bad`)}
                                                                    >
                                                                            <span
                                                                                id={`${tipstId}-bad`}
                                                                                className={`operateBtn ${supportStatus === "1" ? "lightStatus" : ""}`}
                                                                                onClick={() => {
                                                                                    if (supportStatus === "1") {
                                                                                        return
                                                                                    }
                                                                                    changeSupportReplyMessage(id, "1", idx);
                                                                                }}
                                                                            ><Iconfont type="icon-diancai"/></span>
                                                                    </Tooltip>
                                                                    <Tooltip placement={"top"} title={"点击可复制"}
                                                                        getPopupContainer={() => document.getElementById(`${tipstId}-copy`)}
                                                                    >
                                                                            <span
                                                                                id={`${tipstId}-copy`}
                                                                                className="operateBtn"
                                                                                onClick={() => {
                                                                                    copyToClip(messageContent || "");
                                                                                }}
                                                                            ><Iconfont type="icon-fuzhi1"/></span>
                                                                    </Tooltip>

                                                                    <Tooltip placement={"top"} title={"重新回答"}
                                                                        getPopupContainer={() => document.getElementById(`${tipstId}-copy`)}
                                                                    >
                                                                            <span
                                                                                id={`${tipstId}-copy`}
                                                                                className="operateBtn"
                                                                                onClick={() => {
                                                                                    let ids = Date.now().toString()
                                                                                    let newItem = {
                                                                                        'id':ids,
                                                                                        userId: userInfo.userId,
                                                                                        messageContent: query,
                                                                                    }
                                                                                    sendConnection({
                                                                                        'largeModelQaLogDeatilId':id,
                                                                                        "query":query
                                                                                    },newItem)
                                                                                }}
                                                                            ><Iconfont type="icon-tongbu"/></span>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    ) : undefined
                                                }
                                            </>}
                                        </div>
                                    </div>
                                    
                                    {/* 多选框 */}
                                    {isSelf&&<i className={`checkBox checkBoxRight`} onClick={()=>handleSelect(id)}/>}
                                </div>
                            </div>
                        )
                    })
                }

                {/* 导出确认弹窗 */}
                {exportModalOpen&&<div className="exportConversationTips">
                    <div className='mask'></div>
                    <div className='content'>
                        <div><ExclamationCircleOutlined /> 自动剔除“还不够好”的回答？</div>
                        <div className='footer'>
                            <Button shape="round" size="small" onClick={()=>nextStep(true)}>否，为我保留</Button>
                            <Button type="primary" shape="round" size="small" onClick={()=>nextStep()}>是，继续下一步</Button>
                        </div>
                    </div>
                </div>}
            </div>
        )
    }

    const getCoreAndRec = (item, formatContent) => {
        const {id, relQuestions} = item;
        const messageContentId = `messageContent-${id}`;
        return (
            <>
            <div className='sumTitle'>本文核心观点如下：</div>

            {/* AI回答的内容 */}
            <div
                ref={contentRef}
                className={`messageContent answerMessageContent`}
                id={messageContentId}
                dangerouslySetInnerHTML={{__html: formatContent}}
            ></div>

            <div className='sumTitle' style={{marginTop:'0.5rem'}}>你可能还想知道</div>

            {/* AI推荐的问题 */}
            {relQuestions?.length > 0 && <div className='RecommendQ'>
                {relQuestions.map(items=>{
                    return <div key={items} className='relQuestionItem' onClick={()=>{handleRelQuestion(items)}}>{items}</div>
                })}
            </div>}
        </>
        )
    }

    const getRecQ = (item) =>{
        const {id, relQuestions} = item;
        const messageContentId = `messageContent-${id}`;
        return (
            <>
                {/* AI回答的内容 */}
                <div
                    ref={contentRef}
                    className={`messageContent answerMessageContent`}
                    id={messageContentId}
                    dangerouslySetInnerHTML={{__html: '我是AI助手，我可以根据你的问题，快速帮你找到需要的文章和内容，你可以这么问我：'}}
                ></div>

                {/* AI推荐的问题 */}
                {relQuestions?.length > 0 && <div className='RecommendQ'>
                    {relQuestions.map(items=>{
                        return <div key={items} className='relQuestionItem' onClick={()=>{handleRelQuestion(items)}}>{items}</div>
                    })}
                </div>}
            </>
        )
    }

    const getESTips = (item) => {
        const {id} = item;
        const messageContentId = `messageContent-${id}`;
        
        return (
            <>
                <div
                    ref={contentRef}
                    className={`messageContent answerMessageContent`}
                    id={messageContentId}
                    dangerouslySetInnerHTML={{__html: '我是AI助手，检测到当前已有搜索结果，希望让我在结果中进一步挖掘信息吗？'}}
                ></div>

                <div className='bottomOprationBtn'>
                    <Button type="primary" icon={<CheckCircleOutlined />} onClick={()=>{confirmQAType(true)}}>在结果中问答</Button>
                    <Button icon={<CloseCircleOutlined />} onClick={()=>{confirmQAType(false)}}>重新检索</Button>
                </div>
            </>
        )
    }

    const getSeachSourcesContent = (item) => {
        const {id, sources} = item;
        return (<div className="sourceContentBox">
                                                            
            <div className='sumTitle'>根据您的问题，在以下文章中找到了{sendParam.query}内容：</div>

            {/* 来源列表 */}
            <div className="sourceListBox">
                {
                    (sources?.slice(0, 3)).map((item, idx) => {
                        const {content, knowledgeId, pages, knowledgeName, fileType} = item;
                        let sourceId = `${id}-source-${idx}`;
                        console.log(item,"itemitemitemitem")
                        let url =  `/page/knowledgeDetails?permission=true&type=AI&knowledgeId=${knowledgeId}&page=${pages[0]}&fileType=${fileType}`
                        return (
                            <>
                                <span className='sumTitle'>{idx+1}、{knowledgeName} <a href={url} target="_blank">[{idx+1}]</a></span>
                                <div className="sourceItem">
                                    <input 
                                        type="checkbox" 
                                        id="switch" 
                                        className="foldingControl"
                                        value={true}
                                    />
                                    {/* <Checkbox onChange={(e)=>{foldingControlChange(e,sourceId)}}> */}
                                        <div
                                            onClick={()=>{foldingControlChange(sourceId)}}
                                            key={sourceId}
                                            className={`sourceText ${expandKeyObject.includes(sourceId)?'expandSourceText':''}`}
                                        >
                                            <label className="foldingControlBtn" for="switch"></label>
                                            <span dangerouslySetInnerHTML={{__html: content}}></span>
                                        </div>
                                    {/* </Checkbox> */}
                                </div>
                            </>
                        )
                    })
                }
            </div>
        </div>
        )
    }

    const foldingControlChange = (id) => {
        const newExpandKey = [...expandKeyObject];
        if (newExpandKey.includes(id)) {
            
            newExpandKey.splice(newExpandKey.indexOf(id), 1); 
        } else {
            newExpandKey.push(id);
        }
        setExpandKeyObject(newExpandKey);
    };

    const qQuestionContent = (item, formatContent) => {
        const {id} = item;
        const messageContentId = `messageContent-${id}`;
        return (
            <div
                ref={contentRef}
                className={`messageContent`}
                id={messageContentId}
                dangerouslySetInnerHTML={{__html: formatContent}}
            ></div>
        )
    }

    const processingDataFormat = (html, largeModelQaLogId, id) => {
        let newHtml = html;
        const results = findSubstrings(newHtml,'##','$$');
        
        results.map(item=>{
            let raw = "##" + item + "$$";
            let index = parseInt(item)+1
            let url =  `/page/knowledgeDetails?permission=true&type=AI&sourceNum=${item}&largeModelQaLogDeatilId=${id}&chatId=${largeModelQaLogId}`
            let htmlSin = "";

            // 判断来源类型
            // if(aiType === 'detailsSum'){
                
            // }
            if(questionLocation === 1 || questionLocation === 2){
                htmlSin = '<a href='+url+' target="_blank">['+index+']</a>'
            }else{
                // 多文档的时候
                // 单文档 - 知识详情中问答 -- 点击直接当前页定位
                htmlSin = '<a data-my-data='+id+' class="onClickGoDetails" target="_blank">['+index+']</a>'
            }

            newHtml = newHtml.replace(raw, htmlSin)
        })
        
        return newHtml
    }

    function findSubstrings(str, startChar, endChar) {
        const regex = /##[^#]+?\$\$/g;
        const matches = str.match(regex) || [];
        return matches.map(match => match.substring(startChar.length, match.length - endChar.length));
    }

    // 确认检索类型 - ES检索是否在结果中问答
    const confirmQAType = (type) => {
        let param = {
            queryText:"推荐问题",
            questionModule:"summary",
            relQuestions:2,
            refLoc:false,
            sendAPI: Date.now().toString()
        }
        if(!type){
            // 清楚ES查讯参数
            param.knowledgeEsParam = {};
        }
        setDataList([]);
        dispatch(setAIModalState(param))
    }

    const nextStep = (selectAll = false) => {
        // 是否保留“还不够好”的回答。true 保留，false 不保留
        
        if(!selectAll){
            let newData = [];
            dataList.map(item=>{
                if(item.status != '1'){
                    newData.push(item.id)
                }
            })
            setExportIds(newData);
        }
        
        setExporting(2);
        setExportModalOpen(false);
    }

    // 改变导出对话的选择范围
    const handleSelect = (id) => {
        let newExportIds = [...exportIds]
        newExportIds.indexOf(id) === -1 ? newExportIds.push(id) : newExportIds.splice(newExportIds.indexOf(id), 1); 
        console.log(newExportIds,"newExportIds")
        setExportIds([...newExportIds])
    }
    
    // 点赞/踩 回复的消息
    const  changeSupportReplyMessage = async(id, status, index) => {
        const word = status === "0" ? "点赞" : "点踩";
        const res = await Api.Chat.evaluateAnswer({
            data: {
                parentId: id,
                msgView:status,
            },
        });
        const data = responseDataFilter(res);
        if (data) {
            message.success(word + "成功！");
            let newDataList = [...dataList];
            let record = {...newDataList[index]};
            record.status = status;
            newDataList[index] = record;
            setDataList(newDataList);
        }
    }
    
    // 发送问题
    const sendText = (value) => {
        
        let text = value ? value : sendQuestionText
        if (text.replace(/\s/g, "").length > 0) {
            let newDataList = [...dataList];
            let id = Date.now().toString()
            newDataList.push({
                id,
                userId: userInfo.userId,
                messageContent: text,
            });
            
            setDataList(newDataList);
            setQuestionId(id);
            
            chatAreaScrollBottom();
        } else {
            message.warning("输入的问题不能为空！");
        }
    }

   

    // 聊天区域滚动到底部
    const chatAreaScrollBottom = () => {
        const dom = document.getElementById("chatScrollBox");
        if (dom) {
            dom.scrollTop = dom.scrollHeight;
        }
    }

    // 结束话题
    const endTopic = async() => {
        if(!largeModelQaLogDeatilId){
            message.info("当前没有有效对话");
            return false;
        }
        try {
            const res = await Api.Chat.endTalk({
                data: {
                    largeModelQaLogDeatilId,
                },
            });
            if (res.code === 0) {
                setAIModalTips({
                    status:'success',
                    msg: "当前话题已结束！"
                })
                // message.success("结束话题成功！");
            }
        } catch (e) {
            console.log(e);
        }
    }

    // 清除当前对话
    const clearAllTopic = () => {
        endTopic(); // 先结束
        setDataList([])
        setAIModalTips({
            status:'success',
            msg: "清除成功！"
        })
    }


    const handleRelQuestion = (value) => {
        setSendQuestionText(value);
        setInputMessageLength(value.length)
        setInputTextF(value);
    }
    // 优化提问话题
    const optimizeTopic = async() => {
        if(optimizing || !sendQuestionText){
            return false
        }
        setOptimizing(true);
        try {
            const res = await Api.Chat.optimizeQuestion({
                data: {
                    largeModelQaLogId: chatId,
                    query: sendQuestionText,
                },
            });
            setOptimizing(false);
            const data = responseDataFilter(res);
            if (data) {
                setSendQuestionText(data);
                setInputMessageLength(data.length)
                setInputTextF(data);
            }
         }catch(err) {

        }
    }
    
    // 填写询问的问题
    const inputSendText = () => {
        const html = getInputText();
        setSendQuestionText(html);
        setInputMessageLength(html.length)
    }

    // 获取输入的问题
    const getInputText = () => {
        const dom = document.getElementById("inputContent");
        if (dom) {
            const result = dom.textContent;
            return result;
        }
    }
    const setInputTextF = (data) => {
        const dom = document.getElementById("inputContent");
        if (dom) {
            dom.innerHTML = data ? data : null;
        }
    }

    
    
    const onClickHistoryDetails = (id) => {
        setSearchParam({
            "largeModelQaLogId": id,
            "pageIndex": 1,
            "pageSize": 10,
        });
    }
    const getHistoryTopic = () =>{
        return (
            <div className="historyTopicBox">
                <HistoryTopic maxHeight={260} questionLocations={[questionLocation]} onClickHistoryDetails={onClickHistoryDetails}/>
            </div>
        )
    }

    // 查询历史对话明细
    const getHistoryTopicDetail = async(id) => {
        let dom = document.getElementById("chatScrollBox");
        setHistoryScrollTop(dom.scrollHeight);
        
        setHistoryTopicOpen(false);
        try{
            const res = await Api.Chat.getHistoryLogDetail({data:{
                largeModelQaLogId:id,
                ...searchParam
            }})

            const data = responseDataFilter(res);
            
            if(data.records && data.records.length > 0){
                let _data = [];
                data.records.map(item=>{ 
                    item.relQuestions = item.relQuestions ? JSON.parse(item.relQuestions) : [];
                    _data.unshift({
                        "id": item.id,
                        "userId": item.logType === 0 ? userInfo.userId : "robot", //0发送消息 1接收消息
                        "messageContent": item.message,
                        "parentId": item.parentId,
                        "relQuestions": item.relQuestions,
                        "questionType": item.questionType,
                        "sources":item.sources ? JSON.parse(item.sources) : [],
                        "query": item.query
                    })
                })
                setDataList([..._data,...dataList]);
                if(data.total > (data.current*data.size)){
                    addEventListenerF();
                }
                setHistoryPageParam({
                    pageIndex:data.current,
                    total:data.total,
                })
                setMoreLoading(false);
            }else{
                // 多文档的时候查讯历史记录，如果没有记录，就调用推荐问题
                console.log("没有历史记录")
                if(multiChatId){
                    dispatch(setAIModalState({
                        largeModelQaLogId:null,
                        queryText:"推荐问题",
                        questionModule:"summary",
                        relQuestions:2,
                        refLoc:false,
                        sendAPI: Date.now().toString()
                    }))
                }
            }
            setAIModalTips(false);
        }catch(e){
            console.log(e);
            setAIModalTips(false);
        }
    }

    // 导出对话记录
    const exportConversation = () => {
        setExportModalOpen(true);
        setExporting(1);
        // 默认先选中全部对话数据
        let ids = [];
        dataList.map(item=>{
            ids.push(item.id)
        })
        setExportIds(ids);
    }
    // 拼接对话
    const exportMakdown = () => {
        let text = '';
        dataList.map(item=>{
            if(exportIds.includes(item.id)){
                if(item.relQuestions?.length > 0){
                    text += `回答：${item.messageContent} /n`;
                    item.relQuestions.map(items=>{
                        text += `${items} /n`
                    })
                }else if(item.userId === 'robot'){
                    text += `回答：${item.messageContent} /n`
                }else{
                    text += `问：${item.messageContent} /n`
                }
            }
        })
        downloadMarkdown(text,"example.md")
    }
    // 导出
    const downloadMarkdown = (text, filename) => {
        // 创建Blob对象
        const blob = new Blob([text], { type: 'text/markdown' });
        
        // 创建临时的URL
        const url = URL.createObjectURL(blob);
        
        // 创建a标签并设置属性
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        
        // 模拟a标签点击来下载文件
        document.body.appendChild(a);
        a.click();
        
        // 清理临时DOM和URL
        document.body.removeChild(a);
        URL.revokeObjectURL(url);

        exportCancle();
    }
    // 关闭导出
    const exportCancle = () => {
        setExportModalOpen(false);
        setExporting(0);
        setExportIds([]);
    }

    // 光标移动到末尾
    const moveEnd = (dom) => {
        const selection = window.getSelection();
        const range = document.createRange();
        selection.removeAllRanges();
        range.selectNodeContents(dom);
        range.collapse(false);
        selection.addRange(range);
        dom.focus();
        setIsFocus(true)
    }

    // 历史对话记录Popover
    const handleOpenChange = (newOpen) => {
        console.log(newOpen);
        setHistoryTopicOpen(newOpen);
    };


    
    //  移动AI弹窗
    useEffect(() => {
        if(document.querySelector("#moveAIModal")){
            document.querySelector("#moveAIModal").onmousedown = function(ev){
                mDown(ev);
            }
        }
    }, []);

    function mDown(even){
        let root = document.querySelector("#root")
        let rootHeight = root.offsetHeight;
        let rootWidth =  root.offsetWidth;
        let offsetX = even.offsetX, offsetY = even.offsetY;
        let left = even.clientX, top = even.clientY;
        
        document.documentElement.onmousemove = function(ev){
            moveAIModal(ev, left, top, offsetX, offsetY, rootHeight, rootWidth);
        }
        document.documentElement.onmouseup = function(ev){
            moveAIModal(ev, left, top, offsetX, offsetY, rootHeight, rootWidth);
            document.documentElement.onmouseup = null;
            document.documentElement.onmousemove = null;
        }
    }

    const moveAIModal = (ev, left, top, offsetX, offsetY, rootHeight, rootWidth) => {
        let AIIcon = document.querySelector("#AIHelperBox");
        
        // 处理鼠标移动事件
        // 鼠标坐标到body可视区域左侧的距离 - 鼠标坐标到元素的左侧的距离 = 目标元素距离body左侧的距离
        left = (ev.clientX - offsetX);
        top = (ev.clientY - offsetY);

        // 目标元素距离body左侧的距离<=0 = 0; 目标元素距离body左侧的距离 + 100(目标元素自身的宽度) > body的宽度时 = body的宽度时 - 100
        left = left <= 0 ? 0 : (left+500) > rootWidth ? (rootWidth-500) :  left;
        top = top <= 0 ? 0 : (top+600) > rootHeight ? (rootHeight-600) :  top;

        AIIcon.style.left = left + 'px';
        AIIcon.style.top = top + 'px';
    }
    
    
    return (
        <div className="aiModal">
            <div>
                <Text className='aiTopLeftTitle' id='moveAIModal'>AI小赛助手</Text>
                <Space size={"large"} className='aiTopRight'>
                    <Popover
                        placement="bottomRight"
                        // open={historyTopicOpen}
                        content={getHistoryTopic}
                        // onOpenChange={handleOpenChange}
                        trigger="click"
                        getPopupContainer={()=>document.getElementById("historyTopic")}
                    >
                        <span id='historyTopic' className='historyTopicIcon'>
                            <HistoryOutlined /><Text type="secondary">历史对话</Text>
                        </span>
                    </Popover>

                    <Tooltip placement={"topRight"} title={"导出markdown"}
                        getPopupContainer={() => document.getElementById(`exportBotton`)}
                    >
                        <span
                            id={`exportBotton ${dataList.length < 1 ? " disabled" : ""}`}
                            onClick={()=>{
                                if(dataList.length > 0){
                                    exportConversation()
                                }
                            }}
                        >
                            <CloudDownloadOutlined />
                        </span>
                    </Tooltip>
                    
                </Space>
                
            </div>

            {/* 对话列表 */}
            <div className="chatListBox">
                {/* moreLoading */}
                {renderChatList()}
            </div>

            {exporting === 2?(
                <div className='exportBtnBox'>
                    <Button type="primary" shape="round" size="small" onClick={()=>exportMakdown()}>导出makdown</Button>
                    <Button shape="round" size="small" onClick={()=>exportCancle()}>取消</Button>
                </div>
            ):(exporting === 0 && dataList[0]?.messageType != "ESConfirm")  ? (<div>
                
                <div className='btmOpration'>

                    <Tooltip 
                        placement={"top"} 
                        title={"结束当前话题"} 
                        getPopupContainer={()=>document.getElementById("endTopic")}
                    >
                        <div className='endTopic' id='endTopic' onClick={endTopic}>
                            <Iconfont type="icon-jieshuduihua" /> 
                        </div>
                    </Tooltip>
                    
                    <Tooltip 
                        placement={"top"} 
                        title={"清除当前对话记录"} 
                        getPopupContainer={()=>document.getElementById("clearAllTopic")}
                    >
                        <div className='clearAllTopic' id='clearAllTopic' onClick={clearAllTopic}>
                            <ClearOutlined />
                        </div>
                    </Tooltip>
                </div>


                {aIModalTips&&<div className='endTips' style={aIModalTips.position === 'top' ? {top:100, bottom: 'initial'}:{}}>
                    {
                        aIModalTips.status === "success" ? (
                            <CheckCircleOutlined />
                        ) : aIModalTips.status === "loading" ? (
                            <Spin size={"small"} style={{marginRight: 10,marginTop: '-2px'}}></Spin>
                        ):(
                            <CloseCircleOutlined />
                        )
                    }
                    {aIModalTips.msg}
                </div>}
                
                <div className={`inputQuestionBox ${isAnswering ? "answeringStatus" : ""}`}>
                    {/* 输入框 */}
                    <div className="inputBox">
                        <div
                            className='inputText'
                            placeholder={(inputMessageLength > 0 || isAnswering) ? "" : "输入你的问题或需求(1000字以内)"}
                        >
                            {
                                isAnswering ? (<Spin tip={"答复中..."} size={"small"}></Spin>) : (
                                    <div
                                        id="inputContent"
                                        className="inputContent"
                                        contentEditable={true}
                                        onClick={(e) => {
                                            if (!isFocus) {
                                                moveEnd(e.target);
                                            }
                                        }}
                                        onBlur={(e) =>{
                                            setIsFocus(false)
                                        }}
                                        onInput={(val, e) => {
                                            inputSendText(val);
                                        }}
                                        dangerouslySetInnerHTML={{__html: inputText}}
                                        onKeyDown={(e) => {
                                            const {
                                                altKey, shiftKey, ctrlKey,
                                                key,
                                            } = e || {};

                                            if (key === "Enter" && !(altKey || shiftKey || ctrlKey)) {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                sendText();
                                            }
                                        }}
                                    ></div>
                                )
                            }
                        </div>
                        
                        <Tooltip 
                            placement={"top"} 
                            title={"优化提问"} 
                            getPopupContainer={()=>document.getElementById("optimizeBox")}
                        >
                            <div 
                                className='optimizeBox' 
                                id='optimizeBox' 
                                onClick={optimizeTopic}
                                style={sendQuestionText?{cursor: 'pointer'}:{cursor: 'no-drop'}}
                            >
                                
                                <Iconfont type="icon-react_ts" />{optimizing?"优化中...":"优化"}
                                
                            </div>
                        </Tooltip>
                    </div>

                    {/* 发送按钮 */}
                    {/* 发送按钮 | 中止按钮 */}
                    <Tooltip placement={"top"} title={isAnswering ? "中断回答" : "发 送"} getPopupContainer={()=>document.getElementsByClassName("sendQuestionBtn")[0]}>
                        {
                            isAnswering ? (
                                <span
                                    className="sendQuestionBtn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        stopInputAnswerFunc()
                                    }}
                                >
                                    <Iconfont type="icon-zhongzhi" />
                                </span>
                            ) : (
                                <span className="sendQuestionBtn" onClick={() => sendText()}><Iconfont type="icon-icon-test" iStyle={{color: "#1A9DF9"}} /></span>
                            )
                        }
                    </Tooltip>
                </div>
            </div> ):null}
            
        </div>
    )
}

export default AIModal