import React from "react";

import { Tabs } from "antd";
import ScoringTable from "./scoringTable";
import ScoreSettingsTable from "./scoreSettingsTable";
import ScoreForAnsweringTable from "./scoreForAnsweringTable";
import ScoreForStudyTable from "./scoreForStudyTable";
import BackIcon from "../../../components/BackIcon/index";
import "./index.scss";

const PointsRule = () => {
  return (
    <div className="pointsRuleFrame">
      <div className="pointsRuleTabs">
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: `知识价值得分`,
              key: "1",
              children: <ScoringTable />,
            },
            {
              label: `用户得分折算系数设置`,
              key: "2",
              children: <ScoreSettingsTable />,
            },
            {
              label: `回答问题得分`,
              key: "3",
              children: <ScoreForAnsweringTable />,
            },
            {
              label: `个人知识学习得分`,
              key: "4",
              children: <ScoreForStudyTable />,
            },
          ]}
        />
      </div>
      <BackIcon />
    </div>
  );
};

export default PointsRule;
