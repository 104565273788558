const storage = window.localStorage;

export default {
    getItem(key, lock = false) {
        let val = storage.getItem(key);
        return val!='undefined' ? JSON.parse(lock ? decodeURIComponent(val) : val) : null;
    },
    setItem(key, val, lock = false) {
        let value = lock ? encodeURIComponent(JSON.stringify(val)) : JSON.stringify(val)
        storage.setItem(key, value);
    },
    removeItem(key) {
        if (key) {
            storage.removeItem(key);
        }
    },
    clearAll() {
        storage.clear();
    },
};