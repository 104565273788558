import React, { useState, useEffect} from 'react';
import {Layout, Tooltip, Spin, message, Button} from "antd";
import { useDispatch } from "react-redux";

import { setAIModalState } from "../../../../../features/AIIntelligent/AIReducer";
import {zuStandStore} from "../../../../../zustand";
import './index.scss';

const AiBase = () => {
    const dispatch = useDispatch();
    
    const changeIsShowAi = zuStandStore(state => state.changeIsShowAiChat);
    const notYetOpen = () => {
        message.info("暂未开放")
    }

    const openAiModal = () => {
        dispatch(setAIModalState({
            modalOpen:true,
            questionLocation:1, //1 知识中心
            knowledgeIds:[], 
            queryText:"推荐问题",
            questionModule:"summary",
            relQuestions:2,
            refLoc: true, //开启来源定位
            sendAPI: Date.now().toString(),
            multiChatId:null,
            historyTopicId:null,
            selectKnowledgeParam:{},
            knowledgeEsParam:{},
            knowledgePublishedParam:{},
        }))
    }

    return (
        <div className="aiBaseFrame">
            <div className="backgroundBox">
                <span className="entryBtn" onClick={
                    () => {
                        changeIsShowAi(true)
                        openAiModal()
                    }
                    // () => notYetOpen()
                }>立即提问</span>
            </div>
        </div>
    )
}

export default AiBase;
