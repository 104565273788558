import React, { useState, useEffect, useRef } from 'react';
import { InputNumber, Form, Input, message, Tooltip, Modal, Popconfirm} from 'antd';
import Api from "../../../../api";
import {getSizeText, responseDataFilter} from "../../../../utils/tool";
import Iconfont from "../../../../components/Iconfont/Iconfont";
import ResizeTable from "../../../../components/ResizeTable";
import './index.scss';

const InitData = () => {
    const [form] = Form.useForm();
    const columns = [
        {
            title: "序号",
            key: "idx",
            dataIndex: "idx",
            align: "center",
            width: 60,
            minWidth: 60,
            disableResize: true,
            render: (val) => {
                return val || "-";
            },
        },
        {
            title: "业务角色",
            key: "name",
            dataIndex: "name",
            align: "center",
            minWidth: 100,
            render: (val) => {
                return val || "-";
            },
        },
        {
            title: "初始文件数量",
            key: "initNum",
            dataIndex: "initNum",
            align: "center",
            minWidth: 100,
            render: (val, record) => {
                return `${val || 0} 个`;
            },
        },
        {
            title: "初始空间容量",
            key: "initCapacity",
            dataIndex: "initCapacity",
            align: "center",
            minWidth: 100,
            render: (val, record) => {
                return getSizeText(val || 0, "Mb");
            },
        },
        {
            title: "操作",
            key: "operate",
            dataIndex: "operate",
            align: "center",
            disableResize: true,
            width: 100,
            render: (val, record) => {
                const domId = record.id;
                return (
                    <div className="operateBox">
                        <Tooltip title={"调整"} placement={"top"}>
                            <span
                                className="operateBtn editBtn"
                                onClick={() => {
                                    setEditData(record);
                                    form.setFieldsValue({
                                        ...record,
                                    });
                                    setIsModalOpen(true);
                                }}
                            ><Iconfont type="icon-bianji2"/></span>
                        </Tooltip>
                        <Tooltip title={"重置"} placement={"top"}>
                            <Popconfirm
                                placement={"left"}
                                title={"确定重置成全局初始数据吗？"}
                                okText={"确定"}
                                cancelText={"取消"}
                                getPopupContainer={() => document.getElementById(domId)}
                                overlayClassName={"deletePupConfirmBox"}
                                onConfirm={() => {
                                    handleOk(record.id);
                                }}
                            >
                                <span
                                    className="operateBtn resetBtn"
                                    id={domId}
                                ><Iconfont type="icon-huifu"/></span>
                            </Popconfirm>
                        </Tooltip>
                    </div>
                )
            },
        },
    ];

    const initList = [
        {label: "初始文件数量", key: "initNum", unit: "个"},
        {label: "初始空间容量", key: "initCapacity", unit: "Mb"},
        {label: "最大文件数量", key: "maxNum", unit: "个"},
        {label: "最大空间容量", key: "maxCapacity", unit: "Mb"},
    ];
    const [data, setData] = useState({
        initNum: 0,
        initCapacity: 0,
    });
    const globalInitData = useRef({});
    const [initDataList, setInitDataList] = useState([]);
    const [total, setTotal] = useState(0); // 列表数据总条数
    const [pageSize, setPageSize] = useState(15);
    const [currentPage, setCurrentPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false); // 编辑弹窗是否显示
    const [confirmLoading, setConfirmLoading] = useState(false); // 是否保存中
    const [editData, setEditData] = useState({}); // 编辑数据

    const getInitData = async () => {
        const res = await Api.CloudSpace.getGlobalInitData();

        const resData = responseDataFilter(res);
        if (resData) {
            setData(resData);
        }
    }

    // 获取数据列表
    const getDataList = async () => {
        const res = await Api.CloudSpace.getInitDataList({
            data: {
                pageIndex: currentPage,
                pageSize: pageSize,
                name: "",
            },
            loading: true,
            loadingText: "数据加载中2..."
        });

        const resData = responseDataFilter(res);
        if (resData) {
            let dataList = (resData.records || []).map((item, idx) => {
                return {
                    ...item,
                    idx: (currentPage - 1) * pageSize + (idx + 1),
                }
            })
            setInitDataList(dataList);
            setTotal(resData.total);
        }
    }

    // 编辑数据弹窗确认回调
    const handleOk = async (roleId) => {
        const isGlobal = !(editData.id || roleId);
        try {
            let row = globalInitData.current;
            if (!roleId) {
                row = await form.validateFields();
            }
            setConfirmLoading(true);
            let params = {
                initCapacity: row.initCapacity,
                initNum: row.initNum,
                roleId: roleId || editData.id,
            };
            let func = Api.CloudSpace.updateInitSpace;
            if (isGlobal) {
                func = Api.CloudSpace.updateGlobalInitData;
                params.maxNum = row.maxNum;
                params.maxCapacity = row.maxCapacity;
                delete params.roleId;
            }
            const res = await func({data: params,});

            const resData = responseDataFilter(res);
            if (resData) {
                message.success(`${roleId ? "重置" : "修改"}成功!`);
                handleCancel();
                if (isGlobal) {
                    setData({...row,});
                } else {
                    getDataList();
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    // 编辑数据弹窗取消回调
    const handleCancel = () => {
        setConfirmLoading(false);
        setIsModalOpen(false);
        setEditData({});
        form.setFieldsValue({});
    }

    useEffect(() => {
        getInitData();
    }, []);

    useEffect(() => {
        getDataList();
    }, [pageSize, currentPage]);

    useEffect(() => {
        globalInitData.current = data;
    }, [data]);

    const isGlobal = editData?.id === undefined;
    let maxObject = globalInitData.current || {};
    if (isGlobal) {
        maxObject = editData;
    }

    return (
        <div className="initDataFrame">
            {/* 全局初始数据 */}
            <div className="initDataContentBox">
                {
                    initList.map(item => {
                        const {key, label, unit} = item;
                        const isSize = unit === "Mb";
                        const value = data[key];
                        return (
                            <div className="initItemBox" key={key}>
                                <span className="labelText">{label}：</span>
                                <span className="infoText">{ isSize ? getSizeText(value || 0, unit) : (`${value || 0} ${unit}`)}</span>
                            </div>
                        )
                    })
                }
                <div className="initItemBox">
                    <Tooltip title={"调整"} placement={"top"}>
                        <span
                            className="operateBtn editBtn"
                            onClick={() => {
                                const formData = {
                                    initCapacity: data.initCapacity,
                                    initNum: data.initNum,
                                    maxCapacity: data.maxCapacity || 0,
                                    maxNum: data.maxNum || 0,
                                    name: "全局",
                                };
                                setEditData(formData);
                                form.setFieldsValue(formData);
                                setIsModalOpen(true);
                            }}
                        ><Iconfont type="icon-bianji2"/></span>
                    </Tooltip>
                </div>
            </div>

            {/* 业务角色 初始数据 */}
            <div className="tableContentBox" id={"initTableContentBox"}>
                <ResizeTable
                    columns={columns}
                    dataSource={initDataList}
                    tableBoxId="initTableContentBox"
                    computedWidth={false}
                    rowKey={"userId"}
                    pagination={{
                        size: 'small',
                        hideOnSinglePage: true,
                        pageSizeOptions: ['15', '30', '60', '100'],
                        pageSize: pageSize,
                        current: currentPage,
                        total,
                        showTotal: (num) => `总计 ${num} 条`,
                        onChange: (pageNum, pageSize) => {
                            setCurrentPage(pageNum);
                            setPageSize(pageSize);
                        },
                    }}
                />
            </div>

            {/* 编辑初始数据 */}
            <Modal
                title={`修改【${editData.name}】初始数据`}
                open={isModalOpen}
                onOk={() => handleOk()}
                onCancel={handleCancel}
                centered={true}
                maskClosable={false}
                destroyOnClose={true}
                closable={!confirmLoading}
                confirmLoading={confirmLoading}
                wrapClassName={"editInitSpaceModalBox"}
                width={500}
            >
                <Form
                    form={form}
                    component={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    preserve={false}
                >
                    {/*<Form.Item*/}
                    {/*    key={'userName'}*/}
                    {/*    label={'用户名称'}*/}
                    {/*    name={'userName'}*/}
                    {/*>*/}
                    {/*    <span>{editData.userName}</span>*/}
                    {/*</Form.Item>*/}
                    <Form.Item
                        key={'initNum'}
                        label={'文件数量'}
                        name={'initNum'}
                        rules={
                            [
                                {
                                    required:true,
                                    message: `输入文件数量!`
                                },
                                {
                                    type: "number",
                                    required:true,
                                    message: `文件数量区间（0-${maxObject.maxNum}）!`,
                                    min: 0,
                                    max: maxObject.maxNum,
                                },
                            ]
                        }
                    >
                        <InputNumber min={0} max={maxObject.maxNum} addonAfter={'个'} />
                    </Form.Item>
                    <Form.Item
                        key={'initCapacity'}
                        label={'空间容量'}
                        name={'initCapacity'}
                        rules={
                            [
                                {
                                    required:true,
                                    message: `输入空间容量!`
                                },
                                {
                                    type: "number",
                                    required:true,
                                    message: `空间容量区间（0-${maxObject.maxCapacity}）!`,
                                    min: 0,
                                    max: maxObject.maxCapacity
                                },
                            ]
                        }
                    >
                        <InputNumber min={0} max={maxObject.maxCapacity} addonAfter={'Mb'} />
                    </Form.Item>
                    {
                        isGlobal ? (
                            <React.Fragment>
                                <Form.Item
                                    key={'maxNum'}
                                    label={'最大文件数量'}
                                    name={'maxNum'}
                                    rules={
                                        [
                                            {
                                                required:true,
                                                message: `输入最大文件数量!`
                                            }
                                        ]
                                    }
                                >
                                    <InputNumber
                                        min={0}
                                        addonAfter={'个'}
                                        onChange={(val) => {
                                            setEditData({
                                                ...editData,
                                                maxNum: val,
                                            })
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    key={'maxCapacity'}
                                    label={'最大空间容量'}
                                    name={'maxCapacity'}
                                    rules={
                                        [
                                            {
                                                required:true,
                                                message: `输入最大空间容量!`
                                            }
                                        ]
                                    }
                                >
                                    <InputNumber
                                        min={0}
                                        addonAfter={'Mb'}
                                        onChange={(val) => {
                                            setEditData({
                                                ...editData,
                                                maxCapacity: val,
                                            })
                                        }}
                                    />
                                </Form.Item>
                            </React.Fragment>
                        ) : undefined
                    }

                </Form>
            </Modal>
        </div>
    );
}
export default InitData
