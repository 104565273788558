import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Upload, Space, Typography, InputNumber, Modal, message, DatePicker, Switch, Select, Popconfirm, Image } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment'

import Api from "../../../api";
import Iconfont from "../../../components/Iconfont/Iconfont"
import SelfTable from "../../../components/SelfTable"
import {pagingParam} from "../../../utils/defaultValue";
import BackIcon from "../../../components/BackIcon/index";
import CropperImg from "../../../components/CropperImg/index";
import {ModalConfirm} from '../../../utils/tool'

import './index.scss'

const { RangePicker } = DatePicker;
const { Link } = Typography;

const HotTopicKnowledge = () => {
    
    const [form] = Form.useForm();
    const [addForm] = Form.useForm();

    const options = [
        {
            value: -1,
            label: '无',
        },
        {
            value:  0,
            label: '阅读',
        },
        {
            value: 1,
            label: '点赞',
        },
        {
            value: 2,
            label: '收藏',
        },
        {
            value: 3,
            label: '评论',
        },
    ]

    const [searchValue, setSearchValue] = useState('');
    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });

    // 列表数据
    const [listData, setListData] = useState([]);

    // 当前正在操作的数据KEY
    const [editingObj, setEditingObj] = useState({});

    const [tableLoading, setTableLoading] = useState(false);

    // 列表选择数据
    const [selectedRowKey, setSelectedRowKey] = useState([]);

    // 查询数据
    const [searchData, setSearchData] = useState({
        ...pagingParam,
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [uploadFile, setUploadFile] = useState('')

    // 当前操作的数据的图标

    const [imageUrl, setImage] = useState(null);
    const [croppedImageOpen, setCroppedImageOpen] = useState(false);

    // 上传按钮
    const uploadButton = (
        <div>
          <PlusOutlined />
          <div
            style={{
              marginTop: 8,
            }}
          >
            请上传文件比例3:2的图片
          </div>
        </div>
    );

    const columns = [
        {
            title: '知识名称',
            dataIndex: 'knowledgeName',
            width: '',
            render: (status, records) => {
                return records.isPubKnowledge === 0 ? <Link onClick={()=>{goDetails(records)}}>{status}</Link> : status
            }
        },
        {
            title: '展示图片',
            dataIndex: 'fileId',
            width: '150px',
            editable: true,
            changeType:"upload",
            align:"center",
            render:(_,record)=>{
                return <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                maxCount={1}
                showUploadList={false}
                beforeUpload={() => {
                    return false;
                }}
                onChange={(e)=>handleChange(e,record)}
                accept={"image/*"}
            >
                {record.fileUrl ? (
                    <img
                    src={record.fileUrl}
                    alt="avatar"
                    style={{
                        width: '100%',
                    }}
                    />
                ) : (
                    uploadButton
                )}
                </Upload>
            }
        },
        {
            title: '知识类型',
            dataIndex: 'knowledgeTypeName',
            width: '120px',
        },
        {
            title: '文件类型',
            dataIndex: 'fileType',
            width: '90px',
        },
        {
            title: '所属部门',
            dataIndex: 'departmentName',
            width: '160px',
        },
        {
            title: '是否公共知识',
            dataIndex: 'isPubKnowledge',
            width: '120px',
            render: (status) => {
                return status === 0 ? '是' : status === 1 ? '否' : ""
            }
        },
        {
            title: '阅读量',
            dataIndex: 'readNum',
            width: '80px',
            // sorter: true,
        },
        {
            title: '收藏量',
            dataIndex: 'collectionNum',
            width: '80px',
            // sorter: true,
        },
        {
            title: '点赞量',
            dataIndex: 'likeNum',
            width: '80px',
            // sorter: true,
        },
        {
            title: '评论量',
            dataIndex: 'commentNum',
            width: '80px',
            // sorter: true,
        },
        {
            title: '排序',
            dataIndex: 'sort',
            width: '100px',
            sorter: true,
            render: (status,records) => {
                return <InputNumber style={{
                    width: 70,
                }} min={1} defaultValue={status} onPressEnter={(e)=>onSortChange(e.target.value,records)} />
            }
        },
        {
            title: '热门类型',
            dataIndex: 'hotType',
            width: '110px',
            render: (status,records) => {
                return <Select
                    defaultValue={status}
                    style={{
                        width: 80,
                    }}
                    onChange={(value)=>handleTypeChange(value,records)}
                    options={options}
              />
            }
        },
        {
            title: '热门图标',
            dataIndex: 'iconFlag',
            width: '80px',
            render: (status,records) => {
                return records.hotType === 0 ? <Iconfont type={'icon-yuedujilu'}/> :
                records.hotType === 1 ? <Iconfont type={'icon-dianzan_kuai'}/> :
                records.hotType === 2 ? <Iconfont type={'icon-shoucangxiao'}/> :
                records.hotType === 3 ? <Iconfont type={'icon-pinglun'}/>: null
            }
        },
        {
            title: '是否开启推荐知识',
            dataIndex: 'hotFlag',
            width: '120px',
            render: (status,records) => {
                // 0不是 1是
                return <Switch 
                checkedChildren="开启" 
                unCheckedChildren="关闭" 
                checked={status} 
                loading={confirmLoading}
                onChange={()=>onHotFlagChange(records)} 
                />
            }
        },

        // {
        //     title: '是否显示图标',
        //     dataIndex: 'iconFlag',
        //     width: '120px',
        //     render: (status) => {
        //         return status === 0 ? '' : status === 1 ? <span className="hotLabel">热</span> : ""
        //     }
        // },
        {
            title: '操作',
            width: '130px',
            dataIndex: 'operation',
            render: (_, record) => {
                return <Space>
                    <Popconfirm
                        title="您确认要删除这条数据吗?"
                        onConfirm={()=>deleteProject(record)}
                        okText="确定"
                        cancelText="取消"
                        placement="topRight"
                    >
                        <Typography.Link>
                            删除
                        </Typography.Link>
                    </Popconfirm>
                    
                </Space>
                
            },
        },
    ];

    /*
        * 弹窗
    */ 

    // 分页
    const [modalPagination, setModalPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });
    const [modalSearchValue, setModalSearchValue] = useState('');
    // 列表数据
    const [modallistData, setModalListData] = useState([]);
    // 查询数据
    const [modalSearchData, setModalSearchData] = useState({
        ...pagingParam,
    });
    const [modalTableLoading, setModalTableLoading] = useState(false);
    // 列表选择数据
    const [modalSelectedRowKey, setModalSelectedRowKey] = useState([]);

    const modalColumns = [
        {
            title: '知识名称',
            dataIndex: 'knowledgeName',
            width: '',
            render: (status, records) => {
                return records.isPubKnowledge === 0 ? <Link onClick={()=>{goDetails(records)}}>{status}</Link> : status
            }
        },
        {
            title: '知识类型',
            dataIndex: 'knowledgeTypeName',
            width: '120px',
        },
        {
            title: '文件类型',
            dataIndex: 'fileType',
            width: '90px',
        },
        {
            title: '所属部门',
            dataIndex: 'departmentName',
            width: '150px',
        },
        {
            title: '是否公共知识',
            dataIndex: 'isPubKnowledge',
            width: '120px',
            render: (status) => {
                return status === 0 ? '是' : status === 1 ? '否' : ""
            }
        },
        {
            title: '阅读量',
            dataIndex: 'readNum',
            width: '90px',
            // sorter: true,
        },
        {
            title: '收藏量',
            dataIndex: 'collectionNum',
            width: '90px',
            // sorter: true,
        },
        {
            title: '点赞量',
            dataIndex: 'likeNum',
            width: '90px',
            // sorter: true,
        },
        {
            title: '评论量',
            dataIndex: 'commentNum',
            width: '90px',
            // sorter: true,
        }
    ];

    useEffect(() => {
        getList();
    }, [searchData]);

    useEffect(() => {
        if(!searchValue){
            onSearch()
        }
    }, [searchValue]);

    const getList = async() => {
        setTableLoading(true);
        setListData([]);
        try {
            const res = await Api.Home.getHotKnowledgeList({data:searchData});
            setTableLoading(false);
            if(res&&res.code === 0){
                let _data = res.data;
                setListData(_data.records);
                setEditingObj({});
                setPagination({
                    "pageIndex": _data.current,
                    "pageSize": _data.size,
                    "total":_data.total
                });
            }else{
                setListData([]);
            }
        } catch (errInfo) {
            setListData([]);
            console.log('验证失败:', errInfo);
        }
    }

    // 搜索框
    const changeValue = (e) => {
        setSearchValue(e.target.value)
    }

    const onSearch = () => {
        let newSearchData = {...searchData}
        newSearchData.pageIndex = 1;
        newSearchData.knowledgeName = searchValue;
        setSearchData(newSearchData);
    }

    const onResetting = () => {
        setSearchValue("");
        setSearchData({
            ...pagingParam,
            knowledgeName:"",
        })
    }

    const onSortChange = async(value,records) => {
        try {
            let param = {
                id: records.id,
                knowledgeId: records.knowledgeId,
                sort: parseInt(value)
            }
            const res = await Api.Home.updateHotKnowledge({data:param});
            if(res.code === 0){
                getList();
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    const handleTypeChange = async(value,records) => {
        try {
            let param = {
                id: records.id,
                knowledgeId: records.knowledgeId,
                hotType: parseInt(value)
            }
            const res = await Api.Home.updateHotKnowledge({data:param});
            if(res.code === 0){
                getList();
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    
    // 新增
    const handleOk = async() => {
        add()
    }

    // 修改-保存
    const update = async (fileId) => {
        try {
            let param = {
                id: editingObj.id,
                knowledgeId: editingObj.knowledgeId,
                fileId
            }
            message.destroy("upload");
            const res = await Api.Home.updateHotKnowledge({data:param});
            if(res.code === 0){
                message.success("修改成功",2,()=>{
                    getList();
                })
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    };

    const onHotFlagChange = async (records) => {
        setConfirmLoading(true);
        try {
            let param = {
                id:records.id,
                knowledgeId: records.knowledgeId,
                hotFlag: records.hotFlag == 1 ? 0 : 1
            }
            const res = await Api.Home.updateHotKnowledge({data:param});
            setConfirmLoading(false);
            if(res.code === 0){
                getList();
            }
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    }

    const add = async () => {
        if(modalSelectedRowKey.length <=0){
            message.info("请选择需要添加的数据")
            return false
        }
        setConfirmLoading(true);
        try {
            const res = await Api.Home.addHotKnowledge({data:{
                knowledgeIds:modalSelectedRowKey
            }});
            setConfirmLoading(false);
            if(res&&res.code === 0){
                handleCancel();
                message.success("新增成功",2,()=>{
                    getList();
                })
            }
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    }

    const deleteProjects = async (record) => {
        if(selectedRowKey && selectedRowKey.length > 0){
            ModalConfirm({
                title: `您确认要删除所选择的数据吗?`,
                onOk:async(resolve, reject)=> {
                    try{
                        const res = await Api.Home.deleteHotKnowledge({data:{knowledgeIds:selectedRowKey}});
                        resolve()
                        if(res&&res.code === 0){
                            
                            let newSearchData = {...searchData};
                            newSearchData.pageIndex = 1;
                            setSearchData(newSearchData);

                            setSelectedRowKey([]);
                            message.success("删除成功")
                        }
                    }catch{reject()}
                },
            })
        }else{
            message.info("请选择您需要删除的数据")
        }
    }

    const deleteProject = async (record) => {
        try{
            const res = await Api.Home.deleteHotKnowledge({data:{knowledgeIds:[record.knowledgeId]}});
            if(res&&res.code === 0){
                let newSearchData = {...searchData};
                newSearchData.pageIndex = 1;
                setSearchData(newSearchData);

                message.success("删除成功")
            }
        }catch{}
    }

    // 打开
    const addProject = () => {
        addForm.setFieldsValue({
            iconFlag:0,
            hotFlag:0,
        });
        setIsModalOpen(true);
    }

    const handleCancel = () => {
        
        setConfirmLoading(false);
        setIsModalOpen(false);
    }

    const onChanges = (pagination, filters, sorter) => {
        // 排序方式，0按排序序号降序 1按排序序号升序 2按搜索量降序 3按搜索量升序
        // "descend" 降序 "ascend"升序
        
        let newSearchData = {...searchData};
      
        if(sorter.field === "sort" && sorter.order){
            newSearchData.sort = sorter.order === "descend" ? 0 : 1
        }else{
            newSearchData.sort = ""
        }
        
        newSearchData.pageIndex = pagination.current;
        newSearchData.pageSize = pagination.pageSize;
        setSearchData(newSearchData);
    };

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };

    const dateChange = (date, dateString) => {
        let newSearchData = { ...searchData };
        newSearchData.startTime = dateString[0];
        newSearchData.endTime = dateString[1];
        setSearchData(newSearchData);
    };



    /*
        * 弹窗
    */ 
    useEffect(() => {
        if(isModalOpen){
            getModalList();
        }
    }, [modalSearchData,isModalOpen]);

    useEffect(() => {
        if(!modalSearchValue){
            onModalSearch()
        }
    }, [modalSearchValue]);

    const getModalList = async() => {
        setModalTableLoading(true);
        setModalListData([]);
        try {
            const res = await Api.Home.getNotHotKnowledge({data:{
                ...modalSearchData,
                sort:0
            }});
            setModalTableLoading(false);
            if(res&&res.code === 0){
                let _data = res.data;
                setModalListData(_data.records);
                setModalPagination({
                    "pageIndex": _data.current,
                    "pageSize": _data.size,
                    "total":_data.total
                });
            }else{
                setModalListData([]);
            }
        } catch (errInfo) {
            setModalListData([]);
            console.log('验证失败:', errInfo);
        }
    }

    const modalDateChange = (date, dateString) => {
        let newSearchData = { ...modalSearchData };
        newSearchData.startTime = dateString[0];
        newSearchData.endTime = dateString[1];
        setModalSearchData(newSearchData);
    };

    // 搜索框
    const modalChangeValue = (e) => {
        setModalSearchValue(e.target.value)
    }

    const onModalSearch = () => {
        let newSearchData = {...modalSearchData}
        newSearchData.pageIndex = 1;
        newSearchData.knowledgeName = modalSearchValue;
        setModalSearchData(newSearchData);
    }

    const onModalResetting = () => {
        setModalSearchValue("");
        setModalSearchData({
            ...pagingParam,
            knowledgeName:"",
        })
    }

    const onModalChanges = (pagination, filters, sorter) => {
        // 排序方式，0按排序序号降序 1按排序序号升序 2按搜索量降序 3按搜索量升序
        // "descend" 降序 "ascend"升序
        
        let newSearchData = {...modalSearchData};
      
        if(sorter.field === "sort" && sorter.order){
            newSearchData.sort = sorter.order === "descend" ? 0 : 1
        }else if(sorter.field === "searchNum" && sorter.order){
            newSearchData.sort = sorter.order === "descend" ? 2 : 3
        }else{
            newSearchData.sort = ""
        }
        console.log(newSearchData,"newSearchData")

        newSearchData.pageIndex = pagination.current;
        newSearchData.pageSize = pagination.pageSize;
        setModalSearchData(newSearchData);
    };


    const goDetails = (record) => {
        window.open(`/page/knowledgeDetails?knowledgeId=${record.knowledgeId}`)
    }

    // 获取上传的图片
    const handleChange = async({ file, fileList: newFileList },records) => {
        // setFileList(newFileList);
        setUploadFile(file);
        setImage(URL.createObjectURL(newFileList[0].originFileObj))
        console.log(file,"file")
        setCroppedImageOpen(true)
        setEditingObj(records)
    };

    const handleCrop = async(value) => {
        message.loading({
            content:'上传中..',
            key:"upload"
        })
        const formData = new FormData();

        const fileValue = new File([value], uploadFile.name, { type: uploadFile.type }); // 使用文件名创建File对象
        console.log(fileValue); // 这里可以处理file对象，比如上传到服务器
        
        formData.append("file",fileValue);
        
        const res = await Api.Common.uploadFile({
            data:formData,
        });
        if(res&&res.code === 0){
            handleCropperCancel();
            update(res.data.id);
        }
    };

    const handleCropperCancel = () => {
        setCroppedImageOpen(false);
        setEditingObj({});
        setImage(null)
        setUploadFile('')
    }

    return (
        <div className="hotTopicKnowledgeFrame">
            <div className="title">推荐知识</div>
            <div className="operation">
                <Space size={"large"}>
                    <Button type="primary" onClick={addProject}>新增推荐知识</Button>
                    <Button type="primary" danger onClick={deleteProjects}>删除推荐知识</Button>
                </Space>
                
                <div className="operationRight">

                    <Space size={"large"}>
                        <div className="operationItem">
                            <RangePicker
                                disabledDate={disabledDate}
                                value={[
                                    searchData.startTime?moment(searchData.startTime):"",
                                    searchData.endTime?moment(searchData.endTime):""
                                ]}
                                onChange={dateChange}
                                style={{marginLeft:20}}
                            />
                        </div>
                        <div className="operationItem">
                            <Input
                                placeholder="输入关键词搜索"
                                onChange={changeValue}
                                onPressEnter={onSearch}
                                style={{width:260,float:"right"}}
                                value={searchValue}
                                allowClear={true}
                            />
                        </div>
                        <Button type="primary" onClick={onSearch}>搜索</Button>
                        <Button onClick={onResetting}>重置</Button>
                    </Space>
                </div>
            </div>
            <BackIcon />
            <div className="table">
                <Form form={form} component={false}>
                    <SelfTable
                        rowKey={"knowledgeId"}
                        rowRadioSelection={{
                            selectedRowKey:selectedRowKey,
                            setSelectedRowKey:setSelectedRowKey,
                            type:"checkbox",
                            preserveSelectedRowKeys:true
                        }}
                        pagination={pagination}
                        dataSource={listData}
                        columns={columns}
                        loading={tableLoading}
                        onChange={onChanges} 
                        scroll={{
                            x:"1800px"
                        }}
                    />
                </Form>
            </div>

            <Modal 
                title="知识列表" 
                open={isModalOpen} 
                onOk={handleOk} 
                onCancel={handleCancel}
                maskClosable={false}
                destroyOnClose={true}
                confirmLoading={confirmLoading}
                wrapClassName={"selfDesignModal"}
                width={920}
            >
                <div className="operation">
                    <Space size={"large"}>
                        <div className="operationItem">
                            <RangePicker
                                disabledDate={disabledDate}
                                value={[
                                    modalSearchData.startTime?moment(modalSearchData.startTime):"",
                                    modalSearchData.endTime?moment(modalSearchData.endTime):""
                                ]}
                                onChange={modalDateChange}
                            />
                        </div>
                        <div className="operationItem">
                            <Input
                                placeholder="输入关键词搜索"
                                onChange={modalChangeValue}
                                onPressEnter={onModalSearch}
                                style={{width:200,float:"right"}}
                                value={modalSearchValue}
                                allowClear={true}
                            />
                        </div>
                    </Space>
                    <div className="operationRight">
                        <Space size={"large"}>
                            
                            <Button type="primary" onClick={onModalSearch}>搜索</Button>
                            <Button onClick={onModalResetting}>重置</Button>
                        </Space>
                    </div>
                </div>
                <SelfTable
                    rowKey={"knowledgeId"}
                    rowRadioSelection={{
                        selectedRowKey:modalSelectedRowKey,
                        setSelectedRowKey:setModalSelectedRowKey,
                        type:"checkbox",
                        preserveSelectedRowKeys:true,
                        hideAllButton:true
                    }}
                    pagination={modalPagination}
                    dataSource={modallistData}
                    columns={modalColumns}
                    loading={modalTableLoading}
                    onChange={onModalChanges} 
                    scroll={{
                        x:"1200px",
                        y:'250px'
                    }}
                />
            </Modal>

            {croppedImageOpen&&<CropperImg
                open={croppedImageOpen}
                imageUrl={imageUrl}
                handleOk={handleCrop} 
                handleCancel={handleCropperCancel} 
            />}

        </div>
    )
}

export default HotTopicKnowledge