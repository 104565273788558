import react, {useEffect,useState} from 'react';
import { Spin, Select, Typography, Space, Modal, Checkbox, Radio, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import ContentList from "../components/contentList";
import TimelineList from "./components/timelineList";
import DepartmentTree from "./components/departmentTree";
import PolicyTree from "../components/policyTree";
import KnowledgeType from "../../../components/KnowledgeType";
import PolicyList from "../components/policyList";
import NoneData from "../../../components/NoneData";

import Api from "../../../api";
import {getSearchParams} from "../../../utils/tool";
import storage from "../../../utils/storage";

import './index.scss';

let WPSSDK = null;
const { Text, Title, } = Typography;
const { RangePicker } = DatePicker;

const KnowledgeContent = ({history}) => {

    let defalutPolicyThemeList = [];
    let expandedKeys = []

    const isManage = getSearchParams().inlet === "manage";
    
    const [userInfo, setUserInfo] = useState(storage.getItem('userInfo'));

    const [structureType, setStructureType] = useState(getSearchParams().type);

    const checkboxOptions = [
        {
            label: '知识类型',
            value: 'Apple',
        },
        {
            label: '部门分类',
            value: 'Pear',
        },
        {
            label: '文件名称',
            value: 'Orange',
        },
        {
            label: '作者',
            value: 'Orange1',
        },
        {
            label: '创建时间',
            value: 'Orange2',
        },
        {
            label: '访问链接',
            value: 'Orange3',
        },
    ];


    // 下拉框数据
    const [dropdownData, setDropdownData] = useState([]);
    const [dropdownKey, setSelecthKey] = useState('');
    const [selectDisabled, setSelectDisabled] = useState(false);

    // tabs数据
    const [tabsData, setTabsData] = useState([]);
    const [tabsKey, setTabsKey] = useState('');

    // 排序
    const [sortKey, setSortKey] = useState('3');

    const [loadingType, setLoadingType] = useState(false);
    const [leftLoadingType, setLeftLoadingType] = useState(false);


    //子部门树
    const [departmentTree, setDepartmentTree] = useState([]);
    const [departmentTreeId, setDepartmentId] = useState('');

    //政策跟踪主题
    const [policyThemes, setPolicyThemes] = useState([]);
    const [policyNode, setPolicyNode] = useState('');
    const [policyType, setPolicyType] = useState({policyRegionId: "1"});

    // 列表数据
    const [listDate, setListDate] = useState([]);

    // 查询数据
    const [searchData, setSearchData] = useState({
        "pageIndex": 1,
        "pageSize": 10,
        "departmentSubclassificationId": '', //部门子分类
        "knowledgeTypeId": '', //知识类型ID
        "sort": 3, //sort
    });

    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });


    // 知识类型
    const [listType, setListType] = useState('');

    const [listHight, setListHight] = useState(0);

    const [projectId, setProjectId] = useState('');

    const [expandedKeyList, setExpandedKeyList] = useState([]);


    // 政策主题列表tabs
    const items = [
        { label: '政策主题跟踪', key: '1'},
        { label: '政策指标查询', key: '2'},
    ];

    const [tabsActiveKey, setTabsActiveKey] = useState('1');

    const [policyIndicatorInfor, setPolicyIndicatorInfor] = useState({});

    const [wpsPreviewUrl, setWpsPreviewUrl] = useState("");

    const [exportModalOpen, setExportModalOpen] = useState(false);
    const [timeFrame, setTimeFrame] = useState('');

    const [operationData, setOperationData] = useState({
        searchType:0 // 搜索类型 0标题、1全文
    });

    useEffect(() => {
        getInitDate();
        resizeCallBack();
        // 添加窗口变化监听回调函数
        window.addEventListener('resize', resizeCallBack);
        return () => {
            // 移出窗口变化监听
            window.removeEventListener('resize', resizeCallBack)
        }
    }, [])

    // 窗口变化监听回调
    function resizeCallBack() {
        let rootHeight = document.querySelector("#root").offsetHeight;

        // 计算table内容的高度
        let divHeight= document.querySelector('.righBox').getBoundingClientRect().top;

        setListHight(rootHeight - divHeight - 142);
    }


    useEffect(()=>{
        if(String(searchData.knowledgeTypeId) === "4" && searchData.policyThemeId){
            // 切换主题跟踪或指标查询
            if(tabsActiveKey === '2'){
                getPolicyThemeExcle();
            }else{
                getPolicyList();
            }
        }
   },[tabsActiveKey])


    useEffect(()=>{
        const isPolicy = String(searchData.knowledgeTypeId) === "4";
        setListDate([]);
        if(
            (structureType === 'knowledge' && searchData.knowledgeTypeId) ||
            (structureType === 'department' && searchData.departmentId)
        ){
            // 政策主题类型并且选中了政策主题
            if(isPolicy){
                // 当前是主题跟踪还是指标查询
                // 必须有policyThemeId才能调用
                if(searchData.policyThemeId){
                    if(tabsActiveKey === '2'){
                        // 指标查询
                        getPolicyThemeExcle();
                    }else{
                        getPolicyList();
                    }
                }

            }else{
                // 有tabsKey的时候说明tabs有选中的数据，那么部门，知识类型，子分类树必须选中
                // 没有tabsKey的时候说明tabs选中的是全部选项，全部的时候没有子分类树
                // 管理界面可以取消子分类树的选择
                if(
                    tabsKey && 
                    searchData.knowledgeTypeId && 
                    searchData.departmentId && 
                    searchData.departmentSubclassificationId
                    || !tabsKey
                    // || isManage
                ){
                    getList();
                }
            }
        }
    },[JSON.stringify(searchData)])
    // ,[
    //     searchData.pageIndex,
    //     searchData.pageSize,
    //     searchData.knowledgeTypeId,
    //     searchData.departmentId,
    //     searchData.departmentSubclassificationId,
    //     searchData.sort,
    // ])

    useEffect(()=>{
        const {knowledgeTypeId, departmentId} = searchData || {};
        const isPolicy = String(knowledgeTypeId) === "4";
        // 查询左侧树结构
        if(isPolicy){
            // 政策主题
            if(departmentId){
                getPolicyeThemeTree(departmentId);
            }else{
                // 查讯政策公共知识
                getPolicyeThemeTree(-1);
            }
        }else if(!isPolicy && knowledgeTypeId && (departmentId || String(knowledgeTypeId) === "7")){
            // 获取部门树
            getDepartmentTreeF(departmentId, knowledgeTypeId);
        }
    },[searchData.knowledgeTypeId,searchData.departmentId])

    const onTabsChange=(activeKey)=>{
        setTabsActiveKey(activeKey);
    }

    // 获取指标查询的标题和表格预览地址
    const getPolicyThemeExcle = async() => {
        setWpsPreviewUrl("");
        setLoadingType(true);
        try {
            let res = await Api.Knowledge.getByPolicyThemeId({data:{
                policyThemeId:policyNode.id
            }});
            if(res && res.code === 0){
                setPolicyIndicatorInfor(res.data)
            }else{}
        } catch (errInfo) {
            setLoadingType(false);
            console.log('验证失败:', errInfo);
        }

        try {
            if(WPSSDK){
                // 这个销毁没有生效
                // jsSDK.destroy();
                document.getElementsByClassName("wps-excle")[0].innerHTML = "";
            }
            let res = await Api.Knowledge.getPolicyIndicatorPreviewUrl({data:{
                policyThemeId:policyNode.id
            }});
            setLoadingType(false);
            if(res && res.code === 0 && res.data){
                setWpsPreviewUrl(res.data.data.link);
                WPSSDK = OpenSDK.config({
                    url: res.data.data.link,
                    mount: document.querySelector('.wps-excle'),
                })
            }else{
                WPSSDK.destroy();
            }
        } catch (errInfo) {
            setLoadingType(false);
            console.log('验证失败:', errInfo);
        }
    }

    // 获取政策主题列表
    const getPolicyList = async() => {

        setLoadingType(true);
        let _searchData = {...searchData};
        if(_searchData.regionId === 999){
            delete _searchData.regionId
        }
        try {
            let res = await Api.Knowledge.getPolicyPageList({data:{
                ..._searchData,
            }});
            setLoadingType(false);
            if(res && res.code === 0 && res.data.records){
                setListDate(res.data.records);
                setPagination({
                    "pageIndex": res.data.current,
                    "pageSize": res.data.size,
                    "total":res.data.total,
                })
            }else{setListDate([]);}
        } catch (errInfo) {
            setListDate([]);
            setLoadingType(false);
            console.log('验证失败:', errInfo);
        }
    }

    // 政策主题左侧树形结构
    const getPolicyeThemeTree = async(departmentId) =>{
        setLeftLoadingType(true);
        const res = await Api.Knowledge.getPolicyeThemeList({
            data:{
                departmentId
            }
        });
        setLeftLoadingType(false);
        if(res && res.code === 0 && res.data){
            let _data = res.data;

            // 没有记录选中的政策的时候默认选中第一个
            // 先递归出所有可以选择的政策
            if(!sessionStorage.policyThemeId){
                getFirstPolicyeTheme(_data)
            }

            setPolicyThemes(_data);
            // 设置选中第一个
            if(defalutPolicyThemeList.length > 0 && !sessionStorage.policyThemeId){
                onPolicySelect(defalutPolicyThemeList[0])
            }

            if(expandedKeys.length > 0){
                setExpandedKeyList(expandedKeys)
            }
        }else{
            setPolicyThemes('');
        }
    }

    // 获取所有的主题
    const getFirstPolicyeTheme = (data) => {
        data.map(item=>{
            if(item.policyThemeType === 1){
                defalutPolicyThemeList.push(item)
                return false;
            }else if(item.children){

                expandedKeys.push(item.id);//获取所有的分类ID

                getFirstPolicyeTheme(item.children)
            }
        })
    }

    const getInitDate = async() => {
        let departmentData = [], knowledgeData=[];

        /*
        *   部门：D
        *   知识类型：K
        *   子分类：C
        *
        *   部门点击进入：
        *   获取点击的部门ID -> 查询下面的关联知识类型 -> 根据D+K 查询 子分类C
        *
        *   知识类型点击进入：
        *   获取点击的知识类型ID -> 查询知识类型所有关联的部门 -> 根据D+K 查询 子分类C
        */

        // 入口点击的部门或者知识类型ID
        let currentId = getSearchParams().id ? getSearchParams().id : "";
        //刷新页面时需要记录上一次的选中的三个类型
        currentId = sessionStorage.selecthKey ? sessionStorage.selecthKey : currentId;

        const isDepartment = structureType === 'department';

        if(isManage){
            currentId = userInfo.departmentId;
        }
        if(isManage && userInfo.jobNum != '001'){
            setSelectDisabled(true);
        }

        // 查询部门列表
        departmentData = await getDepartmentList(isDepartment ? "" : currentId);

        // 根据 currentId 查询知识类型
        knowledgeData = await getKnowledgeTypeList(isDepartment ? currentId: "");
        
        // 区分是点击部门进入还是知识类型进入
        if(isDepartment){

            if(!isManage){
                knowledgeData.unshift({label:'全部', key:'', value:'',});
            }

            // 根据 currentId 和 知识类型 查询 子分类
            // if(knowledgeData[0] && knowledgeData[0].key){
            //     getDepartmentTreeF(currentId, knowledgeData[0].key); // 获取部门树
            // }

            let _knowledgeTypeId = (knowledgeData[0] ? knowledgeData[0].key : '');
            if (sessionStorage.tabsKey) {
                _knowledgeTypeId = sessionStorage.tabsKey;
            } else {
                sessionStorage.setItem("tabsKey", _knowledgeTypeId);
            }

            if(isManage){
                knowledgeData = knowledgeData.filter((item) => item.key != 4);
                console.log(knowledgeData,"knowledgeData")
            }

            setInitData({
                "dropdownData":departmentData,
                "tabsData":knowledgeData,
                "currentId":currentId,
                "tabsKey":_knowledgeTypeId,
                "departmentID":currentId,
                "knowledgeTypeId":_knowledgeTypeId
            })
        }else{

            let _departmentData = [...departmentData];

            if(currentId == "4" || currentId == "7"){
                _departmentData = [
                    {
                        label:'公共',
                        key:'',
                        value:'',
                    },
                    ...departmentData]
            }else if(!isManage){
                _departmentData.unshift({label:'全部', key:'', value:'',});
            }

            // 根据 currentId 和 知识类型 查询 子分类
            // if(departmentData[0] && departmentData[0].key){
            //     getDepartmentTreeF(departmentData[0].key, currentId); // 获取部门树
            // }

            let departmentId = (_departmentData[0] ? _departmentData[0].key : '')

            if (sessionStorage.tabsKey) {
                departmentId = sessionStorage.tabsKey;
            } else {
                sessionStorage.setItem("tabsKey", departmentId);
            }
            
            setInitData({
                "dropdownData":knowledgeData,
                "tabsData":_departmentData,
                "currentId":currentId,
                "tabsKey":departmentId,
                "departmentID":departmentId,
                "knowledgeTypeId":currentId
            })

        }
    }

    const setInitData = (param) => {
        let _searchData = {...searchData};

        setDropdownData(param.dropdownData);
        setTabsData(param.tabsData);

        setSelecthKey(param.currentId); // 下拉框默认选中上一个页面点击的ID
        setTabsKey(param.tabsKey); // 部门默认选中第一个

        _searchData.departmentId = param.departmentID;
        _searchData.knowledgeTypeId = param.knowledgeTypeId;
        // setListType(param.knowledgeTypeId);

        // 刷新页面时回显上一次记录的选择
        if(sessionStorage.departmentTreeId){
            _searchData.departmentSubclassificationId = sessionStorage.departmentTreeId;
            setDepartmentId(sessionStorage.departmentTreeId);
        }

        if(sessionStorage.policyThemeId){
            _searchData.policyThemeId = sessionStorage.policyThemeId;
            setPolicyNode({
                policyName:sessionStorage.policyThemeName,
                id:sessionStorage.policyThemeId
            })
        }

        if(sessionStorage.policyType){
            _searchData = {
                ..._searchData,
                ...JSON.parse(sessionStorage.policyType)
            }
            setPolicyType(JSON.parse(sessionStorage.policyType))
        }

        // 判断是否带项目ID进入
        if((getSearchParams().projectId || sessionStorage.projectId) && param.knowledgeTypeId == '2'){
            let projectId = getSearchParams().projectId || sessionStorage.projectId;
            _searchData.projectId = projectId
            setProjectId(projectId);
        }

        setSearchData(_searchData);
    }

    // 获取部门列表
    const getDepartmentList = async(knowledgeId="") => {
        let departmentData=[];
        return new Promise(async(resolve, reject) => {
            try{
                const resDepartment = await Api.Department.getDepartmentListAll({
                    data:{
                        knowledgeTypeIds:knowledgeId ? [knowledgeId] :[],
                        knowledgeRel:0
                    }
                });
                if(resDepartment&&resDepartment.code === 0){
                    let oddList = [];
                    let evenList = [];

                    resDepartment.data.map((item,index)=>{
                        let items = {
                            label:item.name,
                            key:item.id,
                            value:item.id
                        }
                        // 奇数的部门牌后面，偶数的部分排前面
                        if((index+1)%2===0){
                            oddList.push(items);
                        }else{
                            evenList.push(items);
                        }
                    })
                    departmentData = [...evenList,...oddList]
                }
                resolve(departmentData);
            }catch{reject()}
        });
    }

    // 根据部门ID获取知识类型ID
    const getKnowledgeTypeList = async(departmentId="") => {
        let knowledgeData=[];
        return new Promise(async(resolve, reject) => {
            try{
                const res = await Api.Knowledge.getAllKnowledger({data:{
                    departmentId
                }});
                if(res && res.code === 0 && res.data){
                    res.data.map(item=>{
                        knowledgeData.push({
                            label:item.knowledgeTypeName,
                            key:item.id,
                            value:item.id,
                            disabled: item.id == 8 ? true : false
                        })
                    })
                }else{
                    // 知识没有的时候清空数据

                }
                resolve(knowledgeData);
            }catch{reject()}
        });
    }

    // 获取部分子分类方法
    const getDepartmentTreeF = async(departmentId,knowledgeId) => {
        setLeftLoadingType(true);

        let res = null;
        
        if(knowledgeId === '7' && !departmentId){
            // 公共参考研报
            res = await Api.Knowledge.getPublicListTree({
                data: { knowledgeTypeId:knowledgeId },
            });
        }else{
            res = await Api.Department.getDepartmentTree({data:{
                departmentId,
                knowledgeTypeId:knowledgeId || searchData.knowledgeTypeId
            }});
        }
        
        setLeftLoadingType(false);
        if(res && res.code === 0 && res.data){
            setDepartmentTree(res.data);
        }else{
            setDepartmentTree([]);
            setDepartmentId('');
        }
    }

    const getList = async() => {
        setLoadingType(true);
        try{
            // if(getSearchParams().inlet === "manage"){
            //     getPublishedKnowledgeList();
            // }else{
                getKnowledgeList()
            // }
        }catch(errorInfo){
            setListDate([]);
            setLoadingType(false);
        }
    }

    // 知识中心获取知识列表
    const getKnowledgeList = async() => {
        let _searchData = {...searchData};
        if(structureType === 'department'){
            _searchData.knowledgeTypeId = _searchData.knowledgeTypeId === '9999'?"":_searchData.knowledgeTypeId
        }else{
            _searchData.departmentId = _searchData.departmentId === '9999'?"":_searchData.departmentId
        }

        let res = "";
        // 参考研报搜索标题的时候走ES的接口
        if((_searchData.text || _searchData.startTime) && _searchData.knowledgeTypeId === '7'){
            if(!_searchData.departmentId){
                // 研报公共知识字段修改
                _searchData.pubDeptSubId = _searchData.departmentSubclassificationId
                delete _searchData.departmentSubclassificationId
                delete _searchData.departmentId
            }
            res = await Api.Knowledge.getESSearch({data:_searchData});
        }else if(_searchData.knowledgeTypeId === '7'&& !_searchData.departmentId){
            // 公共参考研报接口
            if(!_searchData.departmentSubclassificationId){
                // 没有任何分类的时候不查询数据
                setLoadingType(false);
                return false
            }
            res = await Api.Knowledge.getPutKnowledgeList({ data: {
                knowledgeTypeId: _searchData.knowledgeTypeId,
                pageIndex: _searchData.pageIndex,
                pageSize: _searchData.pageSize,
                sort: _searchData.sort,
                pubDeptSubId:_searchData.departmentSubclassificationId
            }});

        }else{
            res = await Api.Knowledge.getKnowledgePageList({data:_searchData});
        }

        setLoadingType(false);
        if(res && res.code === 0 && res.data && res.data.records && res.data.records.length > 0){
            setListDate(res.data.records);
            setPagination({
                "pageIndex": res.data.current,
                "pageSize": res.data.size,
                "total":res.data.total,
            })
        }else{
            setListDate([]);
        }
    }

    // 下拉框改变
    const selectChange = async(value) => {
        setSelecthKey(value);

        sessionStorage.selecthKey = value;

        // 部门进入的时候，下拉框为部门数据
        if(structureType === 'department'){
            // listType == 4 的时候不用调用查询子部门接口
            // 查询知识类型
            let knowledgeData = await getKnowledgeTypeList(value);
            let _knowledgeData = []

            if(getSearchParams().inlet != "manage"){
                _knowledgeData = [
                    {
                        label:'全部',
                        key:'',
                        value:'',
                    },
                    ...knowledgeData]
            }else{
                _knowledgeData = [...knowledgeData]
            }


            // 子分类
            if(_knowledgeData[0]){
                // getDepartmentTreeF(value, knowledgeData[0].key); // 获取部门树
                setTabsData(_knowledgeData);
                setTabsKey(_knowledgeData[0].key); // 部门默认选中第一个
                resetData(_knowledgeData[0].key,value);

            }else{
                resetData('',value);
                setTabsData([]);
                setTabsKey("");
                setListDate([]);
                setDepartmentTree([]);
            }
        }else if(structureType === 'knowledge'){

            // 查询部门列表
            let departmentData = await getDepartmentList(value);

            let _departmentData = [];

            if(value === "4" || value == "7"){
                _departmentData = [
                    {
                        label:'公共',
                        key:'',
                        value:'',
                    },
                    ...departmentData]
            }else if(getSearchParams().inlet != "manage"){
                _departmentData = [
                    {
                        label:'全部',
                        key:'',
                        value:'',
                    },
                    ...departmentData]
            }else{
                _departmentData = [...departmentData]
            }


            // 根据 currentId 和 知识类型 查询 子分类
            if(_departmentData[0]){
                // getDepartmentTreeF(_departmentData[0].key, value); // 获取部门树
                setTabsData(_departmentData);
                setTabsKey(_departmentData[0].key); // 部门默认选中第一个
                resetData(value,_departmentData[0].key);
            }else{
                resetData(value,'');
                setTabsData([]);
                setTabsKey("");
                setListDate([]);
                setDepartmentTree([]);
            }

            // setListType(value);
        }
    }

    // tabs改变
    const tabsChange = (value) => {
        setTabsKey(value);
        sessionStorage.tabsKey = value;
        // 部门进入的时候，tabs为知识类型数据
        if(structureType === 'department'){

            // setListType(value);

            resetData(value,searchData.departmentId);
            // getDepartmentTreeF(searchData.departmentId, value); // 获取部门树

        }else if(structureType === 'knowledge'){


            // 知识类型进入时，tabs为部门数据
            // getDepartmentTreeF(value, searchData.knowledgeTypeId); // 获取部门树
            resetData(searchData.knowledgeTypeId,value);


            // listType == 4 的时候不用调用查询子部门接口
            // getDepartmentTreeF(value);
        }
    }

    // tab切换时重置数据
    const resetData = (knowledgeTypeId,departmentId) => {
        let _searchData = {
            "pageIndex": 1,
            "pageSize": 10,
            "departmentSubclassificationId": '',
            "knowledgeTypeId": knowledgeTypeId,
            "sort": '3',
            "departmentId":departmentId,
        }
        sessionStorage.removeItem("departmentTreeId");
        sessionStorage.removeItem("policyThemeId");
        sessionStorage.removeItem("policyThemeName");
        sessionStorage.removeItem("policyRegionId");
        sessionStorage.removeItem("projectId");

        setProjectId("");
        setSortKey('3');
        setDepartmentId('');
        setPolicyNode("")
        setSearchData(_searchData);
        setOperationData({
            searchType:0 // 搜索类型 0标题、1全文
        })
    }

    // 部门树选择
    const onSelect = (selectedKeys) => {
        setDepartmentId(selectedKeys[0]);
        changeSearchValue({
            'departmentSubclassificationId':selectedKeys[0],
            "pageIndex": 1,
            "pageSize": 10,
        });
    };

    // 排序变化
    const sortChange = (_sort,value) => {
        changeSearchValue({
            'sort':_sort
        });
        setSortKey(_sort);
    }

    // 分页变化
    const paginationChange = (pageIndex,pageSize) => {
        let _searchData = {...searchData}
        _searchData.pageIndex = pageIndex;
        _searchData.pageSize = pageSize;
        setSearchData(_searchData);
    }

    // 选择项目
    const projectChange = (value,option) => {
        sessionStorage.projectId = option.id
        changeSearchValue({
            'projectId':option.id
        });
    }

    // 政策类型改变
    const policyTypeChange = (param) => {
        sessionStorage.policyType = JSON.stringify({
            ...param
        })
        setPolicyType({
            ...param
        });

        delete param.name;
        changeSearchValue({
            "pageIndex":1,
            ...param
        });
    };

    // 政策主题
    const onPolicySelect = (node) => {
        if(node){
            sessionStorage.policyThemeId = node.id;
            sessionStorage.policyThemeName = node.policyName;
            sessionStorage.policyType = "";
            setPolicyNode(node);
            setPolicyType("");
            changeSearchValue({
                'policyThemeId':node.id,
                "policyRegionId":"",
                "pageIndex":1,
                "policySearch":"",
                "startTime":"",
                "endTime":"",
                "code":"",
                "regionId":""
            });
        } else {
            sessionStorage.removeItem("policyThemeId")
            sessionStorage.removeItem("policyThemeName")
            setPolicyNode("")
            changeSearchValue({
                'policyThemeId':"",
            });
        }
    };

    // 更新查询参数
    const changeSearchValue = (data)=>{
        let _searchData = {
            ...searchData,
            ...data
        }
        setSearchData(_searchData);
    }

    // 政策类型下 - 时间和标题筛选
    const operationChange = (value) => {
        changeSearchValue({
            ...value
        });
    };

    const showModal = () => {
        setExportModalOpen(true);
    };
    const handleOk = () => {
        setExportModalOpen(false);
    };
    const handleCancel = () => {
        setExportModalOpen(false);
    };

    const onCheckboxChange = () => {

    }

    const onRadioChange = (e) => {
        setTimeFrame(e.target.value)
    }

    const searchChange = (value, type) => {
        let newOperationData = {...operationData};
        newOperationData.pageIndex = 1;
        // 搜索类型 0标题、1全文
        newOperationData.searchType = 0;

        newOperationData[type] = value;
        if(value === ""){
            operationChange(newOperationData)
        }

        setOperationData(newOperationData)
    }

    const onSearch = () => {
        operationChange(operationData)
    }

    const dateChange = (dateString) => {
        let newOperationData = {...operationData};
        newOperationData.startTime = dateString[0];
        newOperationData.endTime = dateString[1];
        setOperationData(newOperationData);
        operationChange(newOperationData);
    };

    const searchDataKnowledgeTypeId = searchData?.knowledgeTypeId;
    const isPolicy = String(searchDataKnowledgeTypeId) === "4";

    return(
        <div className="knowledgeContentFrame">
            <div className="topTitle">
                <Select
                    value={dropdownKey}
                    options={dropdownData}
                    size={"large"}
                    onSelect={selectChange}
                    disabled={selectDisabled}
                    bordered={false}
                    dropdownStyle={{
                        padding: '9px 16px',
                        fontSize: '0.9rem',
                    }}
                />
                    {/* {getSearchParams().inlet != "manage"} */}
                <div className='tabsBox'>
                    <KnowledgeType
                        tabsData={tabsData}
                        tabsKey={tabsKey}
                        tabsChange={tabsChange}
                        searchData={searchData}
                        history={history}
                        knowledgeTypeId={searchDataKnowledgeTypeId}
                        showAiSearch={getSearchParams().inlet != "manage"}
                    />
                </div>
            </div>
            <div className="content">
                {tabsKey || dropdownKey == '4' || dropdownKey == '7'?<div className="leftBox">
                    <div className={"leftBoxScorll"}>
                        <Spin tip="数据加载中" spinning={leftLoadingType}>
                            {isPolicy ?
                            (<PolicyTree
                                treeDate={policyThemes}
                                selectedNode={policyNode}
                                changePolicyTree={onPolicySelect}
                                type={"view"}
                                expandedKeyList={expandedKeyList}
                            />):
                            (<DepartmentTree
                                departmentTree={departmentTree}
                                onSelect={onSelect}
                                departmentTreeId={departmentTreeId}
                                departmentId={searchData.departmentId}
                                getDepartmentTree={getDepartmentTreeF}
                                knowledgeTypeId={searchDataKnowledgeTypeId}
                            />)}
                        </Spin>
                    </div>
                </div>:""}


                <div className="righBox">

                    <Spin tip="数据加载中" spinning={loadingType}>
                        {
                            (isPolicy && policyNode) ?
                                (
                                    <div className="policyBox">
                                        <div className="policyHeadBox">
                                            <span className="policyNameText">{policyNode.policyName}</span>
                                            <div className="tabListBox">
                                                {
                                                    items.map(item => {
                                                        const {label, key} = item;
                                                        const isActive = tabsActiveKey === key;
                                                        return (
                                                            <span
                                                                className={`tabItem ${isActive ? "activeStatus" : ""}`}
                                                                key={key}
                                                                onClick={() => onTabsChange(key)}
                                                            >{label}</span>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="policyBottomBox" style={{paddingTop: tabsActiveKey === "1" ? 0 : ""}}>
                                            {tabsActiveKey==='1'?(
                                                <PolicyList
                                                    policyThemeName={policyNode.policyName}
                                                    listDate={listDate}
                                                    policyTypeChange={policyTypeChange}
                                                    policyType={policyType}
                                                    pagination={pagination}
                                                    paginationChange={paginationChange}
                                                    operationChange={operationChange}
                                                    searchData={searchData}
                                                    pageType={"titleHref"}
                                                />
                                            ):(<div className="wpsFileBox">
                                                <div className="titleBox">
                                                    <Title level={4}>{policyIndicatorInfor?.name}</Title>
                                                    {/* <p style={{marginTop:20}}>{policyIndicatorInfor?.name}</p> */}
                                                    {!wpsPreviewUrl?(<NoneData icon={"icon-zanwushuju"}/>):""}
                                                </div>

                                                <div className="wps-excle"></div>
                                            </div>)}
                                        </div>
                                        {/*<div className="contentTabs">*/}
                                        {/*    <Tabs items={items} activeKey={tabsActiveKey} onChange={onTabsChange}/>*/}
                                        {/*</div>*/}
                                    </div>
                                )
                                :
                                (isPolicy && !policyNode) ?
                                    (
                                        <div style={{marginTop:20}}>
                                            <NoneData text={"请选择政策主题后查询数据"} icon={"icon-zanwushuju"}/>
                                        </div>
                                    )
                                    : undefined
                        }

                        {/* {
                            String(searchDataKnowledgeTypeId) === "7" && !isManage ?
                                (
                                    <div className="researchReportOperationBox">
                                        <div className="operationItem">
                                            <span>标题：</span>
                                            <Input
                                                value={operationData.text}
                                                allowClear={true}
                                                placeholder={"请输入标题搜索"}
                                                onPressEnter={()=>{
                                                    onSearch();
                                                }}
                                                onChange={(e)=>{
                                                    searchChange(e.target.value,"text")
                                                }}
                                            />
                                        </div>
                                        <div className="operationItem">
                                            <Space size={"large"}>
                                                <Button type="primary"
                                                icon={<SearchOutlined />}
                                                onClick={onSearch}
                                                ></Button>
                                            </Space>
                                        </div>
                                    </div>
                                ) : undefined
                        } */}

                        {
                            !isPolicy ? (
                                <ContentList
                                    sortChange={sortChange}
                                    defaultSortKey={sortKey}
                                    pagination={pagination}
                                    paginationChange={paginationChange}
                                    searchData={searchData}
                                    // type={searchDataKnowledgeTypeId}
                                    listDate={listDate}
                                    projectChange={projectChange}
                                    autoHight={listHight}
                                    projectId={projectId}
                                    getList={getList}
                                    knowledgeData={structureType === 'department'?tabsData:dropdownData}
                                    getDepartmentTree={()=>{
                                        const {knowledgeTypeId, departmentId} = searchData || {};
                                        getDepartmentTreeF(departmentId,knowledgeTypeId)
                                    }}
                                    onSearch={onSearch}
                                    searchChange={searchChange}
                                    dateChange={dateChange}
                                    operationData={operationData}
                                />
                            ) : ""
                        }

                    </Spin>
                </div>
            </div>
            <Modal title="导出列表" wrapClassName="exportModalWrap" open={exportModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <p className="exportTitle">选择要导出的字段</p>
                <div className="checkboxGroup">
                    <Checkbox.Group options={checkboxOptions} onChange={onCheckboxChange} />
                </div>
                <p className="exportTitle">选择要导出的时段</p>
                <div className="checkboxGroup">
                    <Radio.Group onChange={onRadioChange}>
                        <Space direction="vertical">
                            <Radio value={1}>全部时段</Radio>
                            <Radio value={2}>今年以来</Radio>
                            <Radio value={3}>上一年度</Radio>
                            <Radio value={4}>最近一年</Radio>
                            <Radio value={5}>自定义</Radio>
                        </Space>
                    </Radio.Group>
                    {
                        timeFrame === 5 ? (<div style={{marginTop:15}}>选择时段：<RangePicker /></div>) : ""
                    }
                </div>
            </Modal>
        </div>
    )
}

export default KnowledgeContent;
