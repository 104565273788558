import React , {useEffect, useState} from 'react';
import { ContainerOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import HistoryTopic from '../../components/AIModal/components/HistoryTopic/index'
import { setAIModalState } from "../../features/AIIntelligent/AIReducer";
import './index.scss';



const AIAssistant = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const menuItem = [
        {key:1,'title':"文档阅读助手",'describe':'分析理解指定文档，对文档中的文本、图表信息提供检索问答、总结和翻译等服务',icon:<ContainerOutlined />,'url':"/page/aiAssistant/readAssistant"},
        {key:2,'title':"知识检索助手",'describe':'根据你的指令对知识库中的内容进行智能检索，快速分析并给出答案。',icon:<ContainerOutlined />,'url':"/page/aiAssistant/searchAssistant"},
    ]

    useEffect(()=>{
        dispatch(setAIModalState({
            historyTopicId:null
        }))
    },[])

    const onClickHistoryDetails = (id, title, item) => {

        dispatch(setAIModalState({
            historyTopicId:id,
            historyTitle:title
        }))

        if(item.questionLocation === 0){
            // 问答
            history.push("/page/aiAssistant/readAssistant")
        }else{
            // 检索
            history.push("/page/aiAssistant/searchAssistant")
        }
    }

    return (<div className='aiAssistantFrame'>
        <div className='left'>
            <div className='topTitle'>
                <span>AI智能助手</span>
            </div>

            <div className='content'>
                {menuItem.map((item,index)=>{
                    return(<div className='item' key={item.key} onClick={()=>{
                        dispatch(setAIModalState({
                            historyTopicId:null,
                        }));
                        sessionStorage.removeItem("dialogueParam");
                        
                        history.push(item.url)
                    }}>
                        <div className={`itemIcon iconColor${index}`}>{item.icon}</div>
                        <div className='itemText'>
                            <p className='title'>{item.title}</p>
                            <p className='describe'>{item.describe}</p>
                        </div>
                    </div>)
                })}
            </div>

        </div>
        <div className='right'>
            <HistoryTopic type={"All"} onClickHistoryDetails={onClickHistoryDetails}/>
        </div>
    </div>)
}

export default AIAssistant