import React, {useEffect, useState, useRef, } from "react";
import {useHistory} from "react-router-dom";
import {Typography, message, Space, Tooltip, Input, Timeline, Tag, Popconfirm, Popover, Tree, Modal, Result, Button} from "antd";
import {
    RightCircleOutlined, DownCircleOutlined, ExceptionOutlined,
    CheckCircleOutlined, SyncOutlined, ClockCircleOutlined
} from "@ant-design/icons";
import { useSelector,useDispatch } from "react-redux";

import { setAIModalState, refreshAIcarNumF } from "../../../features/AIIntelligent/AIReducer";
import {setKnowledgeState} from "../../../features/editKnowledge/editKnowledgeReducer";
import Iconfont from "../../../components/Iconfont/Iconfont";
import SelfTable from "../../../components/SelfTable/index";
import SelfDesignModal from "../../../components/SelfDesignModal";
import UploadFile from "../components/UploadFile";
import Api from "../../../api";
import {getSizeText, addAICart, readByAI, jumpToPage} from "../../../utils/tool";
import {zuStandStore} from "../../../zustand";
import moment from "moment";
import './index.scss';

const {Title, Link} = Typography;
const {Search} = Input;

const MySpace = (props) => {
    const dispatch = useDispatch();

    const columns = [
        {
            title: '报告名称',
            dataIndex: 'knowledgeName',
            render: (text, record) => {
                const {cartStatus} = record;
                return (
                    <div className="knowledgeNameBox">
                        <span className="knowledgeName overflowEllipsis" title={text} onClick={() => {
                            if(record.knowledgeCheckStatus === 1){
                                jumpToPage(record)
                            }else{
                                let url = `/page/newKnowledge?knowledgeId=${record.id}&type=view`;
                                window.open(url);
                            }
                        }}>{text}</span>
                        {
                            String(cartStatus) === "0" ? (
                                <div className="AiBtnBox">
                                    <Tooltip  title="加入购物车">
                                        <span
                                            className={`AiBtn ${record.addCartStatus != 1 ? 'shoppingCartBtnAvailable' : 'shoppingCartBtnNotAvailable'}`}
                                            onClick={(e)=>{
                                                e.stopPropagation();
                                                if(record.addCartStatus != 1){
                                                    addKnowledgeToAICart([record.id])
                                                }
                                            }}
                                        >
                                            <Iconfont type="icon-waixingren" />
                                            <Iconfont type="icon-jiahao" />
                                        </span>
                                    </Tooltip>
                                    <span className="AiReadBtn" onClick={()=>readByAI(record, history)}>AI帮我读</span>
                                </div>
                            ) : undefined
                        }
                    </div>
                )
            },
            align: "left",
        },
        {
            title: '作者',
            dataIndex: 'knowledgeName',
            render: (text, record) => {
                let authors = record.knowledgeSource;
                // 6参考资料 7参考研报
                if ((record.knowledgeTypeId != '6' && record.knowledgeTypeId != '7')) {
                    authors = record.author
                }
                return <a>{authors}</a>
            },
            ellipsis: true,
        },
        {
            title: '上传时间',
            dataIndex: 'createTime',
            width: "160px",
        },
        {
            title: '分享状态',
            dataIndex: 'knowledgeCheckStatus',
            width: "100px",
            render: (text, record) => (
                <div className="shareStatusBox">
                    {/* knowledge_check_status 0已拒绝，1已通过，2待审批，3草稿文章） */}
                    {   String(record.updateStatus) === '1' ? <Tag icon={<SyncOutlined spin />} color="processing">更新中</Tag> :
                        text === 0 ? <Tag icon={<Iconfont type={"icon-cuowu"} />} color="error">已拒绝</Tag> :
                            text === 1 ? <Tag icon={<Iconfont type={"icon-dui"} />} color="success">已分享</Tag> :
                                text === 2 ? <Tag icon={<SyncOutlined spin />} color="processing">审批中</Tag> :
                                <Tag icon={<Iconfont type={"icon-yuandian"} iStyle={{top: "-0.1rem", marginRight: "0.1rem"}} />} color="cyan">未分享</Tag>
                    }
                </div>
            ),
        },
        {
            title: '占用空间',
            dataIndex: 'size',
            width: "100px",
            render: (text, record) => <a>{getSizeText(Number(text || 0), "kb")}</a>,
            ellipsis: true
        },
        {
            title: '操作',
            dataIndex: 'operation',
            width: '120px',
            render: (_, record) => {
                const {knowledgeCheckStatus, id, updateStatus} = record;
                let params = [record];
                const status = String(knowledgeCheckStatus);
                const isShared = status === "1";
                const isShowUpdate = isShared || status === "2";
                const operateTypeList = [
                    {key: "share", text: "分享",},
                    {key: "edit", text: "补充文章信息",},
                    {key: "copy", text: "复制到…",},
                    {key: "move", text: "移动到…",},
                    {key: "history", text: "历史版本",},
                ];
                if (isShowUpdate) {
                    operateTypeList.shift();
                    operateTypeList.shift();
                    if (isShared) {
                        operateTypeList.unshift({key: "update", text: "更新",},)
                    }
                }

                //  1更新中 不显示更新按钮
                let updateBtn = (
                    <span
                        className={`operateBtn ${String(updateStatus) === '1' ? "disbleBtn" : ""}`}
                        style={isShared ? {} : {opacity: "0.3", cursor: "not-allowed"}}
                        onClick={() => {
                            if (isShared && (String(updateStatus) != '1')) {
                                operateFunc("update", params, false);
                            }
                        }}
                    >更新</span>
                );

                return (
                    <div className="operateBtnBox">
                        {
                            isShowUpdate ?
                            updateBtn
                                :
                                (<span className={`operateBtn shareBtn`} onClick={() => operateFunc("share", params, false)}>分享</span>)
                        }

                        <Popover
                            open={moreShowId === id}
                            placement="leftTop"
                            getPopupContainer={knowledgeList.length > 8 ? () => document.getElementById(id) : null}
                            content={(
                                <div className="mySpaceTableMoreOperateBox moreOperateBox">
                                    {
                                        operateTypeList.map(item => {
                                            const {key, text} = item;
                                            return (
                                                <span key={`${id}-${key}`} className="operateBtn" onClick={() => operateFunc(key, params, false)}>{text}</span>
                                            )
                                        })
                                    }
                                    <Popconfirm
                                        placement={"left"}
                                        title={(<span><Iconfont type='icon-tishi' iStyle={{color: "#ff981e", marginRight: "0.2rem", top: "-0.1rem"}} />确定下载所选择的知识吗？</span>)}
                                        okText={"确定"}
                                        cancelText={"取消"}
                                        overlayClassName="deletePupConfirmBox"
                                        onConfirm={() => operateFunc("download", params, false)}
                                        getPopupContainer={() => document.getElementById(`${id}-downloadBtn`)}
                                    >
                                        <span
                                            className="operateBtn downloadBtn"
                                            id={`${id}-downloadBtn`}
                                            onClick={(e) => {
                                                e?.stopPropagation();
                                                e?.preventDefault();
                                            }}
                                        >下载</span>
                                    </Popconfirm>
                                    {
                                        !isShowUpdate && (
                                            <Popconfirm
                                                placement={"left"}
                                                title={(<span><Iconfont type='icon-fail' iStyle={{color: "#F53F3F", marginRight: "0.2rem", top: "-0.1rem"}} />确定删除所选择的知识吗？</span>)}
                                                okText={"确定"}
                                                cancelText={"取消"}
                                                overlayClassName="deletePupConfirmBox"
                                                onConfirm={() => operateFunc("delete", params, false)}
                                                getPopupContainer={() => document.getElementById(`${id}-deleteBtn`)}
                                            >
                                                <span
                                                    className="operateBtn deleteBtn"
                                                    id={`${id}-deleteBtn`}
                                                    onClick={(e) => {
                                                        e?.stopPropagation();
                                                        e?.preventDefault();
                                                    }}
                                                >删除</span>
                                            </Popconfirm>
                                        )
                                    }
                                </div>
                            )}
                            title={null}
                        >
                            <span
                                className="operateBtn moreBtn"
                                id={id}
                                onClick={(e) => {
                                    e?.stopPropagation();
                                    e?.preventDefault();
                                    setMoreShowId(id);
                                }}
                            >更多</span>
                        </Popover>
                    </div>
                )
            },
        },];


    const historyColumns = [
        {
            title: '报告名称',
            dataIndex: 'knowledgeName',
            align: "left",
        },
        {
            title: '更新时间',
            dataIndex: 'createTime',
            width: "160px",
        },

        {
            title: '操作',
            dataIndex: 'operation',
            width: '120px',
            render: (_, record) => {
                return (<Link onClick={() => {
                    let url = `/page/newKnowledge?knowledgeId=${record.id}&type=historyView`;
                    window.open(url);
                }}>
                    查看
                </Link>)
            },
        },];
    const history = useHistory();
    const operateTypeList = [
        {key: "copy", text: "复制",},
        {key: "move", text: "移动",},
        {key: "download", text: "下载",},
        {key: "edit", text: "补充",},
        {key: "share", text: "分享",},
    ];

    const zuStandState = zuStandStore((state) => state);
    const [knowledgeList, setKnowledgeList] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [pagination, setPagination] = useState({
        pageSize: 15,
        pageIndex: 1,
        total: 0,
    });
    const [selectedRowKeysObject, setSelectedRowKeysObject] = useState({}); // 记录每一页的选项id
    const [selectedRowObject, setSelectedRowObject] = useState({}); // 记录每一页的选项数据
    const [moreShowId, setMoreShowId] = useState(null); // 显示的哪个更多弹窗
    const [fileHistory, setFileHistory] = useState({}); // 历史记录绑定的知识
    const [knowledgeHistoryList, setKnowledgeHistoryList] = useState([]); // 某个知识的历史记录
    const [historyIsShow, setHistoryIsShow] = useState(false); // 历史记录弹窗
    const [spaceTree, setSpaceTree] = useState([]); // 空间分类tree 弹窗
    const [spaceTreeIsShow, setSpaceTreeIsShow] = useState(false); // 空间分类tree 弹窗
    const [selectedTreeIds, setSelectedTreeIds] = useState([]); // 选中的空间分类tree
    const [operateType, setOperateType] = useState(null);
    const [moveOrCopyId, setMoveOrCopyId] = useState(null); // 移动或者复制 选中的知识id
    const [isBatch, setIsBatch] = useState(null); // 是否批量操作
    const [showTipsModal, setShowTipsModal] = useState(false); //分享的确认操作框
    const [editFileVlue, setEditFileVlue] = useState({}); // 需要编辑的知识

    // AI购物车数量变化
    const refreshAIcarNum = useSelector(state => state.AIModal.refreshAIcarNum);

    const hiddenMorePop = () => {
        setMoreShowId(null);
    }

    useEffect(() => {
        getSpaceLKist();
    }, [pagination.pageSize, pagination.pageIndex, zuStandState.spaceTypeIds, zuStandState.isFlush]);

    useEffect(() => {
        getSpaceTree();
        window.addEventListener("click", hiddenMorePop);
        return () => {
            window.removeEventListener("click", hiddenMorePop);
        }
    }, []);


    // AI购物车数量变化 - 刷新列表
    useEffect(()=>{
        if(refreshAIcarNum){
            getSpaceLKist();
        }
    },[refreshAIcarNum])

    const getSpaceTree = async () => {
        const res = await Api.PersonalCenter.querySpaceTree({});
        if (res && res.code == 0) {
            setSpaceTree(res.data);
        }
    };

    const getSpaceLKist = async () => {
        let params = {
            ...pagination,
        };
        if (searchValue) {
            params.name = searchValue;
        }
        if(zuStandState.spaceTypeIds?.length > 0) {
            params.spaceTypeIds = [...zuStandState.spaceTypeIds];
            // params.spaceTypeId = [...zuStandState.spaceTypeIds][0];
        }
        const res = await Api.PersonalCenter.getSpaceLKist({
            data: params,
            loading: true,
            loadingText: "数据加载中..."
        });
        if (res && res.code == 0) {
            const {records=[], total=0} = res?.data || {};
            setKnowledgeList(records);
            setPagination({
                ...pagination,
                total,
            })
        }
    }

    const operateFunc = async (type, knowledgeList, isBatch=true) => {
        setMoreShowId(null);
        if (knowledgeList.length < 1) {
            return;
        }
        setIsBatch(isBatch);
        switch (type) {
            case "delete":
                const res = await Api.PersonalCenter.batchDeleteKnowledgeFiles({
                    data: knowledgeList.map(item => String(item.id)),
                    loading: true,
                    loadingText: "知识删除中..."
                });
                if (res.code == 0) {
                    message.success("知识删除成功!");
                    getSpaceLKist();
                }
                break;
            case "copy":
                setMoveOrCopyId(knowledgeList[0].id)
                setSpaceTreeIsShow(true);
                break;
            case "move":
                setMoveOrCopyId(knowledgeList[0].id)
                setSpaceTreeIsShow(true);
                break;
            case "download":
                const file = knowledgeList[0];
                const downloadRes = await Api.PersonalCenter.batchDownloadKnowledgeFiles({
                    data: knowledgeList.map(item => String(item.id)),
                    loading: true,
                    loadingText: "知识下载中..."
                });
                let href = window.URL.createObjectURL(new Blob([downloadRes]));
                const dom = document.createElement("a");
                dom.download = knowledgeList.length > 1 ? `${Date.now()}.zip` : `${file.knowledgeName}.zip`;
                dom.href = href;
                dom.click();
                break;
            case "update":
                goShare(knowledgeList, "updateFile");
                break;
            case "edit":
                goShare(knowledgeList, "share");
                break;
            case "share":
                goShare(knowledgeList, "share");
                break;
            case "history":
                const historyRes = await Api.PersonalCenter.getKnowledgeFileVersions({
                    data: {
                        knowledgeId: knowledgeList[0].id,
                    },
                    loading: true,
                });
                if (historyRes.code == 0 && historyRes.data?.length > 0) {
                    setKnowledgeHistoryList(historyRes.data);
                    setFileHistory(knowledgeList[0])
                    setHistoryIsShow(true);
                } else {
                    message.warning("暂无历史版本!");
                }
                break;
        }

        setOperateType(type);
    }

    // 去分享
    const goShare = (knowledgeList, type) => {
        if (knowledgeList?.length > 0) {

            let value = knowledgeList.map(item => {
                return {
                    fileName: item.knowledgeName,
                    fileId: item.relationFileId,
                    fileType: item.fileType,
                    id: item.id,
                    knowledgeId: item.id,
                }
            })
            
            setEditFileVlue({
                knowledgeList:value,
                editType: type, //share 分享 补充文章信息。updateFile 更新
            })
            checkInformation(value,{
                knowledgeList:value,
                editType: type, //share 分享 补充文章信息。updateFile 更新
            });

        } else {
            message.warning("请选择需要分享的知识！");
        }
    }

    // 检查文件信息是否想同，或者未填写信息
    const checkInformation = async(list, editFileList) => {
        let knowledgeIds = [], data = list;
        data.map(item=>{
            knowledgeIds.push(item.knowledgeId)
        })
        try{
            let res = await Api.Knowledge.checkKnowledgeInfo({
                data: knowledgeIds,
                loading: true,
                loadingText: "数据查询中..."
            });
            if (res && res.code === 0) {
                // 0一致 1不一致 2信息未填写
                if(res.data === 1){
                    setShowTipsModal(true);
                }else{
                    goEditKnoeledge('',editFileList)
                }
            }
        }catch(e){}
    }

    const goEditKnoeledge = (type,editFileList) => {
        sessionStorage.setItem("shareBeforePath", location.href);
        let _editFileList = editFileList ? editFileList: editFileVlue
        sessionStorage.setItem("editFileVlue", JSON.stringify({
            ..._editFileList,
            operationType:type
        }));
        setEditFileVlue({});
        window.open(`/page/editKnowledge`);
        setShowTipsModal(false)
    }

    // 复制/移动 知识到空间分类
    const moveOrCopyFunc = async (spaceId, knowledgeIds, type) => {
        let func = Api.PersonalCenter.batchCopyKnowledgeFiles;
        let text = "复制";
        if (type === "move") {
            text = "移动";
            func = Api.PersonalCenter.batchMoveKnowledgeFiles;
        }
        const res = await func({
            data: {
                "knowledgeIds": knowledgeIds,
                "spaceTypeId": spaceId,
            },
            loading: true,
            loadingText: text + "中...",
        });

        if (res?.code == 0) {
            message.success(text + "成功！");
            setSelectedTreeIds([]);
            setMoveOrCopyId(null);
            setSpaceTreeIsShow(false);
            setSelectedRowKeysObject({});
            setSelectedRowObject({});
            getSpaceLKist();
        }


    }

    // 打开上传文件弹窗
    const openUploadFunc = () => {
        zuStandState.changeChoiceFileModalIsShow(true);
    }

    // AI帮我找
    const onAISearch = () => {
        dispatch(setAIModalState({
            modalOpen:true,
            questionLocation:1,
            queryText:"推荐问题",
            questionModule:"summary",
            relQuestions:2,
            sendAPI: Date.now().toString(),
        }))
    }

     // 添加知识到AI购物车
     const addKnowledgeToAICart = async (knowledgeIds) => {
        const res = await addAICart(knowledgeIds);
        if(res){
            dispatch(refreshAIcarNumF(Date.now().toString()));
            // getSpaceLKist();
        }
    }

    let rowKeyList = [];
    let rowList = [];
    let rowKeyObj = {};
    let isHaveSharedOrApplying = false;
    for (const key in selectedRowKeysObject) {
        let keys = selectedRowKeysObject[key] || [];
        rowKeyList.push(...(keys));
        selectedRowObject[key].forEach(item => {
            if (!isHaveSharedOrApplying) {
                const status = String(item.knowledgeCheckStatus);
                isHaveSharedOrApplying = status === "1" || status === "2";
            }
            if (!rowKeyObj[item.id]) {
                rowList.push(item);
                rowKeyObj[item.id] = 1;
            }
        })
    }

    const isHaveSelected = rowKeyList.length > 0;
    let operateParams = rowList;

    return (
        <div className="mySpaceFrame">
            <div className="topTitle">
                <div className="searchBox">
                    <span className="title">知识列表</span>
                    <Search
                        placeholder="搜素"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onSearch={getSpaceLKist}
                        style={{width: 280,}}
                    />
                    <div className="AiSearchBtn" onClick={onAISearch} >
                        <Iconfont type={"icon-waixingren"} />
                        <span>AI帮我找</span>
                    </div>
                </div>
                <div className={`rightOperateBox ${isHaveSelected ? "haveSelectedStatus" : ""}`}>
                    <div className="selectedOperateBox">
                        <Popconfirm
                            title={(<span><Iconfont type='icon-fail' iStyle={{color: "#F53F3F", marginRight: "0.2rem", top: "-0.1rem"}} />确定删除所选择的知识吗？</span>)}
                            okText={"确定"}
                            cancelText={"取消"}
                            overlayClassName="deletePupConfirmBox"
                            disabled={!isHaveSelected || isHaveSharedOrApplying}
                            onConfirm={() => operateFunc("delete", operateParams)}
                        >
                            <span className="operateBtn" style={isHaveSharedOrApplying ? {cursor: "not-allowed", opacity: "0.3"} : {}}>删除</span>
                        </Popconfirm>
                        {
                            operateTypeList.map(item => {
                                const {key, text} = item;
                                let isLimitKey = ["edit", "share"].includes(key);
                                if (key === "download") {
                                    return (
                                        <Popconfirm
                                            placement={"top"}
                                            title={(<span><Iconfont type='icon-fail' iStyle={{color: "#F53F3F", marginRight: "0.2rem", top: "-0.1rem"}} />确定下载所选择的知识吗？</span>)}
                                            okText={"确定"}
                                            cancelText={"取消"}
                                            disabled={!isHaveSelected}
                                            overlayClassName="deletePupConfirmBox"
                                            onConfirm={() => operateFunc(key, operateParams)}
                                        >
                                            <span className="operateBtn">{text}</span>
                                        </Popconfirm>
                                    )
                                }
                                return (
                                    <span
                                        key={key}
                                        className="operateBtn"
                                        style={(isHaveSharedOrApplying && isLimitKey) ? {cursor: "not-allowed", opacity: "0.3"} : {}}
                                        onClick={() => {
                                            if (isHaveSharedOrApplying && isLimitKey) {
                                                return
                                            }
                                            operateFunc(key, operateParams)
                                        }}
                                    >{text}</span>
                                )
                            })
                        }
                    </div>

                    <div className="selectedInfo">
                        <span className="infoDesc">已选择 {rowKeyList.length} 项</span>
                        <span className="cancelSelectedBtn" onClick={() => setSelectedRowKeysObject({})}>取消选择</span>
                    </div>
                    <span
                        className="uploadFileBtn"
                        style={zuStandState.selectedSpaceTypeId ? {} : {
                            opacity: 0.7,
                            cursor: "not-allowed",
                        }}
                        onClick={() => {
                            if (zuStandState.selectedSpaceTypeId) {
                                if (!isHaveSelected) {
                                    openUploadFunc()
                                } else {
                                    message.warning("请取消选择的知识！")
                                }
                            } else {
                                message.warning("请选择单个空间分类！")
                            }
                        }}
                    ><Iconfont type="icon-shangchuanyunkongjian" />上传资料</span>
                </div>
            </div>
            <div className="tableBox" id="mySpaceTableBox">
                <SelfTable
                    selfClassName={"aiTableClass"}
                    rowKey="id"
                    bordered={false}
                    tableContentBox="#mySpaceTableBox"
                    selfRowSelection={{
                        type: 'checkbox',
                        selectedRowKeys: selectedRowKeysObject[pagination.pageIndex] || [],
                        onChange: (newSelectedRowKeys, value) => {
                            let newRowKeysObject = {
                                ...selectedRowKeysObject
                            };
                            newRowKeysObject[pagination.pageIndex] = newSelectedRowKeys;

                            let newRowObject = {
                                ...selectedRowObject
                            };
                            newRowObject[pagination.pageIndex] = value;
                            setSelectedRowKeysObject(newRowKeysObject);
                            setSelectedRowObject(newRowObject);
                        },
                    }}
                    pagination={{
                        ...pagination,
                        pageSizeOptions: ["15", "30", "60", "100"],
                        onChange: (currentPage, pageSize) => {
                            setPagination({
                                ...pagination,
                                pageSize,
                                pageIndex: currentPage,
                            })
                        }
                    }}
                    dataSource={knowledgeList}
                    columns={columns}
                    expandable={{
                        expandedRowRender: record => <p style={{margin: 0}}>{record.knowledgeDigest}</p>,
                        rowExpandable: record => record.knowledgeDigest,
                        expandIcon: ({expanded, onExpand, record}) =>
                            expanded ? (
                                <DownCircleOutlined style={{color: "#1C76FD"}} onClick={e => onExpand(record, e)}/>
                            ) : (
                                <RightCircleOutlined style={{color: "#1C76FD"}} onClick={e => onExpand(record, e)}/>
                            )
                    }}
                />
            </div>

            {/* 知识历史版本 */}
            <SelfDesignModal
                open={historyIsShow}
                width={800}
                title={`${fileHistory?.knowledgeName}-历史版本`}
                centered={true}
                closable={true}
                maskClosable={true}
                wrapClassName={"historyListModalBox"}
                destroyOnClose={true}
                onCancel={() => {
                    setHistoryIsShow(false);
                    setKnowledgeHistoryList([]);
                    setFileHistory({});
                }}
                content={(
                    <div>
                        <SelfTable
                            selfClassName={"aiTableClass"}
                            rowKey="id"
                            bordered={false}
                            dataSource={knowledgeHistoryList}
                            columns={historyColumns}
                        />

                        {/* <Timeline>
                            {
                                knowledgeHistoryList.map((item, idx) => {
                                    return (
                                        <Timeline.Item key={idx}>
                                            {moment(item.createTime).format("YYYY-MM-DD HH:mm:ss")}
                                            {item.knowledgeName}
                                        </Timeline.Item>
                                    )
                                })
                            }
                        </Timeline> */}

                    </div>
                )}
            />

            {/* 知识 移动/复制 到分类空间 */}
            <SelfDesignModal
                open={spaceTreeIsShow}
                title={`${isBatch ? "【批量】" : ""}${operateType === "move" ? "移动" : "复制"}知识到空间分类`}
                width={600}
                centered={true}
                closable={true}
                maskClosable={true}
                wrapClassName={"spaceTreeListModalBox"}
                destroyOnClose={true}
                cancelText={"取消"}
                okText={"确定"}
                onCancel={() => {
                    setSelectedTreeIds([]);
                    setMoveOrCopyId(null);
                    setSpaceTreeIsShow(false);
                }}
                onOk={() => {
                    if (selectedTreeIds?.length > 0) {
                        moveOrCopyFunc(selectedTreeIds[0], isBatch ? rowKeyList : [moveOrCopyId], operateType);
                    } else {
                        message.warning("请选择空间分类!");
                    }
                }}
                content={(
                    <div>
                        <Tree
                            style={{marginLeft: 30}}
                            defaultExpandAll={true}
                            multiple={false}
                            checkable={false}
                            selectedKeys={selectedTreeIds}
                            treeData={spaceTree}
                            fieldNames={{ title: 'name', key: 'id', children: 'children' }}
                            rootClassName={"spaceTreeBox"}
                            onSelect={(selectedKeys) => setSelectedTreeIds(selectedKeys)}
                        />
                    </div>
                )}
            />

            <Modal
                title=""
                open={showTipsModal}
                destroyOnClose={true}
                maskClosable={false}
                footer={null}
                width={660}
                onCancel={()=>{setShowTipsModal(false)}}
                wrapClassName={"resultTipsBox"}
            >
                <Result
                    icon={<div className="iconBox iconWarning">
                        <ExceptionOutlined />
                    </div>}
                    title="部分文档基本信息缺失"
                    subTitle="请确认每篇文档基本信息的完整性"
                    extra={[
                        <Button onClick={()=>{setShowTipsModal(false)}}>取消分享</Button>,
                        <Button onClick={()=>goEditKnoeledge('editBatch')}>批量修改</Button>,
                        <Button onClick={()=>goEditKnoeledge('editOneByOne')} type="primary">逐篇确认</Button>,
                    ]}
                />
            </Modal>
        </div>
    );
}
export default MySpace;
