import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Popconfirm, Space, Typography, Select, Modal, message, DatePicker } from 'antd';
import { CloseCircleOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import Api from "../../../api";
import SelfTable from "../../../components/SelfTable"
import BackIcon from "../../../components/BackIcon/index";
import {pagingParam} from "../../../utils/defaultValue";

import './index.scss'

const { Text, Link } = Typography;
const { confirm } = Modal;

const ESList = () => {

    const [form] = Form.useForm();

    const [listData, setListData] = useState([]);
    const [esStatusDate, setEsStatusDate] = useState({});
    
    const [userList, setUserList] = useState([]);
    // 查询数据
    const [searchData, setSearchData] = useState({
        ...pagingParam,
        syncStatus:""
    });
    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });

    const [tableLoading, setTableLoading] = useState(false);
    // 列表选择数据
    const [selectedRowKey, setSelectedRowKey] = useState([]);
    const columns = [
        {
            title: '知识名称',
            dataIndex: 'knowledgeName',
            width: '',
            editable: true,
        },
        {
            title: '格式类型',
            dataIndex: 'fileType',
            width: '100px',
            editable: true,
        },
        {
            title: '所属部门',
            dataIndex: 'departmentName',
            width: '180px',
            editable: true,
        },
        {
            title: '知识类型',
            dataIndex: 'knowledgeTypeName',
            width: '120px',
            editable: true,
        },
        {
            title: '部门分类',
            dataIndex: 'subclassification',
            width: '160px',
            editable: true,
        },
        {
            title: '作者',
            dataIndex: 'author',
            width: '',
            editable: true,
        },

        {
            title: '创建人',
            dataIndex: 'userName',
            width: '140px',
            editable: true,
            filters: userList,
            filterMultiple:false,
            filteredValue:searchData.userId ? [searchData.userId] : [],
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            width: '',
            editable: true,
            sorter: true,
        },
        {
            title: '导入ES状态',
            dataIndex: 'syncStatus',
            width: '120px',
            editable: true,
            render: (text) => {
                // 0成功 1失败
                if(text == 1){
                    return <Text type="danger">
                        失败
                    </Text>
                }else if(text == 0){
                    return <Text type="success">
                        成功
                    </Text>
                }
                
            }
        },
        {
            title: '操作',
            width: '130px',
            dataIndex: 'operation',
            render: (_, record) => {
                if(record.syncStatus == 1){
                    return <Link onClick={()=>syncToEs(record.knowledgeId)}>
                    同步到ES
                </Link>
                    // <Popconfirm 
                    //         placement="left" 
                    //         title={'确定同步数据至ES吗？'} 
                    //         onConfirm={()=>syncToEs(record.knowledgeId)} 
                    //         okText="确定" 
                    //         cancelText="取消">
                        
                    // </Popconfirm>
                }
            }
        },
    ];

    useEffect(() => {
        setInitDate();
        getEsStatusDate();
    }, []);

    useEffect(() => {
        if(userList && userList.length>0){
            console.log("getList")
            getList();
        }
    }, [searchData,userList]);

    // useEffect(() => {
    //     if(searchData.syncStatus === '0' && columns.length > 9){
    //         columns.pop();
    //         setColumns(columns)
    //     }else if(columns.length <= 9){
    //         setColumns([
    //             ...columns,
    //             {
    //                 title: '操作',
    //                 width: '130px',
    //                 dataIndex: 'operation',
    //                 render: (_, record) => {
    //                     return <Link>
    //                     同步到ES
    //                 </Link>
    //                 }
    //             }
    //         ])
    //     }
    // }, [searchData.syncStatus]);

    const setInitDate = async() => {

        // 获取所有用户
        const res = await Api.Common.getAllDepUser({data:{}});
        if(res&&res.code === 0&& res.data){
            let _data = res.data;
            _data.map(item=>{
                item.value = item.id;
                item.text = item.name;
                item.children = item.users
                if(item.users){
                    item.children = cycleData(item.users)
                }
            })
            setUserList(_data)
        }else{
            setUserList([])
        }
        
    }

    const getEsStatusDate = async() => {
        try{
            const esStatus = await Api.Knowledge.getESStatus({data:{}});
            if(esStatus&&esStatus.code === 0&& esStatus.data){
                setEsStatusDate(esStatus.data)
            }
        }catch(erro){}
    }

    const getList = async() => {
        setTableLoading(true);
        let newSearchData = {
            ...searchData
        }
        if(newSearchData.sort){
            newSearchData.sort = newSearchData.sort ==='descend' ? 0 : newSearchData.sort === 'ascend' ? 1 : 0
        }
        const res = await Api.Knowledge.getEsStatusPageList({data:newSearchData});
        setTableLoading(false);
        if(res&&res.code === 0){
            let _data = res.data;
            setListData(_data.records);
            setPagination({
                "pageIndex": _data.current,
                "pageSize": _data.size,
                "total":_data.total
            });
        }else{
            setListData([]);
        }
    }

    const cycleData = (data) => {
        data.map(item=>{
            item.value = item.userId;
            item.text = item.userName+'-'+item.roleName;
        })
        return data
    }

    const changeState = (value) => {
        let newSearchData = {...searchData}
        newSearchData.syncStatus = value
        setSearchData(newSearchData);
        setSelectedRowKey([]);
    }

    const onChanges = (pagination, filters, sorter) => {
        let newSearchData = {...searchData}

        newSearchData.pageIndex = pagination.current ? pagination.current : 1;
        newSearchData.pageSize = pagination.pageSize ? pagination.pageSize : 10;

        newSearchData.sort = sorter.order;
        
        if(filters.userName){
            newSearchData.userId = filters.userName[0]
        }else{
            delete newSearchData.userId
        }

        setSearchData(newSearchData);
    };

    const syncToEs = async(id) => {

        let _knowledgeIds = [], text = '确定同步所有数据至ES吗？';
        if(id){
            _knowledgeIds = [id];
            text = '确定同步这条数据至ES吗？'
        }else if(selectedRowKey.length>0){
            _knowledgeIds = [...selectedRowKey];
            selectedRowKey
            text = `确定同步选中的${selectedRowKey.length}条数据至ES吗？`
        }

        confirm({
            title: text,
            icon: <ExclamationCircleOutlined />,
            content: '',
            onOk: ()=>{
                return new Promise(async (resolve, reject) => {
                    try{
                        const res = await Api.Knowledge.getEsStatusSyncToEs({data:{
                            knowledgeIds:_knowledgeIds
                        }});
                        resolve();
                        if(res&&res.code === 0){
                            getEsStatusDate();
                            getList();
                            setSelectedRowKey([]);
                            message.success("同步成功")
                        }
                    }catch(erro){
                        reject();
                    }
                }).catch(() => {console.log('Oops errors!');reject()});
            },
            onCancel() {},
        });
        
    }

    return (
        <div className="ESListFrame">
            {/* Elasticsearch */}
            <div className="title">ES状态管理</div>
            <div className="operationState">
                <div className="stateItem" style={searchData.syncStatus === "" ? {borderBottom:"2px solid #1890ff"} : {}} onClick={()=>{
                    changeState("");
                }}>
                    <p>全部</p>
                    <p>{esStatusDate.countSuccess+esStatusDate.countFail}</p>
                </div>
                <div className="stateItem" style={searchData.syncStatus === "0" ? {borderBottom:"2px solid #1890ff"} : {}} onClick={()=>{
                    changeState("0");
                }}>
                    <p><CheckCircleOutlined />导入成功</p>
                    <p>{esStatusDate.countSuccess}</p>
                </div>
                <div className="stateItem" style={searchData.syncStatus === "1" ? {borderBottom:"2px solid #1890ff"} : {}} onClick={()=>{
                    changeState("1");
                }}>
                    <p><CloseCircleOutlined />导入失败</p>
                    <p>{esStatusDate.countFail}</p>
                </div>
            </div>

            <BackIcon />
            <div className="table">
                {searchData.syncStatus != "0"&&
                esStatusDate.countFail > 0 &&
                <div className="oprationBox">
                    <Link onClick={()=>syncToEs()}>{selectedRowKey && selectedRowKey.length > 0?"同步选中数据到ES":"一键全部同步到ES"}</Link>
                </div>}
                <Form form={form} component={false}>
                    <SelfTable
                        rowKey={"knowledgeId"}
                        dataSource={listData}
                        columns={columns}
                        onChange={onChanges} 
                        pagination={pagination}
                        loading={tableLoading}
                        rowRadioSelection={{
                            selectedRowKey:selectedRowKey,
                            setSelectedRowKey:setSelectedRowKey,
                            type:"checkbox",
                            preserveSelectedRowKeys:true
                        }}
                        scroll={
                            {
                                x:"1600px"
                            }
                        }
                    />
                </Form>
            </div>
        </div>
    );
    
};
export default ESList;
