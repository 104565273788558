/*
    * 部门管理
*/ 
import myAxios from '../utils/request.js';

   

export default {
    // 获取部门列表
    async getDepartmentListAll (params={}, cb) {
        let res = await myAxios.postJson('/sysDepartment/selectAll', params);
        return res.data;
    },
    
    // 获取部门列表 - 分页
    async getDepartmentPage (params={}, cb) {
        let res = await myAxios.getJson('/sysDepartment/pageList', params);
        return res.data;
    },

    // 新增部门
    async addDepartment (params={}, cb) {
        let res = await myAxios.postJson('/sysDepartment/insert', params);
        return res.data;
    },

    // 删除部门
    async deleteDepartment (params={}, cb) {
        let res = await myAxios.deleteJson('/sysDepartment/delete', params);
        return res.data;
    },

    // 修改部门
    async updateDepartment (params={}, cb) {
        let res = await myAxios.postJson('/sysDepartment/update', params);
        return res.data;
    },

    // 根据部门id查询子菜单树
    async getDepartmentTree (params={}, cb) {
        let res = await myAxios.getJson('/departmentSubclassification/listTree', params);
        return res.data;
    },
    
    // 新增部门子分类
    async addSubclassification (params={}, cb) {
        let res = await myAxios.postJson('/departmentSubclassification/insert', params);
        return res.data;
    },

    // 删除部门子分类
    async deleteSubclassification (params={}, cb) {
        let res = await myAxios.deleteJson('/departmentSubclassification/delete', params);
        return res.data;
    },

    // 修改部门子分类
    async updateSubclassification (params={}, cb) {
        let res = await myAxios.postJson('/departmentSubclassification/update', params);
        return res.data;
    },

    // 根据知识类型id获取分享部门 
    async getDepartmentByKnowledgeTypeId (params={}, cb) {
        let res = await myAxios.getJson('/sysDepartment/getDepartmentByKnowledgeTypeId', params);
        return res.data;
    },

    // 获取登录用户的部门信息
    async getUswerDept (params={}, cb) {
        let res = await myAxios.getJson('/sysDepartment/getDept', params);
        return res.data;
    },
    
}