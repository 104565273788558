import React , {useEffect, useState} from 'react';
import { Modal, Button, Form, Input, message} from 'antd'
import { PlusSquareOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from "react-redux";

import { setAIModalState } from "../../../features/AIIntelligent/AIReducer";
import HistoryTopic from '../../../components/AIModal/components/HistoryTopic/index'
import BackIcon from '../../../components/BackIcon';
import AddFiles from '../components/AddFiles';
import FileList from '../components/FileList';
import Api from '../../../api';
import './index.scss';



const ReadAssistant = () => {
    const dispatch = useDispatch();
    
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const historyTopicId = useSelector(state => state.AIModal.historyTopicId);
    
    const [historyId, setHistoryId] = useState('');
    const [historyTitle, setHistoryTitle] = useState('');

    useEffect(()=>{
       let dialogueParam = sessionStorage.getItem('dialogueParam') 
       if(dialogueParam){
            dispatch(setAIModalState(JSON.parse(dialogueParam)));
            sessionStorage.removeItem('dialogueParam');
       }
    },[])

    useEffect(()=>{
        if(historyTopicId){
            setHistoryId(historyTopicId)
        }
    },[historyTopicId])

    const addTopicBtn = () => {
        setIsModalOpen(true);
    }

    const handleOk = async() => {
        try {
            const row = await form.validateFields();
            sendApi(row,(id)=>{
                setIsModalOpen(false);
                setHistoryId(id)
            })
        }catch(e){
            console.log(e)
        }
    };

    const sendApi = async(param,calback) => {
        try {
            const res = await Api.Chat.createQa({
                data:{
                    ...param,
                    questionLocation:0 // 0文档中的问答， 1知识中心 ， 2ES搜索界面，3查讯全部
                }
            })
            if(res.code === 0){
                message.success("新建成功");
                if(calback){
                    calback(res.data)
                }
                // getTopicList();
            }
        }catch(e){
            console.log(e)
        }
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const calbackIds = (ids)=>{
        console.log(ids,"idsidsids")
        sendApi({
            knowledgeIds: ids
        },(id)=>{
            setHistoryId(id);
            dispatch(setAIModalState({
                historyTopicId:id,
                historyTitle:''
            }))
        })
    }

    return (<div className='readAssistantFrame' id='readAssistantFrame'>
        <div className='header'>
            <div className='topTitle'>
                <span>文档阅读助手</span>
                <BackIcon/>
            </div>
        </div>
        <div className='readContent'>
            <div className='left'>
                <HistoryTopic 
                    type={"clickEffect"} 
                    questionLocations={[0]} 
                    onClickHistoryDetails={(id, title)=>{
                        setHistoryId(id);
                        setHistoryTitle(title)
                        dispatch(setAIModalState({
                            historyTopicId:id,
                            historyTitle:title,
                            knowledgeEsParam:{},
                            selectKnowledgeParam:{},
                            knowledgePublishedParam:{},
                            refreshHistoryTopicList:false,
                        }))
                    }}
                    onDeleteTopic={(type)=>{
                        if(type){
                            // 选中的对话记录被删除
                            setHistoryId('');
                            setHistoryTitle('')
                            dispatch(setAIModalState({
                                historyTopicId:null,
                                historyTitle:'',
                                refreshHistoryTopicList:false,
                            }))
                        }
                    }}
                    historyId={historyId}
                />
                
                <div className='addTopicBtnBox'>
                    <Button onClick={addTopicBtn} className='addTopicBtn' type="primary" icon={<PlusSquareOutlined />}> 新建对话</Button>
                </div>
            </div>
            <div className='right'>
                
                {historyId?<FileList />:<AddFiles calbackIds={calbackIds}/>}
                
            </div>
        </div>

        <Modal title="新建对话" centered={true} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Form
                name="basic"
                form={form}
                labelCol={{span: 6}}
                wrapperCol={{span: 16}}
                initialValues={{remember: true}}
                autoComplete="off"
            >
                <Form.Item
                    label="对话标题"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: '请输入对话标题!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    </div>)
}

export default ReadAssistant