/*
    * 职务
*/ 
import myAxios from '../utils/request.js';

export default {
    // 查询所有专业
    async getSpecialityList (params={}, cb) {
        let res = await myAxios.getJson('/sysUsersProfession/selectAll', params);
        return res.data;
    },
    // 删除专业
    async deleteSpeciality (params={}, cb) {
        let res = await myAxios.deleteJson('/sysUsersProfession/deleteById', params);
        return res.data;
    },
    // 修改专业
    async updateSpeciality (params={}, cb) {
        let res = await myAxios.postJson('/sysUsersProfession/update', params);
        return res.data;
    },
    // 新增专业
    async addSpeciality (params={}, cb) {
        let res = await myAxios.postJson('/sysUsersProfession/insert', params);
        return res.data;
    },
}