import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Table,
  Modal,
  Switch,
  Form,
  Input,
  message,
  Image,
  Upload,
  Button,
} from "antd";
import { SettingFilled, UploadOutlined, FormOutlined,LoadingOutlined ,PlusOutlined } from "@ant-design/icons";
import Api from "../../../api";
import ModalTable from "./modalTable/index";
import Iconfont from "../../../components/Iconfont/Iconfont";
import BackIcon from "../../../components/BackIcon/index";

import "./index.scss";

const GlobalSettings = () => {
  const [form] = Form.useForm();
  const [wordList, setWordList] = useState([]);
  const [corList, setCorList] = useState([]);
  const [AIList, setAIList] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isReqLoading, setIsReqLoading] = useState(true);
  const [isReqLoading2, setIsReqLoading2] = useState(true);
  const [isReqLoading3, setIsReqLoading3] = useState(true);

  const [translationTypeId, setTranslationTypeId] = useState();
  const [imgUrl, setImgUrl] = useState();
  // 当前点击item
  const [currentItem, setCurrentItem] = useState({});
  // 上传文件列表
  const [fileList, setFileList] = useState([]);
  // 上传图标
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);
  // 判断点击的是不是AI
  const [currentType, setCurrentType] = useState();
  // 弹框确认按钮的状态
  // const [btnDisable, setBtnDisable] = useState(true);
  useEffect(() => {
    init();
  }, []);
  const init = () => {
    getTranslateList(0);
    getTranslateList(1);
    getAIsettingList();
  };
  const getTranslateList = async (typeId) => {
    if (typeId === 1) {
      setIsReqLoading2(true);
    } else {
      setIsReqLoading(true);
    }
    let res = await Api.Settings.getTranslateInfo({
      data: { translationType: typeId },
    });
    if (res.data && res.data.length > 0) {
      if (typeId === 1) {
        setIsReqLoading2(false);
        setCorList(res.data);
      } else {
        setIsReqLoading(false);
        setWordList(res.data);
      }
    }
  };
  const getAIsettingList = async () => {
    setIsReqLoading3(true);
    try {
      let res = await Api.Settings.getAISettings();
      setAIList(res.data);
      setIsReqLoading3(false);
    } catch (error) {}
  };

  const props = {
    name: "file",
    multiple: true,
    beforeUpload(file, fileList) {
      setFileList(fileList);
      getFileId(file);
      return false;
    },
  };
  // const beforeUpload = (file, fileList) => {
  //   setFileList(fileList);
  //     getFileId(file);
  // }
  // 修改状态
  const onChange = async (id) => {
    let res = await Api.Settings.enableById({ data: { id } });
    if (res.data) {
      if (id === "1" || id === "2") {
        getTranslateList(0);
      } else {
        getTranslateList(1);
      }
      message.success("操作成功");
    }
  };
  const onChangeAI = async (id) => {
    let res = await Api.Settings.AIEnableById({ data: { id } });
    if (res.data) {
      getAIsettingList();
      message.success("操作成功");
    }
  };
  const columns = [
    {
      title: "图标",
      dataIndex: "fileId",
      key: "fileId",
      className: "table-head",
      render: (_, record) => (
        <>
          <Image
            src={record.fileUrl}
            style={{ display: "block", width: "2rem", height: "2rem" }}
          ></Image>
        </>
      ),
    },
    {
      title: "接口",
      dataIndex: "appName",
      key: "appName",
      className: "table-head table-appName",
    },
    {
      title: "AppID",
      dataIndex: "appKey",
      key: "appKey",
      className: "table-head table-appid",
    },
    {
      title: "Secret",
      dataIndex: "appSecret",
      key: "appSecret",
      className: "table-head table-secret",
    },
    {
      title: "状态",
      key: "isEnable",
      className: "table-head table-enable",
      render: (_, record) => (
        <>
          <Switch
            size="small"
            checked={record.isEnable === 0}
            onChange={() => onChange(record.id)}
          />
          {record.isEnable === 0 ? "启用" : "禁用"}
        </>
      ),
    },
    {
      title: "操作",
      key: "",
      className: "table-head",
      render: (_, record) => (
        <FormOutlined
          onClick={() => showModal(record, { type: "translation" })}
        />
      ),
    },
  ];
  const columnsAI = [
    {
      title: "图标",
      dataIndex: "fileId",
      key: "fileId",
      className: "table-head",
      render: (_, record) => (
        <>
          <Image
            src={record.fileUrl}
            style={{ display: "block", width: "2rem", height: "2rem" }}
          ></Image>
        </>
      ),
    },
    {
      title: "接口",
      dataIndex: "appName",
      key: "appName",
      className: "table-head table-appName",
    },
    {
      title: "AppID",
      dataIndex: "appKey",
      key: "appKey",
      className: "table-head table-appid",
    },
    {
      title: "Secret",
      dataIndex: "appSecret",
      key: "appSecret",
      className: "table-head table-secret",
    },
    {
      title: "状态",
      key: "isEnable",
      className: "table-head table-enable",
      render: (_, record) => (
        <>
          <Switch
            size="small"
            checked={record.isEnable === 0}
            onChange={() => onChangeAI(record.id)}
          />
          {record.isEnable === 0 ? "启用" : "禁用"}
        </>
      ),
    },
    {
      title: "操作",
      key: "",
      className: "table-head",
      render: (_, record) => (
        <FormOutlined onClick={() => showModal(record, { type: "AI" })} />
      ),
    },
  ];
  const getFileId = async (file) => {
    setLoading(true)
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const res = await Api.Common.uploadFile({
        data: formData,
      });
      // console.log(res, "上传");
      if (res.data) {
        setImgUrl(res.data.id);
        setImageUrl(res.data.url)
        setLoading(false)
        message.success("图标上传成功");
      } else {
        setLoading(false)
        message.error("上传失败，请重试");
      }
    }
  };
  const showModal = (record, { type }) => {
    console.log(type, "type");
    if (type === "AI") {
      setCurrentType({ type: "AI", id: record.id });
    } else {
      setTranslationTypeId(record.id);
      setCurrentType({ type: "", id: record.id });
    }
    setCurrentItem(record);
    form.setFieldsValue(record);
    setOpen(true);
  };
  const handleOk = () => {
    form.submit();
    setFileList([]);
  };
  const handleCancel = () => {
    setOpen(false);
    setImageUrl(null)
    setImgUrl(null)
  };
  const onFinish = async (values) => {
    if (values.file) {
      values.fileId = imgUrl;
    } else {
      values.fileId = currentItem.fileId;
    }
    if (currentItem.appKey === values.appKey) {
      let { appName, appSecret, fileId } = values;
      values = { appName, appSecret, fileId };
    }
    if (currentItem.appSecret === values.appSecret) {
      let { appName, fileId } = values;
      values = { appName, fileId };
    }
    setConfirmLoading(true);
    if (currentType.type === "AI") {
      values.id = currentType.id;
      // 请求AI接口
      try {
        let res = await Api.Settings.AIUpdateType({ data: values });
        if (res.data === "修改成功") {
          setOpen(false);
          setConfirmLoading(false);
          form.setFieldsValue("");
          getAIsettingList();
          message.success(res.data);
        }
      } catch (error) {}
    } else {
      values.id = translationTypeId;
      // 请求翻译接口
      let res = await Api.Settings.updateType({
        data: values,
      });
      if (res.data === "修改成功") {
        setOpen(false);
        setConfirmLoading(false);
        form.setFieldsValue("");
        if (translationTypeId === "1" || translationTypeId === "2") {
          getTranslateList(0);
        } else {
          getTranslateList(1);
        }
        message.success(res.data);
      }
    }
    setImgUrl(null)
    setImageUrl(null)
    setConfirmLoading(false);
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        上传图标
      </div>
    </div>
  );
  return (
    <div className="globalSettings">
      <BackIcon />
      <div className="pageTitle">
        <p>
          <Iconfont
            type={"icon-shezhi"}
            iStyle={{ color: "#797979", fontSize: "1.5rem" }}
          ></Iconfont>
          全局功能设置
        </p>
      </div>
      <div className="settings">
        <Row gutter={16}>
          <Col span={8}>
            <Card title="翻译设置" bordered={false}>
              <div className="table-wrapper">
                <Table
                  size="small"
                  columns={columns}
                  dataSource={wordList}
                  loading={isReqLoading}
                  pagination={false}
                />
              </div>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="OCR设置" bordered={false}>
              <div className="table-wrapper">
                <Table
                  size="small"
                  columns={columns}
                  dataSource={corList}
                  loading={isReqLoading2}
                  pagination={false}
                />
              </div>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="AI大模型设置" bordered={false}>
              <div className="table-wrapper">
                <Table
                  size="small"
                  columns={columnsAI}
                  dataSource={AIList}
                  loading={isReqLoading3}
                  pagination={false}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="modalTableView">
        <div className="tableTitle">模块功能</div>
        <ModalTable />
      </div>
      <Modal
        title="修改接口"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <>
            <Button key="back" onClick={handleCancel}>
              取消
            </Button>
            <Button
              key="submit"
              type="primary"
              // disabled={btnDisable}
              onClick={handleOk}
            >
              确认
            </Button>
          </>,
        ]}
      >
        <Form
          name="basic"
          form={form}
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="应用名称"
            name="appName"
            rules={[
              {
                required: true,
                message: "请输入应用名称!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="应用ID"
            name="appKey"
            rules={[
              {
                required: true,
                message: "请输入应用Id!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="应用密钥"
            name="appSecret"
            rules={[
              {
                required: true,
                message: "请输入应用密钥!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="翻译图标" name="file">
            <Upload
              {...props}
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              accept="image/*,.jpg, .jpeg, .png"
              showUploadList={false}
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="avatar"
                  style={{
                    width: "100%",
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default GlobalSettings;
