import myAxios from '../utils/request.js';

export default {
    
    // 新增知识体系标题
    async addKnowledgeNoteSystem (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeNoteSystem/addKnowledgeNoteSystem', params);
        return res.data;
    },
    // 更新知识体系标题
    async updateKnowledgeNoteSystem (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeNoteSystem/updateKnowledgeNoteSystem', params);
        return res.data;
    },
    // 删除知识体系标题
    async removeKnowledgeNoteSystemById (params={}, cb) {
        let res = await myAxios.getJson('/knowledgeNoteSystem/removeKnowledgeNoteSystemById', params);
        return res.data;
    },
    // 获取知识体系标题列表
    async getKnowledgeNoteSystemList (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeNoteSystem/getKnowledgeNoteSystemList', params);
        return res.data;
    },
    // 移动知识体系系统
    async moveNewKnowledgeNoteSystem (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeNoteSystem/moveNewKnowledgeNoteSystem', params);
        return res.data;
    },
    



    // 保存知识体系
    async saveKnowledgeNoteSystem (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeNoteSystemManagement/addKnowledgeNoteSystem', params);
        return res.data;
    },
    async deleteKnowledgeNoteSystemById (params={}, cb) {
        let res = await myAxios.getJson('/knowledgeNoteSystemManagement/removeKnowledgeNoteSystemById', params);
        return res.data;
    },
    
    // 知识体系详细
    async getKnowledgeNoteSystemManagementById (params={}, cb) {
        let res = await myAxios.getJson('/knowledgeNoteSystemManagement/getKnowledgeNoteSystemManagementById', params);
        return res.data;
    },
    
    
    // 获取所有的标签
    async getAllLabelName (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeNoteLabelManagement/getAllLabelName', params);
        return res.data;
    },

    // 根据标签Id获取当前笔记
    async getLabelRelationNoteByLabelId (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeNoteLabelManagement/getLabelRelationNoteByLabelId', params);
        return res.data;
    },

    // 新增标签关联笔记
    async addNewLabelRelationNote (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeNoteLabelManagement/addNewLabelRelationNote', params);
        return res.data;
    },
    
    // 新增或更新讲解体系
    async saveExplain (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeNoteLabelManagement/saveOrUpdateKnowledgeLabelExplain', params);
        return res.data;
    },
    
    // 删除标签的笔记
    async removeLabelNote (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeNoteLabelManagement/removeLabelNote', params);
        return res.data;
    },
    
}