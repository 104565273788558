import React, { useState, useEffect, useRef } from 'react';
import { Typography, Input, Modal, message, Space, Button } from 'antd';
import * as marked from "marked";

import Api from "../../../../api";
import SelfTable from "../../../../components/SelfTable"
import {pagingParam} from "../../../../utils/defaultValue";
import ExportExcel from '../../../../utils/ExportExcel'

import './index.scss'

const { Link } = Typography;
const CueWord = () => {

    const [listData, setListData] = useState([]);
    const [operationData, setOperationData] = useState({});
    // 查询数据
    const [searchData, setSearchData] = useState({
        ...pagingParam,
    });
    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });
    const [tableLoading, setTableLoading] = useState(false);
    const columns = [
        {
            title: '提问内容',
            dataIndex: 'q',
            width: '200px',
        },
        {
            title: '回答',
            dataIndex: 'a',
            width: '',
        },
        {
            title: '姓名',
            dataIndex: 'userName',
            width: '130px',
        },
        {
            title: '工号',
            dataIndex: 'jobNum',
            width: '90px',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            width: '150px',
        },
    ];

    const [checkedList, setCheckedList] = useState(["q","a","userName","jobNum","createTime"]);

    useEffect(() => {
        getList();
    }, [JSON.stringify(searchData)]);

    const getList = async() => {
        setTableLoading(true);
        try{
            const res = await Api.AIModel.pageQaDetail({data:searchData});
            setTableLoading(false);
            if(res&&res.code === 0){
                let _data = res.data;
                setListData(_data.records);
                setPagination({
                    "pageIndex": _data.current,
                    "pageSize": _data.size,
                    "total":_data.total
                });
            }else{
                setListData([]);
            }
        }catch(e){setTableLoading(false);}
    }

    // 搜索框
    
    const onSearch = () => {
        let newSearchData = {...searchData}
        newSearchData.pageIndex = 1;
        setSearchData({
            ...newSearchData,
            ...operationData
        });
    }

    const onResetting = () => {
        setSearchData({
            ...pagingParam,
        })
    }

    const searchChange = (value, type) => {
        let newOperationData = {...operationData};
        if(type === "timeRange"){
            newOperationData.startTime = value[0];
            newOperationData.endTime = value[1];
        }else{
            newOperationData[type] = value;
        }
        newOperationData.pageIndex = 1;
        setOperationData(newOperationData)
    }
    
    const tableChanges = (pagination, filters, sorter) => {
        let newSearchData = {...searchData}

        newSearchData.pageIndex = pagination.current ? pagination.current : 1;
        newSearchData.pageSize = pagination.pageSize ? pagination.pageSize : 10;

        newSearchData.sort = sorter.order;
        
        if(filters.creatorName){
            newSearchData.creator = filters.creatorName[0]
        }

        setSearchData(newSearchData);
    };

    const exportExcel = async() => {

        let exportColumns = [];
        columns.map(item=>{
            if(checkedList.includes(item.dataIndex)){
                exportColumns.push(item)
            }
        })

        ExportExcel([
            {
                column: exportColumns,
                dataSource: listData,
                options:{
                    colWidth: 8,
                    fontSize: 10,
                    isBold: true
                }
            }
        ],"提示词工程和答案")
    }

    
    return (
        <div className="cueWordFrame">
            <div className="title">提示词工程和答案</div>
            <div className="operation">
                <Space size={"large"}>
                    <div className="operationItem">
                        用户姓名：
                        <Input
                            placeholder="输入用户姓名进行搜索"
                            onChange={(e)=>searchChange(e.target.value,"userName")}
                            onPressEnter={onSearch}
                            style={{width:350,float:"right"}}
                            value={operationData.userName}
                            allowClear
                        />
                    </div>
                    <div className="operationItem">
                        工号：
                        <Input
                            placeholder="输入工号进行搜索"
                            onChange={(e)=>searchChange(e.target.value,"jobNum")}
                            onPressEnter={onSearch}
                            style={{width:350,float:"right"}}
                            value={operationData.jobNum}
                            allowClear
                        />
                    </div>
                    <Button type="primary" onClick={onSearch}>搜索</Button>
                    <Button onClick={onResetting}>重置</Button>
                </Space>

                <div className="operationRight">
                    <Button type="primary" onClick={exportExcel}>导出excel</Button>
                </div>
            </div>
            <div className="table">
                <SelfTable
                    rowKey={"typeId"}
                    dataSource={listData}
                    columns={columns}
                    loading={tableLoading}
                    pagination={pagination}
                    onChange={tableChanges} 
                />
            </div>

        </div>
    )
}

export default CueWord;