import React, { useState} from "react";
import { message, Empty } from "antd";

import "./index.scss";
const MyGrowth = () => {
  const [isSpinning, setIsSpinning] = useState(true);

  return (
    <div className="myGrowthFrame">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
  );
};

export default MyGrowth;
