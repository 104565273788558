import react, {useEffect,useState} from 'react';
import { Tooltip, message, Space, Empty, Modal, Tree, Spin, Checkbox, Pagination } from 'antd';
import { BugOutlined, ExclamationCircleOutlined, PlusSquareOutlined, MinusSquareOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import './index.scss';
import {KnowledgeApply, getSearchParams, goKnowledgeDetails, addAICart} from '../../utils/tool'
import storage from "../../utils/storage";
import Iconfont from "../Iconfont/Iconfont";
import KnowledgeFileIcon from "../KnowledgeFileIcon/index";
import SelfPagination from "../SelfPagination/index";
import { refreshAIcarNumF } from "../../features/AIIntelligent/AIReducer";
import {readByAI} from "../../utils/tool";
import ViewImg from "../ViewImg";
import Api from "../../api/index";

const KnowledgeList = ({
    type,
    autoHight,
    paginationChange,
    pagination,
    listDate,
    showProjectName,
    getList,
    knowledgeData,
    showProject,
    selecthValue,
    knowledgeTypeId,
    checkOnchange,
    checkedValue = [],
    getDepartmentTree,
    getClassification,
    showSelect
}) => {
    const isManage = getSearchParams().inlet === "manage";
    const dispatch = useDispatch();

    const [newListDate, setNewListDate] = useState([]);

    const history = useHistory();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [classificationType, setClassificationType] = useState("");
    const [departmentData, setDepartmentData] = useState([]);
    const [departmentId, setDepartmentId] = useState("");

    const [selectedKeys,setSelectedKeys] = useState("");
    const [selectedNode,setSelectedNode] = useState("");
    const [ConfirmLoading, setConfirmLoading] = useState(false);
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [listTree, setListTree] = useState(false);
    const [moveKnowledgeTypeId, setMoveKnowledgeTypeId] = useState('');
    const [moveDepartmentId, setMoveDepartmentId] = useState('');

    const [publickTreeId, setPublickTreeId] = useState('');
    const [spinningType, setSpinningType] = useState(false);
    const [treeKey, setTreeKey] = useState("");
    const [openZipKey, setOpenZipKey] = useState([]);

     // 图片预览列表
    const [viewImgList, setViewImgList] = useState([]);
    const [isShowViewImg, setIsShowViewImg] = useState(false);
    const [viewImgIdx, setViewImgIdx] = useState(0);

    // AI购物车数量变化
    const refreshAIcarNum = useSelector(state => state.AIModal.refreshAIcarNum);

    useEffect(()=>{
        setNewListDate(listDate)
    },[JSON.stringify(listDate)])

    // AI购物车数量变化 - 刷新列表
    useEffect(()=>{
        if(getList && refreshAIcarNum){
            getList();
        }
    },[refreshAIcarNum])


    const getlistTree = async() => {
        setSpinningType(true);
        try{
            let res = await Api.Knowledge.getlistTreeByDepartmentId({data:{
                departmentId:storage.getItem('userInfo').departmentId
            }});
            setSpinningType(false);
            let {code, data} = res || {};
            if(code === 0 && data){
                const tabsKey = sessionStorage.getItem("tabsKey");
                if (isManage && sessionStorage.getItem("tabsKey")) {
                    data = data.filter((it) => String(it.id) === tabsKey)
                }
                data.map(item=>{
                    item.childList = item.departmentSubclassificationVOS;
                    item.subclassification = item.knowledgeTypeName
                })
                setListTree(data);
                setTreeKey(Math.random())
            }else{setListTree([])}
        }catch{setSpinningType(false);setListTree([])}
    }

    const getPublicKnowledgeTypeList = async() => {
        setSpinningType(true);
        try {
            let res = await Api.Knowledge.getPublicListTree({
                data: { knowledgeTypeId:knowledgeTypeId },
            });

            setSpinningType(false);
            if (res && res.code === 0 && res.data) {
                setListTree(res.data);
            }else{setListTree([])}
        } catch (error) {
            setSpinningType(false);
            setListTree([]);
        }
    }

    const updateTreeData = (list, id, childList) =>
        list.map((node) => {
            if (node.id === id) {
                return {
                    ...node,
                    childList,
                };
            }
            if (node.childList) {
                return {
                    ...node,
                    childList: updateTreeData(node.childList, id, childList),
                };
            }
            return node;
        });

    const onLoadData = async({ id, childList }) =>
        new Promise(async (resolve) => {
        if (childList) {
            resolve();
            return;
        }
        let res = await Api.Knowledge.getPublicListTree({
            data: { knowledgeTypeId:id },
        });
        resolve();
        if (res && res.code === 0 && res.data) {
            setListTree((origin) =>updateTreeData(origin, id, res.data))
        }
    });

    const onLoadDataManage = async({ id, childList, knowledgeTypeName }) =>{
        new Promise(async(resolve) => {
            if (childList) {
              resolve();
              return;
            }

            if(knowledgeTypeName){

                const res = await Api.Department.getDepartmentTree({data:{
                    departmentId: id.split("_")[0],
                    knowledgeTypeId: id.split("_")[1],
                }});

                resolve();

                if(res.code === 0 && res.data){

                    setDepartmentData((originList)=>updateMangerTreeData(originList,id,res.data))

                }else{setListTree([])}

            }else{
                const res = await Api.Knowledge.getAllKnowledger({data:{
                    departmentId:id
                }});

                resolve();

                if(res && res.code === 0 && res.data){
                    let knowledgeData = []
                    res.data.map(item=>{
                        if(item.id != '4'){
                            knowledgeData.push({
                                subclassification:item.knowledgeTypeName,
                                id: id+"_"+item.id,
                                knowledgeTypeName:item.id,
                                selectable:false
                            })
                        }
                    })

                    setDepartmentData((originList)=>updateMangerTreeData(originList,id,knowledgeData))

                }
            }

        })
    }
    const updateMangerTreeData = (list, key, children) =>{
        let list1 = list.map((node) => {
            if (node.id === key) {
                node = {
                    ...node,
                    childList:children,
                }
              return node
            }
            if (node.childList) {
              return {
                ...node,
                childList: updateMangerTreeData(node.childList, key, children),
              };
            }
            return node;
        });
        return list1;
    }


    const goKnowledgeList = (item) => {
        history.push(`/page/knowledgeContent?type=knowledge&id=${item.knowledgeTypeId}`);
    }

    const goProjectId = (item) => {
        history.push(`/page/knowledgeContent?type=knowledge&id=2&projectId=${item.projectId}`);
    }


    const handleOk = async() => {
        if(!selectedKeys){
            message.info("选择移动知识的目标位置");
            return false;
        }
        try {
            setConfirmLoading(true);
            let res = null;
            if(type === "generalKnowledge" || type === "researchReport"){
                // 公共知识
                res = await Api.Knowledge.updatePutKnowledge({
                    data: {
                        knowledgeTypeId:moveKnowledgeTypeId,
                        pubDeptSubId:publickTreeId,
                        pubKnowledgeRelId:selectedNode.pubKnowledgeRelId,
                    },
                });
            }else{
                res = await Api.Knowledge.moveKnowledgeType({
                    data: {
                        knowledgeTypeId:moveKnowledgeTypeId ? moveKnowledgeTypeId : selectedKeys,
                        departmentSubclassificationId:moveKnowledgeTypeId? selectedKeys: "",
                        knowledgeId:selectedNode.id || selectedNode.knowledgeId,
                        departmentId: classificationType === 'Manage' ? moveDepartmentId : storage.getItem('userInfo').departmentId
                    },
                });
            }

            setConfirmLoading(false);
            if(res && res.code === 0){
                getList();
                if(getDepartmentTree){
                    getDepartmentTree()
                }
                if(getClassification){
                    getClassification();
                }
                message.success("移动成功");
                handleCancel()
            }else{}
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedKeys("");
        setMoveKnowledgeTypeId("");
        setMoveDepartmentId("");
        setPublickTreeId("");
        setSelectedKeys("");
    };

    const moveClassification = (item) =>{
        getlistTree();
        setSelectedNode(item);
        setIsModalOpen(true);
        setClassificationType("")
    }

    const moveClassificationManage = (item,type) =>{
        getAllDepartmentTree();
        setSelectedNode(item);
        setIsModalOpen(true);
        setClassificationType(type)
    }

    const getAllDepartmentTree = async() => {
        const resDepartment = await Api.Department.getDepartmentListAll({data:{
            knowledgeRel:0
        }});
        if(resDepartment&&resDepartment.code === 0){
            resDepartment.data.map(item=>{
                item.subclassification = item.name;
                item.selectable = false;
            })

            setDepartmentData(resDepartment.data);
        }
    }

    const movePublicClassification = (item) => {
        if(type === "generalKnowledge"){
            getPublicKnowledgeTypeList();
        }else if(type === "researchReport"){
            // 参考研报
            setListTree([{
                subclassification:"参考研报",
                id:"7"
            }]);
        }

        setSelectedNode(item);
        setIsModalOpen(true);
    }

    const onSelect = (selectedKeys,e) => {
        console.log(selectedKeys,e)

        if (isManage && e?.node?.parentId === undefined) {
            return
        }

        setMoveKnowledgeTypeId(e.node.knowledgeTypeId)
        setPublickTreeId(e.node.id);
        setMoveDepartmentId(e.node.departmentId);
        setSelectedKeys(selectedKeys[0]);
    }

    // 取消发布
    const unpublish = async(item) =>{

        Modal.confirm({
            title: "提示",
            icon: <ExclamationCircleOutlined />,
            content: '您确定取消发布当前知识吗？',
            onOk: ()=>{
                return new Promise(async(resolve, reject) => {

                    // try{
                    //     let res = await Api.Knowledge.deletePutKnowledge({
                    //         data: {
                    //             pubKnowledgeRelId:item.pubKnowledgeRelId,
                    //         },
                    //     });
                    //     resolve()
                    //     if(res && res.code === 0){
                    //         getList();
                    //         message.success("取消分享成功");
                    //     }
                    // }catch{
                    //     reject();
                    // }

                    if(detailsLoading){return false;}
                    setDetailsLoading(true);
                    try{
                        let res = await KnowledgeApply(item.id || item.knowledgeId,3);
                        setDetailsLoading(false);

                        if(res.bool){
                            let res = await Api.Knowledge.reviewApply({
                                data: {
                                    knowledgeId:item.id || item.knowledgeId,
                                    applyForType:3
                                },
                            });
                            if(res && res.code === 0){
                                resolve();
                                getList();
                                message.success("取消发布成功");
                            }else{
                                reject();
                            }
                        }else{
                            reject();
                        }
                    }catch{
                        setDetailsLoading(false);
                        reject();
                    }

                }).catch(() => {
                    console.log('Oops errors!');
                    reject()
                });
            },
            onCancel() {},
        });




    }

    // 取消分享
    const cancelSharing = async(item) => {
        Modal.confirm({
            title: "提示",
            icon: <ExclamationCircleOutlined />,
            content: '您确定取消分享当前知识吗？',
            onOk: ()=>{

                return new Promise(async(resolve, reject) => {

                    try{
                        let res = await Api.Knowledge.deletePutKnowledge({
                            data: {
                                pubKnowledgeRelId:item.pubKnowledgeRelId,
                            },
                        });
                        resolve()
                        if(res && res.code === 0){
                            getClassification();
                            getList();
                            message.success("取消分享成功");
                        }
                    }catch{
                        reject();
                    }

                }).catch(() => {
                    console.log('Oops errors!');
                    reject()
                });

            },
            onCancel() {},
        });
    }

    const getListItemContent = (item) => {
        let content = "";
        if(getSearchParams().inlet === "manage" || type === "researchReport" || type === "generalKnowledge" || type === "publicModal"){
            content = ""
        }else if(type==='ES'){
            if(item.knowledgeTypeId === '4'){
                // 政策运维
                content = item.isManual === 1?(
                    <div title={item.policyDigest} className="listItemContent" dangerouslySetInnerHTML={{__html:
                        item.policyDigest
                    }} />
                ):(
                    <div title={item.policyContent} className="listItemContent" dangerouslySetInnerHTML={{__html:
                        item.policyContent
                    }} />
                )
            }else{
                let html = item.knowledgeMainBody?item.knowledgeMainBody:item.note?item.note:item.knowledgeDigest
                content = (
                    <div title={html} className="listItemContent" dangerouslySetInnerHTML={{__html:html}} />
                )
            }
        }else{
            content = (
                <div className="listItemContent" title={item.knowledgeDigest}>
                    {item.knowledgeDigest}
                </div>
            )
        }

        return content
    }

    const getListTime = (item) => {
        let content = "";
        if(getSearchParams().inlet === "manage"){
            content = (<div className="releaseTime">发布于 {item.createTime}</div>)
        }

        return content
    }

    const getFooterOperation = (item) => {
        let content = "";

        if(isManage){

            let opritionButton = [], administratorPrivileges = false

            JSON.parse(sessionStorage.directory).map(item=>{
                if(item.visible === 0){
                    opritionButton.push(item)
                }

                if(item.name.includes("管理员")){
                    administratorPrivileges = true;
                }
            })

            content = (
                <Space size={"large"} className="listItemFooterOperation">
                    {/* <span onClick={()=>moveClassificationManage(item,'Manage')}><Iconfont type="icon-move"/>移动分类-管</span>  */}
                    <span onClick={()=>{
                        if(administratorPrivileges){
                            moveClassificationManage(item,'Manage')
                        }else{
                            moveClassification(item)
                        }
                    }}><Iconfont type="icon-move"/>移动分类</span>
                    <span onClick={()=>unpublish(item)}><Iconfont type="icon-quxiaolianjie"/>取消发布</span>
                </Space>
            )
        }else if(type === "generalKnowledge" || type === "researchReport"){
            content = (
                <Space size={"large"} className="listItemFooterOperation">
                    <span onClick={()=>movePublicClassification(item)}><Iconfont type="icon-move"/>移动分类</span>
                    <span onClick={()=>cancelSharing(item)}><Iconfont type="icon-quxiaolianjie"/>取消分享</span>
                </Space>
            )
        }

        return content
    }

    const getListTitle = (item) =>{
        let content = "";
        if(type==='ES'){

            if(item.knowledgeTypeId === '4'){
                // 政策运维
                content = (
                    <div className="listTitle" dangerouslySetInnerHTML={{__html:
                        item.policyTitle
                    }} />
                )
            }else{
                content = (<div className="listTitle" dangerouslySetInnerHTML={{__html: item.knowledgeTitle}} />)
            }

        }else if(type==='home' || getSearchParams().inlet === "manage"){
            content = (<div className="listTitle" title={item.knowledgeName}>{item.knowledgeName}</div>)
        }else{
            content = (<div className="listTitle" dangerouslySetInnerHTML={{__html: item.knowledgeTitle}}/>)
        }
        return content
    }

    const getProjectName = (item) =>{
        let content = "";
        // knowledgeTypeId = 2 经典案例类型才显示项目
        if(
            (type==='home'&& item.knowledgeTypeId === '2') ||
            showProject
        ){
            content = (<span className="listDetailTitle" onClick={()=>goProjectId(item)}>项目：
            <Tooltip title={item.projectName} placement="topLeft">
                <span className="jumpPointer">{item.projectName||"--"}</span>
            </Tooltip>
            </span>)
        }
        return content
    }

    const getReadNum = (item) =>{
        let content = "";
        if(
            getSearchParams().inlet != "manage"
        ){
            content = (<span>{type==='home'?item.readNum:item.knowledgeReadNum}次阅读</span>)
        }
        return content
    }

    const getAuthors = (item) =>{
        /* 6 参考资料；7 参考研报 时没有作者，只有知识来源*/
        let content = "";
        let iStyle = (item.knowledgeTypeId === '2' || item.subclassification) ? {maxWidth: 60} : {maxWidth: 200}
        let title = (item.knowledgeTypeId == '6' || item.knowledgeTypeId == '7' )?"来源":"作者";

        // 自定义作者
        let custonAuthor = []
        if(item.nameExts && item.nameExts.length > 0){
            item.nameExts.map(item => {
                custonAuthor.push(item)
            })
        }

        if(
            getSearchParams().inlet === "manage"
        ){
            let userName = item.knowledgeSource;
            if((item.knowledgeTypeId != '6' && item.knowledgeTypeId != '7' )){
                userName = [];
                if(item.authors){
                    item.authors.map(item=>{
                        userName.push(item)
                    })
                }
            }

            if(Array.isArray(userName)){
                userName = [...userName,...custonAuthor].toString()
            }

            content = (<span>{title}：
                <Tooltip title={userName} placement="topLeft">
                    <span className="authorBox" style={iStyle} title={userName}>{userName}</span>
                </Tooltip>
            </span>)
        }else if(type==='ES'){
            let author = item.knowledgeSource;
            if((item.knowledgeTypeId != '6' && item.knowledgeTypeId != '7' )){
                author = item.author
            }

            let authorsTitle = [];
            if(item.authors){
                item.authors.map(item=>{
                    authorsTitle.push(item)
                })
            }

            if(Array.isArray(authorsTitle)){
                authorsTitle = [...authorsTitle,...custonAuthor].toString()
            }

            {/* ES搜索作者时返回的author会带html结构，改为 authors 显示title*/}
            content = (<span>{title}：
                <Tooltip title={authorsTitle} placement="topLeft">
                <span className="authorBox" dangerouslySetInnerHTML={{__html: author}}></span>
                </Tooltip>
            </span>)
        }else{
            let authors = item.knowledgeSource;
            // 6参考资料 7参考研报
            if((item.knowledgeTypeId != '6' && item.knowledgeTypeId != '7' )){
                authors = (item.authors && item.authors.length>0) ? item.authors.toString(): item.authors
            }

            if(Array.isArray(authors)){
                authors = [...authors,...custonAuthor].toString()
            }

            content = (<span>{title}：
                <Tooltip title={authors} placement="topLeft">
                    <span className="authorBox" style={iStyle}>{authors}</span>
                </Tooltip>

                </span>)
        }
        return content

    }

    const getSubclassification = (item) => {
        let content = "";

        content = (
            <span>
                所属分类：
                <span className="subclassification" title={item.subclassification}>{item.subclassification}</span>
            </span>
        )

        return content
    }

    const openZipList = (item, knowledgeId) => {
        let _openZipKey = [...openZipKey];

        if(_openZipKey.includes(knowledgeId)){
            let newOpenZipKey = _openZipKey.filter((id)=>id != knowledgeId);

            setViewImgList([]);
            setOpenZipKey(newOpenZipKey)
        }else{
            let urlList = [];

            item.childList.map((childItems,index)=>{
                if(childItems.fileUrl){
                    urlList.push({
                        url:childItems.fileUrl,
                        name:childItems.knowledgeName,
                        id:index
                    })
                }
            })

            setViewImgList([...urlList]);

            _openZipKey.push(knowledgeId)
            setOpenZipKey(_openZipKey)
        }
    }

    const goZipDetails = () => {

    }

    const getChildrenList = (item) => {

        return(
            <div className={`${type === 'home' ? "childrenlistBoxH": "childrenlistBox"}`}>
                {item.childList.map((childItem,index)=>{

                    if(childItem.fileUrl){
                        childItem.id = index
                    }

                    return(
                        <div className="childrenlistItemHeader"
                            onClick={()=>{

                                if(childItem.fileUrl){
                                    setViewImgIdx(childItem.id);
                                    setIsShowViewImg(true);
                                }else if(childItem.id){
                                    goKnowledgeDetails({
                                        ...item,
                                        type,
                                        selecthValue
                                    },childItem)
                                }else{
                                    message.info("当前文件类型不支持")
                                }
                            }}
                        >
                            {/* 文件图标 */}
                            <KnowledgeFileIcon
                                fileType={childItem.fileType}
                                isPic={childItem.isPic}
                            />

                            {/* 标题 */}
                            <div className="listTitle" title={childItem.knowledgeName}>{childItem.knowledgeName}</div>
                        </div>
                    )
                })}
            </div>
        )
    }

    // 添加知识到AI购物车
    const addKnowledgeToAICart = async (knowledgeIds) => {
        const res = await addAICart(knowledgeIds);
        if(res){
            let _newListDate = [...newListDate]
            _newListDate.map(item=>{
                let knowledgeId = item.id || item.knowledgeId || item.policyId || item.elasticId;
                if(knowledgeIds.includes(knowledgeId)){
                    item.addCartStatus = 1;
                }
            })
            dispatch(refreshAIcarNumF(Date.now().toString()))
            setNewListDate(_newListDate)
        }
    }
    // AI帮我读
    const readKnowledgeByAI = (item) => {
        readByAI(item, history)
    }


    return (
        <div className="knowledgeListFrame">
            {newListDate && newListDate.length > 0 ? (
                <div className="listBox" id={"listBox"} style={autoHight && {
                    maxHeight: ((getSearchParams().inlet === "manage" ? autoHight+20 : autoHight) - 32) || 0,
                }}>
                    {newListDate.map(item => {
                        let knowledgeId = item.id || item.knowledgeId || item.policyId || item.elasticId;

                        return(<div key={item.id || item.knowledgeId || item.policyId || item.elasticId} className="listItem">
                            <div onClick={()=>{
                                if(type != "publicModal" && !showSelect){
                                    goKnowledgeDetails({
                                        ...item,
                                        type,
                                        selecthValue
                                    })
                                }
                            }}>
                                <div className="listItemHeader">

                                    {(type === "publicModal" || showSelect) && <Checkbox
                                        value={item.id}
                                        onChange={checkOnchange}
                                        checked={checkedValue.includes(item.id)}
                                        disabled={showSelect ? item.cartStatus != 0 : false }
                                        // showSelect 的时候是AI选择公共知识，
                                        // cartStatus = 0 表示AI同步完成，才可以选择
                                    ></Checkbox>}

                                    {/* 文件图标 */}
                                    <KnowledgeFileIcon
                                        fileType={item.fileType}
                                        isPic={item.isPic}
                                    />

                                    {/* 标题 */}
                                    {getListTitle(item)}

                                    {/* AI */}
                                    {(item.cartStatus === 0 && item.knowledgeTypeId != '7' && !showSelect) &&<div className="AiBtnBox">
                                        <Tooltip 
                                            title="加入购物车"
                                            getPopupContainer={() => document.getElementById(`${knowledgeId}-cart`)}
                                        >
                                            <span
                                                id={`${knowledgeId}-cart`}
                                                className={`AiBtn ${item.addCartStatus != 1 ? 'shoppingCartBtnAvailable' : 'shoppingCartBtnNotAvailable'}`}
                                                onClick={(e)=>{
                                                    e.stopPropagation();
                                                    if(item.addCartStatus != 1){
                                                        addKnowledgeToAICart([knowledgeId])
                                                    }
                                                }}
                                            >
                                                <Iconfont type="icon-waixingren" />
                                                <Iconfont type="icon-jiahao" />
                                            </span>
                                        </Tooltip>
                                        <span
                                            className={`AiReadBtn readBtnAvailable`}
                                            onClick={(e)=>{
                                                e.stopPropagation();
                                                readKnowledgeByAI(item)
                                            }}
                                        >AI帮我读</span>
                                    </div>}

                                    {/* 展开按钮 */}
                                    {item.fileType === 'zip' && item.childList &&
                                        <>{openZipKey.includes(knowledgeId)?
                                            <Tooltip  title="收起">
                                                <MinusSquareOutlined
                                                    className="openZipIcon"
                                                    onClick={(e)=>{
                                                        openZipList(item,knowledgeId);
                                                        e.stopPropagation();
                                                    }}
                                                />
                                            </Tooltip>:
                                            <Tooltip  title="查看更多文件">
                                                <PlusSquareOutlined
                                                    className="openZipIcon"
                                                    onClick={(e)=>{
                                                        openZipList(item,knowledgeId);
                                                        e.stopPropagation();
                                                    }}
                                                />
                                            </Tooltip>
                                        }</>
                                    }

                                    {/* 发布时间 */}
                                    {getListTime(item)}
                                </div>

                                {/* 摘要 */}
                                {getListItemContent(item)}

                            </div>

                            <div className="listItemFooter">
                                {/* ES搜索下的政策运维 */}
                                {
                                    (type==='ES' && item.knowledgeTypeId === '4') ? (
                                        <div className="listItemFooterItem">
                                            {/* <Space size={"large"}> */}
                                                <span>
                                                发文机构：<span className="jumpPointer">{item.policyInstitution || "--"}</span>
                                                </span>

                                                <span>
                                                发文字号：<span className="jumpPointer">{item.docNum || "--"}</span>
                                                </span>

                                                <span>发文时间：{item.publicationTime || "--"}</span>

                                            {/* </Space> */}
                                        </div>
                                    ) : (
                                        <div className="listItemFooterItem">
                                            {/* <Space size={"large"}> */}
                                                <span className="listDetailTitle" onClick={()=>goKnowledgeList(item)}>知识类型：<span className="jumpPointer">{item.knowledgeTypeName}</span></span>

                                                {/* 项目 */}
                                                {getProjectName(item)}

                                                {/* 作者 */}
                                                {getAuthors(item)}

                                                <span>创建时间：{item.createTime}</span>

                                                {/* 所属分类 */}
                                                {item.subclassification&&getSubclassification(item)}

                                                {/* 阅读次数 */}
                                                {getReadNum(item)}

                                                {(item.knowledgeTypeId === '7' && item.sourceType===1) ? <BugOutlined /> : ""}

                                            {/* </Space> */}
                                        </div>
                                    )
                                }

                                {getFooterOperation(item)}
                            </div>

                            {/* ZIP下的文件列表 */}
                            {openZipKey.includes(knowledgeId)&&item.childList&&item.childList.length>0&&<>
                                {getChildrenList(item)}
                            </>}

                        </div>)
                    })}
                </div>
             ):<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

             {pagination && (newListDate && newListDate.length > 0)?(<div className="pagination">
                 <div className="paginationFrame">
                    <Pagination
                        current={pagination.pageIndex}
                        total={pagination.total}
                        pageSize={pagination.pageSize}
                        onChange={paginationChange}
                        showSizeChanger={true}
                        showQuickJumper={true}
                        showTotal={(total) => `总计${total}条数据`}
                    />
                </div>
            </div>):""}

            <Modal
                title="移动文件"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={ConfirmLoading}
                wrapClassName={"selfDesignModal"}
                destroyOnClose={true}
            >
                <p>选择移动知识的目标位置</p>
                <div
                    style={{
                        padding: '1rem',
                        background: '#ffffff'
                    }}
                >
                    <Spin size="small" spinning={spinningType}>
                        {(type === "researchReport" || type === "generalKnowledge")?<Tree
                            onSelect={onSelect}
                            treeData={listTree}
                            selectedKeys={[selectedKeys]}
                            fieldNames={{
                                title: "subclassification",
                                key: "id",
                                children: "childList"
                            }}
                            // loadData={onLoadData}
                        />:(classificationType === 'Manage') ? <Tree
                            loadData={onLoadDataManage}
                            treeData={departmentData}
                            onSelect={onSelect}
                            selectedKeys={[selectedKeys]}
                            fieldNames={{
                                title:"subclassification",
                                key:"id",
                                children:"childList"
                            }}
                        /> :<Tree
                            onSelect={onSelect}
                            treeData={listTree}
                            selectedKeys={[selectedKeys]}
                            defaultExpandAll={true}
                            key={treeKey}
                            fieldNames={{
                                title:"subclassification",
                                key:"id",
                                children:"childList"
                            }}
                        />}
                    </Spin>
                </div>
            </Modal>

            <ViewImg
                viewImgList={viewImgList}
                isShowViewImg={isShowViewImg}
                viewImgIdx={viewImgIdx}
                closeViewImg={() => setIsShowViewImg(false)}
            />
        </div>
    )
}

export default KnowledgeList;
