import { Tabs } from 'antd';
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import UserList from './userList/index';
import RoleList from './role/index';
import DutiesList from './duties/index';
import storage from "../../../utils/storage";
import GroupNameList from './groupNameList/index';
import SpecialityList from './specialityList/index';
import BackIcon from "../../../components/BackIcon/index";

import './index.scss';

const User = () => {
    const history = useHistory();
    const items = {
        'userList':<UserList/>,
        'roleList':<RoleList/>,
        'dutiesList':<DutiesList/>,
        'groupNameList':<GroupNameList/>,
        'specialityList':<SpecialityList />
    }
    
    const [directory, setDirectory] = useState([]);
    const [activeKey, setActiveKey] = useState("");
    // [
    //     { label: '用户管理', key: '1', children: <UserList/> }, // 务必填写 key
    //     { label: '用户角色及权限管理', key: '2', children: <RoleList/> },
    //     { label: '用户职务管理', key: '3', children: <DutiesList/> },
    //   ];

    useEffect(() => {
        let directory = JSON.parse(sessionStorage.directory)
        let newDirectory = directory.map(item=>{
            return {
                label:item.name,
                key:item.id,
                children:items[item.path]
            }
        })
        setDirectory(newDirectory);

        let currntKey = sessionStorage.activeKey ? sessionStorage.activeKey : newDirectory[0].key
        setActiveKey(currntKey)
        
    }, []);

    const onChange = (activeKey) => {
        setActiveKey(activeKey)
        sessionStorage.activeKey = activeKey;
    }

    const goBack = () => {
        history.go(-1);
    }

    return (
        <div className="userFrame">
            <div  className="userTabs">
                <Tabs activeKey={activeKey} onChange={onChange} items={directory}></Tabs>
            </div>
            <BackIcon />
        </div>
    );
};
export default User;
