import React from 'react'
import { Pagination } from 'antd';
import './index.scss'

const SelfPagination = (props) => {
    const {
        showSizeChanger=true,
        showQuickJumper=true,
        showTotal=true,
        onSizeChange,
        total,
        current,
        simple,
        // pageSize = ''
    } = props;
    const showTotalText = (total) => `总计${total}条${simple ? "" : "数据"}`;

    return(
        <div className="paginationFrame">
            <Pagination
                current={current}
                total={total}
                // pageSize={pageSize}
                showSizeChanger={showSizeChanger}
                showQuickJumper={showQuickJumper}
                {...props}
                onChange={onSizeChange}
                showTotal={showTotal?showTotalText:false}
            />
        </div>
    )
};
export default SelfPagination;
