import { Form, Input, Button, Popconfirm, Space, Typography, Select, Modal, message, Checkbox } from 'antd';
import React, { useState, useEffect, useRef } from 'react';

import SelfTable from "../../../../components/SelfTable/index"

import Api from "../../../../api";
import {ModalConfirm} from '../../../../utils/tool'

import './index.scss'

const SpecialityList = () => {
    const [form] = Form.useForm();
    const [addForm] = Form.useForm();

    // 列表数据
    const [listData, setListData] = useState([]);
    const [defaultDate, setDefaultDate] = useState([]);
    // 当前正在操作的数据KEY
    const [editingKey, setEditingKey] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);

    // 列表选择数据
    const [selectedRowKey, setSelectedRowKey] = useState('');

    const [confirmLoading, setConfirmLoading] = useState(false);
    
    const [tableLoading, setTableLoading] = useState(false);

    useEffect(() => {
        getList();
    }, []);

    // 获取列表数据
    const getList = async() => {
        setTableLoading(true);
        const res = await Api.Speciality.getSpecialityList({});
        setTableLoading(false);
        if(res&&res.code === 0){
            let _data = res.data;
            setListData(_data);
            setDefaultDate(_data);
            setEditingKey('');
        }else{
            setListData([]);
            setDefaultDate([]);
        }
    }

    const isEditing = (record) => record.id === editingKey;

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        record,
        index,
        children,
        ...restProps
    }) => {
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: `请输入${title}!`
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    // 修改
    const edit = (record) => {
        form.setFieldsValue({
            ...record,
        });
        setEditingKey(record.id);
    };

    // 取消
    const cancel = () => {
        setListData([...defaultDate])
        setEditingKey('');
    };

    // 修改-保存
    const save = async (key) => {
        try {
            const row = await form.validateFields();
            row.id = key;
            setTableLoading(true);
            const res = await Api.Speciality.updateSpeciality({data:row});
            if(res.code === 0){
                message.success("修改信息成功",2,()=>{
                    getList();
                })
            }else{
                setTableLoading(false);
            }
        } catch (errInfo) {
            setTableLoading(false);
            console.log('验证失败:', errInfo);
        }
    };

    // 新增
    const handleOk = async() => {
        try {
            const row = await addForm.validateFields();
            setConfirmLoading(true);
            const res = await Api.Speciality.addSpeciality({data:row});
            setConfirmLoading(false);
            if(res&&res.code === 0){
                message.success("新增信息成功",2,()=>{
                    setIsModalOpen(false);
                    getList();
                })
            }
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    }

    // 打开新增
    const addDuties = () => {
        setIsModalOpen(true);
    }

    //删除
    const deleteDutiesModal = () => {
        if(selectedRowKey){
            ModalConfirm({
                title: `您确认要删除这条数据吗?`,
                onOk:async(resolve, reject)=> {
                    try{
                        const res = await Api.Speciality.deleteSpeciality({data:{id:selectedRowKey}});
                        resolve();
                        if(res&&res.code === 0){
                            getList();
                            message.success("删除成功")
                            setSelectedRowKey("");
                        }
                    }catch{}
                },
            })
        }else{
            message.info("请选择您需要删除的数据")
        }
    }

    // 关闭新增
    const handleCancel = () => {
        setConfirmLoading(false);
        setIsModalOpen(false);
    }

    const columns = [
        {
            title: '专业名称',
            dataIndex: 'professionName',
            width: '',
            editable: true,
        },
        {
            title: '操作',
            width: '140px',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record.id)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            保存
                        </Typography.Link>
                        <Popconfirm title="取消后数据将不会保存" onConfirm={cancel}>
                            <a>取消</a>
                        </Popconfirm>
                    </span>
                ) : (
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                            调整
                        </Typography.Link>
                    );
            },
        },
    ];

    return (
        <div className="dutiesListFrame">
            <div className="title">配置专业名称</div>
            <div className="operation">
            <Space size={"large"}>
                <Button type="primary" onClick={addDuties}>新增专业</Button>
                <Button type="primary" danger onClick={deleteDutiesModal}>删除专业</Button>
            </Space>
            </div>
            <div className="table">
                <Form form={form} component={false}>
                    <SelfTable
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        rowRadioSelection={{
                            selectedRowKey:selectedRowKey,
                            setSelectedRowKey:setSelectedRowKey,
                        }}
                        dataSource={listData}
                        columns={columns}
                        loading={tableLoading}
                        isEditing={isEditing}
                        pagination={false}
                    />
                </Form>
            </div>

            <Modal 
                title="新增专业" 
                open={isModalOpen} 
                onOk={handleOk} 
                onCancel={handleCancel}
                maskClosable={false}
                destroyOnClose={true}
                confirmLoading={confirmLoading}
            >
                <Form
                    form={addForm}
                    component={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    preserve={false}
                >
                    <Form.Item
                        key={'professionName'}
                        label={'专业名称'}
                        name={'professionName'}
                        rules={[{ required: true, message: `请输入专业名称!` }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>

        </div>
    );
};
export default SpecialityList;
