import React, {useState, useEffect} from "react";
import {
    Tabs,
    Space,
    Dropdown,
    Typography,
    Modal,
    Divider,
    Form,
    Input,
    message,
    Avatar,
    Popover,
    Tooltip,
    Badge,
} from "antd";
import {
    ShoppingCartOutlined,
    ExclamationCircleOutlined,
    HeartOutlined,
    HddFilled,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    QuestionCircleOutlined
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";

import {downloadFile, logOutFc, jumpToPage} from "../../utils/tool";
import { getData } from "../../features/editKnowledge/editKnowledgeReducer";
import storage from "../../utils/storage";
import Api from "../../api/index";

import Image from "../../components/Image/Image";
import Iconfont from "../../components/Iconfont/Iconfont";
import SelfAI from "../../components/SelfAI/index";
import InteractionMessage from "./components/InteractionMessage";
import CheckMessage from "./components/CheckMessage";
import SystemMessage from "./components/SystemMessage";
import CheckToDoMessage from "./components/CheckToDoMessage";
import OwnerApplyMessage from "./components/OwnerApplyMessage";
import KnowledgeTitle from "./components/KnowledgeTitle";
import AICart from "../../components/AICart/index";
import { setAIModalState } from "../../features/AIIntelligent/AIReducer";
import layoutTopLogo from "../../assets/img/layoutTop-logo.png";
import LogoLog from "../../assets/img/login-log.png";
import SingleLogoWhite from "../../assets/img/singleLogoWhite.png";


import "./layout.scss";

const {confirm} = Modal;

let source = null;


const LayoutTop = ({match, isShowHomeNav, showAiModalFunc}) => {
    const [addForm] = Form.useForm();
    let {history} = match;
    const dispatch = useDispatch();
    
    // AI购物车数量
    const refreshAIcarNum = useSelector(state => state.AIModal.refreshAIcarNum);
    const [AIcarNum, setAIcarNum] = useState(0)


    let sseConnectUrl = `/api/sse/createSseConnect?token=${storage.getItem("token")}`
    let sseCloseUrl = `/api/sse/closeSseConnect?token=${storage.getItem("token")}`

    let url = window.location.pathname;

    const themeList = [
        {
            key: 'newEdition',
            label: "新版",
        },
        {
            key: 'oldVersion',
            label: "旧版",
        },
    ]

    const manualList = [
        {
            key: 'simple',
            label: "简版",
        },
        {
            key: 'details',
            label: "详细版",
        },
    ]

    const [themeKey, setThemeKey] = useState("newEdition");

    const [NavRouters, setNavRouters] = useState(storage.getItem("routeRes"));
    const [itemKey, setItemKey] = useState("");
    const [topMenu, setTopMenu] = useState([]);
    const [AIIcon, setAIIcon] = useState(false);
    const [routers, setRouters] = useState({
        manage: "/page/manage",
        message: "/page/notifications",
    });
    const [managementMenu, setManagementMenu] = useState("");

    const [userInfo, setUserInfo] = useState(storage.getItem("userInfo"));

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [confirmLoading, setConfirmLoading] = useState(false);

    const [messagesNum, setMessagesNum] = useState(0);
    // message弹框
    const [messageMadelOpen, setMessageMadelOpen] = useState(false);
    const [interactionMessageList, setInteractionMessageList] = useState({});
    // const [likeList, setLikeList] = useState([]);
    const [checkMessageList, setCheckMessageList] = useState({});
    const [systemList, setSystemList] = useState({});
    const [checkToDoMessageTotal, setCheckToDoMessageTotal] = useState();

    const [tabKey, setTabKey] = useState("1");

    const [onTheDetails, setOnTheDetails] = useState(false);
    const [shoppingCartOPen, setShoppingCartOPen] = useState(false);
    

    useEffect(() => {
        if (NavRouters && NavRouters.data) {
            getRouteRes();
        }
        getMessagesNum();
        getInteractionMessageList();
        getCheckMessageList();
        getSystemList();
        getCheckToDoMessageList();

        establishingAConnection();

        if(window.location.pathname === "/page/knowledgeDetails"){
            setOnTheDetails(true);
        }
        // window.addEventListener("beforeunload", (ev) => {
        //   closeSse()
        // });

        // return()=>{
        //   closeSse()
        // }

    }, []);

    useEffect(()=>{
        console.log(refreshAIcarNum)
        getAIcarNum()
    },[refreshAIcarNum])

    // useEffect(() => {
    //     if (!isModalOpen) {
    //         addForm.resetFields();
    //     }
    // }, [isModalOpen]);

    // 订阅刷新消息
    useEffect(() => {
        document.addEventListener("notify", handleEvent);
        return () => {
            document.removeEventListener("notify", handleEvent);
        };
    }, []);

    useEffect(() => {
        // 路由监听 - 切换导航栏状态
        var arr = Object.keys(routers);
        if (arr.length > 2) {
            history.listen((path) => {
                let routerKey = "";
                
                // 是否显示导航菜单
                if(path.pathname === "/page/knowledgeDetails"){
                    setOnTheDetails(true);
                }else{
                    setOnTheDetails(false);
                }
                
                for (let key in routers) {
                    if (routers[key] === path.pathname) {
                        routerKey = key;
                    }
                }
                setItemKey(routerKey);

                closeMultModal();
            });
        }
    }, [routers]);

    // 获取AI购物车数量
    const getAIcarNum = async() => {
        try{
            const res = await Api.Chat.pageQaCart({data:{}});
            if(res.code === 0){
                setAIcarNum(res.data.total)
            }
        } catch(res){

        }
    }

    const establishingAConnection = () => {

        if ('EventSource' in window) {
            // SSE的API在EventSource对象上
            // 建立SSE连接，直接如下创建EventSource实例，支持跨越
            source = new EventSource(sseConnectUrl);

            // EventSource.readyState代表连接状态，有以下三种情况
            // 0 —> 连接还未建立，或者正在断线重连
            // 1 -> 连接已建立，可以接受数据
            // 2 -> 连接已关闭或请求错误

            // 连接创建成功的回调事件
            source.onopen = function (event) {
                console.log(event, "成功")
            };

            // 连接创建失败的回调事件
            source.onerror = function (event) {
                console.log(event, "失败")
            };

            // 接受到数据的回调事件，event未特殊设置时，默认是message
            source.onmessage = function (event) {
                console.log(event, "onmessage")

                if (event.lastEventId === "resultId") {

                    let notificationType = JSON.parse(event.data).data.notificationType;

                    console.log(notificationType)
                    updateNotification(notificationType);
                }
            };

        } else {
            console.error("当前浏览器不支持SSE")
        }
    }

    const updateNotification = (notificationType) => {
        // 1审批，2提问，3系统通知，4点赞，5收藏，6回答
        if (notificationType === 1) {
            // 我的申请
            getCheckMessageList();
        } else if (notificationType === 2 || notificationType === 4 || notificationType === 5 || notificationType === 6) {
            //互动消息
            getInteractionMessageList();
        } else if (notificationType === 3) {
            // 系统消息
            getSystemList();
        } else if (notificationType === 7) {
            // 待办
            getCheckToDoMessageList();
        }
        getMessagesNum();
    }

    // 关闭Sse连接
    const closeSse = () => {
        source.close();
        const httpRequest = new XMLHttpRequest();
        httpRequest.open('GET', sseCloseUrl, true);
        httpRequest.send();
    }

    //互动消息
    const getInteractionMessageList = async () => {
        try {
            let res = await Api.Message.getInteractionMessage({
                data: {
                    pageIndex: 1,
                    pageSize: 10,
                },
            });
            if (res.data) {
                setInteractionMessageList(res.data);
            }
        } catch (error) {
        }
    };
    // 我的申请
    const getCheckMessageList = async () => {
        try {
            let res = await Api.Message.getCheckMessage({
                data: {
                    pageIndex: 1,
                    pageSize: 10,
                },
            });
            setCheckMessageList(res.data);
        } catch (error) {
        }
    };
    // 系统消息
    const getSystemList = async () => {
        try {
            let res = await Api.Message.getSystemMessage({
                data: {
                    pageIndex: 1,
                    pageSize: 10,
                },
            });
            setSystemList(res.data);
        } catch (error) {
        }
    };
    // 获取待办条数
    const getCheckToDoMessageList = async () => {
        try {
            let res = await Api.Message.getCheckToDoMessage({
                data: {
                    pageIndex: 1,
                    pageSize: 10,
                },
            });
            if (res.data && res.code === 0) {
                setCheckToDoMessageTotal(res.data.total);
            }
        } catch (error) {
        }
    };
    // 我的申请

    const handleEvent = (e) => {
        getMessagesNum();
        getInteractionMessageList();
        getCheckMessageList();
        getSystemList();
        getCheckToDoMessageList();
    };

    const getMessagesNum = async () => {
        let res = await Api.Notice.getStatusNum({});
        if (res && res.code === 0) {
            setMessagesNum(res.data);
        }
    };

    const getRouteRes = () => {
        let _routers = {...routers},
            _topMenu = [],
            navMenu = [];
        
        NavRouters.data.map((item) => {
            if (item.type == 0 && !item.icon) {
                navMenu = [...item.children];
            } else if (item.type == 0 && item.icon) {
                setManagementMenu(item);
            }
        });

        navMenu.map((item) => {
            // 0：目录 1：菜单 2：按钮
            if (item.type === 1 && item.visible != 1) {
                _routers[item.id] = item.path;
                if (item.path === url) {
                    setItemKey(item.id);
                }
                _topMenu.push({
                    key: item.id,
                    label: item.name,
                    children: ``,
                    path: item.path,
                    disabled: !item.path,
                });
            } else if (item.type === 2 && item.visible != 1) {
                setAIIcon(item);
            }
        });
        setTopMenu(_topMenu);
        setRouters(_routers);
    };

    const onChange = (item) => {
        const routerItem = routers[item];
        if (routerItem === "/answer/chat") {
            return showAiModalFunc();
        } else {
            setItemKey(item);
        }
        if (routerItem) {
            if (routers[item].indexOf("http") != -1) {
                window.open(routers[item])
            } else {
                sessionStorage.removeItem("activeKey");
                history.push(routers[item]);
            }
        } else {
            message.info("暂未开放");
        }
    };

    const handleOpenChange = (newOpen) => {
        setMessageMadelOpen(newOpen);
    };
    // 跳转到消息通知页面
    const hide = () => {
        // setInteractionMessageList({ total: 0, records: [] });
        // setCheckMessageList({ total: 0, records: [] });
        // setSystemList({ total: 0, records: [] });
        // setMessagesNum(checkToDoMessageTotal);

        setMessageMadelOpen(false);
        sessionStorage.activeKey = tabKey
        history.push(`/page/notifications`, {});
        closeMultModal();
    };
    const handeleTabKey = (key) => {
        setTabKey(key)
        if (key == "1") {
            getInteractionMessageList();
        } else if (key == "4") {
            getCheckMessageList();
        } else if (key == "5") {
            getSystemList();
        }
        getMessagesNum();
    };
    // 去审批
    const toDoMessage = (checkId,flowType) => {
        setMessageMadelOpen(false);

        sessionStorage.activeKey = tabKey
        history.push(`/page/notifications`, {checkId,"flowType":flowType});
        
        // history.push("/page/manage/knowledgeReview", { checkId });
    };

    // 查看知识详情
    const toKnowledgeDetail = (item) => {
        const knowledgeId = item.knowledgeId, noticeId = item.noticeId;

        if (noticeId) {
            handeleCheckMessage(noticeId);
        }
        setMessageMadelOpen(false);

        // 待审批进入个人中心的详情查看页
        // actualState -1已被删除 0已拒绝，1已通过，2待审批，3草稿文章    ------ 该知识当前实际状态
        // 当前知识处于草稿箱，待审批，或是已被拒绝的状态，查看进入个人中心查看
        if (item.applyStatus === 2 || item.actualState === 3 || item.actualState === 2 || item.actualState === 0) {
            let encodedObject = encodeURIComponent(knowledgeId);
            let url = "";
            if (item.fileType === "online") {
                url = `/page/onLineWrite?knowledgeId=${encodedObject}&type=view`;
            } else {
                url = `/page/newKnowledge?knowledgeId=${encodedObject}&type=view`;
            }
            window.open(url);
        } else {
            jumpToPage({...item, id: undefined, type: "",})
        }

    };

    // 将此条消息已读
    const handeleCheckMessage = async (noticeId) => {
        try {
            let res = await Api.Message.changeNotificationIsReadById({
                data: {notificationId: noticeId},
            });
            if (res.data) {
                handleEvent();
            }
        } catch (error) {
        }
    };

    // 编辑
    const toKnowledgeEdit = (knowledgeFileType, knowledgeId, noticeId) => {
        if (noticeId) {
            handeleCheckMessage(noticeId);
        }
        setMessageMadelOpen(false);
        let encodedObject = encodeURIComponent(knowledgeId);
        let url;
        if (knowledgeFileType === "online") {
            url = `/page/onLineWrite?knowledgeId=${encodedObject}`;
        } else {
            url = `/page/newKnowledge?knowledgeId=${encodedObject}`;
        }
        window.open(url);
    };

    // 下载知识
    const downloadKnowledge = async (item) => {
        let fileType = [
            'docx',
            'docm',
            'doc',
            'xlsx',
            'xls',
            'xlsm',
        ]
        if (fileType.includes(item.fileType)) {
            // word excel 不能按分页下载 只能下载全部
            try {
                let res = await Api.Knowledge.onDownloadKnowledge({
                    data: {knowledgeId: item.knowledgeId},
                });
                if (res) {
                    downloadFile({fileName: item.knowledgeName, path: res.data.path});
                }
            } catch (error) {
            }
        } else {
            // 申请已通过，弹出下载选择弹窗
            jumpToPage({...item, id: undefined, type: "",}, "downloadFile");
        }
        handeleCheckMessage(item.noticeId);
        setMessageMadelOpen(false);
    };

    const checkStatus = async (item, type) => {
        let res = await Api.Message.checkMessageStatus({
            data: {
                id: item.knowledgeId,
                applyId: item.applyId
            },
        });

        if (res.code === 0) {
            let _code = res.data;
            if (_code === -1) {
                message.info("当前知识已被删除");
            } else {
                // 区分当前在那个类型的数据下面
                // 1 互动消息 ； 4 我的申请 ； 6 待办消息
                if (tabKey == 1) {
                    onClickKnowledgeName(_code, item, type);
                } else if (tabKey == 4) {
                    viewKnowledge(_code, item, type);
                } else if (tabKey == 6) {
                    auditorToKnowledgeDetails(_code, item, type);
                }
            }
        }
    }

    // 点击知识名称进入知识详情
    // 只有发布成功的知识才能点击进入详情
    const viewKnowledge = async (_code, item, type) => {
        if (_code === 1) {
            // 进入详情
            goKnowleageDetailesPublished(item);
        } else if (item.applyForType === 1 || item.applyForType === 2 || item.applyForType === 3) {
            // 申请下载 、 发布 、 取消发布 -- 可以进入个人中心的详情页
            goKnowleageDetailesUnPublished(item);
        } else {
            message.info("当前知识已取消发布");
        }
    }

    // 互动消息点击名称的时候 - 只有发布成功的才能进入详情并且打开讨论
    // 取消发布的数据不能进入详情
    const onClickKnowledgeName = async (_code, item, type) => {
        if (_code === 1) {
            // 进入详情
            goKnowleageDetailesPublished(item, type);
        } else {
            message.info("当前知识已取消发布");
            // goKnowleageDetailesUnPublished(item);
        }
    }

    const auditorToKnowledgeDetails = async (_code, item, type) => {
        if (_code != 1 && _code != 2) {
            // 文件待审批中的时候审核人员可以查看
            message.info("当前知识已取消发布");
        } else {
            // 不知道当前这个人有没有权限做笔记和摘录
            // 统一不能操作，进入个人中心的查看
            goKnowleageDetailesUnPublished(item)
        }
    }

    const goKnowleageDetailesUnPublished = async (item) => {
        let knowledgeId = item.knowledgeId;
        let url = "";
        if (item.fileType === "online") {
            url = `/page/onLineWrite?knowledgeId=${knowledgeId}&type=view`;
        } else {
            url = `/page/newKnowledge?knowledgeId=${knowledgeId}&type=view`;
        }
        window.open(url);
    }

    const goKnowleageDetailesPublished = async (item, type) => {
        // 进入详情
        jumpToPage({...item, id: undefined, type: ""}, type === "comment" ? "comment" : undefined);
    }


    // 消息列表显示
    const tabItems = [
        {
            label: (
                <>
                    <Badge count={interactionMessageList?.total} size="small">
                        <HeartOutlined style={{marginRight: "0.2rem"}}/>
                    </Badge>
                    互动消息
                </>
            ),
            key: 1,
            children: (
                <InteractionMessage
                    interactionMessageList={interactionMessageList?.records}
                    toKnowledgeDetail={toKnowledgeDetail}
                    checkStatus={checkStatus}
                ></InteractionMessage>
            ),
        },
        {
            label: (
                <>
                    <Badge count={checkMessageList?.total} size="small">
                        <Iconfont type={"icon-fabu"} iStyle={{marginRight: "0.2rem"}}/>
                    </Badge>
                    我的申请
                </>
            ),
            key: 4,
            children: (
                <CheckMessage
                    checkMessageList={checkMessageList?.records}
                    toKnowledgeDetail={toKnowledgeDetail}
                    toKnowledgeEdit={toKnowledgeEdit}
                    downloadKnowledge={downloadKnowledge}
                    checkStatus={checkStatus}
                    setMessageMadelOpen={setMessageMadelOpen}
                ></CheckMessage>
            ),
        },
        {
            label: (
                <>
                    <Badge count={systemList?.total} size="small">
                        <Iconfont type={"icon-xitong"} iStyle={{marginRight: "0.2rem"}}/>
                    </Badge>
                    系统消息
                </>
            ),
            key: 5,
            children: (
                <SystemMessage systemList={systemList?.records}></SystemMessage>
            ),
        },
        {
            label: (
                <>
                    <Badge count={checkToDoMessageTotal} size="small" offset={[-3, 0]}>
                        <HddFilled style={{marginRight: "0.2rem"}}/>
                    </Badge>
                    待办消息
                </>
            ),
            key: 6,
            children: (
                <CheckToDoMessage
                    toDoMessage={toDoMessage}
                    setCheckToDoMessageTotal={setCheckToDoMessageTotal}
                    toKnowledgeEdit={toKnowledgeEdit}
                    setMessageMadelOpen={setMessageMadelOpen}
                    checkStatus={checkStatus}
                ></CheckToDoMessage>
            ),
        },
        // {
        //   label: (
        //     <>
        //       <HddFilled style={{ marginRight: "0.2rem" }} />
        //       我的申请
        //     </>
        //   ),
        //   key: 5,
        //   children: (
        //     <OwnerApplyMessage
        //       toKnowledgeDetail={toKnowledgeDetail}
        //       toKnowledgeEdit={toKnowledgeEdit}
        //     ></OwnerApplyMessage>
        //   ),
        // },
    ];
    const content = (
        <div className="messageMadel">
            <Tabs defaultActiveKey="1" destroyInactiveTabPane onChange={handeleTabKey} items={tabItems}/>
            <div className="madelFoot">
        <span className="cursorSpan" onClick={hide}>
          查看所有消息
        </span>
            </div>
        </div>
    );

    const logOut = () => {
        confirm({
            title: `确定注销并退出系统吗？`,
            icon: <ExclamationCircleOutlined/>,
            content: "",
            okText: "确认",
            okType: "danger",
            cancelText: "取消",
            onOk: async () => {
                let res = await Api.Login.loginOutFunc({});
                if (res && res.code === 0) {
                    logOutFc();
                }
            },
            onCancel() {
            },
        });
    };

    const changePassword = () => {
        setIsModalOpen(true);
    };

    const myHome = () => {
        // let { history } = match;
        closeMultModal();
        history.push("/personalCenter");
    };

    // 新增
    const handleOk = async () => {
        try {
            const row = await addForm.validateFields();
            setConfirmLoading(true);
            const res = await Api.Login.setPassword({data: row});
            if (res && res.code === 0) {
                message.success("修改成功", 2, () => {
                    logOutFc();
                });
            } else {
                setConfirmLoading(false);
            }
        } catch (errInfo) {
            console.log("验证失败:", errInfo);
        }
    };

    const handleCancel = () => {
        addForm.resetFields();
        setIsModalOpen(false);
        setConfirmLoading(false);
    };

    const items = [
        {
            key: "0",
            label: (
                <Typography.Link onClick={myHome}>
                    <Space>我的主页</Space>
                </Typography.Link>
            ),
        },
        {
            key: "1",
            label: (
                <Typography.Link onClick={changePassword}>
                    <Space>修改密码</Space>
                </Typography.Link>
            ),
        },
        (
            !storage.getItem("token") || 
            (
                storage.getItem("token") && 
                storage.getItem("token").length < 50 && 
                storage.getItem("token").indexOf('serviceplatform-') === -1
            )
        ) &&
        {
            key: "2",
            label: (
                <>
                    <Divider className="minDivider"/>
                    <Typography.Link onClick={logOut}>
                        退出登录
                    </Typography.Link>
                </>
            ),
        }
    ];

    const openUserManual = () => {
        window.open('about:blank').location.href = "/知识库V3.0使用手册/知识库V3.0使用手册.html";
    }

    const backHomePage = () => {
        closeMultModal()
        history.push("/page/homePage")
    }

    // 关闭多文档弹窗
    const closeMultModal = () => {
        dispatch(setAIModalState({
            multiOpen:false,
            multiKnowledgeList:[],// 多文档数组
            multiKnowledgeIds:[], // 多文档ID
            multiCurrent:[], // 当前对话要问的文档
            multiChatId:null, //多文档的问答记录ID
        }))
    }

    const isMobile = () => {
        var userAgentInfo = navigator.userAgent;
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(userAgentInfo);
    }

    const themeChange = ({ key }) => {
        storage.setItem("theme",key);
        setThemeKey(key);
        document.dispatchEvent(new CustomEvent("theme"));
    }

    const manualChange = ({ key }) => {
        if(key == 'simple'){
            window.open('about:blank').location.href = "/知识库产品使用指南专题培训V3版20240611.pdf";
        }else{
            window.open('about:blank').location.href = "/知识库V3.0使用手册/知识库V3.0使用手册.html";
        }
    }


    return (
        <div>
            {!onTheDetails ? <div onClick={backHomePage}>
                <Image
                    imgSrc={
                        url.includes("knowledgeCentre") &&
                        isShowHomeNav
                            ? layoutTopLogo
                            : LogoLog
                    }
                    iClassName="layoutTopLogo"
                    text={location.pathname.includes("personalCenter") ? "我的主页" : ""}
                />
            </div>:<KnowledgeTitle backHomePage={backHomePage}/>}
            
            {!isMobile() && <div className="layoutTopRight">
                
                {!onTheDetails && <div className="layoutTopNav">
                    <div className="navTabs">
                        {topMenu.map(item=>{
                            return <div 
                                key={item.key}
                                className={`navTabsItem ${itemKey === item.key ? 'navTabsItemSelected' : ''} ${item.disabled ? 'navTabsItemDisabled' : 'navTabsItemDefalut'}`} 
                                onClick={()=>{
                                    if(!item.disabled){
                                        onChange(item.key)
                                    }
                                }}
                            >
                                {item.label}
                            </div>
                        })}
                    </div>
                </div>}

                
                <div className="layoutTopIcon">
                    {/* 使用手册 */}
                    <Dropdown
                        arrow
                        menu={{
                            items: manualList,
                            selectable: true,
                            onClick: manualChange,
                        }}
                        trigger={['click']}
                        getPopupContainer={()=>document.getElementById("manualBox")}
                        placement="bottomLeft"
                    >
                        <Tooltip 
                            title="赛迪咨询知识库使用手册"
                            placement="left"
                            getPopupContainer={()=>document.getElementById("manualBox")}
                        >
                            <div className={"manualBox"} id="manualBox">
                                使用说明
                            </div>
                        </Tooltip>
                    </Dropdown>

                    {/* AI问答购物车 */}
                    <Tooltip 
                        title="AI问答购物车"
                        placement="left"
                        getPopupContainer={()=>document.getElementById("shoppingCart")}
                    >
                        <div className={"shoppingCart"} id="shoppingCart" onClick={()=>{setShoppingCartOPen(true)}}>
                            <ShoppingCartOutlined />({AIcarNum})
                        </div>
                    </Tooltip>
                    
                    {/* 主题 */}
                    <Dropdown
                        arrow
                        menu={{
                            items: themeList,
                            selectable: true,
                            onClick: themeChange,
                            defaultSelectedKeys: storage.getItem("theme") ? [storage.getItem("theme")] : ['newEdition'],
                        }}
                        trigger={['click']}
                        getPopupContainer={()=>document.getElementById("themeBox")}
                        placement="bottomLeft"
                        overlayClassName="themePopover"
                    >
                        <Tooltip 
                            title="切换主题"
                            placement="left"
                            getPopupContainer={()=>document.getElementById("themeBox")}
                        >
                            <div className={"themeBox"} id="themeBox">
                                <Iconfont
                                    type={"icon-zhuti2"}
                                />
                                
                            </div>
                        </Tooltip>
                    </Dropdown>
                   
                    {/* 后台管理 */}
                    {managementMenu ? (
                        <Iconfont
                            type={"icon-houtaizonglan"}
                            onClick={() => onChange("manage")}
                        />
                    ) : (
                        ""
                    )}
                    {/* 消息中心 */}
                    <Popover
                        overlayClassName="messagePopover"
                        placement="bottomRight"
                        content={content}
                        trigger="click"
                        open={messageMadelOpen}
                        onOpenChange={handleOpenChange}
                        getPopupContainer={()=>document.getElementById("messageBox")}
                    >
                        <div className={"message"} id="messageBox">
                            <Iconfont type={"icon-line-041"}/>
                            {messagesNum != 0 ? (
                                <i className={"messageTips"}>{messagesNum > 99 ? '99+' : messagesNum}</i>
                            ) : (
                                ""
                            )}
                        </div>
                    </Popover>
                    {/* 个人中心 */}
                    <Dropdown
                        arrow
                        menu={{
                            items,
                        }}
                        placement="bottomRight"
                        trigger={["click"]}
                        getPopupContainer={()=>document.getElementById("headIconBox")}
                        overlayClassName={"dropDownClass"}
                    >
                        <div className={"headIcon"} id="headIconBox">
                            {userInfo && userInfo.userName && userInfo.userName.slice(0, 1)}
                        </div>
                    </Dropdown>
                </div>
            </div>}


            <Modal
                title="修改密码"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                maskClosable={false}
                destroyOnClose={true}
                confirmLoading={confirmLoading}
                className="changePasswordModal"
                wrapClassName={"selfDesignModal"}
            >
                <Form
                    form={addForm}
                    component={false}
                    labelCol={{span: 6}}
                    wrapperCol={{span: 14}}
                    preserve={false}
                >
                    <Form.Item
                        name="newPWD"
                        label="新密码"
                        rules={[
                            {
                                required: true,
                                message: "请输入新密码!",
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    let number = /[0-9]/;
                                    var letter = /[a-z]/i;
                                    var symbol = /[_]/i;
                                    // var chinese = new RegExp("[\\u4E00-\\u9FFF]+","g"); //中文

                                    if (!/^\w+$/.test(value)) {
                                        return Promise.reject(
                                            new Error("密码只能包含字母、数字、下划线")
                                        );
                                    }

                                    let lgh = 0;
                                    if (symbol.test(value)) {
                                        lgh = lgh + 1;
                                    }
                                    if (letter.test(value)) {
                                        lgh = lgh + 1;
                                    }
                                    if (number.test(value)) {
                                        lgh = lgh + 1;
                                    }

                                    if (value.length < 6 || value.length > 18 || lgh < 2) {
                                        return Promise.reject(
                                            new Error(
                                                "密码长度必须6-18之间，至少包含字母、数字、下划线2种"
                                            )
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                        hasFeedback
                    >
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item
                        name="newPWD2"
                        label="确认密码"
                        dependencies={["newPWD"]}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "请输入密码!",
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue("newPWD") === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error("两次密码输入不一致!"));
                                },
                            }),
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>
                </Form>
                <div className="tipsText">提示：修改密码只影响本系统，不会造成门户登录密码的变更</div>
            </Modal>
            {shoppingCartOPen&&<AICart 
                open={shoppingCartOPen} 
                cancelModal={() => setShoppingCartOPen(false)}
            />}
            
        </div>
    );
};

export default LayoutTop;
