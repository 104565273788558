import { createSlice, configureStore } from "@reduxjs/toolkit";

const defaultState = {
  // 多文档问答
  multiOpen: false, // 多文档问答详情页
  multiKnowledgeList:[],// 多文档数组
  multiKnowledgeIds:[], // 多文档ID
  multiCurrent:[], // 当前对话要问的文档
  multiChatId:null, //多文档的问答记录ID
  multiPage:0,

  // AI问答框参数
  modalOpen: false, // AI问答弹窗
  sendAPI:null, // 传入时间戳，根据时间戳的变化发送请求
  questionModule: 'ragChat', // 问题类型 “检索ragRetrieve，问答ragChat 文档总结summary”
  questionLocation: null, // 0 带文档的问答， 1 知识中心 ， 2 ES搜索界面， 3查讯全部
  knowledgeIds:[], // 需要提问的知识id - questionLocation = 0的时候
  knowledgeEsParam:{}, // questionLocation = 2的时候, 在当前ES搜素结果中查讯
  selectKnowledgeParam:{}, // uestionLocation = 1的时候, 知识中心参数
  refLoc: false, // 是否开启来源定位，默认为False
  relQuestions:0, //推荐问题的数量
  queryText:"", // 提问的内容
  
  // AI购物车
  refreshAIcarNum: false, //更新AI购物车数量显示
  publicKnowledge:{ // AI购物车
    publicCheckedList:{}, //选中加入购物车的公共知识
    publicPageIndex:1
  },

  // AI问答助手
  historyTopicId:null, // 历史对话记录ID
  historyTitle:null, // 历史对话记录标题
  refreshHistoryTopicList: false, //刷新历史对话记录列表

  // AI从本地上传文件解析
  uploadModalType:'AICart', // AICart购物车，文档阅读助手 - createCv新建，upDateCv修改
};

const AIReducer = createSlice({
  name: "AI",
  initialState: {
    value: 0,
    
    // 多文档问答
    multiOpen: false, // 多文档问答详情页
    multiKnowledgeList:[],// 多文档数组
    multiKnowledgeIds:[], // 多文档ID
    multiCurrent:[], // 当前对话要问的文档
    multiChatId:null, //多文档的问答记录ID

    // AI问答框参数
    modalOpen: false, // AI问答弹窗
    sendAPI:null, // 传入时间戳，根据时间戳的变化发送请求
    questionModule: 'ragChat', // 问题类型 “检索ragRetrieve，问答ragChat 文档总结summary”
    questionLocation: null, // 0 带文档的问答， 1 知识中心 ， 2 ES搜索界面， 3查讯全部
    knowledgeIds:[], // 需要提问的知识id - questionLocation = 0的时候
    knowledgeEsParam:{}, // questionLocation = 2的时候, 在当前ES搜素结果中查讯
    selectKnowledgeParam:{}, // uestionLocation = 1的时候, 知识中心参数
    knowledgePublishedParam:{}, // 个人中心分享成功的参数
    refLoc: false, // 是否开启来源定位，默认为False
    relQuestions:0, //推荐问题的数量
    queryText:"", // 提问的内容
    
    // AI购物车
    refreshAIcarNum: false, //传入时间戳，更新AI购物车数量显示
    publicKnowledge:{ // AI购物车
      publicCheckedList:{}, //选中加入购物车的公共知识
      publicPageIndex:1
    },

    // AI问答助手
    historyTopicId:null, // 历史对话记录ID
    historyTitle:null, // 历史对话记录标题
  },
  reducers: {
    setAIModalState: (state, action) => {
      for (let key in action.payload) {
        state[key] = action.payload[key];
      }
    },
    getAIModalState: (state, action) => {
      state.downloadModalStatus = action.payload;
    },
    // 通知更新购物车数量
    refreshAIcarNumF: (state, action) => {
      state.refreshAIcarNum = action.payload;
    },
    refreshPublicKnowledge: (state, action) => {
      state.publicKnowledge = action.payload;
    },
    getData: (state, action) => {
      // Redux Toolkit 允许在 reducers 中编写 "mutating" 逻辑。
      // 它实际上并没有改变 state，因为使用的是 Immer 库，检测到“草稿 state”的变化并产生一个全新的
      // 基于这些更改的不可变的 state。
      console.log(state);
      return state;
    },
    // 当关闭AI问答弹窗的时候 重置数据
    resetAiData:(state, action)=>{
      return defaultState;
    }
  },
});

export const { setAIModalState, getAIModalState, refreshAIcarNumF, refreshPublicKnowledge, getData, resetAiData } = AIReducer.actions;

export default AIReducer.reducer;