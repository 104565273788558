import React, { useState, useEffect } from 'react';
import { Col, Row, DatePicker, Timeline, Empty, Spin } from 'antd';
import { LikeOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import moment from 'moment'

import Api from "../../../api";
import SelfPagination from "../../../components/SelfPagination";
import {KnowledgeApply, gethalfMonthDate, jumpToPage} from '../../../utils/tool'

import './index.scss';

const { RangePicker } = DatePicker;

const Like = () => {
    const history = useHistory();
    // 查询数据
    const [searchData, setSearchData] = useState({
        "pageIndex": 1,
        "pageSize": 10,
        startTime: gethalfMonthDate()[0],
        endTime: gethalfMonthDate()[1],
        "type":4
    });

    const [dataList, setDataList] = useState([]);

    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });

    const [spinningSate, setSpinningSate] = useState(false);
    const [detailsLoading, setDetailsLoading] = useState(false);

    useEffect(() => {
        getList();
    }, [searchData]);

    const getList = async() => {
        setSpinningSate(true);

        try{
            const res = await Api.Notice.getPageList({data:searchData});
            setSpinningSate(false);
            if(res && res.code ===0 && res.data){
                setDataList(res.data.records);
                setPagination({
                    "pageIndex": res.data.current,
                    "pageSize": res.data.size,
                    "total":res.data.total,
                })
            }else{
                setDataList([]);
            }
            // 更新未读消息数量
            document.dispatchEvent(new CustomEvent('notify'));
        } catch (errInfo) {
            setDataList([]);
            setSpinningSate(false);
            console.log('验证失败:', errInfo);
        }
    }

    const onSizeChange = (page, pageSize) => {
        let _searchData = {...searchData};
        _searchData.pageIndex = page,
        _searchData.pageSize = pageSize,
        setSearchData(_searchData)
    }

    const goKnowleage =(item)=>{
        history.push(`/page/knowledgeContent?type=knowledge&id=${item.knowledgeTypeId}`);
    }

    const goKnowleageDetailes =async(item)=>{
        if(detailsLoading){return false;}
        setDetailsLoading(true);
        try{
            let res = await KnowledgeApply(item.id || item.knowledgeId,4);
            setDetailsLoading(false);
            if(res.bool){
                jumpToPage({...item, type: ""})
            }
        }catch{setDetailsLoading(false);}
    }

    const dateChange = (date, dateString) => {
        let newSearchData = { ...searchData };
        newSearchData.startTime = dateString[0];
        newSearchData.endTime = dateString[1];
        setSearchData(newSearchData);
    };

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };

    return (
        <div className="likeFrame">
            <div className="operation">
                收到的赞同
                <RangePicker
                    disabledDate={disabledDate}
                    value={[
                        searchData.startTime?moment(searchData.startTime):"",
                        searchData.endTime?moment(searchData.endTime):""
                    ]}
                    onChange={dateChange}
                    style={{marginLeft:20}}
                />
            </div>
            <div className="content">
                <Spin spinning={spinningSate}>
                    {dataList&&dataList.length>0 ? dataList.map(item=>{
                        return <div className="listItem" key={item.date}>
                            <p className="date">{item.date}</p>
                            <Timeline>
                                {
                                    item.notificationVoList.map(items=>{
                                        return(
                                        <Timeline.Item key={items.noticeId}>
                                            <div>
                                                <Row>
                                                    <Col span={2}>{items.time}</Col>
                                                    <Col span={8}>{items.promoteName}赞了&nbsp;&nbsp;
                                                        <span className='colorBlue' onClick={()=>{goKnowleage(items)}}>{items.knowledgeTypeName}</span>中的文件
                                                        <span className='colorBlue' onClick={()=>{goKnowleageDetailes(items)}}>《{items.knowledgeMessageVo.knowledgeName}》</span>
                                                    </Col>
                                                    <Col span={4}><LikeOutlined /></Col>
                                                    <Col span={10}></Col>
                                                </Row>
                                            </div>
                                        </Timeline.Item>
                                        )
                                    })
                                }
                            </Timeline>
                        </div>
                    }):<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </Spin>
            </div>
            {dataList&&dataList.length>0?(
            <div className="footer">
                <SelfPagination
                    total={pagination.total}
                    current={pagination.pageIndex}
                    onSizeChange={onSizeChange}
                />
            </div>
            ):""}
        </div>
    );
}

export default Like;
