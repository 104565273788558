import { Form, Input, Button, Popconfirm, Space, Typography, Select, Modal, message, DatePicker, Checkbox } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons'
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment'

import Api from "../../../../api";
import SelfTable from "../../../../components/SelfTable/index"
import {userStatus, pagingParam} from "../../../../utils/defaultValue";

import './index.scss'
const { confirm } = Modal;

const UserList = () => {
    const [form] = Form.useForm();
    const [addForm] = Form.useForm();
    // 所属部门
    const [department, setDepartment] = useState([]);
    // 职务
    const [duties, setDuties] = useState([]);
    // 用户角色
    const [role, setRole] = useState([]);
    // 系统角色
    const [sysRole, setSysRole] = useState([]);
    // 列表数据
    const [listData, setListData] = useState([]);
    const [defaultDate, setDefaultDate] = useState([]);
    // 当前正在操作的数据
    const [editingData, setEditingData] = useState();

    const [searchValue, setSearchValue] = useState('');
    // 查询数据
    const [searchData, setSearchData] = useState({
        ...pagingParam,
        "jobOrName":"",
    });
    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [entryTime, setEntryTime] = useState('');

    const [addDate, setAddDate] = useState([
        {
            title:"工号",
            dataIndex:"jobNum",
            type:""
        },
        {
            title:"姓名",
            dataIndex:"userName",
            type:""
        },
        {
            title:"所属部门",
            dataIndex:"departmentId",
            type:"select"
        },
        {
            title:"次分配部门",
            dataIndex:"deptSeIds",
            type:"checkbox"
        },
        {
            title:"职务",
            dataIndex:"jobId",
            type:"select"
        },
        {
            title:"业务角色",
            dataIndex:"roleId",
            type:"select"
        },
        // {
        //     title:"分组",
        //     dataIndex:"groupId",
        //     type:"select"
        // },
        // {
        //     title:"专业",
        //     dataIndex:"professionId",
        //     type:"select"
        // },
        {
            title:"入职时间",
            dataIndex:"entryTime",
            type:"time"
        },
        {
            title:"邮箱",
            dataIndex:"email",
            type:""
        },
        {
            title:"手机号",
            dataIndex:"phone",
            type:""
        },
        {
            title:"状态",
            dataIndex:"status",
            type:"select"
        }
    ])

    const [confirmLoading, setConfirmLoading] = useState(false);

    const [tableLoading, setTableLoading] = useState(false);

    const [groupList, setGroupList] = useState([]);

    const [professionList, setProfessionList] = useState([]);

    useEffect(() => {
        setInitDate();
    }, []);

    useEffect(() => {
        getList();
    }, [searchData]);

    const setInitDate = async() => {
        const resRole = await Api.Roles.getRoles({});
        if(resRole && resRole.code === 0){
            let newData = resRole.data.map(item=>{
                return{
                    value: item.id,
                    label: item.name,
                    text: item.name,
                }
            });
            setRole(newData);
        }
        //  flag = 1 需要查询班子成员
        const resDepartment = await Api.Department.getDepartmentListAll({data:{
            flag:1
        }});
        if(resDepartment && resDepartment.code === 0){
            let newData = resDepartment.data.map(item=>{
                return{
                    value: item.id,
                    label: item.name,
                    text: item.name,
                }
            });
            setDepartment(newData);
        }
        const resJob = await Api.Duties.getDutiesList({});
        if(resJob && resJob.code === 0){
            let newData = resJob.data.map(item=>{
                return{
                    value: item.id,
                    label: item.name,
                    text: item.name,
                }
            });
            setDuties(newData);
        }

        const resSpeciality = await Api.Speciality.getSpecialityList({});
        if(resSpeciality && resSpeciality.code === 0){
            let newData = resSpeciality.data.map(item=>{
                return{
                    value: item.id,
                    label: item.professionName,
                    text: item.professionName,
                }
            });
            setProfessionList(newData);
        }

        const resGroup = await Api.Group.getGroupList({});
        if(resGroup && resGroup.code === 0){
            let newData = resGroup.data.map(item=>{
                return{
                    value: item.id,
                    label: item.groupName,
                    text: item.groupName,
                }
            });
            setGroupList(newData);
        }

        // const resSysRole = await Api.Roles.getAllSysRolesList({});
        // if(resSysRole && resSysRole.code === 0){
        //     let newData = resSysRole.data.map(item=>{
        //         return{
        //             value: item.id,
        //             label: item.name,
        //         }
        //     });
        //     setSysRole(newData);
        // }
    }

    // 获取列表数据
    const getList = async() => {
        setTableLoading(true);
        const res = await Api.User.getUserBusinessList({data:searchData});
        setTableLoading(false);
        if(res&&res.code === 0){
            let _data = res.data
            let _records = _data.records.map(item=>{
                if(item.entryTime){
                    item.entryTime = moment(item.entryTime,'YYYY-MM-DD');
                }
                return item
            });

            setListData(_records);
            setDefaultDate(_records);
            setPagination({
                "pageIndex": _data.current,
                "pageSize": _data.size,
                "total":_data.total
            });
            setEditingData('');
        }else{
            setListData([]);
            setDefaultDate([]);
        }
    }

    
    // 职务下拉框选中
    const listChange = (value,dataIndex,type) =>{
        if(dataIndex === "jobId"){
            getRoleId(value,type)
        }
    }

    // 根据职务获取角色
    const getRoleId = async(id,type) => {
        const res = await Api.Roles.selectByJobId({data:{jobId:id}});
        if(res&&res.code === 0&&res.data){
            if(type){
                addForm.setFieldValue('roleId',res.data.id);
                addForm.validateFields(["roleId"]);
            }else{
                form.setFieldValue('roleId',res.data.id);
                form.validateFields(["roleId"]);
            }
        }
    }

    const isEditing = (record) => record.id === editingData.id;

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        changeType,
        record,
        index,
        children,
        ...restProps
    }) => {
        // 下拉选择框数据
        let options = [];
        if(changeType === 'select'){
            options = dataIndex === 'status' ? userStatus :
            dataIndex === 'jobId' ? duties :
            dataIndex === 'departmentId' ?  [...department,{
                value: '',
                label: '无',
            }]:
            dataIndex === 'roleId' ? role : 
            dataIndex === 'groupId' ? groupList : 
            dataIndex === 'professionId' ? professionList : [];
        }else if(changeType === 'checkbox'){
            options = dataIndex === 'deptSeIds' ? department : [];
        }
        
        const inputNode = changeType === 'select' ? <Select 
            options={options}
            onSelect={(value) => listChange(value,dataIndex)}
        /> :changeType === 'checkbox' ? <Checkbox.Group
            options={options}
            disabled={editing ? false:true}
            defaultValue={record.deptSeIds}
        /> 
        : changeType === 'time' ? <DatePicker onChange={datePickerChange} style={{width:'100%'}}/>:<Input />;

        // 次分配部门名称
        const secDepartmentName = [];
        if(changeType === 'checkbox' && dataIndex === 'deptSeIds' && record.deptSeIds.length > 0){
            department.map(item=>{
                if(record.deptSeIds.includes(item.value)){
                    secDepartmentName.push(item.label)
                }
            })
        }

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        key={dataIndex}
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: (dataIndex === 'departmentId' || changeType === "checkbox") ? false : true,
                                message: (changeType === 'select'||changeType === 'time') ? `请选择${title}!`: `请输入${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ): (
                    <>{(changeType === "checkbox" && dataIndex === 'deptSeIds') ? secDepartmentName.join('，') : children}</>
                )}
            </td>
        );
    };

    // 修改
    const edit = (record) => {
        form.setFieldsValue({
            ...record,
        });
        setEditingData(record);
    };

    const resetPassword = (record) => {
        try {
            confirm({
                title: "重置密码",
                icon: <ExclamationCircleOutlined />,
                content: "确认重置"+record.userName+"的密码吗？",
                onOk: ()=>{
                    return new Promise(async(resolve, reject) => {
                        const res = await Api.User.resetUserPassword({data:{
                            id:record.id
                        }});
                        resolve();
                        if(res && res.code === 0){
                            message.success("重置成功");
                        }
                    }).catch(() => {
                        console.log('Oops errors!');
                        reject()
                    });
                },
                onCancel() {},
            });
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    // 取消
    const cancel = () => {
        setListData([...defaultDate])
        setEditingData('');
        setEntryTime('');
    };

    const datePickerChange = (date, dateString) => {
        setEntryTime(dateString);
    }

    // 保存
    const save = async (key) => {
        try {
            const row = await form.validateFields();
            setTableLoading(true);
            row.id = key;
            row.entryTime = entryTime;
            const res = await Api.User.updateUser({data:row});
            if(res && res.code === 0){
                message.success("修改用户信息成功",2,()=>{
                    getList();
                    cancel();
                })
            }else{
                setTableLoading(false);
            }
            
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
            setTableLoading(false);
        }
    };

    // 新增
    const handleOk = async() => {
        try {
            const row = await addForm.validateFields();
            setConfirmLoading(true);
            const res = await Api.User.addUser({data:row});
            res.entryTime = moment(row.entryTime).format('YYYY-MM-DD')
            setConfirmLoading(false);
            if(res && res.code === 0){
                message.success("新增用户信息成功",2,()=>{
                    setIsModalOpen(false);
                    getList();
                })
            }
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    }

    // 新增用户
    const addUser = () => {
        setIsModalOpen(true);
    }
    const handleCancel = () => {
        setConfirmLoading(false);
        setIsModalOpen(false);
    }

    // 搜索框
    const changeValue = (e) => {
        setSearchValue(e.target.value)
    }
    const onSearch = () => {
        let newSearchData = {...searchData}
        newSearchData.pageIndex = 1;
        newSearchData.jobOrName = searchValue;
        setSearchData(newSearchData);
    }

    const columns = [
        {
            title: '工号',
            dataIndex: 'jobNum',
            width: '100px',
            editable: true,
        },
        {
            title: '姓名',
            dataIndex: 'userName',
            width: '100px',
            editable: true,
        },
        {
            title: '所属部门',
            dataIndex: 'departmentId',
            width: '',
            editable: true,
            filters: [...department.filter((val, i) => {
                return val.value !== ''
            })],
            filterMultiple:false,
            filteredValue:searchData.departmentId ? [searchData.departmentId] : [],
            render:(_, record)=>{
                return record.departmentName
            },
            changeType:"select"
        },
        {
            title: '次分配部门',
            dataIndex: 'deptSeIds',
            width: '300px',
            editable: true,
            changeType:"checkbox",
            align:"left",
            className:"table-align-center"
        },
        {
            title: '职务',
            dataIndex: 'jobId',
            width: '',
            editable: true,
            filters: [...duties],
            filterMultiple:false,
            filteredValue:searchData.jobId ? [searchData.jobId] : [],
            render:(_, record)=>{
                return record.jobName
            },
            changeType:"select"
        },
        {
            title: '业务角色',
            dataIndex: 'roleId',
            width: '',
            editable: true,
            filters: [...role],
            filterMultiple:false,
            filteredValue:searchData.roleId ? [searchData.roleId] : [],
            render:(_, record)=>{
                if(record.roleName){
                    return record.roleName
                }else if(record.status === 4){
                    return '游客'
                }else{
                    return ''
                }
            },
            changeType:"select" 
        },
        // {
        //     title: '分组',
        //     dataIndex: 'groupId',
        //     width: '',
        //     editable: true,
        //     filters: [...groupList],
        //     filterMultiple:false,
        //     filteredValue:searchData.groupId ? [searchData.groupId] : [],
        //     render:(text, record, index)=>{
        //         return record.groupName
        //     },
        //     changeType:"select" 
        // },
        // {
        //     title: '专业',
        //     dataIndex: 'professionId',
        //     width: '',
        //     editable: true,
        //     filters: [...professionList],
        //     filterMultiple:false,
        //     filteredValue:searchData.professionId ? [searchData.professionId] : [],
        //     render:(text, record, index)=>{
        //         return record.professionName
        //     },
        //     changeType:"select" 
        // },
        {
            title: '入职时间',
            dataIndex: 'entryTime',
            width: '',
            editable: true,
            render:(text, record, index)=>{
                return record.entryTime?moment(record.entryTime).format('YYYY-MM-DD'):""
            },
            changeType:"time" 
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            width: '200px',
            editable: true,
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            width: '160px',
            editable: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: '100px',
            editable: true,
            filters: [...userStatus],
            filterMultiple:false,
            filteredValue:(searchData.status || searchData.status == 0) ? [searchData.status] : [],
            render:(text, record, index)=>{
                // 状态 0正常 1暂停 2注销 3删除
                return text === 0 ? '正常' : 
                text === 1 ? '暂停' :
                text === 2 ? '注销' :
                text === 3 ? '删除':
                text === 4 ? '待启用':"";
            },
            changeType:"select"
        },
        {
            title: '操作',
            width: '130px',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record.id)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            保存
                        </Typography.Link>
                        <Popconfirm title="取消后数据将不会保存" onConfirm={cancel}>
                            <a>取消</a>
                        </Popconfirm>
                    </span>
                ) : (
                        <Space>
                            <Typography.Link disabled={editingData !== ''} onClick={() => edit(record)}>
                                调整
                            </Typography.Link>
                            <Typography.Link disabled={editingData !== ''} onClick={() => resetPassword(record)}>
                                重置密码
                            </Typography.Link>
                        </Space>
                    );
            },
        },
    ];

    const tableChanges = (pagination, filters) => {
        let newSearchData = {...searchData}
        newSearchData.pageIndex = pagination.current;
        newSearchData.pageSize = pagination.pageSize;
        for (let key in filters) {
            newSearchData[key] = filters[key] ? filters[key][0] : ''
        }
        setSearchData(newSearchData);
    }

    const onResetting = () => {
        setSearchValue("");
        setSearchData({
            ...pagingParam,
            "jobOrName":"",
        })
    }

    return (
        <div className="userListFrame">
            <div className="title">用户管理</div>
            <div className="operation">
                <Space size={"large"}>
                    <div className="operationItem">
                        <Input
                            placeholder="输入用户姓名、工号进行搜索"
                            onChange={changeValue}
                            onPressEnter={onSearch}
                            style={{width:350,float:"right"}}
                            value={searchValue}
                            // allowClear
                            // suffix={
                            //     <SearchOutlined onClick={onSearch} />
                            // }
                        />
                    </div>
                    <Button type="primary" onClick={onSearch}>搜索</Button>
                    <Button onClick={onResetting}>重置</Button>
                </Space>
                <div className="operationRight">
                    <Button type="primary" onClick={addUser}>新增用户</Button>
                </div>
            </div>
            <div className="table">
                <Form form={form} component={false}>
                    <SelfTable
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        dataSource={listData}
                        columns={columns}
                        pagination={pagination}
                        loading={tableLoading}
                        onChange={tableChanges} 
                        isEditing={isEditing}
                        scroll={
                            {
                                x:"2000px"
                            }
                        }
                    />
                </Form>
            </div>

            <Modal 
                title="新增用户" 
                open={isModalOpen} 
                onOk={handleOk} 
                onCancel={handleCancel}
                maskClosable={false}
                destroyOnClose={true}
                confirmLoading={confirmLoading}
                // forceRender
            >
                <Form
                    form={addForm}
                    component={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    preserve={false}
                >
                    {addDate.map(item=>{
                        return(
                            <Form.Item
                                key={item.dataIndex}
                                label={item.title}
                                name={item.dataIndex}
                                rules={[
                                    // { 
                                    //     required: item.dataIndex === 'departmentId' ? false : true,
                                    //     message: item.type === 'select' ? `请选择${item.title}!`:`请输入${item.title}!` 
                                    // },
                                    () => (item.dataIndex === 'jobNum'?{
                                        required: true,
                                        validator(_, value) {
                                          if(value && /^[\da-z]+$/i.test(value)){
                                            return Promise.resolve();
                                          }
                                          if(value){
                                            return Promise.reject(new Error("工号只能包含字母、数字"));
                                          }else{
                                            return Promise.reject(new Error("请输入工号"));
                                          }
                                        },
                                    }:item.dataIndex === 'phone'?{
                                        required: true,
                                        validator(_, value) {
                                          let number = /[0-9]/;
                                          if(number.test(value) && value.length === 11){
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(new Error("请输入11位数字手机号码"));
                                        },
                                    }:item.dataIndex === 'email'?{
                                        required: true,
                                        validator(_, value) {
                                            let emailReg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/
                                          if(emailReg.test(value)){
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(new Error("请输入正确的邮箱格式"));
                                        },
                                    }:{
                                        required: (
                                            item.dataIndex === 'departmentId' ||
                                            item.dataIndex === 'deptSeIds'
                                        ) ? false : true,
                                        validator(_, value) {
                                          if(value || value == 0 || item.dataIndex === 'departmentId'|| item.dataIndex === 'deptSeIds'){
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(new Error((item.type === 'select' || item.type === 'time') ? `请选择${item.title}!`:`请输入${item.title}!`));
                                        },
                                    }),
                                ]}
                            >
                                {(()=>{
                                    if(item.type === 'select'){
                                        return <Select 
                                            options={item.dataIndex === 'status' ? userStatus :
                                            item.dataIndex === 'jobId' ? duties :
                                            item.dataIndex === 'departmentId' ?  [...department,{
                                                value: '',
                                                label: '无',
                                            }]:
                                            item.dataIndex === 'roleId' ? role :
                                            item.dataIndex === 'groupId' ? groupList : 
                                            item.dataIndex === 'professionId' ? professionList : []
                                        }
                                            onSelect={(value) => listChange(value,item.dataIndex,'add')}
                                        />
                                    }else if(item.type === 'time'){
                                        return <DatePicker style={{width:'100%'}}/>
                                    }else if(item.dataIndex === 'deptSeIds'){
                                        return <Checkbox.Group options={[...department]} />
                                    }
                                    else{
                                        return <Input />
                                    }
                                })()}
                            </Form.Item>
                        )
                    })}
                </Form>
            </Modal>

        </div>
    );
};
export default UserList;
