import React, { useState, useEffect} from 'react';
import {Spin, Empty} from 'antd';
import { SyncOutlined, VerticalAlignTopOutlined } from '@ant-design/icons';
import {useHistory} from "react-router-dom";

import Api from '../../../../../api'
import storage from "../../../../../utils/storage";
import './index.scss';

const HotSpot = () => {
    const [title] = useState();
    let history = useHistory();
    
    // 查询数据
    const [hotWordsSearchData, setHotWordsSearchData] = useState({
        "pageIndex": 1,
        "pageSize": 5,
    });

    const [hotLoadingState, setHotLoadingState] = useState(false);

     // 分页
     const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "pages":0
    });
    const [hotWordsList, setHotWordsList] = useState([]);

    useEffect(()=>{
        getHotWordsList()
    },[hotWordsSearchData])

    // 搜素热词
    const getHotWordsList = async() => {
        setHotLoadingState(true);
        try{
            let res = await Api.Home.getHotWords({data:{
                ...hotWordsSearchData,
                hotFlag:1
            }});
            setHotLoadingState(false);
            if(res && res.code === 0 && res.data && res.data.records){
                let _data = res.data;
                _data.records.map((item,index)=>{

                    if(_data.current > 1){
                        item.serialNumber = ((_data.current-1)*5)+index;
                    }else{
                        item.serialNumber = _data.current*index;
                    }
                })
                setHotWordsList(_data.records);
                setPagination({
                    pageIndex: _data.current,
                    pageSize: _data.size,
                    pages:_data.pages,
                })
            }else{
                setHotWordsList([]);
            }
        }catch{setHotLoadingState(false);}
    }

    const exchangeHotList = () => {
        // 热词
        let newHotWordsSearchData = {...hotWordsSearchData}
        if(newHotWordsSearchData.pageIndex >= pagination.pages){
            newHotWordsSearchData.pageIndex = 1
        }else{
            newHotWordsSearchData.pageIndex = newHotWordsSearchData.pageIndex+1
        }
        setHotWordsSearchData(newHotWordsSearchData)
    }

    const goSearchHotWords = (items) => {
        storage.setItem('searchData', JSON.stringify({
            text:items.hotWord,
            searchType:'1'
        }));
        history.push('/page/knowledgeSearch');
    }

    return (
        <div className="hotSpotFrame">
            <div className="titleBox">
                <span>大家都在搜</span>
                <div className="exchange" onClick={exchangeHotList}>
                    <SyncOutlined />换一换
                </div>
            </div>
            <Spin tip="数据加载中" spinning={hotLoadingState}>
                {hotWordsList && hotWordsList.length > 0 ?hotWordsList.map((items,index)=>{
                    return(<div key={items.id} className="hotWordsListItem"
                        onClick={()=>goSearchHotWords(items)}
                    >
                        {items.serialNumber===0?<VerticalAlignTopOutlined />:
                        <span
                            className="index"
                            style={
                                items.serialNumber===1?{color:'#FF4721'}:
                                items.serialNumber===2?{color:'#F0851A'}:
                                items.serialNumber===3?{color:'#DDBD36'}:
                                {color:'#999999'}
                            }
                        >{items.serialNumber}</span>}

                        <span className="hotWord" title={items.hotWord}>{items.hotWord}</span>

                        {items.iconFlag===1&&<span className="hotLabel">热</span>}
                    </div>)
                }):<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </Spin>
        </div>
    )
}

export default HotSpot;
