import { Tabs } from 'antd';
import React, { useState, useEffect } from 'react';

import AnalyzeList from './analyzeList/index';
import DocumentMode from './documentMode/index';
import CueWord from './cueWord/index';
import SensitiveWords from './sensitiveWords/index';

import storage from "../../../utils/storage";
import BackIcon from "../../../components/BackIcon/index";

import './index.scss';

const AIModel = () => {

    const [tableHight, setTableHight] = useState(0);

    const items = {
        'analyzeList':<AnalyzeList/>,
        'documentMode':<DocumentMode/>,
        'cueWord':<CueWord/>,
        'sensitiveWords':<SensitiveWords/>
    }

    const [directory, setDirectory] = useState([]);
    const [activeKey, setActiveKey] = useState("");

    useEffect(() => {
        let directory = JSON.parse(sessionStorage.directory)
        console.log(directory)
        let newDirectory = directory.map(item=>{
            return {
                label:item.name,
                key:item.id,
                children:items[item.path]
            }
        })
        setDirectory(newDirectory);
        let currntKey = sessionStorage.activeKey ? sessionStorage.activeKey : newDirectory[0].key;
        setActiveKey(currntKey);
    }, []);

    const onChange = (activeKey) => {
        setActiveKey(activeKey)
        sessionStorage.activeKey = activeKey;
    }

    return (
        <div className="AIModelFrame">
            <div  className="AIModelTabs">
                <Tabs activeKey={activeKey} onChange={onChange} items={directory}></Tabs>
            </div>
            <BackIcon />
        </div>
    );
};
export default AIModel;
