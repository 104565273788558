import myAxios from "../utils/request.js";
import callback from "../utils/callback.js";

export default {
  // 下载文件
  async downLoadFile(params = {}, cb) {
    let res = await myAxios.getJson("/file/singleCheck", params);
    return res.data;
  },

  // 文件上传
  async uploadFile(params = {}, cb) {
    let res = await myAxios.axiosFunc({
      url: "/sysFile/upload",
      method: "post",
      headers: {
        //可加可不加
        "Content-Type": "multipart/form-data;",
      },
      ...params,
    });
    return res.data;
  },

  // 文件分片上传
  async fragmentUpload(params = {}, cb) {
    let res = await myAxios.axiosFunc({
      url: "/sysFile/fragmentUpload",
      method: "post",
      headers: {
        //可加可不加
        "Content-Type": "multipart/form-data;",
      },
      ...params,
    });
    return res.data;
  },

  // 根据fileMd5Id查询文件是否存在 true存在 false不存在
  async checkFileExist(params = {}, cb) {
    let res = await myAxios.axiosFunc({
      url: "/sysFile/checkFileExist",
      method: "post",
      headers: {
        //可加可不加
        "Content-Type": "multipart/form-data;",
      },
      ...params,
    });
    return res.data;
  },

  // 跟据分片数据的MD5查询 已上传成功但还未合并的分片
  async checkFragment(params = {}, cb) {
    let res = await myAxios.postJson("/sysFile/checkFragment", params);
    return res.data;
  },

  // 根据单个分片数据的MD5查询
  async checkFragmentOne (params = {}, cb) {
    let res = await myAxios.postJson("/sysFile/checkFragmentOne", params);
    return res.data;
  },

  // 文件分片上传 --- 不新增知识
  async fragmentUploadCommon(params = {}, cb) {
    let res = await myAxios.axiosFunc({
      url: "/sysFile/fragmentUploadCommon",
      method: "post",
      headers: {
        //可加可不加
        "Content-Type": "multipart/form-data;",
      },
      ...params,
    });
    return res.data;
  },
  // 根据fileMd5Id查询文件是否存在 true存在 false不存在 --- 不新增知识
  async checkFileExistCommon(params = {}, cb) {
    let res = await myAxios.axiosFunc({
      url: "/sysFile/checkFileExistCommon",
      method: "post",
      headers: {
        //可加可不加
        "Content-Type": "multipart/form-data;",
      },
      ...params,
    });
    return res.data;
  },

  // 获取地区
  async getRegions (params={}, cb) {
    let res = await myAxios.getJson('/PolicyEsCenterController/getAllPlace', params);
    return res.data;
  },
  // 获取国务院/部委
  async getDepartments (params={}, cb) {
    let res = await myAxios.getJson('/countryDept/getCountryDeptByType', params);
    return res.data;
  },

  // 获取所有部门、用户数据-tree
  async getAllDepUser (params={}, cb) {
    let res = await myAxios.getJson('/sysDepartment/getAllDepUser', params);
    return res.data;
  },

  // 轻推跳转根据checkId获取 审核流程详情（不包含后续未进行的流程信息）
  async getWorkflowDetailByCheckIdForQT (params={}, cb) {
    let res = await myAxios.getJson('/QingtuiController/getWorkflowDetailByCheckIdForQT', params);
    return res.data;
  },
  // 轻推跳转根据checkId获取 审核流程详情（不包含后续未进行的流程信息）  流程平台
  async getWorkflowDetailByCheckIdForQTPlatform (params={}, cb) {
    let res = await myAxios.getJson('/checkWf/getWorkflowDetailByCheckIdForQT', params);
    return res.data;
  },
  

  // 同意/驳回 -- 流程平台
  async updateSkipForQT (params={}, cb) {
    let res = await myAxios.postJson('/applyWf/skipForQT', params);
    return res.data;
  },
  async updateApplyTypeForQT (params={}, cb) {
    let res = await myAxios.postJson('/QingtuiController/updateApplyTypeForQT', params);
    return res.data;
  },

  // 轻推查询我的待办消息
  async getCheckToDoMessageForQT (params={}, cb) {
    let res = await myAxios.getJson('/QingtuiController/getCheckToDoMessageForQT', params);
    return res.data;
  },

   // 研报数据
    // 查询行业分类
  async getIndustry (params={}, cb) {
    let res = await myAxios.getJson('/researchReport/getIndustry', params);
    return res.data;
  },

  // 查询发布机构
  async getIssuer (params={}, cb) {
    let res = await myAxios.getJson('/researchReport/getIssuer', params);
    return res.data;
  }
};
