import { createSlice, configureStore } from "@reduxjs/toolkit";

const editKnowledgeReducer = createSlice({
  name: "knowledge",
  initialState: {
    value: 0,
    editType:"", //updateFile 更新，share 分享
    knowledgeList: [],
    knowledgeDetail: {},
    downloadModalStatus: false,
    refreshKnowledgeList: false,
    fileTotalPages: 0,
    fileCurrentPage:1
  },
  reducers: {
    setKnowledgeDetailData: (state, action) => {
      state.knowledgeDetail = action.payload;
    },
    setDownloadModalStatus: (state, action) => {
      state.downloadModalStatus = action.payload;
    },
    setKnowledgeState: (state, action) => {
      for (let key in action.payload) {
        state[key] = action.payload[key];
      }
      console.log(state);
    },
    getData: (state, action) => {
      // Redux Toolkit 允许在 reducers 中编写 "mutating" 逻辑。
      // 它实际上并没有改变 state，因为使用的是 Immer 库，检测到“草稿 state”的变化并产生一个全新的
      // 基于这些更改的不可变的 state。
      console.log(state.knowledgeList);
      return state.knowledgeList;
    },
  },
});

export const { setKnowledgeDetailData, setDownloadModalStatus, setKnowledgeState, getData } = editKnowledgeReducer.actions;

// export const selectCount = (state) => {
//   return state.knowledgeList;
// };

export default editKnowledgeReducer.reducer;