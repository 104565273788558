import React, {
  useEffect,
  useImperativeHandle,
  forwardRef,
  useState,
} from "react";
let editor = null;
const UEditor = (props, ref) => {
  useEffect(() => {
    setConfig(
      props.initData,
      props.config,
      props.setContent,
      props.editorReady
    );
  }, [props.initData, props.config, props.setContent]);
  // useEffect(() => {
  //   return () => {
  //   editor.destroy();
  //   // editor.removeListener(); //不要打开，否则返回有问题报错
  //   }
  //   }, []);
  // 初始化编辑器
  const { setScrollTop, getScrollTopAndText } = props;
  const setConfig = (initData, config, setContent, editorReady) => {
    editor =
      window.UE &&
      window.UE.getEditor("editor", {
        elementPathEnabled: false, // 取消左下角元素路径
        autoFloatEnabled: false,
        initialFrameWidth: "100%", // 初始宽度
        zIndex: 1,
        maximumWords: 999999999999, // 限制最多可输入字符
        wordCountMsg: "当前已输入{#count}个字符", // 右下角输入字数提示
        wordCount: true, // 开启统计输入字数功能
        ...config,
      });
    // 初始化UEditor
    editor.ready(() => {
      if (editorReady) {
        editorReady(editor);
      }
      // if (initData) {
      //   editor.setContent(initData); //设置默认值/表单回显
      // }
    });
    editor.addListener("contentChange", function () {
      if (setContent) {
        setContent(editor.getContent(), editor.getPlainTxt());
      }
    });
    editor.addListener("selectionChange", function () {
      // 获取百度编辑器的工具类
      var domUtils = window.UE.dom.domUtils;
      // 获取光标的坐标，创建一个临时dom
      var bkStart = editor.selection.getRange().createBookmark().start;
      // dom隐藏
      bkStart.style.display = "";
      // 计算x坐标
      var x = domUtils.getXY(bkStart).x;
      // 计算y坐标
      var y = bkStart.offsetHeight + domUtils.getXY(bkStart).y - 20;
      // 获取文本
      var text = editor.selection.getText();
      // 移除临时DOM
      bkStart.parentNode.removeChild(bkStart);
      // 在这里使用x和y坐标进行后续处理

      if (getScrollTopAndText) {
        getScrollTopAndText(text, { x, y });
      }
      if (setScrollTop) {
        setScrollTop({ x, y });
      }
    });
  };
  useImperativeHandle(ref, () => ({
    getUEContent: () => {
      return editor.getContent(); //获取编辑器内容
    },
  }));
  return <script id="editor" type="text/plain" />;
};

export default forwardRef(UEditor);
