import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import "moment/locale/zh-cn";
import Fingerprint2 from 'fingerprintjs2'
import { useHistory } from "react-router-dom";

import store from "./store/store.js"
import { Provider } from "react-redux";

// import Vconsole from 'vconsole'

import routesConfig from "./routesConfig.js";
import reportWebVitals from "./reportWebVitals";
import Api from "./api/index";
import LayoutBox from "./pages/layout/layout";
import PersonalCenterLayoutBox from "./pages/personalCenter/layout/layout";
import storage from "./utils/storage";
import {getSearchParams, getLoginUrl} from './utils/tool'

import "./assets/css/common.scss";
import "./index.scss";
import "./assets/css/iconfont.css";
import './components/SelfDesignModal/index.scss';

const Root = routesConfig.root.Root;
const base_url = routesConfig.root.baseUrl || "/";
const RouterDom = () => {

  const history = useHistory();
  const [type,setType] = useState(false);
  const [pagePath,setPagePath] = useState({});
  const [personalCenterPath,setPersonalCenterPath] = useState({});

  const setRouteList = async(routes, baseUrl) => {
    let pathList = [], routeList = [];
    let childrenList = []
    routes.map((item) => {
      let dom = null;
      const path = baseUrl + item.path;
      pathList.push(path);
      const Component = item.component;
      if (Component) {
        dom = (
          <Route
            exact={item.exact}
            path={path}
            key={item.name}
            component={(props) => (<Component {...props} />)}
          />
        );
      } else {
        dom = (
          <Route
            exact={item.exact}
            path={path}
            key={item.name}
            render={item.render}
          >
          </Route>
        );
      }
      routeList.push(dom);
      if(item.children && item.children.length > 0){
        childrenList=[...childrenList,...item.children]
      }
    });

    if(childrenList.length > 0){

      let List = await setRouteList(childrenList, baseUrl);

      return {routeList:[...routeList,...List.routeList],pathList:[...pathList,...List.pathList]}

    }else{
      return {routeList, pathList}
    }

  }

  const routeFunc = async (routes, baseUrl) => {

    let list = await setRouteList(routes, baseUrl)

    return { routeList:list.routeList,pathList:list.pathList };
  };

  useEffect(()=>{
    console.log(window.location.pathname)
     // if(window.location.pathname === '/examine' || window.location.pathname === '/examineList'){
      setType(true);
    // }else{
    //   getFingerprint();
    // }

    getPathList();

  },[])

  const getFingerprint = async() => {

    try{
      // 获取指纹
      let fingerprint = Fingerprint2.get(function(components) {
        console.log(components)
        const values = components.map(function(component,index) {
          if(component.key != "plugins"){
            return component.value
          }
        })
        // 生成最终id murmur
        const murmur = Fingerprint2.x64hash128(values.join(''), 31);

        // 最终的浏览器指纹
        let _fingerprint = window.btoa(murmur+window.navigator.userAgent);

        // console.log(murmur,"获取指纹")

        localStorage.fingerprint = _fingerprint

        checkLoginStatus(_fingerprint)

      });

    }catch{
      setType(true);
    }
  }

  const checkLoginStatus = async(fingerprint) => {
    // 传入fingerprint判断是否登录
    try{
      let res = await Api.Login.getLoginInfo({ data: {
        satoken:fingerprint
      },noVerify: true });
      setLoginInfo(res);
      setType(true);
    }catch{
      setType(true);
    }
  }

  const setLoginInfo = async(res) => {
    const { data, code } = res || {};
    if (code === 0) {
      const { userName, phone, roleId, email, id, departmentName, departmentId ,fileUrl, jobNum,} = data || {};
      const userInfo = {
        userName,
        phone,
        roleId,
        email,
        id,
        departmentName,
        departmentId,
        fileUrl,
        jobNum,
      };
      storage.setItem("token", data.saTokenInfo.tokenValue);
      storage.setItem("WebSocketSubProtocol", data.fileToken);
      storage.setItem("userInfo", userInfo);
      const routeRes = await Api.Login.getlistMenuTree({
        data: { satoken: data.saTokenInfo.tokenValue },
      });
      storage.setItem("routeRes", routeRes);
      // history.replace("/page/homePage");
    }
  }


  const getPathList = async()=>{
    const pagePath = await routeFunc(
      routesConfig.root.routes,
      base_url
    );
    setPagePath(pagePath);

    const personalCenterPath = await routeFunc(
      routesConfig.root.personalRoutes,
      ""
    );
    setPersonalCenterPath(personalCenterPath);
  }


  return (
    <>{type?(
      <ConfigProvider locale={zhCN}>
      <Router>
        <Switch>
          <Route exact path="/login" component={routesConfig.Login} />
          <Route exact path="/examine" component={routesConfig.Examine} />
          <Route exact path="/examineList" component={routesConfig.ExamineList} />
          <Route exact path="/text2" component={routesConfig.Text2} />
          <Route exact path="/error" component={routesConfig.ErrorPage} />
          <Route
            path="/personalCenter"
            render={(match) => (
              <LayoutBox
                match={{ ...match }}
                pathList={personalCenterPath.pathList}
              >
                <PersonalCenterLayoutBox
                  match={{ ...match }}
                  pathList={personalCenterPath.pathList}
                >
                  {personalCenterPath.routeList}
                </PersonalCenterLayoutBox>
              </LayoutBox>
            )}
          />

          <Route
            path="/page"
            render={(match) => (
              <LayoutBox
                match={{ ...match }}
                pathList={pagePath.pathList}
                updateRoute={(route) => getRouteList(route)}
              >
                {pagePath.routeList}
              </LayoutBox>
            )}
          />
          <Route path="/404" component={routesConfig.NotFound} />
          <Redirect exact from="/" to={"/login"} />
          <Redirect from="/*" to={"/404"} />
        </Switch>
      </Router>
    </ConfigProvider>
    ):""}</>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <RouterDom />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
