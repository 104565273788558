import { configureStore } from "@reduxjs/toolkit";

import editKnowledgeReducer from "../features/editKnowledge/editKnowledgeReducer";
import AIReducer from "../features/AIIntelligent/AIReducer";

const store = configureStore({
  reducer: {
    editKnowledge: editKnowledgeReducer,
    AIModal:AIReducer
  },
});

export default store;
