import React,{useState, useEffect} from 'react';
import { Table, DatePicker, Space, Button } from 'antd';
import moment from 'moment'

import Api from "../../../api";
import {operationType,pagingParam} from "../../../utils/defaultValue";
import BackIcon from "../../../components/BackIcon/index";
import SelfTable from "../../../components/SelfTable/index"

import './index.scss'

const { RangePicker } = DatePicker;

const translate = {
    999: '全部',
    0: '登录访问',
    1: '查看文件',
    2: '上传文件',
    3: '在线写作',
    4: '下载文件',
    5: '添加笔记',
    6: '分享笔记',
}

const Log = () => {
    const [tableHight, setTableHight] = useState(0);
    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });

    // 查询数据
    const [searchData, setSearchData] = useState({
        ...pagingParam,
        "startTime": "",
        "endTime": "",
        "operationType": '',
        "userId": ''
    });

    const [userList, setUserList] = useState([]);

    const [tableLoading, setTableLoading] = useState(false);

    const [listData, setListData] = useState([]);

    const [operationData, setOperationData] = useState({});

    const columns = [
        // {
        //     title: '序号',
        //     dataIndex: 'index',
        //     width: '70px',
        //     editable: false,
        //     render:(text, record, index)=>{
        //         return index+1
        //     }
        // },
        {
            title: '日期',
            dataIndex: 'operationTime',
            width: '200px',
            editable: false,
            render:(text)=>{
                return text ? text.split(" ")[0] :""
            }
        },
        {
            title: '用户名称',
            dataIndex: 'userId',
            filters: [...userList],
            filterMultiple:false,
            filteredValue:searchData.userId ? [searchData.userId] : [],
            width: '200px',
            render:(text, record)=>{
                return record.userName
            }
        },
        {
            title: '工号',
            dataIndex: 'jobNum',
            width: '120px',
            editable: false,
        },
        {
            title: '用户角色',
            dataIndex: 'roleName',
            width: '150px',
            editable: false,
        },
        {
            title: '操作类型',
            dataIndex: 'operationType',
            filters: [...operationType],
            filterMultiple:false,
            filteredValue:searchData.operationType ? [searchData.operationType] : [],
            width: '200px',
            render:(text, record)=>{
                return translate[text === '' ? 999: text]
            }
        },
        {
            title: '操作时间',
            dataIndex: 'operationTime',
            width: '200px',
            editable: false,
            render:(text)=>{
                return text ? text.split(" ")[1] :""
            }
        },
        {
            title: '操作对象',
            dataIndex: 'name',
            width: '200px',
            editable: false
        },
        {
            title: '时长(分钟)',
            dataIndex: 'operationDuration',
            width: '',
            editable: false,
        },
      ];

    useEffect(() => {
        setInitDate();
        let divHeight= document.querySelector('.layoutContent');
        setTableHight(divHeight.offsetHeight-55-118-80);
    }, []);

    useEffect(()=>{
        getList()
    },[searchData])

    const getList = async() => {
        setTableLoading(true);
        const res = await Api.User.getLogPage({data:searchData});
        setTableLoading(false);
        if(res&&res.code === 0){
            let _data = res.data;
            setListData(_data.records);
            setPagination({
                "pageIndex": _data.current,
                "pageSize": _data.size,
                "total":_data.total
            });
        }else{
            setListData([]);
        }
    }

    const setInitDate = async(startTime,endTime) => {
        const res = await Api.User.getUserByTime({data:{
            "startTime": startTime,
            "endTime": endTime
        }});
        if(res&&res.code === 0&& res.data){
            let _data = res.data;
            let userList = _data.map(item=>{
                return {
                    value: item.id,
                    text: item.user_name,
                }
            })
            setUserList(userList)
        }else{
            setUserList([])
        }
    }

    const onChanges = (pagination, filters) => {
        let newSearchData = {...searchData}
        newSearchData.pageIndex = pagination.current;
        newSearchData.pageSize = pagination.pageSize;
        newSearchData.operationType = filters.operationType ? filters.operationType[0] : "";
        newSearchData.userId = filters.userId ? filters.userId[0] : "";
        setSearchData(newSearchData);
    };
    const dateChange = (date, dateString) => {
        let newSearchData = {...searchData}
        newSearchData.startTime = dateString[0];
        newSearchData.endTime = dateString[1];
        newSearchData.pageIndex = 1;
        newSearchData.pageSize = 10;
        newSearchData.userId = "";
        setSearchData(newSearchData);
        setInitDate(dateString[0], dateString[1]);
    }

    const searchChange = (value, type) => {
        let newOperationData = {...operationData};
        newOperationData.startTime = value[0];
        newOperationData.endTime = value[1];
        newOperationData.pageIndex = 1;
        setOperationData(newOperationData)
    }

    const onSearch = () => {
        setSearchData({
            ...searchData,
            ...operationData
        });
    }
    const onResetting = () => {
        setOperationData({})
        setSearchData({
            ...pagingParam,
            "startTime": "",
            "endTime": "",
            "operationType": '',
            "userId": ''
        })
    }

    const disabledDate = (current) => {
        // Can not select days after today and today
        return current && current > moment().endOf('day');
    };

    return(
        <div className="logFrame">
            <div className="title">日志管理</div>
            <div className="operation">
                {/* 选择时段：<RangePicker onChange={dateChange}/> */}
                {/* 选择时段：<RangePicker onChange={dateChange}/> */}
                <Space size={"large"}>
                    <div className="operationItem">
                        选择时段：
                        <RangePicker
                        disabledDate={disabledDate}
                        value={operationData.startTime?[moment(operationData.startTime), moment(operationData.endTime)]:[]}
                        onChange={(date, dateString)=>{
                            searchChange(dateString,"timeRange")
                        }}/>
                    </div>
                    <Button type="primary" onClick={onSearch}>搜索</Button>
                    <Button onClick={onResetting}>重置</Button>
                </Space>
            </div>
            <BackIcon />
            <div className="table">
                <SelfTable
                    dataSource={listData}
                    columns={columns}
                    pagination={pagination}
                    loading={tableLoading}
                    onChange={onChanges}
                    footerHeight={54}
                    scroll={
                        {
                            x:"1500px"
                        }
                    }
                />
                {/* <Table
                    rowKey="id"
                    columns={columns}
                    bordered
                    dataSource={listData}
                    onChange={onChanges}
                    pagination={{
                        total:pagination.total,
                        current:pagination.pageIndex,
                        pageSize:pagination.pageSize,
                        showSizeChanger:true,
                        showQuickJumper:true,
                        showTotal:()=> `总计${pagination.total}条数据`
                    }}
                    scroll={{
                        scrollToFirstRowOnChange:true,
                        y:tableHight
                    }}
                    loading={tableLoading}
                /> */}
            </div>
        </div>
    )
}
export default Log
