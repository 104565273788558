import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Tabs,
  Popconfirm,
  Switch,
  Col,
  Row,
  message,
 
} from "antd";
import { SettingOutlined } from "@ant-design/icons";

import { getSearchParams } from "../../utils/tool";
import ApprovalNotice from "./approvalNotice/index";
import Comment from "./comment/index";
import Like from "./like/index";
import Collect from "./collect";
import System from "./system";
import Todo from "./toDo";
import Api from "../../api";

import "./index.scss";

const Notifications = () => {
  const location = getSearchParams();

  // 1 互动消息=评论与回复；审批消息=我的申请；系统消息=系统消息
  const [directory, setDirectory] = useState([
    { label: "评论与回复", key: "1", children: <Comment/> },// 务必填写 key
    { label: "被赞同", key: "2", children: <Like /> },
    { label: "被收藏", key: "3", children: <Collect /> },
    { label: "我的申请", key: "4", children: <ApprovalNotice/> }, 
    { label: "系统消息", key: "5", children: <System /> },
    { label: "我的待办", key: "6", children: <Todo /> },
  ]);

  const [messageSettingsList, setMessageSettingsList] = useState([]);
  const [messageSettingsDate, setMessageSettingsDate] = useState({});
  const [activeKey, setActiveKey] = useState("");

  useEffect(() => {
    getSwitchType();
  }, []);

  useEffect(() => {
    let currntKey = sessionStorage.activeKey ? sessionStorage.activeKey : "1";
    
    setActiveKey(currntKey)
    
  }, [sessionStorage.activeKey]);

  const getSwitchType = async () => {
    const res = await Api.Notice.getNotificationSwitch({});
    if (res && res.code === 0 && res.data) {
      setMessageSettingsList(res.data);
      let settingDate = {};
      res.data.map(item=>{
        settingDate[item.id] = item.configurationSwitch
      })
      setMessageSettingsDate(settingDate)
    } else {
      setMessageSettingsList([]);
    }
  };

  const onSwitchChange = (checked, id) => {
    let _messageSettingsDate = { ...messageSettingsDate };
    _messageSettingsDate[id] = checked ? 1 : 0;
    setMessageSettingsDate(_messageSettingsDate);
  };

  const confirm = async () => {
    let data = [];

    console.log(messageSettingsDate,"messageSettingsDate")

    for (let key in messageSettingsDate) {
      data.push({
        configurationSwitch: messageSettingsDate[key],
        id: key,
      });
    }
    const res = await Api.Notice.updateNotificationSwitch({ data });
    if (res && res.code === 0 && res.data) {
      message.info("修改配置成功");
      getSwitchType();
    } else {
      message.info("修改配置失败");
      getSwitchType();
    }
  };

  const onChange = (activeKey) => {
    setActiveKey(activeKey)
    sessionStorage.activeKey = activeKey;
  }

  return (
    <div className="notificationsFrame">
      <Tabs
        activeKey={activeKey}
        items={directory}
        onChange={onChange}
      ></Tabs>
      <Popconfirm
        placement="bottomRight"
        title={
          <div className="messageSettingsBox">
            <p>设置你想接收的消息提醒</p>
            {messageSettingsList.map((item) => {
              return (
                <Row key={item.id}>
                  <Col span={2}></Col>
                  <Col span={14}>{item.notificationName}</Col>
                  <Col span={8}>
                    <Switch
                      checkedChildren="开启"
                      unCheckedChildren="关闭"
                      defaultChecked={item.configurationSwitch}
                      onChange={(checked) => onSwitchChange(checked, item.id)}
                    />
                  </Col>
                </Row>
              );
            })}
          </div>
        }
        onConfirm={confirm}
        showCancel={false}
        icon={""}
      >
        <div className="messageSettings">
          <SettingOutlined />
          消息设置
        </div>
      </Popconfirm>
      
    </div>
  );
};

export default Notifications;
