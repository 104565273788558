import React,{ useState, useEffect } from 'react';
import {Form, Input, Typography, message, Spin, Modal, Popconfirm, Empty} from 'antd';
import { SearchOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from "react-redux";

import { setAIModalState } from "../../../../features/AIIntelligent/AIReducer";
import Api from "../../../../api/index";
import './index.scss';

const HistoryTopic = ({onClickHistoryDetails, onDeleteTopic, questionLocations, maxHeight, type, historyId}) => {
    const dispatch = useDispatch();
    
    // const questionLocation = useSelector(state => state.AIModal.questionLocation);// 0文档中的问答， 1知识中心 ， 2ES搜索界面
    const knowledgeIds = useSelector(state => state.AIModal.knowledgeIds); //需要提问的知识id

    const [form] = Form.useForm();
    const [inputValue, setInputValue] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [topicList, setTopicList] = useState([]) // 历史对话列表
    const [topicLoading, setTopicLoading] = useState(false);
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [currentHistoryItem, setCurrentHistoryItem] = useState({});
    const [currentId, setCurrentId] = useState('');
    const [confirmLoading, setConfirmLoading] = useState(false);

    
    useEffect(()=>{
        // getTopicList();
    },[])

    useEffect(()=>{
        getTopicList();
        setCurrentId(historyId);
    },[historyId])

    // 获取历史对话记录
    const getTopicList = async() => {
        setTopicLoading(true);
        try{
            const res = await Api.Chat.getHistoryLog({data:{
                'title':inputValue,
                'knowledgeIds':type?[]:knowledgeIds, // 只有AI对话框中的历史记录可以需要根据知识ID查讯
                questionLocations //问答位置
            }})
            setTopicLoading(false);
            if(res.code === 0){
                setTopicList(res.data);
            }else{
                message.error(res.msg); 
            }
        }catch(e){
            setTopicLoading(false);
            console.log(e)
        }
    }

    const handleOk = async() => {
        try {
            const row = await form.validateFields();
            setConfirmLoading(true)
            const res = await Api.Chat.updateTitle({
                data:{...row,largeModelQaLogId:currentHistoryItem.id}
            })
            setConfirmLoading(false)
            if(res.code === 0){
                message.success("修改成功")
                setIsModalOpen(false);
                getTopicList();

                if(currentId && historyId){
                    dispatch(setAIModalState({
                        historyTitle:row.title
                    }))
                }
            }
        }catch(e){
            setConfirmLoading(false)
            console.log(e)
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const searchInputChange = (e) => {
        setInputValue(e.target.value)
    }

    const showModal = async(item) => {
        form.setFieldsValue({
            "title":item.title,
            "largeModelQaLogId":item.id
        });
        setCurrentHistoryItem(item)
        setIsModalOpen(true);
    };

    // 删除历史对话记录
    const deleteTopicConfirm = async(id) => {
        try{
            const res = await Api.Chat.removeTitle({data:{"largeModelQaLogId":id}})
            if(res.code === 0){
                message.success("删除成功");
                getTopicList();
                if(onDeleteTopic){
                    if(currentId === id){
                        // 删除的是当前选中的数据
                        onDeleteTopic(true)
                    }
                }
            }else{
                message.error(res.msg); 
            }
        }catch(e){
            console.log(e)
        }
    }

    return (<div style={{height: '100%'}}>
        <div className="historyTopic">
            <div className="titleBox">
                <span className='titleText'>对话列表</span> 
                <div className='searchBox'>
                    {showSearchInput&&<Input size='small' value={inputValue} onChange={searchInputChange} onPressEnter={getTopicList}/> }
                    <SearchOutlined onClick={()=>{setShowSearchInput(true)}}/>
                </div>
                
            </div>
            <div className="content" style={maxHeight?{maxHeight}:{}}>
                <Spin spinning={topicLoading} tip={"加载中..."} size={"small"}>
                    {topicList.length >0 ? topicList.map(item=>{
                        const {id, title} = item
                        return(<div 
                            className={`item ${type === 'clickEffect' ? (currentId === id) ? 'selectedItem' : '' : 'selectedItem' }`} 
                            key={id} 
                            onClick={() => {
                                setCurrentId(id)
                                setInputValue("");
                                onClickHistoryDetails(id,title, item)
                            }}>
                        <div className="itemTitle">{title}</div>
                        <div>
                            <span>{item.createTime}</span>
                            <Popconfirm
                                placement="bottomRight"
                                title="确认删除对话记录吗?"
                                onConfirm={(e)=>{
                                    e.stopPropagation();
                                    e.preventDefault();
                                    deleteTopicConfirm(id)
                                }}
                                onCancel={(e)=>{
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                okText="确认"
                                cancelText="取消"
                                getPopupContainer={()=>document.getElementsByClassName(id)[0]}
                            >
                                <DeleteOutlined onClick={(e)=>{
                                    e.stopPropagation();
                                    e.preventDefault();
                                }} className={id}/>
                            </Popconfirm>
                            
                            <EditOutlined onClick={(e)=>{
                                e.stopPropagation();
                                e.preventDefault();
                                showModal(item);
                            }}/>
                        </div>
                    </div>)
                    }):<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </Spin>
            </div>
        </div>

        <Modal 
            title="修改对话标题" 
            centered={true} 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            confirmLoading={confirmLoading}
        >
            <Form
                name="basic"
                form={form}
                labelCol={{span: 6}}
                wrapperCol={{span: 16}}
                initialValues={{remember: true}}
                autoComplete="off"
            >
                <Form.Item
                    label="对话标题"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: '请输入对话标题!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    </div>)
}

export default HistoryTopic