import myAxios from '../utils/request.js';
export default {
    // 获取计分规则
    async getKnowledgeValueScore(params = {}, cb) {
        let res = await myAxios.getJson("/knowledgeValueScore/select", params);
        return res.data;
    },
    // 新增或修改计分规则  
    async knowledgeValueScoreInsertOrUpdate(params = {}, cb) {
        let res = await myAxios.postJson("/knowledgeValueScore/insertOrUpdate", params);
        return res.data;
    },
    // 获取用户得分折算系数
    async getUserScoreConvertCoefficient(params = {}, cb) {
        let res = await myAxios.getJson("/userScoreConvertCoefficient/select", params);
        return res.data;
    }, 
    // 修改得分折算系数 
    async userScoreConvertCoefficientUpdate(params = {}, cb) {
        let res = await myAxios.postJson("/userScoreConvertCoefficient/update", params);
        return res.data;
    }, 
    // 新增
    async userScoreConvertCoefficientInsert(params = {}, cb) {
        let res = await myAxios.postJson("/userScoreConvertCoefficient/insert", params);
        return res.data;
    }, 
    // 删除 
    async userScoreConvertCoefficientDeleteById(params = {}, cb) {
        let res = await myAxios.deleteJson("/userScoreConvertCoefficient/deleteById", params);
        return res.data;
    }, 
    // 获取回答问题得分计分规则
    async getAnswerQuestionScore(params = {}, cb) {
        let res = await myAxios.getJson("/answerQuestionScore/select", params);
        return res.data;
    }, 
    // 修改更新回答计分规则 
    async setAnswerQuestionScore(params = {}, cb) {
        let res = await myAxios.postJson("/answerQuestionScore/set", params);
        return res.data;
    }, 
    // 个人知识学习得分 
    async getKnowledgeStudyScore(params = {}, cb) {
        let res = await myAxios.getJson("/knowledgeStudyScore/select", params);
        return res.data;
    }, 
    // 个人知识学习得分修改  
    async setKnowledgeStudyScore(params = {}, cb) {
        let res = await myAxios.postJson("/knowledgeStudyScore/set", params);
        return res.data;
    }, 
}