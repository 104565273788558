import React, {useState, useEffect, useRef, useContext} from "react";
import {
    BrowserRouter as Router,
    useHistory,
    Link,
    useLocation,
} from "react-router-dom";
import {
    Layout, Menu, Upload, message, Tooltip,
    Tree, Dropdown, Modal, Form, TreeSelect,
    Input, Progress, Tag,
} from "antd";
import {
    LoadingOutlined, PlusOutlined, UploadOutlined, EllipsisOutlined,
    RightCircleOutlined, DownCircleOutlined, CloseCircleOutlined,
    CheckCircleOutlined, SyncOutlined, ClockCircleOutlined
} from "@ant-design/icons";
import Iconfont from "../../../components/Iconfont/Iconfont.js";
import UploadFile from "../components/UploadFile/index";
import SelfDesignModal from "../../../components/SelfDesignModal";
import storage from "../../../utils/storage";
import {personalChildren} from "../../../routesConfig";
import Api from "../../../api";
import {ModalConfirm, getSizeText} from '../../../utils/tool';
import propsContext from './context';
import {zuStandStore} from "../../../zustand";
import SelfTable from "../../../components/SelfTable";
import CropperImg from "../../../components/CropperImg/index";

import "./layout.scss";

const {Sider, Content} = Layout;
const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
};

const PersonalCenterLayoutBox = (props) => {
    const {children, match} = props;
    const containerRef = useRef(null);
    const treeRef = useRef(null);
    const history = useHistory();
    const location = useLocation();
    const userinfo = storage.getItem("userInfo");
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(userinfo?.fileUrl); // 默认头像
    const [modalFlag, setModalFlag] = useState(false);
    const [modalFlag2, setModalFlag2] = useState(false);
    const [CurrentMenuKey, setCurrentMenuKey] = useState("");
    const [openKey, setOpenKey] = useState([]);
    const [currentPath, setCurrentPath] = useState("");
    const acceptedFileTypes = "image/png,image/jpeg,image/svg+xml,image/webp";
    let modalList = [
        {id: 1, title: "上传文档", icon: "icon-icon-cloud-upload"},
        {id: 2, title: "在线写作", icon: "icon-wenben"},
        {id: 3, title: "流程地图", icon: "icon-gongyiluxian"},
    ];

    const [uswerDept, setUswerDept] = useState("");

    // 我的空间分类
    const [isExpand, setIsExpand] = useState(false);
    const [spaceTreeData, setSpaceTreeData] = useState([]);
    const moreOperationList = [
        { label: '新建子分类', key: 'add' },
    ];
    const moreChildrenOperationList = [
        { label: '新建子分类', key: 'add' },
        { label: '重命名', key: 'updata' },
        { label: '复制到...', key: 'copy' },
        { label: '移动到...', key: 'move' },
        { label: '排序', key: 'order' },
        {label: '删除', key: 'delete' },
    ];
    const [operationType, setOperationType] = useState(null);
    const [showMoreBoxId, setShowMoreBoxId] = useState(null);
    const [addForm] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false); // 添加/编辑 分类
    const [selectedNodeKeys, setSelectedNodeKeys] = useState(false); // 选中我的空间下操作的空间分类节点
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [editParam, setEditParam] = useState("");
    const [treeModalOpen, setTreeModalOpen] = useState(false);
    const [seleteTreeNode,setSeleteTreeNode] = useState("");
    const [checkTreeIds,setCheckTreeIds] = useState([]);
    const [spaceWaringIsShow, setSpaceWaringIsShow] = useState(false); // 空间容量不足提示弹窗
    const [spaceIsShow, setSpaceIsShow] = useState(false); // 空间管理弹窗
    const [spaceInfo, setSpaceInfo] = useState({
        totalStatus: {
            icon: "icon-success",
            tipText: "空间充足",
            usedValue: "0%",
            status: "safe",
        },
        capacityStatus: {
            icon: "icon-success",
            tipText: "空间充足",
            usedValue: "0%",
            status: "safe",
        },
        fileNumStatus: {
            icon: "icon-success",
            tipText: "空间充足",
            usedValue: "0%",
            status: "safe",
        },
        "id": null,
        "userId": "1",
        "otherCapacity": 0,
        "otherNum": 0,
        "initCapacity": 0,
        "initNum": 0,
        "totalCapacity": 0,
        "totalNum": 0,
        "useCapacity": 0,
        "useNum": 0,
    });

    // 分享文档释放空间
    const columns = [
        {
            title: '报告名称',
            dataIndex: 'knowledgeName',
            render: (text) => <a>{text}</a>,
            ellipsis: true,
            align: "left",
        },
        {
            title: '分享状态',
            dataIndex: 'knowledgeCheckStatus',
            width: "120px",
            align: "left",
            render: (text) => (
                <div className="shareStatusBox">
                    {/* knowledge_check_status 0已拒绝，1已通过，2待审批，3草稿文章） */}
                    {
                        text === 0 ? <Tag icon={<Iconfont type={"icon-cuowu"} />} color="error">已拒绝</Tag> :
                            text === 1 ? <Tag icon={<Iconfont type={"icon-dui"} />} color="success">已分享</Tag> :
                                text === 2 ? <Tag icon={<SyncOutlined spin />} color="processing">审批中</Tag> : <Tag icon={<Iconfont type={"icon-yuandian"} iStyle={{top: "-0.1rem", marginRight: "0.1rem"}} />} color="cyan">未分享</Tag>
                    }
                </div>
            ),
        },
        {
            title: '占用空间',
            dataIndex: 'fileSize',
            width: "120px",
            align: "left",
            render: (text, record) => <a>{getSizeText(Number(text || 0), "kb")}</a>,
            ellipsis: true
        },

        {
            title: '操作',
            dataIndex: 'operation',
            width: '120px',
            align: "left",
            render: (_, record) => {
                return (<div className="operateBtnBox"><span className="goShareBth" onClick={() => goShare([record.id])}>去分享</span></div>)
            },
        },];
    const [shareKnowledgeIsShow, setShareKnowledgeIsShow] = useState(false);
    const [unShareKnowledgeList, setUnShareKnowledgeList] = useState([]);
    const [pagination, setPagination] = useState({
        pageSize: 10,
        pageIndex: 1,
        total: 0,
    });
    const [selectedRowKeysObject, setSelectedRowKeysObject] = useState({});
    const [selectedSpaceTypeId, setSelectedSpaceTypeId] = useState(null);
    const zuStandState = zuStandStore((state) => state);
    const changeSpaceTypeIdsFunc = zuStandState.changeSpaceTypeIds;

    // 上传头像
    const [uploadImageUrl, setuploadImage] = useState(null);
    const [croppedImageOpen, setCroppedImageOpen] = useState(false);
    const [uploadFile, setUploadFile] = useState('');


    useEffect(() => {
        getUswerDept();

        getSpaceTree();

        // 在文档对象上绑定点击事件处理程序
        const handleOutsideClick = (event) => {
            if (!containerRef.current.contains(event.target)) {
                setModalFlag(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
            changeSpaceTypeIdsFunc(null);
        };
    }, []);

    useEffect(() => {
        if (shareKnowledgeIsShow) {
            getUnShareKnowledgeList();
        }
    }, [pagination.pageSize, pagination.pageIndex]);

    useEffect(() => {
        if (!zuStandState.uploadOpen) {
            getUserSpaceUsedStatus();
        }
    }, [zuStandState.uploadOpen]);

    useEffect(() => {
        // 展开当前路径所在菜单
        let openKeyList = []
        for (const item of personalChildren) {
            const {children, path} = item;
            if (children?.length > 0) {
                let flag = false;
                for (let child of children) {
                    if (child.path === location.pathname) {
                        flag = true;
                        openKeyList = [path];
                        break
                    }
                }
                if (flag) {
                    break;
                }
            }
        }
        setOpenKey(openKeyList)
    }, [currentPath]);
    useEffect(() => {
        if (location.pathname === "/personalCenter") {
            setCurrentMenuKey("/personalCenter/myHome");
        } else {
            setCurrentMenuKey(location.pathname);
        }
    }, [location.pathname]);

    const getSpaceTree = async () => {
        const res = await Api.PersonalCenter.querySpaceTree({});
        if (res && res.code == 0) {
            setSpaceTreeData(res.data);
        }
    };

    const getUswerDept = async () => {
        const res = await Api.Department.getUswerDept({});
        if (res && res.code == 0) {
            setUswerDept(res.data.seNames.join('，'))
        }
    };

    // 获取用户空间使用状态
    const getUserSpaceUsedStatus = async () => {
        const res = await Api.PersonalCenter.getSpaceUsedStatus();
        if (res && res.code == 0) {
            const {
                totalCapacity, totalNum,
                useCapacity, useNum,
            } = res?.data || {};
            let usedRate1 = useCapacity / totalCapacity;
            let usedRate2 = useNum / totalNum;
            let rate = usedRate1;
            if(usedRate2 > usedRate1) {
                rate = usedRate2;
            }

            setSpaceInfo({
                totalStatus: computedSpaceInfo(rate || 0),
                capacityStatus: computedSpaceInfo(usedRate1 || 0),
                fileNumStatus: computedSpaceInfo(usedRate2 || 0),
                ...(res?.data || {}),
            });

            if (rate >= 0.8) {
                setSpaceWaringIsShow(true);
            }
        }
    }

    // 计算空间信息
    function computedSpaceInfo (rate) {
        let usedRate = rate;

        if (rate > 1) {
            usedRate = 1;
        }

        let spaceInfo = {
            icon: "icon-success",
            tipText: "空间充足",
            usedValue: `${Number(Number(usedRate * 100).toFixed(2))}%`,
            status: "safe",
        };

        if (usedRate >= 1) {
            spaceInfo.icon = "icon-fail";
            spaceInfo.tipText = "空间不足，请分享上传的知识";
            spaceInfo.status = "fail";
        } else if (usedRate >= 0.8) {
            spaceInfo.icon = "icon-tishi";
            spaceInfo.tipText = "空间不足，将影响文档上传";
            spaceInfo.status = "waring";
        }

        return spaceInfo;
    }

    // 获取用户未分享文档列表
    const getUnShareKnowledgeList = async () => {
        const res = await Api.PersonalCenter.getKnowledgeNotPublished({
            data: {
                ...pagination,
                type: 2,
                isCreated: 1,
            },
            // loading: true,
            // loadingText: "数据加载中..."
        });
        if (res && res.code == 0) {
            const {
                records, total,
            } = res?.data || {};
            setUnShareKnowledgeList(records);
            setPagination({
                ...pagination,
                total,
            })
        }
    }

    // 头像上传-文件上传获取fileId
    const handleChange = async ({ file, fileList: newFileList }) => {
        console.log(file, "上传头像文件");

        // console.log(file, "上传头像文件");
        if (file) {
            let fileTypeIsOk = acceptedFileTypes.includes(file.type);
            if (!fileTypeIsOk) {
                message.error("仅支持上传 PNG、JPEG 、WEBP 和 SVG 图片文件！")
                return false
            }
            // console.log(file, "filefilefile")
            // const formData = new FormData();
            // formData.append("file", file);
            // const res = await Api.Common.uploadFile({
            //     data: formData,
            // });
            // if (res && res.code == 0) {
            //     avaHandeleChange(res.data.id, res.data.url);
            // }
            setUploadFile(file);
            setuploadImage(URL.createObjectURL(newFileList[0].originFileObj));
            setCroppedImageOpen(true);

        }
    };

    const handleCrop = async(value) => {
        message.loading({
            content:'上传中..',
            key:"upload"
        })
        const formData = new FormData();

        const fileValue = new File([value], uploadFile.name, { type: uploadFile.type }); // 使用文件名创建File对象
        console.log(fileValue); // 这里可以处理file对象，比如上传到服务器

        formData.append("file",fileValue);

        const res = await Api.Common.uploadFile({
            data:formData,
        });
        if(res&&res.code === 0){
            handleCropperCancel();
            avaHandeleChange(res.data.id, res.data.url);
        }
    };

    const handleCropperCancel = () => {
        setCroppedImageOpen(false);
        setuploadImage(null)
        setUploadFile('')
    }


    // 编辑头像
    const avaHandeleChange = async (fileId, url) => {
        try {
            let res = await Api.User.editAvatar({data: {fileId}});
            if (res && res.code == 0) {
                setImageUrl(url);
                let _userinfo = userinfo;
                _userinfo.fileUrl = url;
                storage.setItem("userInfo", _userinfo);
                message.success("头像上传成功");
            } else {
                message.error("上传失败，请重试");
            }
        } catch (error) {
        }
    };
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                暂无头像,请上传
            </div>
        </div>
    );
    const toOperate = (id) => {
        setModalFlag(false);
        if (id === 1) {
            if (spaceInfo.totalStatus.status === "fail") {
                message.warning("空间已满，请分享知识释放空间！");
                return
            }
            setModalFlag2(true);
        } else if (id === 2) {
            storage.removeItem("knowledgeDetailInfo");
            let url = `/page/onLineWrite`
            window.open(url);
            // history.push("/page/onLineWrite");
        } else {
            message.info("暂未开放");
            // history.push("/personalCenter/myNote/noteManage");
        }
    };
    const iconStyle = {
        fontSize: "25px",
        marginRight: "15px",
    };

    function getItem(label, key, icon, children, disabled) {
        return {
            key,
            icon,
            children,
            label,
            disabled
        };
    }

    const menuRender = (list) => {
        return list.map((item) => {
            const {redirect, name, path, icon, children} = item;
            return getItem(
                redirect ? (name) : (<Link to={path} onClick={() => setCurrentMenuKey(path)}>{name}</Link>),
                path,
                icon ? (<Iconfont type={icon} iStyle={iconStyle}></Iconfont>) : null,
                children?.length > 0 ? menuRender(children) : null,
                ["/personalCenter/myEquity/pointsMall", "/personalCenter/myGrowth"].includes(path)
            );
        });
    };
    let menuRouting = menuRender(personalChildren);

    useEffect(() => {
        // 路由改变的时候获取当前菜单应该展开的key
        changeOpenKey(window.location.pathname)
        // 路由监听
        history.listen((path, action) => {
            changeOpenKey(path.pathname)
        });
    }, []);
    const changeOpenKey = (pathname) => {
        let _path = pathname.split("/");
        setOpenKey([`/${_path[1]}/${_path[2]}`]);
    }

    // const getOpenKeys = (openkey) => {
    //   const latestOpenKey = openkey.find((key) => openKey.indexOf(key) === -1);
    const getOpenKeys = (keyList) => {
        const latestOpenKey = keyList.find((key) => !openKey.includes(key));
        setIsExpand(false);
        setOpenKey(latestOpenKey ? [latestOpenKey] : []);
    };
    const menuOnClick = (values) => {
        if (values.keyPath?.length < 2) {
            setOpenKey([]);
        }
        if (values.key === "/personalCenter/mySpace") {
            setIsExpand(true);
        } else {
            setIsExpand(false);
            setSelectedSpaceTypeId(null); // 取消选定的空间分类
            setCheckTreeIds([]); // 取消勾选的空间分类
            changeSpaceTypeIdsFunc([]); // 取消勾选的空间分类
        }
        if (values.key === "/personalCenter/myEquity/pointsMall") {
            history.replace(location.pathname);
            message.info("暂未开放");
        }
        if (values.key === "/personalCenter/myGrowth") {
            history.replace(location.pathname);
            message.info("暂未开放");
        }
    };

    const onMoreOperationChange = (e, showMoreId) => {
        // console.log(showMoreId);
        setOperationType(e.key);
        if(e.key === 'add'){
            setIsModalOpen(true);
            setEditParam("");
            setSeleteTreeNode("");
            if (showMoreId) {
                addForm.setFieldsValue({
                    parentId: showMoreId,
                });
            }

        }else if(e.key === 'updata' || e.key === 'order'){
            setIsModalOpen(true);
            addForm.setFieldsValue({
                name: editParam.name,
                orderNum: editParam.orderNum,
                parentId: editParam.parentId,
            });
        }else if(e.key === 'delete'){
            deleteSpaceTreeF();
        }else if(e.key === 'move' || e.key === 'copy'){
            setTreeModalOpen(true);
        }
    }

    // 新增
    const handleOk = async() => {
        try {
            const row = await addForm.validateFields();
            console.log(row, editParam.id);
            if(operationType === "edit" && row.parentId === editParam.id){
                message.info("父级菜单不能为自己");
                return false
            }
            row.url = row.url ? row.url.trim():"";
            setConfirmLoading(true);
            if(editParam.id){
                row.id = editParam.id
                updataSpaceTreeF(row)
            }else{
                addSpaceTreeF(row);
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    const updataSpaceTreeF = async (row) => {
        const res = await Api.PersonalCenter.updataSpaceTree({data:row});
        if(res&&res.code === 0){
            message.success("操作成功",2,()=>{
                handleCancel();
                getSpaceTree();
            })
        }else{setConfirmLoading(false);}
    }

    const addSpaceTreeF = async (row) => {
        const res = await Api.PersonalCenter.addSpaceTree({data:row});
        if(res&&res.code === 0){
            message.success("操作成功",2,()=>{
                handleCancel();
                getSpaceTree();
            })
        }else{setConfirmLoading(false);}
    }

    const deleteSpaceTreeF = async () => {
        ModalConfirm({
            title: `您确认要删除 "${editParam.name}" 分类吗?`,
            onOk:async(resolve, reject)=> {
                try{
                    const res = await Api.PersonalCenter.deleteSpaceTree({data:{id:editParam.id}});
                    if(res && res.code === 0){
                        getSpaceTree();
                        message.success("删除成功")
                    }
                    resolve();
                }catch{}
            },
        })
    }

    // 关闭新增
    const handleCancel = () => {
        setConfirmLoading(false);
        setIsModalOpen(false);
        setEditParam("");

        setTreeModalOpen(false);
        setSeleteTreeNode("");
        setSelectedSpaceTypeId(null);
    }

    const moveTreeNodeOk = () => {
        if(!seleteTreeNode){
            message.info("请选择分类");
            return false
        }
        if(seleteTreeNode === editParam.parentId){
            message.info("你当前已在此分类下");
            return false
        }
        if(seleteTreeNode === editParam.id){
            message.info("分类不能选择本身");
            return false
        }

        if(operationType === 'move'){
            updataSpaceTreeF({id:editParam.id,parentId:seleteTreeNode})
        }else if(operationType === 'copy'){
            addSpaceTreeF({
                parentId:seleteTreeNode,
                name: editParam.name,
                orderNum: editParam.orderNum,
            })
        }
    }

    const moveTreeNodeCancel = () => {
        setTreeModalOpen(false);
        setConfirmLoading(false);
        setEditParam("");
        setSeleteTreeNode("");
    }

    const onTreeNodeSelect = (selectedKeys, info) => {
        setSeleteTreeNode(selectedKeys[0])
    }

    const onTreeNodeCheck = (selectedKeys, info) => {
        setCheckTreeIds(selectedKeys);
        changeSpaceTypeIdsFunc(selectedKeys);
        // console.log(selectedKeys, info);
    }

    // 扩容
    const extendFunc = () => {
        message.warning("功能开发中！");
    }

    // 去分享文件释放空间
    const goRelease = async () => {
        await getUnShareKnowledgeList();
        setSpaceWaringIsShow(false);
        setSpaceIsShow(false);
        setShareKnowledgeIsShow(true);
    }

    // 去分享
    const goShare = (ids) => {
        if (ids?.length > 0) {
            if (ids.length > 1) {

            } else {

            }
        } else {
            message.warning("请选择需要分享的知识！");
        }
    }

    const {totalStatus, capacityStatus, fileNumStatus,} = spaceInfo;
    const colorObject = {
        safe: "#0256FF",
        waring: "#E37318",
        fail: "#ff3a36",
    };

    let rowKeyList = [];
    for (const key in selectedRowKeysObject) {
        rowKeyList.push(...(selectedRowKeysObject[key] || []))
    }

    return (
        <Layout>
            <Sider width="14.1rem" style={{marginRight: "0.5rem", background: "none"}} theme="light">
                <div className="PCLleftBox">
                    <div className="topList">
                        {/* 基本信息 & 上传知识 */}
                        <div className="topBox">
                            <div className="user">
                                <div className="ava">
                                    <Upload
                                        name="avatar"
                                        accept="image/png,image/jpeg,image/svg+xml,image/webp"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        // beforeUpload={handleChange}
                                        beforeUpload={() => {
                                            return false;
                                        }}
                                        onChange={handleChange}

                                    >
                                        {imageUrl ? (
                                            <img
                                                src={imageUrl}
                                                // alt="avatar"
                                                style={{
                                                    width: "110%",
                                                    height: "110%"
                                                }}
                                            />
                                        ) : (
                                            uploadButton
                                        )}
                                    </Upload>
                                </div>

                                <div className="info">
                                    <div>{userinfo?.userName}</div>
                                    <span>{userinfo?.departmentName}</span>
                                    <Tooltip placement={"right"} title={uswerDept}>
                                        <div className="userDescription">{uswerDept}</div>
                                    </Tooltip>
                                </div>
                                <div className="uploadBtn" ref={containerRef}>
                                    <Tooltip
                                        open={modalFlag}
                                        trigger={"click"}
                                        placement={"rightTop"}
                                        overlayClassName={"personalCenterTooltipBox"}
                                        title={(
                                            <div className="modal">
                                                {
                                                    modalList.map((item) => {
                                                        const {id, icon, title} = item;
                                                        const isThree = String(id) === "3";
                                                        return (
                                                            <div
                                                                className={`modalBtn ${isThree ? "disabledBtn" : null}`}
                                                                key={id}
                                                                onClick={() => {
                                                                    if (!isThree) {
                                                                        toOperate(id)
                                                                    }
                                                                }}
                                                            >
                                                                <Iconfont type={icon}/>
                                                                <span>{title}</span>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        )}
                                    >
                                        <button
                                            style={{fontSize: "0.8rem", color: "#ff0003", cursor: "pointer"}}
                                            onClick={() => setModalFlag(!modalFlag)}
                                        >
                                            <UploadOutlined style={{marginRight: '10px', fontSize: "0.85rem"}}/>
                                            <span>上传知识</span>
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>

                        {/* 菜单栏 */}
                        <div className="menuBox">
                            {/* <Menu
                                theme="light"
                                mode="inline"
                                items={menuRouting}
                                onOpenChange={getOpenKeys}
                                selectedKeys={[CurrentMenuKey]}
                                openKeys={openKey}
                                onClick={menuOnClick}
                            /> */}
                            <Menu
                                theme="light"
                                mode="inline"
                                onOpenChange={getOpenKeys}
                                selectedKeys={[CurrentMenuKey]}
                                openKeys={openKey}
                                onClick={menuOnClick}
                                className="menuList"
                            >
                                {menuRouting.map(item=>{
                                    if(item.key === "/personalCenter/mySpace"){
                                        return (
                                            <>

                                                <Menu.Item key={item.key}>
                                                    {item.icon}{item.label}
                                                    <div className="rightOperateBox">
                                                        <Dropdown
                                                            menu={{
                                                                items:moreOperationList,
                                                                onClick: (e) => {
                                                                    // setSelectedNodeKeys([]);
                                                                    setSelectedSpaceTypeId(null);
                                                                    onMoreOperationChange(e);
                                                                }
                                                            }}
                                                        >
                                                            <div className="moreIcon">
                                                                <EllipsisOutlined />
                                                            </div>
                                                        </Dropdown>
                                                        <span
                                                            className={`ant-menu-submenu-inline expandBtn ${isExpand ? "expandStatus" : ""}`}
                                                            onClick={(e) => {
                                                                e?.stopPropagation();
                                                                if (!isExpand) {
                                                                    setOpenKey([])
                                                                }
                                                                setIsExpand(!isExpand);
                                                            }}
                                                        ><i className="ant-menu-submenu-arrow"></i></span>
                                                    </div>
                                                </Menu.Item>
                                                <Tree
                                                    ref={treeRef}
                                                    style={{marginLeft: 65}}
                                                    checkable
                                                    defaultExpandAll={true}
                                                    // defaultSelectedKeys={['0-0-0', '0-0-1']}
                                                    // defaultCheckedKeys={['0-0-0', '0-0-1']}
                                                    // onSelect={onSelect}
                                                    checkedKeys={checkTreeIds}
                                                    selectedKeys={[selectedSpaceTypeId]}
                                                    onCheck={onTreeNodeCheck}
                                                    onSelect={(selectedNodeKeys, evt) => {
                                                        const treeInstance = treeRef.current;
                                                        if (treeInstance) {
                                                            const {node} = evt;
                                                            treeInstance.onNodeCheck(evt, node, !node.checked);
                                                        }
                                                    }}
                                                    treeData={spaceTreeData}
                                                    fieldNames={{ title: 'name', key: 'id', children: 'children' }}
                                                    rootClassName={`spaceTreeBox ${isExpand ? "" : "foldStatus"}`}
                                                    onMouseLeave={(e) => {setShowMoreBoxId(null)}}
                                                    titleRender={(node) => {
                                                        return (
                                                            <div
                                                                onMouseEnter={(e) => {
                                                                    setShowMoreBoxId(node.id)
                                                                    setEditParam(node);
                                                                }}
                                                            >
                                                                {node.name}
                                                                {
                                                                    showMoreBoxId === node.id && (
                                                                        <Dropdown
                                                                            menu={{
                                                                                items: moreChildrenOperationList,
                                                                                onClick: (e) => {
                                                                                    e.domEvent?.stopPropagation();
                                                                                    e.domEvent?.preventDefault();
                                                                                    setSelectedSpaceTypeId(node.id);
                                                                                    onMoreOperationChange(e, showMoreBoxId);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="moreIcon"
                                                                                onClick={(e) => {
                                                                                    e?.stopPropagation();
                                                                                    e?.preventDefault();
                                                                                }}
                                                                            >
                                                                                <EllipsisOutlined />
                                                                            </div>
                                                                        </Dropdown>
                                                                    )
                                                                }
                                                        </div>
                                                        )
                                                    }}
                                                >
                                                </Tree>
                                            </>
                                        )
                                    }else if(item.children){
                                        return (
                                            <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
                                                {item.children.map(it=>{
                                                    return (
                                                        <Menu.Item key={it.key}>{it.icon}{it.label}</Menu.Item>
                                                    )
                                                })}
                                            </Menu.SubMenu>
                                        )
                                    }else{
                                        return (
                                            <Menu.Item key={item.key}>{item.icon}{item.label}</Menu.Item>
                                        )
                                    }
                                })}
                            </Menu>
                        </div>
                    </div>
                    {/* 空间使用状态 */}
                    <div className={`spaceInfoBox ${totalStatus.status}`}>
                        {/* 使用状态 - 数值 */}
                        <div className="infoUsedValueBox">
                            <span className="usedValue">空间已使用：{totalStatus.usedValue}</span>
                            <span className="manageBtn" onClick={() => setSpaceIsShow(true)}>查看</span>
                        </div>
                        {/* 使用状态 - 进度条 */}
                        <div className="usedProcessBox">
                            <span className="processBox"><i className="processBar" style={{width: totalStatus.usedValue || 0}}></i></span>
                            <Iconfont type={totalStatus.icon} />
                        </div>
                        {/* 提示文字 */}
                        {
                            totalStatus.tipText ? (<span className="usedTipText">{totalStatus.tipText}</span>) : undefined
                        }
                    </div>
                </div>
            </Sider>
            <Content>
                <propsContext.Provider value={setCurrentPath}>
                    {children}
                </propsContext.Provider>
            </Content>

            {/* 文件上传 */}
            <SelfDesignModal
                open={modalFlag2 || zuStandState.choiceFileModalIsShow}
                centered={true}
                closeIcon={false}
                closable={false}
                maskClosable={false}
                wrapClassName={"uploadFileModalBox"}
                destroyOnClose={true}
                content={(
                    <UploadFile
                        isChoiceFile={true}
                        isSelected={false}
                        onCancel={() => {
                            setModalFlag2(false);
                            zuStandState.changeChoiceFileModalIsShow(false);
                        }}
                        uploadType={"knowledge"}
                    />
                )}
            />

            {/* 新增/编辑 子分类 */}
            <Modal
                title={`${operationType === "add" ? "新增" : "编辑"}子分类`}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                confirmLoading={confirmLoading}
                wrapClassName={"personCenterAddModalBox"}
            >
                <Form
                    form={addForm}
                    component={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    preserve={false}
                >
                    {
                        selectedSpaceTypeId ? (
                            <Form.Item
                                key={'parentId'}
                                label={'上级分类'}
                                name={'parentId'}
                            >
                                <TreeSelect
                                    disabled={operationType === "add"}
                                    // allowClear
                                    treeDefaultExpandAll
                                    treeData={spaceTreeData}
                                    fieldNames={{
                                        label:"name",
                                        value:"id"
                                    }}
                                />
                            </Form.Item>
                        ) : undefined
                    }

                    <Form.Item
                        key={'name'}
                        label={'分类名称'}
                        name={'name'}
                        rules={[
                            {
                                required: true,
                                message: `请输入分类名称!`
                            },
                        ]}
                    >
                        <Input type={'text'} />
                    </Form.Item>
                    <Form.Item
                        key={'orderNum'}
                        label={'序号'}
                        name={'orderNum'}
                    >
                        <Input type={'number'}/>
                    </Form.Item>
                </Form>
            </Modal>


            {/* 移动/复制分类 */}
            <Modal
                title="选择分类"
                open={treeModalOpen}
                onOk={moveTreeNodeOk}
                onCancel={moveTreeNodeCancel}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                wrapClassName={"personCenterAddModalBox"}
                confirmLoading={confirmLoading}
                width={400}
            >
                <Tree
                    style={{marginLeft: 30}}
                    defaultExpandAll={true}
                    treeData={spaceTreeData}
                    fieldNames={{ title: 'name', key: 'id', children: 'children' }}
                    rootClassName={"spaceTreeBox"}
                    onSelect={onTreeNodeSelect}
                />
            </Modal>

            {/* 管理空间使用情况 */}
            <SelfDesignModal
                open={spaceIsShow}
                width={"50%"}
                centered={true}
                closeIcon={false}
                closable={false}
                maskClosable={true}
                wrapClassName={"spaceUsedStatusModalBox"}
                onCancel={() => setSpaceIsShow(false)}
                content={(
                    <div className="spaceUsedStatusBox">
                        <div className="spaceUsedHeadBox">
                            <span className="title">空间使用情况</span>
                            {/* <span className="extendBtn" onClick={() => extendFunc()}>去扩容</span> */}
                        </div>
                        <div className="spaceInfoList">
                            <div className="spaceItemBox">
                                <div className="infoBox">
                                    <span className="labelText">存储容量:</span>
                                    <span className="valueText">已使用 {getSizeText(spaceInfo.useCapacity, "mb")}（共 {getSizeText(spaceInfo.totalCapacity, "mb")}）</span>
                                </div>
                                <div className={`usedProcessBox ${capacityStatus.status}`}>
                                    <Tooltip
                                        open={spaceIsShow && capacityStatus.status !== "safe"}
                                        color={"#fff"}
                                        getPopupContainer={() => document.getElementById("capacityProcessBox")}
                                        title={(
                                            <div className="toolTipContentBox">
                                                <Iconfont type={capacityStatus.icon} />
                                                <span className="tipText">空间不足将影响文件上传</span>
                                                <span className="releaseBtn" onClick={() => goRelease()}>立即释放</span>
                                            </div>
                                        )}
                                        placement={"topRight"}
                                    >
                                        <span className="processBox" id={"capacityProcessBox"}>
                                            <i
                                                style={{width: capacityStatus.usedValue || 0}}
                                                className="processBar"
                                            ></i>
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="spaceItemBox">
                                <div className="infoBox">
                                    <span className="labelText">文件数量:</span>
                                    <span className="valueText">已使用 {spaceInfo.useNum} 份（共 {spaceInfo.totalNum} 份）</span>
                                </div>
                                <div className={`usedProcessBox ${fileNumStatus.status}`}>
                                    <Tooltip
                                        open={spaceIsShow && fileNumStatus.status !== "safe"}
                                        color={"#fff"}
                                        getPopupContainer={() => document.getElementById("fileNumProcessBox")}
                                        title={(
                                            <div className="toolTipContentBox">
                                                <Iconfont type={fileNumStatus.icon} />
                                                <span className="tipText">空间不足将影响文件上传</span>
                                                <span className="releaseBtn" onClick={() => goRelease()}>立即释放</span>
                                            </div>
                                        )}
                                        placement={"topRight"}
                                    >
                                        <span className="processBox" id={"fileNumProcessBox"}>
                                            <i
                                                style={{width: fileNumStatus.usedValue || 0}}
                                                className="processBar"
                                            ></i>
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            />

            {/* 空间使用警告弹窗 */}
            <SelfDesignModal
                open={spaceWaringIsShow}
                width={600}
                centered={true}
                maskClosable={false}
                closeIcon={false}
                closable={false}
                wrapClassName={"spaceNotEnoughModalBox"}
                onCancel={() => setSpaceWaringIsShow(false)}
                content={(
                    <div className="spaceNotEnoughBox">
                        <div className={`usedProcessBox ${totalStatus.status}`}>
                            <Progress
                                type="circle"
                                percent={Number(totalStatus.usedValue.replace("%", ""))}
                                strokeColor={colorObject[totalStatus.status] || colorObject.safe}
                                width={70}
                            />
                        </div>
                        <span className="tipTextOne">{totalStatus.status === "fail" ? "个人知识库存储空间已满" : "个人知识库存储空间不足提醒"}</span>
                        <span className="tipTextTwo">将上传的知识发布至公共知识库可释放个人存储空间</span>
                        <div className="btnBox">
                            {
                                totalStatus.status !== "fail" ? (
                                    <span className="operateBtn closeModalBtn" onClick={() => setSpaceWaringIsShow(false)}>下次再说</span>
                                ) : undefined
                            }
                            <span className="operateBtn releaseBtn" onClick={() => goRelease()}>立即分享</span>
                        </div>
                        <div className="bottomTipBox">
                            <span className="tipText">*了解个人知识库存储空间</span>
                            <Tooltip
                                placement="bottomLeft"
                                trigger={"click"}
                                title={(
                                    <div
                                        className="tooltipContentBox"
                                        style={{
                                            width: "7.5rem",
                                            fontSize: "0.5rem",
                                            lineHeight: "1rem",
                                        }}
                                    >
                                        <span style={{display: "block"}}>存储空间上限为 {getSizeText(spaceInfo.totalCapacity, "mb")};</span>
                                        <span>文件数量上限为 {spaceInfo.totalNum} 篇。</span>
                                    </div>
                                )}
                            >
                                <span className="ruleBtn">管理规则</span>
                            </Tooltip>
                        </div>
                    </div>
                )}
            />

            {/* 释放空间弹窗 */}
            <SelfDesignModal
                open={shareKnowledgeIsShow}
                width={1000}
                centered={true}
                maskClosable={false}
                wrapClassName={"shareKnowledgeModalBox"}
                onCancel={() => {
                    setShareKnowledgeIsShow(false);
                    setSelectedRowKeysObject({});
                    setPagination({
                        pageSize: 10,
                        pageIndex: 1,
                        total: 0,
                    })
                }}
                content={
                    (
                        <div className="shareKnowledgeBox">
                            <span className="title">分享文档以释放云空间</span>
                            <div className="infoBox">
                                <span className="infoDesc">共有 {pagination.total} 份文件（限额{spaceInfo.totalNum}份），占用空间 {getSizeText(spaceInfo.useCapacity, "mb")}（限额{getSizeText(spaceInfo.totalCapacity, "mb").replace(" ","")}）</span>
                                <div className={`infoOperateBox ${rowKeyList.length < 1 ? "disabledStatus" : ""}`}>
                                    <span className="selectedNum">已选择 {rowKeyList.length} 项</span>
                                    <span className="cancelSelectBtn" onClick={() => setSelectedRowKeysObject({})}>取消选择</span>
                                    <span className="shareBtn" onClick={() => goShare(rowKeyList)}>批量分享</span>
                                </div>
                            </div>
                            <div className="tableBox">
                                <SelfTable
                                    rowKey="knowledgeId"
                                    bordered={false}
                                    selfRowSelection={{
                                        type: 'checkbox',
                                        selectedRowKeys: selectedRowKeysObject[pagination.pageIndex] || [],
                                        onChange: (newSelectedRowKeys) => {
                                            let newRowKeysObject = {
                                                ...selectedRowKeysObject
                                            };
                                            newRowKeysObject[pagination.pageIndex] = newSelectedRowKeys;
                                            setSelectedRowKeysObject(newRowKeysObject);
                                        },
                                    }}
                                    pagination={{
                                        ...pagination,
                                        showSizeChanger:false,
                                        showQuickJumper:false,
                                        onChange: (currentPage, pageSize) => {
                                            setPagination({
                                                ...pagination,
                                                pageSize,
                                                pageIndex: currentPage,
                                            })
                                        }
                                    }}
                                    dataSource={unShareKnowledgeList}
                                    columns={columns}
                                    expandable={{
                                        expandedRowRender: record => <p style={{margin: 0}}>{record.knowledgeDigest}</p>,
                                        rowExpandable: record => record.knowledgeDigest,
                                        expandIcon: ({expanded, onExpand, record}) =>
                                            expanded ? (
                                                <DownCircleOutlined style={{color: "#1C76FD"}} onClick={e => onExpand(record, e)}/>
                                            ) : (
                                                <RightCircleOutlined style={{color: "#1C76FD"}} onClick={e => onExpand(record, e)}/>
                                            )
                                    }}
                                />
                            </div>
                        </div>
                    )
                }
            />

            {croppedImageOpen&&<CropperImg
                open={croppedImageOpen}
                imageUrl={uploadImageUrl}
                handleOk={handleCrop}
                handleCancel={handleCropperCancel}
                aspectRatio={1/1}
                width={100}
                height={100}
            />}

        </Layout>
    );
};

export default PersonalCenterLayoutBox;
