import React, { useState, useEffect } from "react";
import { List, Empty, message } from "antd";

import Api from "../../../../api";
import "./index.scss";
const InteractionMessage = ({ interactionMessageList, toKnowledgeDetail, checkStatus }) => {

  const [likeList, setLikeList] = useState([]);
  const [otherList, setOtherList] = useState([]);

  useEffect(() => {
    if (interactionMessageList) {
      let _list = [...interactionMessageList];
      // console.log(interactionMessageList, "list");
      let newLikeList = _list.filter((item) => item.notificationType == "4");
      console.log(newLikeList, "newLikeList");
      init(newLikeList);

      setOtherList(() => {
        let newOtherList = _list.filter((item) => item.notificationType != "4");
        return newOtherList;
      });
    } else {
      interactionMessageList = [];
    }
  }, []);

  // 处理获取到的互动消息，
  const init = (list) => {
    let _list = [...list];
    const result = _list.reduce((acc, currentItem) => {
      // 根据 knowledgeId 字段进行分组
      const group = acc.find(
        (item) => item[0].knowledgeId === currentItem.knowledgeId
      );
      if (group) {
        group.push(currentItem);
      } else {
        acc.push([currentItem]);
      }
      return acc;
    }, []);
    setLikeList(result);
  };

  return (
    <div className="interactionMessageFrame">
      <List size="small">
        {likeList &&
          likeList.map((item, index) => {
            return (
              <List.Item>
                {item && item.slice(0, 3).map((likeItem, ind) => {
                  return (
                    <span key={likeItem.userId}>
                      {likeItem.userName}
                      {ind == item.slice(0, 3).length - 1 ? "" : "、"}
                    </span>
                  );
                })}

                {item?.length > 1 ? (
                  <>
                    等{item && item.length}
                    个用户
                  </>
                ) : null}

                赞了你发布的知识

                <span
                  className="cursorSpan interactionKnowledgeName"
                  onClick={() => {
                    checkStatus(item[0])
                  }}
                >
                  {/* 自己发布的知识 */}
                  《{item[0].knowledgeName}》
                </span>
              </List.Item>
            );
          })}
        {otherList &&
          otherList.map((item) => {
            return (
              <List.Item>
                {item.notificationType === 1 ? (
                  <>审批</>
                ) : item.notificationType === 2 ? (
                  <div>
                    <span>{item.userName}</span>在
                    <span
                      className="cursorSpan interactionKnowledgeName"
                      onClick={() =>{
                        checkStatus(item)
                      }}
                    >
                      《{item.knowledgeName}》
                    </span>
                    中向你提问，点击
                    <span
                      className="cursorSpan"
                      onClick={() =>
                        checkStatus(item,"comment")
                      }
                    >
                      立即查看
                    </span>
                  </div>
                ) : item.notificationType === 3 ? (
                  "更新"
                ) : item.notificationType === 5 ? (
                  <>
                    <span>{item.userName}</span>收藏了你发布的知识
                    <span
                      className="cursorSpan interactionKnowledgeName"
                      onClick={() =>{
                        checkStatus(item)
                      }}
                    >
                      《{item.knowledgeName}》
                    </span>
                  </>
                ) : item.notificationType === 6 ? (
                  <div>
                    <span>{item.userName}</span>回复了你在
                    <span
                      className="cursorSpan interactionKnowledgeName"
                      onClick={() =>
                        checkStatus(item)
                      }
                    >
                      《{item.knowledgeName}》
                    </span>
                    中的评论，点击
                    <span
                      className="cursorSpan"
                      onClick={() =>
                        checkStatus(item,"comment")
                      }
                    >
                      立即查看
                    </span>
                  </div>
                ) : null}
              </List.Item>
            );
          })}
      </List>
      {interactionMessageList?.length > 0 ? null : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};

export default InteractionMessage;
