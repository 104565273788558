import React from 'react'
import {Modal} from 'antd'
import Iconfont from "../Iconfont/Iconfont";
import PropTypes from 'prop-types'

function SelfDesignModal (props={}) {
    const {
        title, icon, color='#333', destroyOnClose,
        content, wrapClassName, width=540,
        okText, cancelText, onOk, onCancel,
    } = props;

    function confirmFunc () {
        const type = Object.prototype.toString.call(onOk);
        if (type === "[object Function]" || type === "[object AsyncFunction]") {
            onOk()
        }
    }
    function cancelFunc () {
        if (Object.prototype.toString.call(onCancel) === "[object Function]") {
            onCancel()
        }
    }

    return (
        <Modal
            closeIcon={(<Iconfont type="icon-close1" />)}
            width={String(width)}
            centered={true}
            closable={true}
            maskClosable={false}
            {...props}
            open={props.open || false}
            wrapClassName={'selfDesignModal ' + wrapClassName}
            footer={null}
            title={null}
        >
            <div className="selfDesignContentBox">
                <div className="titleBox" style={{color, display: (title || icon) ? "" : "none"}}>
                    {icon ? <Iconfont type={icon} /> : '' }
                    <span>{title}</span>
                </div>
                <div className="contentBox">
                    {content}
                </div>
                <div className="operateBtnBox" style={{display: (okText || cancelText) ? "" : "none"}}>
                    {
                        cancelText ? (<span
                            className="operateBtn cancelBtn"
                            onClick={() => cancelFunc()}
                        >{cancelText}</span>) : null
                    }
                    {
                        okText ? (<span
                            className="operateBtn confirmBtn"
                            onClick={() => confirmFunc()}
                        >{okText}</span>) : null
                    }
                </div>
            </div>
        </Modal>
    )
}

SelfDesignModal.propTypes = {
    title: PropTypes.any,
    icon: PropTypes.any,
    content: PropTypes.any,
    color: PropTypes.string,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    centered: PropTypes.bool,
    closable: PropTypes.bool,
    maskClosable: PropTypes.bool,
    wrapClassName: PropTypes.string,
    width: PropTypes.any,
    closeIcon: PropTypes.any,
    destroyOnClose: PropTypes.bool,
    open: PropTypes.any,
}

export default SelfDesignModal;
