// 用户状态
const userStatus = [
    {
        value: 0,
        label: '正常',
        text: '正常',
    },
    {
        value: 1,
        label: '暂停',
        text: '暂停',
    },
    {
        value: 4,
        label: '待启用',
        text: '待启用',
    },
    // {
    //     value: 2,
    //     label: '注销',
    //     text: '注销',
    // },
    // {
    //     value: 3,
    //     label: '删除',
    //     text: '删除',
    // }
]
// 管理权限
const userAdministrator = [
    {value:0,label:"部门已发布知识管理"},
    {value:1,label:"部门政策运维管理"},
    {value:2,label:"部门审核管理"},
    {value:3,label:"系统管理"}
]
// 审核权限
const userAuthority = [
    {
        value: 0,
        label: '无审核权限',
    },
    {
        value: 1,
        label: '有审核权限',
    }
]
// 操作权限
const operationAuthority = [
    {
        value: 0,
        label: '无需审批',
    },
    {
        value: 1,
        label: '按知识类型要求进行审批',
    }
]
// 操作类型
const operationType = [
    {
        value: '',
        text: '全部',
    },
    {
        value: '0',
        text: '登录访问',
    },
    {
        value: 1,
        text: '查看文件',
    },
    {
        value: 2,
        text: '上传文件',
    },
    {
        value: 3,
        text: '在线写作',
    },
    {
        value: 4,
        text: '下载文件',
    },
    {
        value: 5,
        text: '添加笔记',
    },
    {
        value: 6,
        text: '分享笔记',
    }
]

const approvalNotice = [
    {
        value: '',
        label: '全部状态',
    },
    {
        value: 2,
        label: '待审批',
    },
    {
        value: 1,
        label: '已通过',
    },
    {
        value: 0,
        label: '已拒绝',
    }
]

const serachType = [
    {
        value: '1',
        label: '全文'
    },
    {
        value: '0',
        label: '标题'
    },
    {
        value: '2',
        label: '作者'
    },
    // {
    //     value: '3',
    //     label: '笔记',
    //     disabled: true,
    // },
    {
        value: '4',
        label: '摘要'
    }
]

const pagingParam = {
    "pageIndex": 1,
    "pageSize": 10,
}

const fileLimitType = [
    "application/pdf", // pdf
    // "image/png", // png
    // "image/jpeg", // jpeg
    "application/zip", // zip
    "application/x-zip-compressed", // zip
    // "text/plain", // txt
    "application/vnd.ms-works", // wps wdb
    "application/msword", // doc
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
    "application/vnd.ms-word.document.macroEnabled.12", // docm
    "application/vnd.ms-excel", // xls
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
    "application/vnd.ms-excel.sheet.macroEnabled.12", // xlsm
    "application/vnd.ms-powerpoint", // ppt
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // pptx
    "application/vnd.ms-powerpoint.presentation.macroEnabled.12", // pptm
];

export {
    userStatus,
    userAdministrator,
    userAuthority,
    operationType,
    approvalNotice,
    serachType,
    pagingParam,
    operationAuthority,
    fileLimitType
}