import React, { useState, useEffect } from 'react';
import { Tree, Button, Empty, Tooltip } from 'antd';
import { CaretDownOutlined, FileTextOutlined, FolderOpenOutlined, FolderOutlined,} from '@ant-design/icons';
import Iconfont from "../../../../components/Iconfont/Iconfont";
// import storage from "../../../utils/storage";

import "./index.scss";

const {DirectoryTree} = Tree;

const PolicyTree = ({
    treeDate,
    changePolicyTree,
    selectedNode,
    type,
    menuClickFunc,
    expandedKeyList
}) => {
    const [activeNode, setActiveNode] = useState(null);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [menuStyle, setMenuStyle] = useState({});

    const [expandedKeys, setExpandedKeys] = useState([]);

    const menuList = [
        {text: "新增子分类", icon: "icon-add", key: "addSort",},
        {text: "新增主题", icon: "icon-xinjian", key: "addTheme",},
        {text: "编辑", icon: "icon-edit1", key: "edit",},
        {text: "删除", icon: "icon-shanchu1", key: "delete",},
    ];

    useEffect(() => {
        window.addEventListener("click", cancelActiveNode);
        if (!(selectedNode?.id)) {
            const node = firstPolicy(treeDate);
            if (node) {
                changePolicyTree(node);
            }
        }
        return () => {
            window.removeEventListener("click", cancelActiveNode)
        }
    }, [])

    useEffect(() => {
        if(expandedKeyList && expandedKeyList.length > 0){
            setExpandedKeys([...expandedKeyList]);
        }
    }, [expandedKeyList])

    useEffect(() => {
        // if (!(selectedNode?.id)) {
            const node = firstPolicy(treeDate);
            // if (node) {
                changePolicyTree(node);
            // }
        // }
    }, [treeDate])

    // 高亮显示第一个主题节点
    function firstPolicy (list) {
        for (const item of list) {
            if (String(item.policyThemeType) === "1") {
                return item;
            } else if (item.children?.length > 0) {
                return firstPolicy(item.children);
            }
        }
        return undefined;
    }

    // 取消右键选中
    function cancelActiveNode () {
        setActiveNode(null);
        setMenuIsOpen(false);
        const domList = document.getElementsByClassName("activeNodeStatus");
        for (let domListElement of domList) {
            domListElement.className = domListElement.className.replace(/\s+activeNodeStatus/g, "");
        }
    }

    const titleRender = (nodeData) => {
        const domId = `nodeId-${nodeData.id}`;
        return (
            <span id={domId} style={{whiteSpace: "pre-wrap", wordBreak: "break-all",}}>{nodeData.policyName}</span>
        );
    }

    const onSelect = (selectedKeys, e) => {
        const node = e.node;
        // if (String(node?.policyThemeType) === "1") {
            if (type === "view") {
                if (e.selected) {
                    changePolicyTree(e.node)
                }
            } else {
                changePolicyTree(e.selected ? e.node : null)
            }
        // }
    }

    const onRightMenuFunc = ({event, node}) => {
        if (type !== "view") {
            const domList = document.getElementsByClassName("activeNodeStatus");
            for (let domListElement of domList) {
                domListElement.className = domListElement.className.replace(/\s+activeNodeStatus/g, "");
            }
            const domId = `nodeId-${node.id}`;
            let targetDom = document.getElementById(domId);
            if (targetDom) {
                let dom = targetDom.parentNode.parentNode;
                if (dom) {
                    dom.className += " activeNodeStatus";
                    setActiveNode(node);
                    setMenuStyle({
                        width: dom.clientWidth,
                        height: dom.clientHeight,
                        top: dom.offsetTop,
                        left: dom.offsetLeft - 8,
                    });
                    setMenuIsOpen(true);
                }
            }
        }
    }

    const menuClick = (key, activeNode) => {
        setMenuIsOpen(false);
        menuClickFunc(key, activeNode);
    }

    const onExpand = (expandedKeys, e) => {
        console.log(expandedKeys, e,"expandedKeys, e")
        let id = e.node.id;
        if(e.expanded){
            let _expandedKeys = [...expandedKeys,id]
            setExpandedKeys(_expandedKeys);
        }else{
             let _expandedKeys = expandedKeys.filter((item) => {
                return item != id
            });
            console.log(_expandedKeys,"_expandedKeys")
            setExpandedKeys(_expandedKeys)
        }
    }

    const {policyThemeType, id: activeId, children} = activeNode || {};
    const isTheme = String(policyThemeType) === "1";
    const isHaveChildren = children?.length > 0;
    let firstChildPolicyThemeType = isHaveChildren ? String(children[0].policyThemeType) : undefined;

    const selectedKeys = selectedNode?[selectedNode.id]:[];
    
      console.log(selectedKeys,selectedNode,"selectedKeys")
    return(
        <div className="policyTreeFrame">
            {
                treeDate && treeDate.length > 0 ?
                    (
                        <Tree
                            showIcon={true}
                            icon={(item) => {
                                const isTheme = String(item.policyThemeType) === "1";
                                return isTheme ? <FileTextOutlined /> : (item.expanded ? <FolderOpenOutlined /> : <FolderOutlined />);
                            }}
                            switcherIcon={<CaretDownOutlined />}
                            treeData={treeDate}
                            onSelect={onSelect}
                            onRightClick={onRightMenuFunc}
                            titleRender={titleRender}
                            selectedKeys={selectedKeys}
                            expandedKeys={[...expandedKeys]}
                            // defaultExpandAll={true}
                            // autoExpandParent={true}
                            onExpand={onExpand}
                            blockNode
                            // draggable={draggable}
                            // onDrop={onDrop}
                            fieldNames={{
                                title:"policyName",
                                key: "id",
                                children:"children",
                                isLeaf:"policyThemeType",
                            }}
                        />
                    )
                    :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }

            <Tooltip
                disabled={type === "view"}
                placement={"right"}
                open={menuIsOpen}
                // getPopupContainer={() => document.getElementById(domId)}
                key={activeId}
                overlayClassName="menuToolTipBox"
                trigger={"contextMenu"}
                title={(
                    <div
                        className="menuBox"
                        onClick={(e) => e?.stopPropagation()}
                    >
                        {
                            menuList.map(item => {
                                const {text, icon, key} = item;
                                let content = (
                                    <div
                                        className="menuItem"
                                        key={key}
                                        onClick={() => menuClick(key, activeNode)}
                                    >
                                        <Iconfont type={icon}></Iconfont>
                                        <span className="menuText">{text}</span>
                                    </div>
                                );
                                switch (key) {
                                    case "delete":
                                        // if ((activeId === selectedNode?.id) || (!isTheme && isHaveChildren)){
                                        //     content = null;
                                        // } else {
                                            content = (
                                                // <Popconfirm
                                                //     placement={"right"}
                                                //     title={`你确定要删除该${isTheme ? "主题" : "类型"}吗？`}
                                                //     overlayClassName={"deletePupConfirmBox"}
                                                //     onConfirm={() => menuClick(key, activeNode)}
                                                // >
                                                    <div
                                                        className="menuItem"
                                                        key={key}
                                                        onClick={() => menuClick(key,activeNode)}
                                                    >
                                                        <Iconfont type={icon}></Iconfont>
                                                        <span className="menuText">{text}</span>
                                                    </div>
                                                // {/* </Popconfirm> */}
                                            )
                                        // }
                                        break;
                                    case "addSort":
                                        if (isTheme || (isHaveChildren && firstChildPolicyThemeType === "1")) {// 当前节点是主题节点 或者 子节点是主题节点，就不能添加分类节点
                                            content = null;
                                        }
                                        break;
                                    case "addTheme":
                                        if (isTheme || (isHaveChildren && firstChildPolicyThemeType === "0")) { // 当前节点是主题节点 或者 子节点是分类节点，就不能添加主题节点
                                            content = null;
                                        }
                                        break;
                                    case "edit":
                                        break;
                                }

                                return content;
                            })
                        }
                    </div>
                )}
            >
                <div style={menuStyle} className="menuRootNode"></div>
            </Tooltip>
        </div>
    )
}

export default PolicyTree;
