import React, { useState, useEffect } from "react";
import { DatePicker, Select, Col, Row, Typography, Empty, Spin, message} from "antd";
import { useHistory } from "react-router-dom";
import moment from 'moment'

import Api from "../../../api";
import {approvalNotice} from "../../../utils/defaultValue";
import ProcessStatus from "../../../components/ProcessStatus";
import {KnowledgeApply, gethalfMonthDate, downloadFile, jumpToPage} from '../../../utils/tool'
import NoneData from "../../../components/NoneData";
import SelfPagination from "../../../components/SelfPagination";

import "./index.scss";


const { RangePicker } = DatePicker;
const { Text, Link } = Typography;

const ApprovalNotice = () => {
  const history = useHistory();
  // 查询数据
  const [searchData, setSearchData] = useState({
    applyStatus: "",
    startTime: gethalfMonthDate()[0],
    endTime: gethalfMonthDate()[1],
    pageIndex: 1,
    pageSize: 10,
  });

  const [dataList, setDataList] = useState([]);

  // 分页
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 0,
    total: 0,
  });

  const [spinningSate, setSpinningSate] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);

  const [processStatusID, setProcessStatusID] = useState("");
  const [flowType, setFlowType] = useState("");

    useEffect(() => {
        if(searchData.startTime && searchData.endTime){
            getList();
        }
    }, [searchData]);

    const getList = async () => {
        setSpinningSate(true);

        try {
            const res = await Api.Notice.getApplyPage({ data: searchData });
            setSpinningSate(false);
            if (res && res.code === 0 && res.data) {
                setDataList(res.data.records);
                setPagination({
                    pageIndex: res.data.current,
                    pageSize: res.data.size,
                    total: res.data.total,
                });
            }else{
                setDataList([]);
            }
            // 更新未读消息数量
            document.dispatchEvent(new CustomEvent("notify"));
        } catch (errInfo) {
            setDataList([]);
            setSpinningSate(false);
            console.log("验证失败:", errInfo);
        }
    };

    const dateChange = (date, dateString) => {
        let newSearchData = { ...searchData };
        newSearchData.startTime = dateString[0];
        newSearchData.endTime = dateString[1];
        setSearchData(newSearchData);
    };

    const selectChange = (value) => {
        let newSearchData = { ...searchData };
        newSearchData.applyStatus = value;
        setSearchData(newSearchData);
    };

    // // 下载文件
    const onDownload = (item) => {
        console.log(item,"itemitemitemitem")
        let fileType = [
            'docx',
            'docm',
            'doc',
            'xlsx',
            'xls',
            'xlsm',
            'online'
        ]
        if(fileType.includes(item.fileType)){
            // word excel 不能按分页下载 只能下载全部
            downloadFile({
                fileName:item.knowledgeName,
                path:item.knowLedgeUrl
            })

        } else {
            // 申请已通过，弹出下载选择弹窗
            jumpToPage({...item, type: "", id: undefined,}, "downloadFile")
        }
    }

    const onSizeChange = (page, pageSize) => {
        let _searchData = { ...searchData };
        _searchData.pageIndex = page;
        _searchData.pageSize = pageSize;
        setSearchData(_searchData);
    };

    const goKnowleageDetailes = async(item)=>{
        let knowledgeId = item.knowledgeId;
        // 待审批进入个人中心的详情查看页
        // actualState -1已被删除 0已拒绝，1已通过，2待审批，3草稿文章    ------ 该知识当前实际状态
         // 当前知识处于草稿箱，待审批，或是已被拒绝的状态，查看进入个人中心查看
        if(item.applyStatus === 2 || item.actualState === 3 || item.actualState === 2 || item.actualState === 0){
            let encodedObject = encodeURIComponent(knowledgeId);
            let url = "";
            if (item.fileType === "online") {
                url = `/page/onLineWrite?knowledgeId=${encodedObject}&type=view`;
            } else {
                url = `/page/newKnowledge?knowledgeId=${encodedObject}&type=view`;
            }
            window.open(url);
        }else{
            jumpToPage({...item, id: undefined, type: ""});
        }
    }

    const onEditNow = (item) => {
        if (item.fileType === "online") {
            window.open(`/page/onLineWrite?knowledgeId=${item.knowledgeId}`)
        } else {
            window.open(`/page/newKnowledge?knowledgeId=${item.knowledgeId}`)
        }
    }

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };

    const viewStatus = (items) => {
        setFlowType(items.flowType)
        setProcessStatusID(items.id)
    }

    const checkStatus = async(item) => {
        let res = await Api.Message.checkMessageStatus({
            data: {
                id:item.knowledgeId,
                applyId:item.id
            },
        });

        if(res.code === 0){
            let _code = res.data;
            // -1已被删除 0已拒绝，1已通过，2待审批，3草稿文章  ------ 该知识当前实际状态
            if(_code === -1){
                message.info("当前知识已被删除");
            }
            //  applyForType 1下载申请,2发布知识申请,3取消发布知识申请,4阅读权限申请，5 更新知识
            //  applyStatus  0拒绝，1同意，2待审批

            else if(item.applyForType === 2 || item.applyForType === 5 || (item.applyForType === 4 && _code === 1)){
                // 查看-
                // 1.自己发布的
                // 2.阅读申请已通过的并且当前知识处于发布中状态
                goKnowleageDetailes({
                    ...item,
                    actualState:_code
                })
            }
            else if(item.applyForType === 3 && item.myselfApply &&  _code === 3){
                // 编辑
                // 取消发布知识，并且是发布人，并且当前知识在草稿箱
                onEditNow({
                    ...item,
                    actualState:_code
                })
            }else if(item.applyForType === 1 && _code === 1){
                // 下载，查讯当前知识是否是已发布状态
                // 下载
                onDownload(item)
            }else {
                message.info("当前知识已取消发布");
            }

        }
    }

    const getOperate = (items) => {
        {/* applyForType 1下载申请,2发布知识申请,3取消发布知识申请,4阅读权限申请 */}
        {/* applyStatus  0拒绝，1同意，2待审批 */}
        // myselfApply 是否是本人操作
        // actualState -1已被删除 0已拒绝，1已通过，2待审批，3草稿文章    ------ 该知识当前实际状态
        let content = "";
        if(items.actualState === -1){
            content = <Text>知识已被删除</Text>
        }else if(items.applyStatus === 1){
            content =

            // 下载申请-并且申请已通过
            (items.applyForType === 1 && items.applyStatus === 1) ?
            <Link onClick={() => checkStatus(items,"download")}>立即下载</Link> :

            // 1.自己发布的
            // 2.阅读申请已通过的
            (items.applyForType === 2 || (items.applyForType === 4 && items.applyStatus === 1)) ?
            <Link onClick={() => checkStatus(items,"view")}>立即查看</Link> :

            // 取消发布知识，并且是发布人，并且当前申请已通过或者当前知识在草稿箱
            (items.applyForType === 3 && items.myselfApply && (items.applyStatus === 1 || items.actualState === 3)) ?
                <Link onClick={() => checkStatus(items,"edit")}>立即编辑</Link>
            : "";

        }else{
            content = <Link onClick={() => viewStatus(items)}>
                {items.applyStatus === 0 || items.actualState === 0 ? "查看原因":"查看状态"}
            </Link>
        }

        return content
    }

    return (
        <div className="approvalNoticeFrame">
            <div className="operation">
                最近申请
                <RangePicker
                    disabledDate={disabledDate}
                    value={[
                        searchData.startTime?moment(searchData.startTime):"",
                        searchData.endTime?moment(searchData.endTime):""
                    ]}
                    onChange={dateChange}
                    style={{marginLeft:20}}
                />
                <Select
                    value={searchData.applyStatus}
                    style={{
                        width: 120,
                        marginLeft:'0.5rem'
                    }}
                    onChange={selectChange}
                    options={approvalNotice}
                />
            </div>
            <div className="content">
                {
                    (!searchData.startTime&&!searchData.endTime&&
                    dataList&&dataList.length<=0)&&(<NoneData text={"请选择时间后查询数据"} icon={"icon-zanwushuju"}/>)
                }
                {
                    (searchData.startTime&&searchData.endTime&&
                    dataList&&dataList.length<=0)&&(<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)
                }

                <Spin spinning={spinningSate}>


                {dataList&&dataList.map(item=>{
                    return <div className="listItem" key={item.date}>
                        <p className="date">{item.date}</p>
                        {
                            item.applyManagements&&item.applyManagements.map(items=>{
                                return(
                                    <div key={items.id}>
                                        {/* 1下载申请,2发布知识申请,3取消发布知识申请,4阅读权限申请 */}
                                        <p className="time">{items.createTime}&nbsp;&nbsp;
                                            {items.applyForType === 1 ?
                                            <>申请下载<Link>{items.knowledgeTypeName}</Link>中的文件：</>
                                            :items.applyForType === 4 ?
                                            <>申请查看<Link>{items.knowledgeDepartmentName}</Link>的文件：</>
                                            :items.applyForType === 2 ? '申请发布文件':items.applyForType === 3 ?"申请取消发布"
                                            :items.applyForType === 5 ?"申请更新文件":null}
                                        </p>
                                        <div className="fileList">
                                            <Row>
                                                <Col span={20}>
                                                {/* 申请查看的时候，只有通过了，并且没有被删除 才能跳转详情 */}
                                                {/* applyForType 1下载申请,2发布知识申请,3取消发布知识申请,4阅读权限申请*/}
                                                {/* actualState -1已被删除 0已拒绝，1已通过，2待审批，3草稿文章    ------ 该知识当前实际状态 */}
                                                    <span
                                                        className={(items.applyForType != 4 || (items.applyForType === 4 && items.applyStatus === 1)) && items.actualState != -1 ?'colorBlue':""}
                                                        style={items.actualState === -1?{
                                                            textDecoration:'line-through'
                                                        }:{}}
                                                        onClick={()=>{
                                                            if((items.applyForType != 4 || (items.applyForType === 4 && items.applyStatus === 1)) && items.actualState != -1){
                                                                goKnowleageDetailes(items)
                                                            }
                                                        }}
                                                    >《{items.knowledgeName}》</span>
                                                </Col>
                                                <Col span={2}>
                                                    {items.applyStatus === 1 ?
                                                    <Text type="success">已通过</Text> :
                                                    items.applyStatus === 0 ?
                                                    <Text type="danger">已拒绝</Text> :
                                                    items.applyStatus === 2 ?
                                                    <Link>待审批</Link> : ''}
                                                </Col>
                                                {/* applyForType 1下载申请,2发布知识申请,3取消发布知识申请,4阅读权限申请 */}
                                                {/* applyStatus  0拒绝，1同意，2待审批 */}

                                                {/* {items.applyForType === 1 ?  */}

                                                {/* {items.applyForType === 1 ?  */}
                                                    <Col span={2}>
                                                        {getOperate(items)}
                                                        {/* {items.applyStatus === 1 ?
                                                        <Link onClick={() => onDownload(items)}>立即下载</Link> :
                                                        items.applyStatus === 0 ?
                                                        <Link onClick={() => viewStatus(items)}>查看原因</Link> :
                                                        <Link onClick={() => viewStatus(items)}>查看状态</Link>} */}
                                                    </Col>
                                                {/* :items.applyForType != 1?
                                                <Col span={2}>
                                                        {items.applyStatus === 1 ?
                                                        <Link onClick={() => goKnowleageDetailes(items)}>立即查看</Link> :
                                                        items.applyStatus === 0 ?
                                                        <Link onClick={() => viewStatus(items)}>查看原因</Link> :
                                                        <Link onClick={() => viewStatus(items)}>查看状态</Link>}
                                                </Col>:""} */}
                                            </Row>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                })}
                </Spin>
            </div>
            {(dataList&&dataList.length>0)?(
            <div className="footer">
                <SelfPagination
                    total={pagination.total}
                    current={pagination.pageIndex}
                    onSizeChange={onSizeChange}
                />
            </div>
            ):""}

            {processStatusID?(<ProcessStatus
                id={processStatusID}
                flowType={flowType}
                onCancelBack={()=>{
                    setProcessStatusID("")
                }}
            />):""}



        </div>
    );
  };
export default ApprovalNotice;
