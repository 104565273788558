import React, { useEffect, useState, useRef, useContext } from "react";
import { Empty, Spin, Progress } from "antd";
import Iconfont from "../../../../components/Iconfont/Iconfont";
import Api from "../../../../api";
import {useHistory} from "react-router-dom";

import "./index.scss";
import propsContext from "../../layout/context";

const AllNumView = () => {
  const progressBarRef = useRef(null);
  const [dataShow, setDataShow] = useState([]);
  const [isSpinning, setIsSpinning] = useState(true);
  const [scoreList, setScoreList] = useState([]);
  const [scoreAllNum, setScoreAllNum] = useState({});
  const setCurrentPath = useContext(propsContext);

  const history = useHistory()

  useEffect(() => {
    getList();
    getScoreList();
  }, []);
  useEffect(() => {
    if (scoreList) {
      setTimeout(() => {
        scoreList.map((item) => {
          setProgress(
            item.scoreValue,
            item.scoreName === "贡献积分"
              ? ".contributeProgress"
              : ".studyProgress"
          );
        });
      }, 1000);
    }
  }, [scoreList]);
  const getList = async () => {
    let res = await Api.PersonalCenter.getShowData({});
    setDataShow(res.data);
    setIsSpinning(false);
  };
  const getScoreList = async () => {
    try {
      let res = await Api.PersonalCenter.getUserScore({});
      if (res.data) {
        setScoreAllNum(res.data[0]);
        setScoreList(res.data.splice(1, 3));
      }
    } catch (error) {}
  };
  const setProgress = (value, className) => {
    let percent = (value / scoreAllNum.scoreValue) * 100;
    var progressBar = document.querySelector(className);
    // console.log(progressBar, "progressBar");
    if (progressBar) {
      progressBar.style.height = percent + "%";
    }
  };

  const toOtherPage = (id) => {
    const pagePath = {
      1: "/personalCenter/myRead",
      2: "/personalCenter/myNote/noteManage",
      3: "/personalCenter/myStar",
      4: "/personalCenter/myUpload/published",
    };
    if (pagePath[id]) {
      history.replace(pagePath[id]);
      setCurrentPath(Math.random())
    }
  }

  return (
    <div className="allNumViewFrame">
      <div className="title">数据总览</div>
      <div className="dataShow">
        {isSpinning ? (
          <Spin />
        ) : (
          <>
            <div className="firstNum">
              <div className="numInfo">
                {scoreAllNum.scoreName} <span>{scoreAllNum.scoreValue}</span>
              </div>
              <div className="progressBox">
                {scoreList &&
                  scoreList.map((item, index) => {
                    return (
                      <div key={index} className="progressItem">
                        <div className="scoreValue">{item.scoreValue}</div>
                        <div className="scoreName">{item.scoreName}</div>
                        <Progress
                          percent={
                            (item.scoreValue / scoreAllNum.scoreValue) * 100
                          }
                          size="small"
                          showInfo={false}
                          strokeColor={index == 0
                              ? "#f83d25"
                              : index == 1
                              ? "#259ff8"
                              : "#6cc41f"
                          }
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
            {dataShow.length > 0 ? (
              dataShow.map((item, index) => {
                return (
                  <div key={item.id} className="numBox" onClick={() => toOtherPage(item.id)}>
                    <div>
                      <span className="messageNumber">
                        {item.messageNumber}
                      </span>
                      {/* <span>{item.dataTypeUnit}</span> */}
                    </div>
                    <div className="userPersonalDataType">
                      <Iconfont
                        type={
                          index == 0
                            ? "icon-shuben"
                            : index == 1
                            ? "icon-bijiben"
                            : index == 2
                            ? "icon-shoucang2"
                            : "icon-fenxiang"
                        }
                        iStyle={{marginRight:"0.3rem",marginTop:index == 0?"0.2rem":index==1?"0.1rem":"0"}}
                      ></Iconfont>
                      <span>{item.dataTypeName}</span>
                    </div>
                  </div>
                );
              })
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AllNumView;
