import myAxios from "../utils/request.js";

export default {
  // 根据翻译类型（0文本翻译 1ocr文字识别） 获取可用的 app_type file_id file_url
  async getTranslateInfo(params = {}, cb) {
    let res = await myAxios.getJson("/translateInfo/getTranslateInfo", params);
    return res.data;
  },
  //
  // 禁用-启用
  async enableById(params = {}, cb) {
    let res = await myAxios.getJson("/translateInfo/enableById", params);
    return res.data;
  },
  // 修改
  async updateType(params = {}, cb) {
    let res = await myAxios.postJson("/translateInfo/update", params);
    return res.data;
  },
  // 大AI模型信息
  async getAISettings(params = {}, cb) {
    let res = await myAxios.getJson("/aiModel/getAiModel", params);
    return res.data;
  },
  // 大AI模型 禁用-启用
  async AIEnableById(params = {}, cb) {
    let res = await myAxios.getJson("/aiModel/enableById", params);
    return res.data;
  },
   // 大AI模型 修改
   async AIUpdateType(params = {}, cb) {
    let res = await myAxios.postJson("/aiModel/update", params);
    return res.data;
  },
   // 模块功能
   async getModuleFunction(params = {}, cb) {
    let res = await myAxios.getJson("/moduleFunction/getModuleFunction", params);
    return res.data;
  },
   // 
   async moduleEnableById(params = {}, cb) {
    let res = await myAxios.getJson("/moduleFunction/enableById", params);
    return res.data;
  },
  async moduleUpdate(params = {}, cb) {
    let res = await myAxios.postJson("/moduleFunction/update", params);
    return res.data;
  },
};
