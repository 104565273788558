import React,{useState, useEffect} from 'react';
import { Table, DatePicker, Button, Space } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment'

import SelfTable from "../../../../components/SelfTable/index"

import './index.scss'
import Api from "../../../../api";
import {downloadFileBlob, get7DaysDate} from "../../../../utils/tool";

const { RangePicker } = DatePicker;

const DepartmentalContribution = () => {

    const defalRange = get7DaysDate();

    // 查询数据
    const [searchData, setSearchData] = useState(defalRange);

    const [tableLoading, setTableLoading] = useState(false);

    const [listData, setListData] = useState([]);

    const [columns, setColumns] = useState([]);
    const [department, setDepartment] = useState([]);
    const [operationData, setOperationData] = useState(defalRange);

    useEffect(()=>{
        setInitDate();
    },[])

    useEffect(()=>{
        if(department && department.length>0){
            getList();
        }
    },[JSON.stringify(searchData),department])

    const setInitDate = async() => {
        const resDepartment = await Api.Department.getDepartmentListAll({});
        if(resDepartment && resDepartment.code === 0){
            let newData = resDepartment.data.map(item=>{
                return{
                    value: item.id,
                    label: item.name,
                    text: item.name,
                }
            });
            newData.push({
                value: '',
                label: '无',
            })
            console.log(newData,"newDatanewDatanewData")
            setDepartment(newData);
        }
    }

    const getList = async() => {
        setTableLoading(true);
        const res = await Api.Statistics.getDepartmentStatisticsList({data:searchData});
        setTableLoading(false);
        if(res&&res.code === 0&&res.data){
            let _data = res.data,newData=[],columns=[];
            for(let i = 0; i<_data.length; i++){
                let list = _data[i].departmentContributionSonVOList;
                let items={}
                items['departmentId'] = _data[i].departmentName;
                if(i === 0){
                    columns.push({
                        title: '部门名称',
                        dataIndex: 'departmentId',
                        filters: [...department.filter((val, i) => {
                            return val.value !== ''
                        })],
                        filterMultiple:false,
                        width:"230px",
                        filteredValue:searchData.departmentId ? [searchData.departmentId] : [],
                    })
                }
                for(let j = 0; j<list.length; j++){
                    items[list[j].knowledgeTypeId] = list[j].contributionNum;
                    if(i === 0){
                        columns.push({
                            title: list[j].knowledgeTypeName,
                            dataIndex: list[j].knowledgeTypeId,
                            width: '100px',
                        })
                    }
                }
                newData.push(items)
            }
            setColumns(columns)
            setListData(newData);
        }else{
            setListData([]);
        }
    }

    const dateChange = (date, dateString) => {
        let newSearchData = {...searchData}
        newSearchData.startTime = dateString[0];
        newSearchData.endTime = dateString[1];
        setSearchData(newSearchData);
    }

    const exportFile = async() => {
        try{
            const res = await Api.Statistics.getDepartmentStatisticsExport({data:searchData});
            if(res){
                downloadFileBlob(res,'部门知识贡献概览统计明细.xlsx')
            }
        }catch{

        }
    }

    const tableChanges = (pagination, filters) => {
        let newSearchData = {...searchData}
        for (let key in filters) {
            newSearchData[key] = filters[key] ? filters[key][0] : ''
        }
        setSearchData(newSearchData);
    }

    const searchChange = (value, type) => {
        let newOperationData = {...operationData};
        newOperationData.startTime = value[0];
        newOperationData.endTime = value[1];
        // newOperationData.pageIndex = 1;
        setOperationData(newOperationData)
    }

    const onSearch = () => {
        setSearchData({
            ...searchData,
            ...operationData
        });
    }

    const onResetting = () => {
        setOperationData(defalRange);
        setSearchData(defalRange)
    }

    const disabledDate = (current) => {
        // Can not select days after today and today
        return current && current > moment().endOf('day');
    };

    return(
        <div className="departmentalContributionFrame">
            <div className="title">部门知识贡献概览</div>
            <div className="operation">
                {/* 选择时段：<RangePicker onChange={dateChange}/> */}
                <Space size={"large"}>
                    <div className="operationItem">
                        选择时段：
                        <RangePicker 
                            ranges={{
                                '当天': [moment(), moment()],
                                '近7天':[moment().subtract(7, 'day'), moment()],
                                '本月': [moment().startOf('month'), moment()],
                                '近三个月': [moment().subtract(3, 'months'), moment()],
                            }}
                            disabledDate={disabledDate}
                            value={operationData.startTime?[moment(operationData.startTime), moment(operationData.endTime)]:[]}
                            onChange={(date, dateString)=>{
                                searchChange(dateString,"timeRange")
                            }}
                        />
                    </div>
                    <Button type="primary" onClick={onSearch}>搜索</Button>
                    <Button onClick={onResetting}>重置</Button>
                </Space>

                <div className="operationRight">
                    <Space size={"large"}>
                        <Button onClick={exportFile} icon={<DownloadOutlined />}>导出</Button>
                    </Space>
                </div>
            </div>
            <div className="table">
                <SelfTable
                    dataSource={listData}
                    columns={columns}
                    pagination={false}
                    loading={tableLoading}
                    onChange={tableChanges} 
                    scroll={
                        {
                            x:"1600px"
                        }
                    }
                />
            </div>
        </div>
    )
}
export default DepartmentalContribution