import React, {useState} from "react";
import {
    PlusSquareOutlined,
    StarFilled,
    MinusSquareOutlined,
    // FilePptOutlined,
    // FilePdfOutlined,
    // FileExcelOutlined
} from "@ant-design/icons";
import {Progress, Badge, message, Popconfirm, Tooltip,} from "antd";
import {useHistory} from "react-router-dom";
import { useDispatch } from "react-redux";

import { setAIModalState, refreshAIcarNumF } from "../../../../features/AIIntelligent/AIReducer";
import Iconfont from "../../../../components/Iconfont/Iconfont";
import KnowledgeFileIcon from "../../../../components/KnowledgeFileIcon"
import ProcessStatus from "../../../../components/ProcessStatus";
import ViewImg from "../../../../components/ViewImg";
import Api from '../../../../api'
import "./index.scss";
import {jumpToPage, readByAI, addAICart} from "../../../../utils/tool";

const KnowledgeItem = ({list, type, showModal}) => {
    // list为传递的数据   type为页面类型，用于判断右下角操作按钮有哪些
    const history = useHistory();
    const dispatch = useDispatch();
    
    const [processStatusID, setProcessStatusID] = useState("");
    const [flowType, setFlowType] = useState("");

    const [openZipKey, setOpenZipKey] = useState([]);
    
     // 图片预览列表
    const [viewImgList, setViewImgList] = useState([]);
    const [isShowViewImg, setIsShowViewImg] = useState(false);
    const [viewImgIdx, setViewImgIdx] = useState(0);

    const toKnowledgeDetail = async (item) => {
        if (type === "read") {
            let res = await Api.Message.checkMessageStatus({
                data: {
                    id: item.knowledgeId,
                },
            });
            if (res.code === 0) {
                let _code = res.data;
                // -1已被删除 0已拒绝，1已通过，2待审批，3草稿文章
                if (_code === -1) {
                    message.info("当前知识已被删除");
                } else if (_code === 1) {
                    jumpToPage({...item, id: undefined, type: ""})
                } else {
                    message.info("当前知识已取消发布");
                }
            }
        } else {
            jumpToPage({...item, id: undefined, type: ""})
        }
    };

    const toKnowledgeEditView = (knowledgeId, fileType) => {
        let encodedObject = encodeURIComponent(knowledgeId);
        let url;
        if (fileType === "online") {
            url = `/page/onLineWrite?knowledgeId=${encodedObject}&type=view`;
        } else {
            url = `/page/newKnowledge?knowledgeId=${encodedObject}&type=view`;
        }
        window.open(url);
    };

    // 跳转知识中心
    const toKnowledgeType = (id) => {
        history.push(`/page/knowledgeContent?type=knowledge&id=${id}`);
    };

    const viewStatus = (items) => {
        setFlowType(items.flowType)
        setProcessStatusID(items.applyId)
    }

    const titleRender = (type, item) => {
        let props = {};
        const {knowledgeName, knowledgeId, fileType} = item;
        switch (type) {
            case "audit":
                props = {
                    className: "pointer",
                    onClick: () => toKnowledgeEditView(knowledgeId, fileType),
                }
                break;
            case "draft":
                props = {};
                break;
            default:
                props = {
                    className: "pointer",
                    onClick: () => toKnowledgeDetail(item),
                }
                break;
        }
        return (<span {...props}>{knowledgeName}</span>);
    }
    const rightTextRender = (type, item) => {
        let text = {};
        switch (type) {
            case "read":
                text = `上次阅读 ${item.lastReadTime}`;
                break;
            case "star":
                text = `收藏于 ${item.collectionTime}`;
                break;
            case "published":
                text = `发布于 ${item.knowledgePublishTime}`;
                break;
            case "audit":
                text = `提交于 ${item.createTime}`;
                break;
            default:
                text = `编辑于 ${item.updateTime}`;
                break;
        }
        return (<span className={"time"}>{text}</span>);
    }

    // 添加知识到AI购物车
    const addKnowledgeToAICart = async (knowledgeIds) => {
        const res = await addAICart(knowledgeIds);
        if(res){
            list.map(item=>{
                let knowledgeId = item.id || item.knowledgeId || item.policyId || item.elasticId;
                if(knowledgeIds.includes(knowledgeId)){
                    item.addCartStatus = 0;
                }
            })
            dispatch(refreshAIcarNumF(Date.now().toString()));
            // getSpaceLKist();
        }
    }

    const operateRender = (type, item, idx, len) => {
        const {
            knowledgeReadSchdule, id, knowledgeId, readNum,
            knowledgeCheckStatus, knowledgeTypeName, fileType,
        } = item;
        let operate;
        switch (type) {
            case "read":
                operate = (<Progress percent={knowledgeReadSchdule} size="small"/>);
                break;
            case "star":
                operate = (
                    <div
                        className="star pointer"
                        onClick={() => showModal(id)}
                    >
                        <span>取消收藏</span>
                        <StarFilled style={{color: "gold", fontSize: "1rem"}}/>
                    </div>
                );
                break;
            case "published":
                operate = (
                    <div className="operate">
                        <div>
                            <Iconfont type={"icon-yanjing"} />
                            <span>{readNum}</span>
                        </div>
                        <div
                            className="pointer"
                            onClick={() => showModal(knowledgeId)}
                        >
                            <Iconfont type={"icon-quxiaolianjie"} />
                            <span>取消发布</span>
                        </div>
                    </div>
                )
                break;
            case "audit":
                if (String(knowledgeCheckStatus) === "2") {
                    operate = (
                        <div
                            className="state1"
                            onClick={() => viewStatus(item)}
                        ><Badge color="#666" /> 待审核</div>
                    )
                } else {
                    operate = (
                        <div className="operate3">
                            <div
                                className="pointer span"
                                onClick={() =>
                                    showModal({
                                        item,
                                        KnowledgeType: type,
                                        type: "delete",
                                    })
                                }
                            >
                                <Iconfont type={"icon-shanchu"} />
                                <span>删除</span>
                            </div>
                            <div
                                className="pointer span"
                                onClick={() =>
                                    showModal({
                                        item,
                                        KnowledgeType: type,
                                        type: "edit",
                                    })
                                }
                            >
                                <Iconfont type={"icon-bianji"} />
                                <span>编辑</span>
                            </div>
                            <span
                                className="state2"
                                onClick={() => viewStatus(item)}
                            ><Badge color="rgb(198, 4, 4)"/> 未通过</span>
                        </div>
                    )
                }
                break;
            case "draft":
                const publishId = `publish-${id || knowledgeId}`;
                const popConfirmProps = {
                    placement: idx > 0 ? (idx < (len - 1) ? "left" : "topLeft") : "leftBottom",
                    okText: "确认",
                    cancelText: "取消",
                    overlayClassName: "deletePupConfirmBox",
                }
                operate = (
                    <div className="operate3">
                        <Popconfirm
                            {...popConfirmProps}
                            title={"确认删除这条知识吗？"}
                            onConfirm={() => {
                                showModal({
                                    item,
                                    KnowledgeType: type,
                                    type: "delete",
                                })
                            }}
                            getPopupContainer={() => document.getElementById(publishId+'delete')}
                        >
                            <div className="pointer span" id={publishId+'delete'}>
                                <Iconfont type={"icon-shanchu"} />
                                <span>删除</span>
                            </div>
                        </Popconfirm>

                        <Popconfirm
                            {...popConfirmProps}
                            title={"确认对此条知识进行修改吗？"}
                            onConfirm={() => {
                                showModal({
                                    item,
                                    KnowledgeType: type,
                                    type: "edit",
                                })
                            }}
                            getPopupContainer={() => document.getElementById(publishId+'edit')}
                        >
                            <div className="pointer span"  id={publishId+'edit'}>
                                <Iconfont type={"icon-bianji4"} />
                                <span>编辑</span>
                            </div>
                        </Popconfirm>

                        <Popconfirm
                            disabled={!knowledgeTypeName}
                            {...popConfirmProps}
                            title={(
                                <div className="publishPopConfirmContentBox">
                                    <span className="tipText">确认发布这条知识吗？</span>
                                    {/*{*/}
                                    {/*    fileType === "zip" ? (*/}
                                    {/*        <div className="operateBox" id={publishId}>*/}
                                    {/*            <span className="labelText">是否解压：</span>*/}
                                    {/*            <Switch*/}
                                    {/*                defaultChecked={false}*/}
                                    {/*                unCheckedChildren={"不解压"}*/}
                                    {/*                checkedChildren={"解压"}*/}
                                    {/*                size={"small"}*/}
                                    {/*            />*/}
                                    {/*        </div>*/}
                                    {/*    ) : undefined*/}
                                    {/*}*/}
                                </div>
                            )}
                            onConfirm={() => {
                                let params = {
                                    item,
                                    KnowledgeType: type,
                                    type: "publish",
                                    isDecomparession: false,
                                };
                                if (fileType === "zip") {
                                    const dom = document.getElementById(publishId);
                                    if (dom) {
                                        params.isDecomparession = dom.getElementsByClassName("ant-switch-checked").length > 0;
                                    }
                                }
                                showModal(params);
                            }}
                            getPopupContainer={() => document.getElementById(publishId+'box')}
                        >
                            <div
                                id={publishId+'box'}
                                className="pointer span"
                                style={knowledgeTypeName ? {cursor: "pointer"} : {opacity: 0.5, cursor: "not-allowed"}}
                                onClick={() => {
                                    console.log(item.fileType);

                                    if (!knowledgeTypeName) {
                                        message.warning("补充知识详细信息后再进行发布哦");
                                    }
                                }}
                            >
                                <Iconfont type={"icon-fabu"} />
                                <span>发布</span>
                            </div>
                        </Popconfirm>
                    </div>
                );
                break;
        }
        return operate;
    }

    const openZipList = (item, knowledgeId) => {
        let _openZipKey = [...openZipKey];

        if(_openZipKey.includes(knowledgeId)){
            let newOpenZipKey = _openZipKey.filter((id)=>id != knowledgeId);
            setViewImgList([]);
            setOpenZipKey(newOpenZipKey)
        }else{
            let urlList = [];

            item.childList.map((childItems,index)=>{
                if(childItems.fileUrl){
                    urlList.push({
                        url:childItems.fileUrl,
                        name:childItems.knowledgeName,
                        id:index
                    })
                }
            })

            setViewImgList([...urlList]);

            _openZipKey.push(knowledgeId)
            setOpenZipKey(_openZipKey)
        }
    }

    const getChildrenList = (item) => {
        
        return(
            <div className={`childrenlistBox`}>
                {item.childList.map((childItem,index)=>{

                    if(childItem.fileUrl){
                        childItem.id = index;
                    }

                    return(
                        <div className="childrenlistItemHeader"
                            onClick={()=>{
                                if(childItem.fileUrl){
                                    setViewImgIdx(childItem.id);
                                    setIsShowViewImg(true);
                                }else if(childItem.id){
                                    let _item={
                                        ...item,
                                        id:item.id || item.knowledgeId
                                    }
                                    jumpToPage({..._item},undefined,undefined,childItem)
                                }else{
                                    message.info("当前文件类型不支持")
                                }
                            }}
                        >
                            {/* 文件图标 */}
                            <KnowledgeFileIcon
                                fileType={childItem.fileType}
                                isPic={childItem.isPic}
                            />

                            {/* 标题 */}
                            <div className="listTitle" title={childItem.knowledgeName}>{childItem.knowledgeName}</div>
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <div className="knowledgeLineFrame">
            {
                list?.map((item, idx) => {
                    const {
                        id, knowledgeId, fileType, knowledgeTypeName,
                        knowledgeTypeId, projectName, knowledgeSource,
                        knowledgeUserRelVOList,nameExts
                    } = item;
                    let customId = id || knowledgeId;

                    let userNameList = [];
                    if(knowledgeUserRelVOList){
                        knowledgeUserRelVOList.map(item=>{
                            userNameList.push(item.userName)
                        })
                    }
                    if(nameExts){
                        nameExts.map(item => {
                            userNameList.push(item)
                        })
                    }
                    let userNames = userNameList.join(",");
                    let _knowledgeId = id || knowledgeId;
                    return (
                        <div className="item" key={_knowledgeId}>
                            <div className="firstLine">
                                <div className="fileIcon"><KnowledgeFileIcon fileType={fileType}/></div>

                                <div className="title">
                                    {titleRender(type, item)}

                                    {(item.cartStatus === 0 && knowledgeTypeId != '7') &&<div className="AiBtnBox">
                                        <Tooltip  title="加入购物车"> 
                                            <span
                                                className={`AiBtn ${item.addCartStatus != 1 ? 'shoppingCartBtnAvailable' : 'shoppingCartBtnNotAvailable'}`}
                                                onClick={(e)=>{
                                                    e.stopPropagation();
                                                    if(item.addCartStatus != 1){
                                                        addKnowledgeToAICart([_knowledgeId])
                                                    }
                                                }}
                                            >
                                                <Iconfont type="icon-waixingren" />
                                                <Iconfont type="icon-jiahao" />
                                            </span>
                                        </Tooltip>
                                        
                                        <span className="AiReadBtn" onClick={()=>readByAI(item, history)}>AI帮我读</span>
                                    </div>}
                                    
                                </div>
                                
                                {/* 展开按钮 */}
                                {fileType === 'zip' && item.childList &&
                                    <>{openZipKey.includes(customId)?
                                        <Tooltip  title="收起">
                                            <MinusSquareOutlined
                                                className="openZipIcon"
                                                onClick={(e)=>{
                                                    openZipList(item, customId);
                                                    e.stopPropagation();
                                                }}
                                                style={{marginTop: 4}}
                                            />
                                        </Tooltip>:
                                        <Tooltip  title="查看更多文件">
                                            <PlusSquareOutlined 
                                                className="openZipIcon"
                                                onClick={(e)=>{
                                                    openZipList(item, customId);
                                                    e.stopPropagation();
                                                }}
                                                style={{marginTop: 4}}
                                            />
                                        </Tooltip>
                                    }</>
                                }
                                <div className="itemRightBox">{rightTextRender(type, item)}</div>
                            </div>

                            <div className="secondLine">
                                <div className="itemLeftBox">
                                    <div>
                                        <span>知识类型：</span>
                                        <span
                                            className="value pointer"
                                            onClick={() => toKnowledgeType(knowledgeTypeId)}
                                        >{knowledgeTypeName}</span>
                                    </div>

                                    {
                                        knowledgeTypeName === "经典案例" ? (
                                            <div>
                                                <span>项目：</span>
                                                <span className="value">{projectName}</span>
                                            </div>
                                        ) : null
                                    }

                                    {
                                        knowledgeTypeId === '6' || knowledgeTypeId === '7' ? (
                                            <div>
                                                <span>来源：</span>
                                                <span>{knowledgeSource}</span>
                                            </div>
                                        ) : (
                                            <div style={{
                                                display: 'inline-block',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                maxWidth: '170px'
                                            }} title={userNames}>
                                                <span>作者：{userNames}</span>
                                            </div>
                                        )
                                    }

                                    <div>
                                        <span>创建时间：</span>
                                        <span>{item.createTime}</span>
                                    </div>
                                </div>

                                <div className="itemRightBox">{operateRender(type, item, idx, list.length)}</div>
                            </div>

                            {/* ZIP下的文件列表 */}
                            {openZipKey.includes(customId)&&item.childList&&item.childList.length>0&&<>
                                {getChildrenList(item)}
                            </>}
                        </div>
                    );
                })
            }

            {
                processStatusID ? (
                    <ProcessStatus
                        id={processStatusID}
                        flowType={flowType}
                        onCancelBack={() => {
                            setProcessStatusID("")
                        }}
                    />
                ) : undefined
            }

            <ViewImg
                viewImgList={viewImgList}
                isShowViewImg={isShowViewImg}
                viewImgIdx={viewImgIdx}
                closeViewImg={() => setIsShowViewImg(false)}
            />
        </div>
    );
};

export default KnowledgeItem;
