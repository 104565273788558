import {
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Switch,
  message,
} from "antd";
import React, { useState, useEffect } from "react";

import SelfTable from "../../../../components/SelfTable";
import Api from "../../../../api";
import "./index.scss";

const ModalTable = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [isReqLoading, setIsReqLoading] = useState(true);
  // const [pagination, setPagination] = useState({});
  useEffect(() => {
    getModalList();
  }, []);
  const getModalList = async () => {
    setIsReqLoading(true);
    try {
      let res = await Api.Settings.getModuleFunction();
      // console.log(res, "res");
      setData(res.data);
      setIsReqLoading(false);
    } catch (error) {}
  };
  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `请输入${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record) => record.id === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.id);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (id) => {
    try {
      const row = await form.validateFields();
      let res = await Api.Settings.moduleUpdate({ data: { id, ...row } });
      if (res.code === 0 && res.msg === "执行成功") {
        setEditingKey("");
        getModalList();
      }
    } catch (errInfo) {}
  };
  const moduleEnable = async (id) => {
    try {
      let res = await Api.Settings.moduleEnableById({ data: { id } });
      if (res.data === "操作成功") {
        getModalList();
        message.success(res.data);
      }
    } catch (error) {}
  };
  const columns = [
    {
      title: "功能名称",
      dataIndex: "moduleName",
      // width: "20%",
      editable: true,
    },
    {
      title: "描述",
      dataIndex: "moduleDescription",
      // width: "45%",
      editable: true,
    },
    {
      title: "设置",
      dataIndex: "isEnable",
      // width: "15%",
      editable: false,
      render: (_, record) => (
        <>
          <Switch
            checked={record.isEnable === 0}
            onChange={() => moduleEnable(record.id)}
          />
          <div style={{ marginLeft: "0.3rem", fontSize: "0.8rem" }}>
            {record.isEnable === 0 ? "启用" : "禁用"}
          </div>
        </>
      ),
    },
    {
      title: "操作",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              保存
            </Typography.Link>
            <Popconfirm title="确认取消?" onConfirm={cancel}>
              <a>取消</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            // disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            编辑
          </Typography.Link>
        );
      },
    },
  ];

  return (
    <div className="modalTableFrame">
      <Form form={form} component={false}>
        <SelfTable
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          isEditing={isEditing}
          loading={isReqLoading}
          dataSource={data}
          columns={columns}
          rowClassName="editable-row"
          // pagination={{
          //   onChange: cancel,
          // }}
        />
      </Form>
    </div>
  );
};

export default ModalTable;
