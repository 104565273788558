import { Form, Input, Button, Popconfirm, Radio, Space, Typography, Select, Modal, message, Checkbox } from 'antd';
import React, { useState, useEffect, useRef } from 'react';

import SelfTable from "../../../../components/SelfTable/index"

import Api from "../../../../api";
import {userAdministrator,userAuthority,pagingParam, operationAuthority} from "../../../../utils/defaultValue";
import {ModalConfirm} from '../../../../utils/tool'

import './index.scss'

const RoleList = () => {
    const [form] = Form.useForm();
    const [addForm] = Form.useForm();
    // 审核权限 0一级审核 1二级审核
    const [authority, setAuthority] = useState(userAuthority)
    // 查阅权限
    const [department, setDepartment] = useState([]);
    // 职务
    const [defaultDuties, setDefaultDuties] = useState([]);
    // 系统角色
    const [sysRole, setSysRole] = useState([]);
    const [duties, setDuties] = useState([]);
    // 管理权限 0部门已发布知识管理 1部门政策运维管理 2部门审核管理 3系统管理
    const [administrator, setAdministrator] = useState(userAdministrator);

    // 列表数据
    const [listData, setListData] = useState([]);
    const [defaultDate, setDefaultDate] = useState([]);
    // 当前正在操作的数据KEY
    const [editingKey, setEditingKey] = useState('');

    // 查询数据
    const [searchData, setSearchData] = useState({
        ...pagingParam
    });
    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [addDate, setAddDate] = useState([
        {
            title:"角色名称",
            dataIndex:"name",
            type:""
        },
        {
            title:"默认对象",
            dataIndex:"jobId",
            type:"select"
        },
        {
            title:"系统角色",
            dataIndex:"sysRoleId",
            type:"select"
        },
        {
            title:"审核权限",
            dataIndex:"checkPerm",
            type:"select"
        },
        {
            title: '发布权限',
            dataIndex: 'releasePerm',
            type:"radio"
        },
        {
            title: '取消发布权限',
            dataIndex: 'unreleasePerm',
            type:"radio"
        },
        {
            title: '查阅权限',
            dataIndex: 'readPerm',
            type:"radio"
        },
        {
            title: '下载权限',
            dataIndex: 'downloadPerm',
            type:"radio"
        },
    ]);

    const [confirmLoading, setConfirmLoading] = useState(false);

    // 列表选择数据
    const [selectedRowKey, setSelectedRowKey] = useState('');
    
    const [tableLoading, setTableLoading] = useState(false);

    useEffect(() => {
        setInitDate();
        getJobData();
    }, []);

    useEffect(() => {
        getList();
    }, [searchData]);

    const setInitDate = async() => {
        // 查询所有部门
        const resDepartment = await Api.Department.getDepartmentListAll({});
        if(resDepartment && resDepartment.code === 0){
            let newData = resDepartment.data.map(item=>{
                return{
                    value: item.id,
                    label: item.name,
                }
            });
            newData.unshift({
                value: '0',
                label: '本部门',
            })
            setDepartment(newData);
        }

        const resSysRole = await Api.Roles.getAllSysRolesList({});
        if(resSysRole && resSysRole.code === 0){
            let newData = resSysRole.data.map(item=>{
                return{
                    value: item.id,
                    label: item.name,
                }
            });
            setSysRole(newData);
        }
        
    }

    const getJobData = async() => {
        // 获取未绑定的职务
        const resJob = await Api.Duties.getBoundJob({});
        if(resJob && resJob.code === 0){
            let newData = resJob.data.map(item=>{
                return{
                    value: item.id,
                    label: item.name,
                }
            });
            newData.push({
                value: 9999,
                label: '无',
            })
            setDefaultDuties(newData)
            setDuties(newData);
        }
    }

    // 获取列表数据
    const getList = async() => {
        setTableLoading(true);
        const res = await Api.Roles.getRolesList({data:searchData});
        setTableLoading(false);
        if(res&&res.code === 0){
            let _data = res.data;
            setListData(_data.records);
            setDefaultDate(_data.records);
            setPagination({
                "pageIndex": _data.current,
                "pageSize": _data.size,
                "total":_data.total
            });
            setEditingKey('');
        }else{
            setListData([]);
            setDefaultDate([]);
        }
    }

    const isEditing = (record) => record.id === editingKey;

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        record,
        index,
        children,
        changeType,
        ...restProps
    }) => {
        let options = [], selectValue = "";
        // 下拉选择框数据
        if(changeType === "select"){
            options = dataIndex === 'checkPerm' ? authority :
            dataIndex === 'jobId' ? duties : 
            dataIndex === 'sysRoleId' ? sysRole : [];
        }

        const inputNode = changeType === 'select' ? <Select 
            options={options}
        /> : changeType === 'radio' ? 
        <Radio.Group
            options={operationAuthority}
            defaultValue={selectValue}
        />
        : <Input />;

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: dataIndex === 'manageTypes' ? false : true,
                                message: 
                                changeType === 'select' ? `请选择${title}!`:
                                changeType === 'radio' ? `请勾选${title}!`:
                                `请输入${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    <>{children}</>
                )}
            </td>
        );
    };

    // 修改
    const edit = (record) => {
        // 添加当前修改数据已选择的职务信息
        if(record.jobId && record.jobId != 9999){
            let _duties = [...duties]
            _duties.push({
                value: record.jobId,
                label: record.jobName,
            })
            setDuties(_duties);
        }

        // 回填数据
        form.setFieldsValue({
            ...record,
        });
        setEditingKey(record.id);
    };

    // 取消
    const cancel = () => {
        // 重置未绑定的职务
        setDuties(defaultDuties);
        setListData([...defaultDate])
        setEditingKey('');
    };

    // 修改-保存
    const save = async (key) => {
        try {
            const row = await form.validateFields();
            setTableLoading(true);
            row.jobId = row.jobId === 9999 ? null : row.jobId;
            row.id = key;
            const res = await Api.Roles.updateRoles({data:row});
            if(res&&res.code === 0){
                message.success("修改信息成功",2,()=>{
                    getList();
                    getJobData();
                })
            }else{
                setTableLoading(false);
            }
        } catch (errInfo) {
            setTableLoading(false);
            console.log('验证失败:', errInfo);
        }
    };

    // 新增
    const handleOk = async() => {
        try {
            const row = await addForm.validateFields();
            setConfirmLoading(true);
            row.jobId = row.jobId === 9999 ? null : row.jobId;
            const res = await Api.Roles.addRoles({data:row});
            setConfirmLoading(false);
            if(res&&res.code === 0){
                message.success("新增信息成功",2,()=>{
                    setIsModalOpen(false);
                    getList();
                    getJobData();
                })
            }
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    }

    // 打开新增角色权限
    const addRole = () => {
        // 重置未绑定的职务
        setDuties(defaultDuties);

        setConfirmLoading(false);
        setIsModalOpen(true);
    }

    //删除角色权限
    const deleteRoleModal = () => {
        if(selectedRowKey){
            ModalConfirm({
                title: `您确认要删除这条数据吗?`,
                onOk:async(resolve, reject)=> {
                    try{
                        const res = await Api.Roles.deleteRole({data:{id:selectedRowKey}});
                        resolve();
                        if(res&&res.code === 0){
                            setSelectedRowKey("");
                            let newSearchData = {...searchData}
                            newSearchData.pageIndex = 1;
                            setSearchData(newSearchData);
                            getJobData();
                            message.success("删除成功");
                        }
                    }catch{}
                },
            })
        }else{
            message.info("请选择您需要删除的数据")
        }
    }

    // 关闭新增角色权限
    const handleCancel = () => {
        // form.setFieldsValue({});
        setIsModalOpen(false);
    }

    // 分页变化
    const paginationChange = (page, pageSize) => {
        let newSearchData = {...searchData}
        newSearchData.pageIndex = page;
        newSearchData.pageSize = pageSize;
        setSearchData(newSearchData);
    }


    const columns = [
        {
            title: '业务角色',
            dataIndex: 'name',
            width: '150px',
            editable: true,
            align:"center",
        },
        {
            title: '系统角色',
            dataIndex: 'sysRoleId',
            width: '200px',
            editable: true,
            align:"center",
            render:(text, record, index)=>{
                return record.sysRoleName
            },
            changeType:"select"
        },
        {
            title: '默认对象',
            dataIndex: 'jobId',
            width: '200px',
            editable: true,
            align:"center",
            render:(text, record, index)=>{
                return record.jobName ? record.jobName :'无'
            },
            changeType:"select"
        },
        {
            title: '审核权限',
            dataIndex: 'checkPerm',
            width: '130px',
            editable: true,
            align:"center",
            render:(text, record, index)=>{
                return text == 0 ? '无审核权限' : text == 1 ? '有审核权限' : "--"
            },
            changeType:"select"
        },
        {
            title: '发布权限',
            dataIndex: 'releasePerm',
            width: '190px',
            editable: true,
            align:"center",
            render:(text, record, index)=>{
                return text == 0 ? '无需审批' : text == 1 ? '按知识类型要求进行审批 ' : "--"
            },
            changeType:"radio"
        },
        {
            title: '取消发布权限',
            dataIndex: 'unreleasePerm',
            width: '190px',
            editable: true,
            align:"center",
            render:(text, record, index)=>{
                return text == 0 ? '无需审批' : text == 1 ? '按知识类型要求进行审批 ' : "--"
            },
            changeType:"radio"
        },
        {
            title: '查阅权限',
            dataIndex: 'readPerm',
            width: '190px',
            editable: true,
            align:"center",
            render:(text, record, index)=>{
                return text == 0 ? '无需审批' : text == 1 ? '按知识类型要求进行审批 ' : "--"
            },
            changeType:"radio"
        },
        {
            title: '下载权限',
            dataIndex: 'downloadPerm',
            width: '190px',
            editable: true,
            align:"center",
            render:(text, record, index)=>{
                return text == 0 ? '无需审批' : text == 1 ? '按知识类型要求进行审批 ' : "--"
            },
            changeType:"radio"
        },
        {
            title: '操作',
            width: '140px',
            dataIndex: 'operation',
            align:"center",
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record.id)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            保存
                        </Typography.Link>
                        <Popconfirm title="取消后数据将不会保存" onConfirm={cancel}>
                            <a>取消</a>
                        </Popconfirm>
                    </span>
                ) : (
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                            调整
                        </Typography.Link>
                    );
            },
        },
    ];

    // 拼装列表数据
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                changeType: col.changeType
            }),
        };
    });

    // 单选框
    const rowSelection = {
        columnWidth:"80px",
        selectedRowKeys:[selectedRowKey],
        onChange: (selectedRowKeys) => {
            setSelectedRowKey(selectedRowKeys[0])
        },
        getCheckboxProps: (record) => ({
          name: record.name,
        }),
        columnTitle:<Button onClick={()=>setSelectedRowKey('')} type="link">取消</Button>
    };

    return (
        <div className="roleListFrame">
            <div className="title">角色及权限管理</div>
            <div className="operation">
                <Space size={"large"}>
                    <Button type="primary" onClick={addRole}>新增业务角色</Button>
                    <Button type="primary" danger onClick={deleteRoleModal}>删除业务角色</Button>
                </Space>
            </div>
            <div className="table">
                <Form form={form} component={false}>
                    <SelfTable
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        rowRadioSelection={{
                            selectedRowKey:selectedRowKey,
                            setSelectedRowKey:setSelectedRowKey,
                        }}
                        pagination={{
                            ...pagination,
                            onChange: paginationChange,
                        }}
                        dataSource={listData}
                        columns={columns}
                        loading={tableLoading}
                        isEditing={isEditing}
                        scroll={
                            {
                                x:"2000px"
                            }
                        }
                    />
                    {/* <Table
                        rowKey="id"
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        rowSelection={{
                            type: 'radio',
                            ...rowSelection,
                          }}
                        scroll={{
                            scrollToFirstRowOnChange:true,
                            y:tableHight
                        }}
                        bordered
                        dataSource={listData}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        pagination={{
                            total:pagination.total,
                            current:pagination.pageIndex,
                            pageSize:pagination.pageSize,
                            onChange: paginationChange,
                            showSizeChanger:true,
                            showQuickJumper:true,
                            showTotal:()=> `总计${pagination.total}条数据`
                        }}
                        loading={tableLoading}
                    /> */}
                </Form>
            </div>

            <Modal 
                title="新增角色及权限" 
                open={isModalOpen} 
                onOk={handleOk} 
                onCancel={handleCancel}
                maskClosable={false}
                destroyOnClose={true}
                confirmLoading={confirmLoading}
                // forceRender
            >
                <Form
                    form={addForm}
                    component={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    preserve={false}
                >
                    {addDate.map(item=>{
                        return(
                            <Form.Item
                                key={item.dataIndex}
                                label={item.title}
                                name={item.dataIndex}
                                rules={[{ required: item.required === 'false' ? false : true, message: (item.type === 'select' || item.type === 'checkbox') ? `请选择${item.title}!`:`请输入${item.title}!` }]}
                            >
                                {item.type === 'select' ? <Select 
                                    options={item.dataIndex === 'checkPerm' ? authority :
                                    item.dataIndex === 'jobId' ? duties:
                                    item.dataIndex === 'sysRoleId' ? sysRole :[]}
                                /> :item.type === 'radio' ? <Radio.Group
                                    options={operationAuthority}
                                />:<Input />}
                            </Form.Item>
                        )
                    })}
                </Form>
            </Modal>

        </div>
    );
};
export default RoleList;
