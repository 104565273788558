import React,{ useState, useEffect } from 'react';
import {Table, Input, Dropdown, Space, message, Spin, Tooltip, Modal, Popconfirm, Button, Popover, Col} from 'antd';
import {
    ShoppingCartOutlined,
    FileAddOutlined,
    RightCircleOutlined,
    DownCircleOutlined
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Api from "../../api/index";
import { getSizeText, ModalConfirm, addAICart, jumpToPage } from '../../utils/tool'
import { refreshAIcarNumF, setAIModalState } from "../../features/AIIntelligent/AIReducer";
import PersonalKnowledge from '../PersonalKnowledge';
import PublicKnowledge from '../PublicKnowledge';
import SelfTable from "../SelfTable/index"
import SelfDesignModal from "../SelfDesignModal"; 
import UploadFile from "../../pages/personalCenter/components/UploadFile/index";

import './index.scss';

const { Search } = Input;

const AICart = ({open, cancelModal}) => {
    const history = useHistory();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();
    const [personalknowledgeOpen, setPersonalknowledgeOpen] = useState(false);
    const [publicknowledgeOpen, setPublicknowledgeOpen] = useState(false);
    const [multipleLength, setMultipleLength] = useState(0);
    const [allSelectedRowKey, setAllSelectedRowKey] = useState({});
    const [selectedRowKey, setSelectedRowKey] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [searchData, setSearchData] = useState({
        pageIndex: 1,
        pageSize:10
    });
    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });
    const [pageLoading, setPageLoading] = useState(false);

    const themeList = [
        {
            key: 'public',
            label: "从公共知识库添加",
        },
        {
            key: 'personal',
            label: "从个人知识库添加",
        },
        {
            key: 'upLoad',
            label: "上传本地文档",
        },
    ]
    
    const columns = [
        {
          title: '资料名称',
          dataIndex: 'knowledgeName',
          key: 'knowledgeName',
          ellipsis: true,
          render: (text, record) => {
            return (
                <a className="overflowEllipsis" title={text} onClick={() => jumpToPage(record)}>{text}</a>
            )
        },
        },
        {
          title: '资料类型',
          dataIndex: 'fileType',
          key: 'fileType',
          width: '80px',
        },
        {
          title: '发布者',
          dataIndex: 'userName',
          key: 'userName',
          width: '100px',
        },
        {
          title: '修改时间',
          dataIndex: 'updateTime',
          key: 'updateTime',
          width: '120px',
        },
        {
            title: '文件大小',
            dataIndex: 'size',
            key: 'size',
            width: '80px',
            render: (text) => <span>{getSizeText(parseInt(text))}</span>,
        },
        {
            title: '操作',
            dataIndex: '',
            key: 'x',
            width: '80px',
            render: (text,record) => <a onClick={()=>removeKnowledge([record.id])}>移除</a>,
        },
      ];

    const [fileModalIsShow, setFileModalIsShow] = useState(false);

    // AI购物车数量
    const refreshAIcarNum = useSelector(state => state.AIModal.refreshAIcarNum);

    useEffect(() => {
        setIsModalOpen(open);
        if(open){
            getAIcarList();
        }
    }, [open])

    useEffect(()=>{
        getAIcarList();
    },[JSON.stringify(searchData)])

    useEffect(()=>{
        if(refreshAIcarNum){
            getAIcarList();
        }
    },[refreshAIcarNum])

    useEffect(()=>{
        
        let _allSelectedRowKey = {...allSelectedRowKey};
        _allSelectedRowKey[pagination.pageIndex] = selectedRowKey;
        setAllSelectedRowKey(_allSelectedRowKey);
        
    },[JSON.stringify(selectedRowKey)])

    useEffect(()=>{
        let _multipleLength = 0
        for(let key in allSelectedRowKey){
            console.log(allSelectedRowKey[key].length)
            _multipleLength+=allSelectedRowKey[key].length
        }
        if(_multipleLength === 0){
            _multipleLength = selectedRowKey.length
        }
        setMultipleLength(_multipleLength);
        
    },[JSON.stringify(allSelectedRowKey)])

    useEffect(()=>{
        setSelectedRowKey(allSelectedRowKey[pagination.pageIndex] || [])
    },[pagination.pageIndex])

    // 删除
    const removeKnowledge = async(ids) => {
        ModalConfirm({
            title: "确认从购物车移除这条知识吗？",
            onOk: async (resolve, reject) => {
              try {
                let res = await Api.Chat.removeCart({data:ids});
                resolve();
                if (res.code === 0) {
                    message.success("操作成功");
                    getAIcarList();
                    dispatch(refreshAIcarNumF(Date.now().toString()))
                }
              } catch (error) {}
            },
        });
    }


    // 获取AI购物车
    const getAIcarList = async() => {
        setTableLoading(true);
        try{
            const res = await Api.Chat.pageQaCart({data:searchData});
            const {code, data} = res;
            setTableLoading(false);
            if(code === 0){
                setListData(data.records);
                
                // 默认选中全部
                let ids = [];
                data.records.map(item=>{
                    ids.push(item.id)
                })
                setSelectedRowKey(ids);

                let _allSelectedRowKey = {...allSelectedRowKey};
                _allSelectedRowKey[data.current] = ids;
                setAllSelectedRowKey(_allSelectedRowKey);

                setPagination({
                    "pageIndex": data.current,
                    "pageSize": data.size,
                    "total":data.total
                });
                
            }
        } catch(res){
            setTableLoading(false);
        }
    }

    const handleOk = async() => {
        try {
            const row = await form.validateFields();
            console.log(row)
        }catch(e){
            console.log(e)
        }
        // setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        cancelModal();
    };

    const addKnowledge = () => {
        
    }

    const themeChange = ({ key }) => {
        if(key === 'personal'){
            setPersonalknowledgeOpen(true);
        }else if(key === 'public'){
            setPublicknowledgeOpen(true);
        }else if(key === 'upLoad'){
            setFileModalIsShow(true);
            dispatch(setAIModalState({
                uploadModalType:'AICart',
            }))
        }
    }

    const onSearch = (value) => {
        let newSearchData = {...searchData}
        newSearchData.name = value;
        setSearchData(newSearchData)
    }

    const tableChanges = (pagination, filters) => {
        let newSearchData = {...searchData}
        newSearchData.pageIndex = pagination.current;
        newSearchData.pageSize = pagination.pageSize;
        setSearchData(newSearchData);
    }

    const addKnowledgeF = async(knowledgeIds) => {
        const res = await addAICart(knowledgeIds);
        if(res){
            getAIcarList();
            dispatch(refreshAIcarNumF(Date.now().toString()))
        }
    }

    const startConversation = async() => {
        
        let ids = [];
        for(let key in allSelectedRowKey){
            if(allSelectedRowKey[key] && allSelectedRowKey[key].length > 0){
                ids = [...ids,...allSelectedRowKey[key]]
            }
        }
        
        if(ids.length <= 0){
            message.warning("请添加要对话的知识")
            return false;
        }

        setPageLoading(true)
        
        // 1、删除选中数据
        // 2、创建对话记录
        // 3、跳转文档阅读助手
        try {
            let res = await Api.Chat.removeCart({data:ids});
            if (res.code === 0) {
                // 删除成功
                const res = await Api.Chat.createQa({
                    data:{
                        knowledgeIds: ids,
                        questionLocation:0 // 0文档中的问答， 1知识中心 ， 2ES搜索界面， 为空查讯全部
                    }
                })
                if(res.code === 0){
                    // 创建成功
                    // dispatch(setAIModalState({
                    //     questionLocation: 0,
                    //     knowledgeIds: ids,
                    //     historyTopicId:res.data,
                    // }))
                    
                    dispatch(refreshAIcarNumF(Date.now().toString()));

                    setPageLoading(false);
                    
                    getAIcarList();
                        // cancelModal();

                    sessionStorage.setItem("dialogueParam", JSON.stringify({
                        questionLocation: 0,
                        knowledgeIds: ids,
                        historyTopicId:res.data,
                    }));
                    
                    window.open('/page/aiAssistant/readAssistant');
                        
                    // setTimeout(()=>{
                        
                        // history.push('/page/aiAssistant/readAssistant');
                        // dispatch(setAIModalState({
                        //     modalOpen:true,
                        // }))
                    // },800)
                }
            }else{
                setPageLoading(false);
            }
        } catch (error) {setPageLoading(false);}

       
        
       
    }
    
    return (
        <>
            <Modal 
                centered={true} 
                open={isModalOpen} 
                footer={null}
                width={1000}
                wrapClassName={"aiCartModal"}
                onCancel={handleCancel}
            >
                <Spin spinning={pageLoading}>
                    <div className='aiCartHeader'>
                        <div className='title'>
                            <span className='titleIcon'>AI 知识篮子<ShoppingCartOutlined /></span><br/>
                            <span style={{fontSize:12}}>添加文档到知识篮子，选中文件进行AI解读</span>
                        </div>
                        <Button className={listData.length > 0 ? 'buttonPrimary':''} type="primary" onClick={startConversation} disabled={listData.length <= 0}>开始对话</Button>
                    </div>
                    <div className='aiCartSearch'>
                        <div className='searchInput'>
                            <span className='inputTitle'>已添加知识：</span>
                            <Search placeholder="请输入文件名称" onSearch={onSearch}/>
                        </div>
                        
                        {/* 已选{multipleLength}项<a style={{marginLeft:5}} onClick={()=>{
                            setMultipleLength(0);
                            setAllSelectedRowKey({});
                            setSelectedRowKey([]);
                        }}>取消选择</a> */}
                        
                        <Dropdown
                            arrow
                            menu={{
                                items: themeList,
                                selectable: true,
                                onClick: themeChange,
                            }}
                            trigger={['click']}
                            getPopupContainer={()=>document.getElementById("addKnowledge")}
                            placement="bottomLeft"
                            overlayClassName="themePopover"
                        >
                            <Button 
                                id='addKnowledge' 
                                className='startConversation' 
                                type="text" 
                                icon={<FileAddOutlined />} 
                            >添加知识</Button>
                        </Dropdown>
                    </div>
                    <SelfTable
                        rowRadioSelection={{
                            type: 'checkbox',
                            selectedRowKey: selectedRowKey,
                            setSelectedRowKey:setSelectedRowKey,
                        }}
                        selfClassName={"aiTableClass"}
                        dataSource={listData}
                        columns={columns}
                        pagination={pagination}
                        loading={tableLoading}
                        onChange={tableChanges} 
                        expandable={{
                            expandedRowRender: (record) => (
                                <p
                                style={{
                                    margin: 0,
                                }}
                                >
                                {record.knowledgeDigest}
                                </p>
                            ),
                            rowExpandable: record => record.knowledgeDigest,
                            expandIcon: ({expanded, onExpand, record}) =>
                                expanded ? (
                                    <DownCircleOutlined style={{color: "#1C76FD"}} onClick={e => onExpand(record, e)}/>
                                ) : (
                                    <RightCircleOutlined style={{color: "#1C76FD"}} onClick={e => onExpand(record, e)}/>
                                )
                        }}
                        bordered={false}
                        tableBodyHight={400}
                    />
                </Spin>
            </Modal>
            {personalknowledgeOpen&&<PersonalKnowledge 
                open={personalknowledgeOpen} 
                cancelModal={()=>setPersonalknowledgeOpen(false)}
                addKnowledgeF={addKnowledgeF}
            />}

            {publicknowledgeOpen&&<PublicKnowledge
                open={publicknowledgeOpen} 
                cancelModal={()=>setPublicknowledgeOpen(false)}
                addKnowledgeF={addKnowledgeF}
            />}

            {/* 文件上传 */}
            <SelfDesignModal
                open={fileModalIsShow}
                centered={true}
                closeIcon={false}
                closable={false}
                maskClosable={false}
                wrapClassName={"uploadFileModalBox"}
                destroyOnClose={true}
                getContainer={document.getElementsByClassName("aiCartModal")[0]}
                content={(
                    <UploadFile
                        isChoiceFile={true}
                        isSelected={false}
                        onCancel={() => {
                            setFileModalIsShow(false);
                        }}
                        uploadType={"parsingFiles"}
                    />
                )}
            />
        </>
    );
}

export default AICart;