import React, {useEffect, useState, memo} from 'react';
import { Empty, Pagination, message, Typography, Switch, Space, Spin} from 'antd';
import { LikeOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom';

import Api from "../../../../api/index";
import {ModalConfirm} from '../../../../utils/tool'
import Iconfont from "../../../../components/Iconfont/Iconfont";

import './index.scss'

const { Title, Text } = Typography;

const NoteList = ({
    noteType,
    modifyNodeLable,
    nodeList,
    getNodeList,
    NodePagination,
    setLocationNotes, 
    spinningType, 
    knowledgeId,
    setNodePaginationChange,
    excerptList,
}) => {
    
    const location = useLocation();
    const [switchLoading, setSwitchLoading] = useState(false);
    const [newNodeList, setNewNodeList] = useState([]);
    
    useEffect(()=>{
        setNewNodeList(nodeList)
    },[JSON.stringify(nodeList)])
    
    const NodePaginationChange = (page, pageSize) => {
        setNodePaginationChange(page);
    }

    const onNodeLike = async(item) =>{
        try{
            let res = await Api.Knowledge.setNoteDetailLike({data:{
                "id":item.id,
                "likeType":(item.isLike === 0 || !item.isLike) ? 1 : 0
            }});
            if(res && res.code === 0){
                getNodeList(Date.now());
                message.success((item.isLike === 0 || !item.isLike) ? "点赞成功" : "取消点赞")
            }else{}
        }catch{}
    }

    const deleteNodeLable = async(item) =>{
        ModalConfirm({
            title: '确定要删除这条笔记吗？',
            onOk:async(resolve, reject)=> {
                try{
                    let res = await Api.Knowledge.deleteNoteDetailById({data:{
                        "id":item.id
                    }});
                    resolve()
                    if(res && res.code === 0){
                        getNodeList(Date.now());
                        message.success("删除成功")
                    }else{}
                }catch{}
            },
        })
    }

    const changeIsOwner = async(item) =>{
        setSwitchLoading(true);
        try{
            const res = await Api.Knowledge.shareNote({data:{
                "id":item.id
            }});
            setSwitchLoading(false);
            if(res && res.code === 0){
                message.success("修改成功");
                getNodeList(Date.now());
            }else{}
        }catch{setSwitchLoading(false);}
    }

    const openNodeText = (items) =>{
        let _NodeList = [...newNodeList]
        _NodeList.map(item=>{
            if(item.id === items.id){
                item.isShow = !item.isShow
            }
        })
        setNewNodeList(_NodeList)
    }

    const openNodeOriginal = (items) =>{
        let _NodeList = [...newNodeList]
        _NodeList.map(item=>{
            if(item.id === items.id){
                item.isShowOriginal = !item.isShowOriginal
            }
        })
        setNewNodeList(_NodeList)
    }

    return(
        <div className="noteBoxFrame">

            {noteType === 0 && (
                <div>
                    {/* <div className="noteOperation">
                        <Iconfont type={'icon-bijiben'}/>学习笔记
                    </div> */}
                    <Spin size="small" spinning={spinningType}>

                        {newNodeList&&newNodeList.length>0?newNodeList.map(item=>{

                            let showIcon = false
                            document.querySelector('#visibilityBox').innerHTML = item.noteDetailMessage;
                            if(document.querySelector('#visibilityBox').scrollHeight - 1 > 100){
                                showIcon = true
                            }

                            let isShowOriginal = false
                            document.querySelector('#visibilityBoxOriginal').innerHTML = item.noteBelongMessage;
                            if(document.querySelector('#visibilityBoxOriginal').scrollHeight - 1 > 60){
                                isShowOriginal = true
                            }

                            return( 
                                <div 
                                    key={item.id} 
                                    className="noteContent"
                                >
                                    <div className="noteTitle">
                                        <Iconfont type={'icon-dangdang'} iStyle={{flex:1}}/>
                                        
                                        <div className="noteTitleOperation">
                                            
                                            <Space>
                                                <Iconfont type={'icon-bianji2'}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        modifyNodeLable(item);
                                                    }}
                                                />
                                                <Iconfont type={'icon-shanchu1'}
                                                    onClick={()=>{
                                                        deleteNodeLable(item)
                                                    }}
                                                />
                                                <Switch size="small" 
                                                    checked={item.isOwner}
                                                    onClick={()=>{
                                                        changeIsOwner(item)
                                                    }}
                                                    loading={switchLoading}
                                                />共享
                                            </Space>
                                        </div>
                                    </div>

                                    <span style={{fontSize: '0.8rem'}}>{item.noteDetailTitle}</span>   

                                    <div className="content"
                                        onClick={(e)=>{
                                            e.preventDefault();
                                            setLocationNotes(item)
                                        }}>
                                        <div className={`noteText ${item.isShow ? "":"textEllipsis"}`} >
                                            {item.noteDetailMessage}
                                            {showIcon?(
                                                <div 
                                                    className={item.isShow ? "moreBtn": "moreBtnOpen moreBtn"}
                                                    onClick={(e)=>{
                                                        e.stopPropagation();
                                                        openNodeText(item)
                                                    }}
                                                >
                                                {item.isShow ? "收起" : "... 展开"}
                                                </div>
                                            ):""}
                                        </div>

                                        {/* <Iconfont 
                                            type={`${item.isShow ? "icon-shangjiantou4":"icon-xiala"}`}
                                            className={`defalutRotate`}
                                            onClick={(e)=>{
                                                e.stopPropagation();
                                                openNodeText(item)
                                            }}
                                        /> */}
                                        

                                        <div className={`originalDetails ${item.isShowOriginal ? "":"originalEllipsis"}`}>
                                            {item.noteBelongMessage}
                                            {isShowOriginal?(
                                                <div 
                                                    className={item.isShowOriginal ? "moreBtn": "moreBtnOpen moreBtn"}
                                                    onClick={(e)=>{
                                                        e.stopPropagation();
                                                        openNodeOriginal(item)
                                                    }}
                                                >
                                                {item.isShowOriginal ? "收起" : "... 展开"}
                                                </div>
                                            ):""}
                                        </div>
                                    </div>
                                </div>
                            )

                        }):null}
                        {((!excerptList || excerptList.length <= 0) && 
                            (!newNodeList || newNodeList.length <= 0))?
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />:null}
                    </Spin>
                    {NodePagination && newNodeList && newNodeList.length>0 ?(
                        <Pagination 
                            size="small" 
                            pageSize={NodePagination.pageSize}
                            total={NodePagination.total} 
                            current={NodePagination.current}
                            onChange={NodePaginationChange}
                            className="notePagination"
                        />
                    ):""}
                    
                </div>
            )}

            {noteType === 1 && (
                <div>
                    <Spin size="small" spinning={spinningType}>
                        {newNodeList&&newNodeList.length>0?newNodeList.map(item=>{
                            
                            let showIcon = false
                            document.querySelector('#visibilityBox').innerHTML = item.noteDetailMessage;
                            if(document.querySelector('#visibilityBox').scrollHeight - 1 > 100){
                                showIcon = true
                            }

                            return(<div key={item.id} className="publicNoteContent">

                                <div className="noteContentRight">

                                    <div className="headSculpture">
                                        {item.noteBelongFileUrl?<img src={item.noteBelongFileUrl}/>:<span>{item.noteBelongName.slice(0, 1)}</span>}
                                        {item.noteBelongName}
                                    </div>

                                    <span style={{fontSize: '0.8rem'}}>{item.noteDetailTitle}</span>  

                                    <div className="content"
                                        onClick={(e)=>{
                                            e.preventDefault();
                                            setLocationNotes(item)
                                        }}>
                                        <div className={`noteText ${item.isShow ? "":"textEllipsis"}`}>{item.noteDetailMessage}</div>

                                        {showIcon?(<Iconfont 
                                            type={`${item.isShow ? "icon-shangjiantou4":"icon-xiala"}`}
                                            className={`defalutRotate`}
                                            onClick={(e)=>{
                                                e.stopPropagation();
                                                openNodeText(item)}
                                            }
                                        />):""}

                                        <div className="originalDetails">{item.noteBelongMessage}</div>
                                    </div>
                                    
                                    <div className="noteTime">
                                        {item.createTime}

                                        <div className="learnFromIt" 
                                            onClick={(e) => {
                                                modifyNodeLable({
                                                    noteDetailTitle:item.noteDetailTitle,
                                                    noteDetailMessage:item.noteDetailMessage,
                                                    noteDetailLabel:item.noteDetailLabel,
                                                    startIndex:item.startIndex,
                                                    endIndex:item.endIndex,
                                                    noteBelongMessage:item.noteBelongMessage
                                                });
                                            }}>
                                            <Iconfont 
                                                type={`icon-fenxiang1`}
                                            />借鉴一下
                                        </div>

                                        <Text type="secondary"
                                            onClick={()=>{
                                                onNodeLike(item)
                                            }}
                                        >
                                            <LikeOutlined  
                                            style={item.isLike?{color:"rgb(240, 209, 85)"}:{}}/>赞({item.likeNum})
                                        </Text>
                                        
                                        
                                    </div>
                                </div>
                            </div>)
                        }):<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    </Spin>
                    {NodePagination && newNodeList && newNodeList.length>0?(
                        <Pagination 
                            size="small" 
                            pageSize={NodePagination.pageSize}
                            total={NodePagination.total} 
                            current={NodePagination.current}
                            onChange={NodePaginationChange}
                            className="notePagination"
                        />
                    ):""}
                </div>
            )}
        </div>
    )
}

export default memo(NoteList);