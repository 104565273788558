import { File } from 'better-xlsx';
import { saveAs } from 'file-saver';

// 计算表头列数
function getColumns(arr) {
    let columnNum = 0;
    arr.map(ele => {
        if (ele.children) {
            columnNum += getColumns(ele.children);
        } else {
            columnNum++;
        }
    });
    return columnNum;
}

// 获取最大值
function max(arr) {
    return arr.reduce((accu, curr) => {
        if (curr > accu) return curr;
        return accu;
    });
}

// 获取表头rows
function getDepth(arr) {
    const eleDepths = [];
    arr.forEach(ele => {
        let depth = 0;
        if (Array.isArray(ele.children)) {
            depth = getDepth(ele.children);
        }
        eleDepths.push(depth);
    });
    return 1 + max(eleDepths);
}

// 按顺序展平column
function columnLine(column) {
    let arr = []
    column.forEach(ele => {
        if (ele.children === undefined || ele.children.length === 0) {
            arr.push(ele);
        } else {
            arr.push(...columnLine(ele.children));
        }
    });
    return arr
}

// 初始化表头
function init(column, rowIndex, columnIndex, sheet, opt={}, depth) {
    column.forEach((item, index) => {
        let hCell = sheet.cell(rowIndex, columnIndex);
        // 如果没有子元素, 撑满列
        if (item.title === '操作') {
            hCell.value = '';
        } else if (item.children === undefined || item.children.length === 0) {
            // 第一行加一个单元格
            hCell.value = item.title;
            hCell.vMerge = depth - rowIndex - 1;
            hCell.style.align.h = 'center';
            hCell.style.align.v = 'center';
            hCell.style.font.size = opt.fontSize || null
            hCell.style.font.size = opt.fontSize || null
            hCell.style.font.bold = opt.isBold || false
            columnIndex++;
            // rowIndex++
        } else {
            let childrenNum = getColumns(item.children);
            hCell.hMerge = childrenNum - 1;
            hCell.value = item.title;
            hCell.style.align.h = 'center';
            hCell.style.align.v = 'center';
            hCell.style.font.size = opt.fontSize || null
            hCell.style.font.bold = opt.isBold || false
            let rowCopy = rowIndex;
            rowCopy++;
            init(item.children, rowCopy, columnIndex, sheet, opt, depth);
            // 下次单元格起点
            columnIndex = columnIndex + childrenNum;
        }
    });
}

function ExportExcel (list, fileName='sheetName') {
    // 新建工作谱
    const file = new File();
    list.forEach((item, idx) => {
        const {column, dataSource, sheetName, option = {}} = item
        const sheet = file.addSheet(sheetName || `sheet-test【${idx+1}】`);
        computedSheet(column, dataSource, sheet,  option)

    })

    // 保存文件
    file.saveAs('blob').then(function(content) {
        saveAs(content, fileName + '.xlsx');
    });
}

function computedSheet(column, dataSource, sheet, opt={}) {
    // 获取表头行数
    let depth = getDepth(column);

    // 获取表头的列数
    let columnNum = getColumns(column);

    // 新建表头行数
    let rowArr = [];
    for (let k = 0; k < depth; k++) {
        let row = sheet.addRow();
        row.setHeightCM(opt.rowHeight || 0.8);
        rowArr.push(row);
    }

    // 根据列数填充单元格
    rowArr.map(ele => {
        for (let j = 0; j < columnNum; j++) {
            let cell = ele.addCell();
            cell.value = j;
        }
    });

    // 初始化表头
    init(column, 0, 0, sheet, opt, depth);

    // 按顺序展平column
    let columnLineArr = columnLine(column);

    // 根据column,将dataSource里面的数据排序，并且转化为二维数组
    let dataSourceArr = dataSource.map(ele => {
        return columnLineArr.map(item => {
            const dataIndex = item.dataIndex
            return {
                [dataIndex]: ele[dataIndex],
                value: ele[dataIndex],
                cellNum: ele.cellNum ? (ele.cellNum[dataIndex] || 0) : 1
            }
        });
    });

    // 绘画表格数据
    dataSourceArr.forEach((item, index) => {
        //根据数据,创建对应个数的行
        let row = sheet.addRow();
        row.setHeightCM(opt.rowHeight || 0.8);

        //创建对应个数的单元格
        item.forEach((ele, idx) => {
            let cell = row.addCell()
            if (ele.hasOwnProperty('num')) {
                cell.value = index + 1;
            } else {
                cell.value = ele.value;
            }
            cell.style.align.v = 'center';
            cell.style.align.h = 'center';
            cell.style.font.size = opt.fontSize || null

            if (ele.cellNum > 0) {
                cell.hMerge = ele.cellNum - 1
            } else {
                cell.hidden = true
            }
        });
    });

    //设置每列的宽度
    for (let i = 0; i < columnNum; i++) {
        sheet.col(i).width = opt.colWidth || 20;
    }
}

export default ExportExcel;