import React, { useState, useEffect } from "react";
import { Col, Row, Spin, Timeline, Empty, DatePicker, Typography } from "antd";
import moment from 'moment'

import Api from "../../../api";
import NoneData from "../../../components/NoneData";
import SelfPagination from "../../../components/SelfPagination";
import {gethalfMonthDate} from '../../../utils/tool'

import "./index.scss";

const { RangePicker } = DatePicker;
const { Text, Link } = Typography;

const System = () => {
  // 查询数据
  const [searchData, setSearchData] = useState({
    pageIndex: 1,
    pageSize: 10,
    startTime: gethalfMonthDate()[0],
    endTime: gethalfMonthDate()[1],
  });

  const [dataList, setDataList] = useState([]);

  // 分页
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 0,
    total: 0,
  });

  const [spinningSate, setSpinningSate] = useState(false);

  useEffect(() => {
    getList();
  }, [searchData]);

    const getList = async() => {
        setSpinningSate(true);
        try{
            const res = await Api.Message.getSystemNotification({data:searchData});
            setSpinningSate(false);
            if(res && res.code ===0 && res.data){
                let _data = [...res.data.records]
                let newData = [];
                let nowDate = "";

                _data.map(item=>{
                    let date = item.createTime.split(" ")[0];
                    if(nowDate === date){
                        newData[newData.length-1].list.push(item)
                    }else{
                        nowDate = date
                        newData.push({
                            date: date,
                            list: [item]
                        })
                    }

                })
                setDataList(newData);
                setPagination({
                    "pageIndex": res.data.current,
                    "pageSize": res.data.size,
                    "total":res.data.total,
                })
            }else{
                setDataList([]);
            }
            // 更新未读消息数量
            document.dispatchEvent(new CustomEvent('notify'));
        } catch (errInfo) {
            setDataList([]);
            setSpinningSate(false);
            console.log('验证失败:', errInfo);
        }
    }

    const onSizeChange = (page, pageSize) => {
        let _searchData = { ...searchData };
        (_searchData.pageIndex = page),
        (_searchData.pageSize = pageSize),
        setSearchData(_searchData);
    };

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };

    const dateChange = (date, dateString) => {
        let newSearchData = { ...searchData };
        newSearchData.startTime = dateString[0];
        newSearchData.endTime = dateString[1];
        setSearchData(newSearchData);
    };

    const checkStatus = async(item) => {
        let res = await Api.Message.checkMessageStatus({
            data: {
                id:item.knowledgeId,
                applyId:item.id
            },
        });
      
        if(res.code === 0){
            let _code = res.data;
            //  applyForType 1下载申请,2发布知识申请,3取消发布知识申请,4阅读权限申请
            // -1已被删除 0已拒绝，1已通过，2待审批，3草稿文章  ------ 该知识当前实际状态
            if(_code === -1){
                message.info("当前知识已被删除");
            }else{
                if (item.fileType === "online") {
                    window.open(`/page/onLineWrite?knowledgeId=${item.knowledgeId}`)
                } else {
                    window.open(`/page/newKnowledge?knowledgeId=${item.knowledgeId}`)
                }
            }
        }
    }

    const goKnowleage =(item)=>{
        history.push(`/page/knowledgeContent?type=knowledge&id=${item.knowledgeTypeId}`);
    }

  return (
    <div className='systemMessageFrame'>
       <div className="operation">
            最近的系统通知
            <RangePicker 
                disabledDate={disabledDate}
                value={[
                    searchData.startTime?moment(searchData.startTime):"",
                    searchData.endTime?moment(searchData.endTime):""
                ]}
                onChange={dateChange}
                style={{marginLeft:20}}
            />
       </div>
      <div className="content">
            <Spin spinning={spinningSate}>
                {dataList&&dataList.length>0 ? dataList.map(item=>{
                    return <div className="listItem" key={item.date}>
                        <p className="date">{item.date}</p>
                        <Timeline>
                            {
                                item.list.map(items=>{
                                    return(
                                    <Timeline.Item key={items.noticeId}>
                                        <div>
                                            <Row>
                                                {/* <Col span={10}>
                                                    {items.knowledgeMessageVo.message}
                                                </Col> */}
                                                <Col span={10}>
                                                    你在
                                                    <Link onClick={()=>{goKnowleage(items)}}>{items.knowledgeTypeName}</Link>
                                                    中发布的文件
                                                    <Link onClick={()=>{checkStatus(items)}}>《{items.knowledgeName}》</Link>
                                                    ，被部门{items.oleName}取消发布，点击
                                                    <span onClick={()=>{checkStatus(items)}}>立即编辑</span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Timeline.Item>
                                    )
                                })
                            }
                        </Timeline>
                    </div>
                }):<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </Spin>
            </div>
            {dataList&&dataList.length>0?(
                <div className="footer">
                    <SelfPagination
                        total={pagination.total}
                        current={pagination.pageIndex}
                        onSizeChange={onSizeChange}
                    />
                </div>
            ):""}
    </div>
  );
};

export default System;
