import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'antd';


import Cropper from 'react-cropper';
import "cropperjs/dist/cropper.css";
const CropperImg = ({
    open, 
    imageUrl, 
    handleOk, 
    handleCancel,
    aspectRatio=3 / 2,
    width=300,
    height=200
}) => {
    
    const cropperRef = useRef(null);

    const handleCrop = () => {
        // 调用crop方法来获取裁剪后的图片
        if (cropperRef.current) {
            const canvas = cropperRef.current.cropper.getCroppedCanvas()
            canvas.toBlob(blob => {
                // 使用 blob 对象
                console.log(blob)
                handleOk(blob)
            });
        }
    };
    
    return (<Modal 
        title="剪裁图片" 
        open={open} 
        onOk={handleCrop} 
        onCancel={handleCancel}
        maskClosable={false}
        destroyOnClose={true}
        wrapClassName={"selfDesignModal"}
        width={600}
    >
       <Cropper
            ref={cropperRef}
            src={imageUrl}
            style={{ height: 400, width: "100%" }}
            aspectRatio={aspectRatio} // 设置宽高比，例如4:3
            width={width} // 设置裁剪区域的宽度
            height={height} // 设置裁剪区域的高度
            // 其他props根据需要设置
        />
    </Modal>)
}


export default CropperImg;