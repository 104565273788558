import react, { useEffect, useState } from "react";
import {
  Tabs,
  Modal,
  Button,
  Input,
  Divider,
  Select,
  message,
  Space,
  Empty,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { useDispatch } from "react-redux";

import Iconfont from "../../../../components/Iconfont/Iconfont";
import { setAIModalState } from "../../../../features/AIIntelligent/AIReducer";
import "./index.scss";

const { Search } = Input;
const KnowledgeTypeTab = ({
  tabsData,
  tabsKey,
  tabsChange,
  toSearch,
  searchIsNull,
  siftData
}) => {
  const [serchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  useEffect(()=>{
    setSearchValue("")
  },[tabsKey])

  const onSearch = (value) => {
    toSearch(serchValue);
  };

  const searchChange = (value, type) => {
    setSearchValue(value);
  }

  // AI帮我找
  const onAISearch = () => {
    dispatch(setAIModalState({
        modalOpen:true,
        questionLocation:1,
        knowledgePublishedParam:siftData,
        selectKnowledgeParam:{},
        knowledgeEsParam:{},
        queryText:"推荐问题",
        questionModule:"summary",
        relQuestions:2,
        sendAPI: Date.now().toString(),
    }))
}

  return (
    <div className="knowledgeTypeTabFrame">
      {tabsData?.length > 0 ? (
        <>
          <Tabs items={tabsData} activeKey={tabsKey} onChange={tabsChange} />
          {/* {!searchIsNull ? ( */}
          {/* <> */}
            {/* {serchBlock ? ( */}
          {siftData&&<div className="AiSearchBtn" onClick={onAISearch} >
              <Iconfont type={"icon-waixingren"} />
              <span>AI帮我找</span>
          </div>}
          <div className="searchInput">
            <Search 
              placeholder={"请输入标题搜索"}
              value={serchValue}
              onSearch={toSearch}
              allowClear={true}
              onChange={(e)=>{
                searchChange(e.target.value)
              }}
              onPressEnter={onSearch}
            />
          </div>
          {/* //   ) : (
          //     <div className="searchIcon">
          //       <SearchOutlined onClick={handeleToSearch} />
          //     </div>
          //   )}
          // </> */}
          {/* ) : null} */}
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{ height: "1.2rem" }}
        />
      )}
    </div>
  );
};

export default KnowledgeTypeTab;
