import React, { useState, useEffect} from 'react';
import {Spin} from 'antd';

import Api from '../../../../../api'
import KnowledgeList from '../../../../../components/KnowledgeList'
import './index.scss';

const LatestKnowledge = () => {
    const [title] = useState();

    
    const [recommendedKnowledge, setRecommendedKnowledge] = useState([]);
    const [loadingState, setLoadingState] = useState(false);

    useEffect(()=>{
        getKnowledgeList();
    },[])

     // 知识列表
     const getKnowledgeList = async() => {
        setLoadingState(true);
        try{
            let res = await Api.Home.getRecommendOrLatestKnowledge({data:{queryType:1}});
            setLoadingState(false);
            if(res && res.code === 0 && res.data && res.data.records){
                let _records = res.data.records;
                for(let i=0; i<_records.length; i++){
                    let userList = _records[i].knowledgeUserRelVOList;
                    let userText = []
                    for(let j=0; j<userList.length; j++){
                        userText.push(userList[j].userName)
                    }
                    _records[i].authors = userText
                }

                setRecommendedKnowledge(_records);
            }else{
                setRecommendedKnowledge([]);
            }
        }catch{setLoadingState(false);}
    }

    return (
        <div className="latestKnowledgeFrame">
            <div className="title">最新知识</div>
            <Spin tip="数据加载中" spinning={loadingState}>
                <KnowledgeList
                    type={"home"}
                    listDate={recommendedKnowledge}
                />
            </Spin>
        </div>
    )
}

export default LatestKnowledge;
