import myAxios from '../utils/request.js';
import callback from "../utils/callback.js";

export default {
    // 获取验证码
    async getAuthCaptcha (params={}, cb) {
        let res = await myAxios.getJson('/auth/captcha', params);
        return res.data;
    },

    // 登录
    async loginFunc (params={}, cb) {
        let res = await myAxios.postJson('/sysUsers/login', params);
        return res.data;
    },

    // 指纹登录
    async getLoginInfo (params={}, cb) {
        let res = await myAxios.getJson('/sysUsers/getLoginInfo', params);
        return res.data;
    },

    async getIP (params={}, cb) {
        let res = await myAxios.getJson('/sysUsers/getIP', params);
        return res.data;
    },
    
    // 获取用户资料
    async getUserInfo (params={}, cb) {
        let res = await myAxios.getJson('/ou/info', params);
        return res.data;
    },

    // 注销用户
    async delUser (params={}, cb) {
        let res = await myAxios.postJson('/delUser.json', params);
        return res.data;
    },

    // 修改密码
    async setPassword (params={}, cb) {
        let res = await myAxios.postJson('/sysUsers/reset', params);
        return res.data;
    },

    // 退出登录
    async loginOutFunc (params={}, cb) {
        let res = await myAxios.getJson('/sysUsers/logout', params);
        return res.data;
    },

    // 根据已登录用户查询菜单
    async getlistMenuTree (params={}, cb) {
        let res = await myAxios.getJson('/sysPermissions/listMenuTree', params);
        return res.data;
    },

    // 根据已登录用户查询菜单
    async getCaptcha (params={}, cb) {
        let res = await myAxios.postJson('/captcha/getCaptcha', params);
        return res.data;
    },
    
};