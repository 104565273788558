import myAxios from '../utils/request.js';

export default {
    // 分页查询知识列表以及知识的解析状态（ 0:处理成功 1:正在处理;2:处理失败;3:未处理、4未推送 ）
    async getPageLMSyncStatus (params={}, cb) {
        let res = await myAxios.postJson('/largeModelFileSync/pageLMSyncStatus', params);
        return res.data;
    },

    // 将未推送、处理失败的文件重新同步文件至大模型
    async reSyncFile (params={}, cb) {
        let res = await myAxios.postJson('/largeModelFileSync/reSyncFile', params);
        return res.data;
    },

    // 切分模式列表
    async getProcessType (params={}, cb) {
        let res = await myAxios.getJson('/largeModelQa/getProcessType', params);
        return res.data;
    },
    // 详情
    async getProcessTypeDetail (params={}, cb) {
        let res = await myAxios.getJson('/largeModelQa/getProcessTypeDetail', params);
        return res.data;
    },
    // 设置当前切分模式
    async setProcessType (params={}, cb) {
        let res = await myAxios.postJson('/largeModelQa/setProcessType', params);
        return res.data;
    },
    

    // 分页查询提示词工程和答案
    async pageQaDetail (params={}, cb) {
        let res = await myAxios.postJson('/largeModelQaLog/pageQaDetail', params);
        return res.data;
    },

    // 敏感词CRUD
    async getPageSensitiveList (params={}, cb) {
        let res = await myAxios.postJson('/senWord/pageList', params);
        return res.data;
    },
    async addSensitiveWord (params={}, cb) {
        let res = await myAxios.getJson('/senWord/addSenWord', params);
        return res.data;
    },
    async deleteSenWord (params={}, cb) {
        let res = await myAxios.postJson('/senWord/deleteSenWord', params);
        return res.data;
    },
    async updateSenWord (params={}, cb) {
        let res = await myAxios.postJson('/senWord/updateSenWord', params);
        return res.data;
    },
    // 同步敏感词至大模型，为空表示全部同步 传输方式 [1,2,3]
    async syncSenWord (params={}, cb) {
        let res = await myAxios.postJson('/senWord/syncSenWord', params);
        return res.data;
    },

    
    
}
