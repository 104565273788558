import React,{useState, useEffect} from 'react';
import { Spin, Tabs, Space, Button, Modal, Select, Input, Cascader, Checkbox, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import './index.scss'
import ClassificationTree from "../components/classificationTree"
import KnowledgeList from '../../../../components/KnowledgeList'
import KnowledgeType from '../../../../components/KnowledgeType'
import BackIcon from '../../../../components/BackIcon'
import {pagingParam} from "../../../../utils/defaultValue";
import Api from '../../../../api'
import {getSearchParams} from "../../../../utils/tool";

const GeneralKnowledge = () => {

    const [loadingType, setLoadingType] = useState(false);

    const [tabsData, setTabsData] = useState([]);
    const [tabsKey, setTabsKey] = useState('');

    //分类树
    const [treeList, setTreeList] = useState([]);
    const [treeId, setTreeId] = useState('');

    const [listHight, setListHight] = useState(0);

    // 查询数据
    const [searchData, setSearchData] = useState({
        ...pagingParam
    });

    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });

    // 列表数据
    const [listDate, setListDate] = useState([]);
    const [defaultKnowledgeType, setDefaultKnowledgeType] = useState([]);

    // 弹窗
    const [unPublistDate, setUnPubListDate] = useState([]);
    const [modalLoadingType, setModalLoadingType] = useState(false);
    const [knowledgeModalOpen, setKnowledgeModalOpen] = useState(false);
    const [modalSelectTree, setModalSelectTree] = useState([]);
    // 查询数据
    const [modalSearchData, setModalSearchData] = useState({
        ...pagingParam,
    });
    // 分页
    const [modalPagination, setModalPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });
    const [searchValue, setSearchValue] = useState('');
    const [knowledgeTypeId, setKnowledgeTypeId] = useState('');
    const [cascaderValue, setCascaderValue] = useState([]);
    // 全选
    const [checkedAll, setCheckedAll] = useState(false);
    const [checkedList, setCheckedList] = useState({});
    const [indeterminate, setIndeterminate] = useState(false);

    // 全选本页
    const [checkedCurrentPageAll, setCheckedCurrentPageAll] = useState(false);
    const [checkedCurrentPageList, setCheckedCurrentPageList] = useState({});
    const [currentPageIndeterminate, setCurrentPageIndeterminate] = useState(false);
    
    useEffect(() => {
        resizeCallBack();
        // 添加窗口变化监听回调函数
        window.addEventListener('resize', resizeCallBack);
        return () => {
            // 移出窗口变化监听
            window.removeEventListener('resize', resizeCallBack)
        }
    }, [])

    // 窗口变化监听回调
    function resizeCallBack() {
        let rootHeight = document.querySelector("#root").offsetHeight;

        // 计算table内容的高度
        let divHeight= document.querySelector('.righBox').getBoundingClientRect().top;

        setListHight(rootHeight - divHeight - 120);
    }

    useEffect(()=>{
        if(getSearchParams().type === "generalKnowledge"){
            getKnowlageTypeList();
            getKnowledgeList();
        }else{
            // 参考研报
            setTabsData([
                {
                    label:'参考研报',
                    key:'7',
                    value:'7',
                }
            ]);
            setTabsKey('7');
            setKnowledgeTypeId('7');
            getDepartmentList('7');
            setModalSearchData({
                ...modalSearchData,
                knowledgeTypeIds:['7']
            })
        }
    },[])

    useEffect(()=>{
        if(tabsKey){
            getClassification();
        }
        console.log(tabsKey,"tabsKeytabsKeytabsKey")
        changeSearchValue({
            'knowledgeTypeId':tabsKey,
            ...pagingParam,
        });
    },[tabsKey])

    useEffect(()=>{
        changeSearchValue({
            'knowledgeTypeId':tabsKey,
            'pubDeptSubId':treeId,
            ...pagingParam,
        });
    },[treeId])

    useEffect(()=>{
        if(searchData.pubDeptSubId){
            getList();
        }
    },[searchData])

    useEffect(()=>{
        if(knowledgeModalOpen){
            getUnPubKnowledgeList();
        }
    },[modalSearchData])
    
    const getKnowlageTypeList = async() => {
        try {
            let res = await Api.Knowledge.getPutKnowledgeType({ data: {},});

            if (res && res.code === 0) {
                let _data = [];

                res.data.map((item)=>{
                    _data.push({
                        label:item.knowledgeTypeName,
                        key:item.id,
                        value:item.id
                    })
                })
                setTabsData(_data);
                setTabsKey(_data[0].key);
            }
        } catch (error) {}
    }


    const getClassification = async() => {
        let res = await Api.Knowledge.getPublicListTree({
            data: { knowledgeTypeId:tabsKey },
        });
        
        if (res && res.code === 0 && res.data) {
            setTreeList(res.data)
            // setTreeId(res.data[0].id);
        }else{
            setTreeList([])
            setTreeId("");
        }
    }

    const getList = async() => {
        setLoadingType(true);
        try {
            let res = await Api.Knowledge.getPutKnowledgeList({ data: searchData});

            setLoadingType(false);
            if (res && res.code === 0) {
                setListDate(res.data.records);
                setPagination({
                    "pageIndex": res.data.current,
                    "pageSize": res.data.size,
                    "total":res.data.total,
                })
            }else{
                setListDate([]);
            }
        } catch (error) {
            setListDate([]);
            setLoadingType(false);
        }
        
    }

    // tabs改变
    const tabsChange = (value) => {
        setTreeId("");
        setListDate([]);
        setTabsKey(value);
    }

    // 树选择
    const onSelect = (selectedKeys) => {
        console.log(selectedKeys,"selectedKeysselectedKeysselectedKeys")
        setTreeId(selectedKeys[0]);
    };

    // 分页变化
    const paginationChange = (pageIndex,pageSize) => {
        let _searchData = {...searchData}
        _searchData.pageIndex = pageIndex;
        _searchData.pageSize = pageSize;
        setSearchData(_searchData);
    }

    // 更新查询参数
    const changeSearchValue = (data)=>{
        let _searchData = {
            ...searchData,
            ...data
        }
        console.log(_searchData,"_searchData")
        setSearchData(_searchData);
    }

    const openSearch = () => {

    }

    const getUnPubKnowledgeList = async() => {
        setModalLoadingType(true)
        try {
            modalSearchData.knowledgeTypeIds = modalSearchData.knowledgeTypeIds?modalSearchData.knowledgeTypeIds:defaultKnowledgeType

            let res = await Api.Knowledge.getUnPubList({ data: modalSearchData});

            setModalLoadingType(false);
            if (res && res.code === 0) {
                
                let _data = res.data;
                // 勾选了全选按钮后，当前筛选的类型下的数据都选中
                if(checkedAll){
                    let ids = [];
                    _data.records.map(item=>{
                        ids.push(item.id)
                    })
                    checkedList[_data.current] = ids;
                    setCheckedList({...checkedList});
                    setIndeterminate(false);
                }else if(_data.records.length > 0){
                    // 判断当前页是否有选中的数据
                    let currentPageList = checkedList[_data.current] || [];

                    setCurrentPageIndeterminate(!!currentPageList.length && currentPageList.length < _data.records.length);
                    if(currentPageList && currentPageList.length >= _data.records.length){
                        setCheckedCurrentPageAll(true);
                    }else{
                        setCheckedCurrentPageAll(false)
                    }
                }else{
                    setCheckedCurrentPageAll(false);
                    setIndeterminate(false);
                    setCurrentPageIndeterminate(false);
                }

                setUnPubListDate(_data.records);
                setModalPagination({
                    "pageIndex": _data.current,
                    "pageSize": _data.size,
                    "total":_data.total,
                })
            }else{
                setCheckedCurrentPageAll(false);
                setIndeterminate(false);
                setCurrentPageIndeterminate(false);
            }
        } catch (error) {
            setModalLoadingType(false);
        }
    }

    const addKnowlage = () => {
        setKnowledgeModalOpen(true);
        getUnPubKnowledgeList()
    }

    const handleCancel = () => {
        setKnowledgeModalOpen(false);
        setCheckedAll(false);
        setCheckedList({});
        setIndeterminate(false);
        setCascaderValue([]);
        setModalSearchData({
            ...pagingParam,
        });
        setSearchValue("");
        setUnPubListDate([])
    }

    const handleOk = async() => {
        if(JSON.stringify(checkedList) != "{}"){
            let param = {};

            if(checkedAll){
                param = {
                    unPubKnowledgeDTO:{
                        ...modalSearchData
                    },
                    knowledgeTypeId:tabsKey,
                    pubDeptSubId:treeId,
                }
            }else{
                let ids = [];
                for (let key in checkedList){
                    ids = [...ids,...checkedList[key]]
                }
                param = {
                    knowledgeTypeId:tabsKey,
                    pubDeptSubId:treeId,
                    knowledgeIds:ids
                }
            }

            try {
                let res = await Api.Knowledge.addPutKnowledge({ data: param});
    
                if (res && res.code === 0) {
                    message.success("添加成功");
                    handleCancel();
                    getList();
                    getClassification();
                }
            } catch (error) {}
            
        }else{
            message.info('请选择需要添加的知识')
        }
    }

    const modalPaginationChange = (pageIndex,pageSize) => {
        let _searchData = {...modalSearchData}
        _searchData.pageIndex = pageIndex;
        _searchData.pageSize = pageSize;
        setModalSearchData(_searchData);
    }

    // 搜索框
    const changeValue = (e) => {
        setSearchValue(e.target.value)
    }

    const onSearch = () => {
        let newSearchData = {...modalSearchData}, _cascaderValue = [...cascaderValue]
        newSearchData.pageIndex = 1;
        newSearchData.knowledgeName = searchValue;
        

        if(getSearchParams().type === "researchReport"){
            _cascaderValue.unshift('7')
        }

        if(_cascaderValue && _cascaderValue.length > 0){
            newSearchData.knowledgeTypeIds = [_cascaderValue[0]];
        }else{
            newSearchData.knowledgeTypeIds = ""
        }

        if(_cascaderValue && _cascaderValue.length > 1){
            newSearchData.departmentId = _cascaderValue[1];
        }else{
            newSearchData.departmentId = ""
        }

        if(_cascaderValue && _cascaderValue.length > 2){
            newSearchData.departmentSubclassificationId = _cascaderValue[_cascaderValue.length-1];
        }else{
            newSearchData.departmentSubclassificationId = ""
        }

        console.log(newSearchData,"newSearchData")
        setModalSearchData({
            ...newSearchData
        });
    }

    const onResetting = () => {
        setSearchValue("");
        setModalSearchData({
            ...pagingParam,
            "knowledgeName":"",
            knowledgeTypeIds:getSearchParams().type === "researchReport" ? ['7'] : defaultKnowledgeType
        })
        setCascaderValue([])
    }

    const getKnowledgeList = async() => {
        try{
            let res = await Api.Knowledge.getPutKnowledgeType({ data: {},});

            if(res && res.code === 0 && res.data){
                let _data = res.data, ids = [];

                _data.map(item=>{
                    item.isLeaf = false;
                    item.subclassification = item.knowledgeTypeName;
                    ids.push(item.id)
                })

                setModalSelectTree(res.data)
                setDefaultKnowledgeType(ids)
            }else{}
        }catch{}
    }

    const getDepartmentList = async(id) => {
        try{
            const res = await Api.Department.getDepartmentListAll({
                data:{
                    knowledgeTypeId:id
                }
            });
            if(res && res.code === 0 && res.data){
                let _data = res.data;

                res.data.map(item=>{
                    item.subclassification = item.name;
                    item.isLeaf = false;
                })
                
                setModalSelectTree(_data)
                
            }else{}
        }catch{}
    }

    const loadData = async(selectedOptions) => {
        
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;

        console.log(targetOption,"targetOption")
        
        // 参考研报不需要选择知识类型
        if(targetOption.name || getSearchParams().type === "researchReport"){
            getSubcategorization(targetOption)
        }else if(targetOption.knowledgeTypeName){
            getAllDepartment(targetOption)
            setKnowledgeTypeId(targetOption.id)
        }
    }

    const getAllDepartment = async(targetOption) => {
        try{
            const res = await Api.Department.getDepartmentListAll({
                data:{
                    knowledgeTypeId:targetOption.id
                }
            });
            targetOption.loading = false;
            if(res && res.code === 0 && res.data){
                res.data.map(item=>{
                    item.subclassification = item.name;
                    item.isLeaf = false;
                })
                
                targetOption.childList = [...res.data];
                setModalSelectTree([...modalSelectTree]);
            }else{
                targetOption.isLeaf = true;
                targetOption.childList = [];
                setModalSelectTree([...modalSelectTree]);
            }
        }catch{targetOption.loading = false;}
    }

    const getSubcategorization = async(targetOption) => {
        try{
            const res = await Api.Department.getDepartmentTree({data:{
                departmentId:targetOption.id,
                knowledgeTypeId:knowledgeTypeId
            }});
            targetOption.loading = false;
            if(res && res.code === 0 && res.data){
                res.data.map(item=>{
                    if(targetOption.childList && targetOption.childList.length > 0){
                        item.isLeaf = false;
                    }
                })
                targetOption.childList = [...res.data];
                setModalSelectTree([...modalSelectTree]);
            }else{
                targetOption.isLeaf = true;
                targetOption.childList = [];
                setModalSelectTree([...modalSelectTree]);
            }
        }catch{targetOption.loading = false;}
    }

    const onCascaderChange = (value, selectedOptions) => {
        setCascaderValue(value)
        console.log(value, selectedOptions)
    }

    const onChangeCheckedAll = () => {
        setCheckedAll(!checkedAll)
        if(!checkedAll){
            let ids = [];
            unPublistDate.map(item=>{
                ids.push(item.id)
            })
            checkedList[modalPagination.pageIndex] = ids;
            setCheckedList({...checkedList});
            setIndeterminate(false);

            // 当前页
            setCheckedCurrentPageAll(true);
            setCurrentPageIndeterminate(false);
        }else{
            // checkedList[modalPagination.pageIndex] = [];
            setCheckedList({})
            setIndeterminate(false);

             // 当前页
            setCheckedCurrentPageAll(false);
            setCurrentPageIndeterminate(false);
        }
    }

    const checkOnchange = (e) => {
        let _newList = checkedList[modalPagination.pageIndex]
        if(e.target.checked){
            _newList = _newList ? [..._newList,e.target.value]:[e.target.value]
        }else{
            _newList = _newList.filter(item=>item!=e.target.value)
        }
        checkedList[modalPagination.pageIndex] = _newList;
        setCheckedList({...checkedList})
        // setIndeterminate(!!_newList.length && _newList.length < unPublistDate.length);
        setCurrentPageIndeterminate(!!_newList.length && _newList.length < unPublistDate.length);
        
        if(_newList.length >= unPublistDate.length){
            setCheckedCurrentPageAll(true)
        }
    }

    // 全选本页面
    const onChangeCheckedCurrentPageAll = () => {
        setCheckedCurrentPageAll(!checkedCurrentPageAll)
        if(!checkedCurrentPageAll){
            let ids = [];
            unPublistDate.map(item=>{
                ids.push(item.id)
            })
            checkedList[modalPagination.pageIndex] = ids;
            setCheckedList({...checkedList});
            setCurrentPageIndeterminate(false);
        }else{
            checkedList[modalPagination.pageIndex] = [];
            setCheckedList({...checkedList})
            setCurrentPageIndeterminate(false);
        }
    }
    
    return (
        <div className="generalKnowledgeFrame">
            <div className="topTitle">
                <div className='leftTitle'>公共知识</div>
                <div className='tabsBox'>
                    <KnowledgeType
                        tabsData={tabsData}
                        tabsKey={tabsKey}
                        tabsChange={tabsChange}
                        searchData={{
                            knowledgeTypeId:tabsKey
                        }}
                        knowledgeTypeId={tabsKey}
                    />

                    {/* <Tabs items={tabsData} activeKey={tabsKey} onChange={tabsChange}/>
                    
                    <div className="searchIcon" onClick={openSearch}>
                        <SearchOutlined />
                    </div> */}
                    
                    {treeId&&<div className="addButton">
                        <Button type="primary" onClick={addKnowlage}>添加</Button>
                    </div>}

                    {/* <BackIcon /> */}
                </div>
            </div>

            <div className="content">
                <div className="leftBox">
                    <ClassificationTree 
                        treeList={treeList}
                        onSelect={onSelect}
                        treeId={treeId}
                        getClassification={getClassification}
                        knowledgeTypeId={tabsKey}
                    />
                </div>

                <div className="righBox">
                    <Spin tip="数据加载中" spinning={loadingType}>
                        <KnowledgeList
                            type={getSearchParams().type}
                            listDate={listDate}
                            pagination={pagination}
                            paginationChange={paginationChange}
                            autoHight={listHight + 20}
                            getList={getList}
                            showProject={false}
                            knowledgeTypeId={tabsKey}
                            getClassification={getClassification}
                        />
                    </Spin>
                </div>
            </div>

            <Modal 
                title="知识列表" 
                wrapClassName="knowledgeModalWrap" 
                open={knowledgeModalOpen} 
                onOk={handleOk} 
                onCancel={handleCancel}
                width={920}
            >
                <Spin tip="数据加载中" spinning={modalLoadingType}>
                    <div className="operation">
                        <div className="operationItem">
                            <span className="title">类型：</span>
                            <Cascader 
                                value={cascaderValue}
                                options={modalSelectTree} 
                                loadData={loadData} 
                                onChange={onCascaderChange} 
                                fieldNames={{
                                    label: 'subclassification', 
                                    value: 'id', 
                                    children: 'childList' 
                                }}
                                changeOnSelect
                                allowClear
                            />
                        </div>
                        <div className="operationItem">
                            <Input
                                placeholder="输入关键词搜索"
                                onChange={changeValue}
                                onPressEnter={onSearch}
                                value={searchValue}
                                allowClear
                            />
                        </div>
                        <div className="operationItem" style={{width:150}}>
                            <Space size={"large"}> 
                                <Button type="primary" onClick={onSearch}>搜索</Button>
                                <Button onClick={onResetting}>重置</Button>
                            </Space>
                        </div>
                    </div>
                    <KnowledgeList
                        type={"publicModal"}
                        listDate={unPublistDate}
                        pagination={modalPagination}
                        paginationChange={modalPaginationChange}
                        autoHight={listHight}
                        getList={getList}
                        showProject={false}
                        checkOnchange={checkOnchange}
                        checkedValue={checkedList[modalPagination.pageIndex]}
                    />
                    
                </Spin>
                {unPublistDate && unPublistDate.length > 0 &&<div className="selectAll">
                    <Checkbox 
                        indeterminate={currentPageIndeterminate} 
                        checked={checkedCurrentPageAll} 
                        onChange={onChangeCheckedCurrentPageAll}
                    >全选本页</Checkbox>

                    <Checkbox 
                        indeterminate={indeterminate} 
                        checked={checkedAll} 
                        onChange={onChangeCheckedAll}
                    >全选</Checkbox>
                </div>}
            </Modal>
        </div>
    )
}
export default GeneralKnowledge
