import React, { useEffect, useState } from "react";

import { CaretUpFilled, CaretDownFilled } from "@ant-design/icons";
import { Spin, Empty } from "antd";

import Api from "../../../api";
import storage from "../../../utils/storage";
import KnowledgeItem from "../components/KnowledgeItem";
import KnowledgeTypeTab from "../components/knowledgeTypeTab";
import SelfPagination from "../../../components/SelfPagination";
import "./index.scss";

const myRead = () => {
  const [readList, setReadList] = useState([]);
  // 分类筛选
  const [tabsData, setTabsData] = useState();
  const [tabsKey, setTabsKey] = useState();
  // 排序
  const [progressSortKey, setProgressSortKey] = useState("default");
  const [readTimeSortKey, setReadTimeSortKey] = useState("down");
  
  // 请求数据的所有参数
  const [data, setData] = useState({});
  const [siftData, setSiftData] = useState({
    isLastReadTime: 1,
    isSchdule: null,
    knowledgeTypeId: null,
    pageIndex: 1,
    pageSize: 10,
    knowledgeName: null,
  });
  const [isSpinning, setIsSpinning] = useState(true);
  const [listHight, setListHight] = useState(0);
  useEffect(() => {
    resizeCallBack();
    // 添加窗口变化监听回调函数
    window.addEventListener('resize', resizeCallBack);
    return () => {
        // 移出窗口变化监听
        window.removeEventListener('resize', resizeCallBack)
    }
  }, [])

  // 窗口变化监听回调
  function resizeCallBack() {
      let rootHeight = document.querySelector("#root").offsetHeight;

      // 计算table内容的高度
      let divHeight= document.querySelector('.content').getBoundingClientRect().top;

      setListHight(rootHeight - 266);
  }
  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    getList(siftData);
  }, [siftData]);
  // 获取tab筛选类型
  const init = async () => {
    let res = await Api.PersonalCenter.getAllKnowledgeTab({});
    setTabsData(res);
    setTabsKey(res[0].key);
    storage.setItem("siftTabs", res);
  };
  const getList = async (data) => {
    setIsSpinning(true);
    let res = await Api.PersonalCenter.getUserKnowledgeRead({ data });
    setReadList(res.data.records);
    setData(res.data);
    setIsSpinning(false);
  };
  const tabsChange = (value) => {
    setTabsKey(value);
    let _siftData = { ...siftData };
    _siftData.knowledgeName = "";
    if (value === "all") {
      _siftData.knowledgeTypeId = null;
    } else {
      _siftData.knowledgeTypeId = value;
    }
    setSiftData(_siftData);
  };
  // 排序
  const progressSort = () => {
    let _siftData = { ...siftData };
    let nextSort;
    //排序方式的切换
    if (progressSortKey === "down") nextSort = "up";
    else if (progressSortKey === "up") nextSort = "default";
    else nextSort = "down";
    //修改当前排序方式
    setProgressSortKey(nextSort);
    if (nextSort === "up") {
      _siftData.isSchdule = 2;
    } else if (nextSort === "down") {
      _siftData.isSchdule = 1;
    } else {
      _siftData.isSchdule = null;
    }
    setSiftData(_siftData);
  };
  const readTimeSort = () => {
    let _siftData = { ...siftData };
    let nextSort;
    //排序方式的切换
    if (readTimeSortKey === "down") nextSort = "up";
    else if (readTimeSortKey === "up") nextSort = "default";
    else nextSort = "down";
    //修改当前排序方式
    setReadTimeSortKey(nextSort);
    if (nextSort === "up") {
      _siftData.isLastReadTime = 2;
    } else if (nextSort === "down") {
      _siftData.isLastReadTime = 1;
    } else {
      _siftData.isLastReadTime = null;
    }
    setSiftData(_siftData);
  };
  // 页码变化时
  const changePage = (page, pageSize) => {
    let _siftData = { ...siftData };
    _siftData.pageIndex = page;
    _siftData.pageSize = pageSize;
    setSiftData(_siftData);
  };
  const toSearch = (value) => {
    let _siftData = { ...siftData };
    _siftData.pageIndex = 1;
    _siftData.knowledgeName = value;
    setSiftData(_siftData);
  };
  return (
    <div className="myReadFrame">
      <div className="tabs">
        {/* {isSpinning ? (
          <Spin />
        ) : ( */}
          <KnowledgeTypeTab
            tabsData={tabsData}
            tabsKey={tabsKey}
            tabsChange={tabsChange}
            toSearch={toSearch}
            searchIsNull={false}
          ></KnowledgeTypeTab>
        {/* )} */}
      </div>

      <div className="content">
        <div className="sort">
          <div className="progressSort" onClick={progressSort}>
            按进度排序
            <div className="icon">
              <CaretUpFilled
                style={{ color: progressSortKey == "up" ? "#0099cc" : null }}
              />
              <CaretDownFilled
                style={{ color: progressSortKey == "down" ? "#0099cc" : null }}
              />
            </div>
          </div>
          <div className="readTimeSort" onClick={readTimeSort}>
            按阅读时间排序
            <div className="icon">
              <CaretUpFilled
                style={{ color: readTimeSortKey == "up" ? "#0099cc" : null }}
              />
              <CaretDownFilled
                style={{ color: readTimeSortKey == "down" ? "#0099cc" : null }}
              />
            </div>
          </div>
        </div>

        <div className="readView" style={{height:listHight}}>
          {isSpinning ? (
            <Spin size="large" />
          ) : (
            <>
              {readList.length > 0 ? (
                <KnowledgeItem list={readList} type={"read"}></KnowledgeItem>
              ) : (
                <div className="null">
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </>
          )}
        </div>

        <div className="pager">
          {data.total > 10 ? (
            <SelfPagination
              current={siftData.pageIndex}
              onSizeChange={changePage}
              total={data.total * 1}
            ></SelfPagination>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default myRead;
