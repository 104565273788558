import myAxios from "../utils/request.js";

const prefixUrl = "/chat/third";

export default {
    // 获取该用户的智能问答和知识问答ID
    async getChatId (params={}, cb) {
        let res = await myAxios.getJson(`${prefixUrl}/sys/get/chatIds`, params);
        return res.data;
    },

    // 检查知识库是否为空
    async checkKnowledgeLibrary (params={}, cb) {
        let res = await myAxios.getJson(`${prefixUrl}/sys/check/knowledge`, params);
        return res.data;
    },

    // 查询问答 聊天记录列表
    async getChatList (params={}, cb) {
        let res = await myAxios.getJson(`${prefixUrl}/sys/list/chat`, params);
        return res.data;
    },

    // 点赞 / 点踩
    async changeMessageAttitude (params={}, cb) {
        let res = await myAxios.postJson(`${prefixUrl}/sys/edit/chatMessage`, params);
        return res.data;
    },

    // 停止回答
    async stopAnswer (params={}, cb) {
        let res = await myAxios.postJson(`${prefixUrl}/sys/stop/chat`, params);
        return res.data;
    },

    // 获取 wps 文件连接
    async getWPSFilePreview (params={}, cb) {
        let res = await myAxios.getJson(`/chat/files/${params.fileId}/previewUrl`, params);
        delete params.fileId;
        return res.data;
    },





    /*
        * AI-xwx
    */ 
    // 获取对话列表 
    async getDialogueRecord (params={}, cb) {
        let res = await myAxios.postJson(`/sys/delete/chat`, params);
        return res.data;
    },

    // 结束当前话题
    async endTalk (params={}, cb) {
        let res = await myAxios.postJson(`/largeModelQa/endQA`, params);
        return res.data;
    },
    
    // 删除对话记录
    async deleteDialogueRecord (params={}, cb) {
        let res = await myAxios.postJson(`/sys/delete/chat`, params);
        return res.data;
    },
    // 根据问答记录id获取问答记录详细内容
    async getHistoryLogDetail (params={}, cb) {
        let res = await myAxios.postJson(`/largeModelQaLog/getHistoryLogDetail`, params);
        return res.data;
    },
    
    // 优化提问
    async optimizeQuestion (params={}, cb) {
        let res = await myAxios.postJson(`/largeModelQa/optimizeQuestion`, params);
        return res.data;
    },
    
    // 获取问答记录列表
    async getHistoryLog (params={}, cb) {
        let res = await myAxios.postJson(`/largeModelQaLog/getHistoryLog`, params);
        return res.data;
    },
    // 新建对话
    async createQa (params={}, cb) {
        let res = await myAxios.postJson(`/largeModelQa/createQa`, params);
        return res.data;
    },

    // 新增问答记录关联的知识
    async addQaKnowledge (params={}, cb) {
        let res = await myAxios.postJson(`/largeModelQa/addQaKnowledge`, params);
        return res.data;
    },
    // 修改问答记录关联的知识
    async updateQaKnowledge (params={}, cb) {
        let res = await myAxios.postJson(`/largeModelQa/updateQaKnowledge`, params);
        return res.data;
    },
    

    // 根据问答记录id获取知识列表
    async getKnowledgeList (params={}, cb) {
        let res = await myAxios.getJson(`/largeModelQaLog/getKnowledgeList`, params);
        return res.data;
    },
    
    // 更新问答记录标题
    async updateTitle (params={}, cb) {
        let res = await myAxios.getJson(`/largeModelQaLog/updateTitle`, params);
        return res.data;
    },
    // 删除问答记录
    async removeTitle (params={}, cb) {
        let res = await myAxios.getJson(`largeModelQaLog/remove`, params);
        return res.data;
    },
    // 删除问答记录的知识
    async deleteQaKnowledge (params={}, cb) {
        let res = await myAxios.postJson(`/largeModelQa/deleteQaKnowledge`, params);
        return res.data;
    },
    
    // 0点赞，1 点踩
    async evaluateAnswer (params={}, cb) {
        let res = await myAxios.getJson(`/largeModelQaLog/evaluate`, params);
        return res.data;
    },

    // 查询购物车的知识列表
    async pageQaCart (params={}, cb) {
        let res = await myAxios.postJson(`/qaCart/pageQaCart`, params);
        return res.data;
    },
    // 添加购物车
    async addCart (params={}, cb) {
        let res = await myAxios.postJson(`/qaCart/addCart`, params);
        return res.data;
    },
    
    async removeCart (params={}, cb) {
        let res = await myAxios.postJson(`/qaCart/removeCart`, params);
        return res.data;
    },

    // 查询我创作的（作者中有我的）
    async getPageMyCreate (params={}, cb) {
        let res = await myAxios.postJson(`/qaCart/pageMyCreate`, params);
        return res.data;
    },
    // AI问答助手 - 对话知识详情列表
    // async getQaDetailList (params={}, cb) {
    //     let res = await myAxios.postJson(`/qaCart/pageQaDetail`, params);
        
    // }

    // 根据问答详细记录id、sources值获取知识id以及页数等信息
    async getContentByDetailIdAndSource (params={}, cb) {
        let res = await myAxios.getJson('/largeModelQa/getContentByDetailIdAndSource', params);
        return res.data;
    },
};
