import React from 'react';
import PropTypes from 'prop-types';

const Image = ({
    imgSrc,
    iStyle,
    iClassName,
    alt,
    text,
    onClick
}) => {
    return (<div className={['imgBox', iClassName].join(" ")} style={iStyle} onClick={onClick}><img src={imgSrc} alt={alt}/>{text ? <span>{text}</span> : ""}</div>)
}

Image.defaultProps = {
    iStyle: {},
    iClassName: '',
    alt: '',
};
Image.propTypes = {
    imgSrc: PropTypes.any.isRequired,
    iClassName: PropTypes.string,
    alt: PropTypes.string,
    text: PropTypes.string,
    iStyle: PropTypes.object,
};

export default Image;